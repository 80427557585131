import * as React from 'react';

import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { toExcel } from 'to-excel';

export interface IHeader {
    /** Sets the column label. Required */
    label: string;
    /** Sets the data field identifier. Required */
    field: string;
    /** Sets the type data of the column (String, Number, etc.). Optional */
    type?: string;
}

export interface exportXLSParams {
    headers: IHeader[];
    data: unknown[];
    filename?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {},
    button: {}
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    disabled: boolean;
    onClick: () => Promise<exportXLSParams>;
}

class ExcelButton extends React.Component<Props & ButtonProps> {
    public static defaultProps = {
        disabled: false
    };

    onExportToExcelClicked = () => {
        const { onClick } = this.props;

        if (!onClick) {
            return;
        }

        onClick().then((params: exportXLSParams) => {
            const { headers, data, filename } = params;
            if (filename) {
                toExcel.exportXLS(headers, data, filename);
            } else {
                toExcel.exportXLS(headers, data);
            }
        }).catch(() => {
            console.error('ERROR - Cannot export to Excel file');
        });
    };

    public render() {
        const {
            classes,
            className,
            children,
            ...buttonProps
        } = this.props;
        const mainClasses = `${classes.root}${className ? ` ${className}` : ''}`;
        // Remove unnecessary props to avoid bind with javascript events
        const buttonPropsUpdated = {...buttonProps};
        
        return (
            <div className={mainClasses}>
                <Button
                    {...buttonPropsUpdated}
                    className={classes.button}
                    onClick={this.onExportToExcelClicked}
                >
                    {children}
                </Button>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(ExcelButton);
export {MUIComponent as ExcelButton};
