import * as React from 'react';

import {
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
    WidgetData,
    WidgetDataPhotoProperties,
    WidgetDataPickerProperties,
    WidgetDataTextProperties,
    WidgetDataToggleProperties,
    WidgetDataValues,
    WidgetType,
} from '../../models/report';
import memoize from 'memoize-one';
import { WidgetContentGeneratorInstanceType } from '../work-order-details';

export enum WidgetContentGeneratorType {
    Read = 0,
    Write,
}

export interface WidgetPhotoData {
    height: number;
    imageEncoded: string;
    timeStamp: string;
    width: number;
    format: string;
}

export interface WidgetDataForPrinting {
    widgetType: WidgetType;
    data: string | WidgetPhotoData;
}

interface WidgetValidationData {
    widgetType: WidgetType;
    isValid: boolean;
}

interface WidgetValidationList {
    [key: string]: WidgetValidationData;
}

const regexImageFormat = /^data:image\/([a-zA-Z0-9])+/;

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            minHeight: '5em',
            width: '100%',
            padding: '0 0.5em',
        },
        widgetLabel: {},
        widgetTextFieldRead: {},
        widgetTextFieldWrite: {
            flex: 1,
            minHeight: '3em',
        },
        widgetTextFieldInput: {
            flex: 1,
            alignItems: 'stretch',
            padding: '0.5em',
            minHeight: 0,
            lineHeight: 1.5,
        },
        widgetPhotoRead: {},
        widgetToggle2WaysContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'center',
        },
        widgetToggle2WaysColorBase: {
            '&$widgetToggle2WaysColorChecked': {
                color: theme.ccPalette.cc_colors.solid.ccLeftsilhouette.main,
                '& + $widgetToggle2WaysColorBar': {
                    backgroundColor:
                        theme.ccPalette.cc_colors.solid.ccLeftsilhouette.main,
                },
            },
        },
        widgetToggle2WaysColorBar: {},
        widgetToggle2WaysColorChecked: {},
        widgetToggleMulti: {
            alignSelf: 'center',
        },
    });

interface Props extends WithStyles<typeof styles> {
    className?: string;
    generatorType: WidgetContentGeneratorType;
    mandatoryFields?: string[];
    values?: WidgetDataValues;
    timeStamp?: string;
    widgets: WidgetData[] | undefined;
    onValidationChange?: (isValid: boolean) => void;
    onWidgetChange?: (widgetId: string, value: string) => void;
    innerRef?: (element: WidgetContentGeneratorInstanceType) => void;
}

interface States {
    isFormValid: boolean;
}

class WidgetContentGenerator extends React.Component<Props, States> {
    public static defaultProps = {};

    state = {
        isFormValid: false,
    };

    widgetValidationList: WidgetValidationList = {} as WidgetValidationList;

    componentDidMount() {
        this.refreshWidgetValidationList();
        if (this.props?.innerRef) {
            this.props.innerRef(this as unknown as WidgetContentGeneratorInstanceType);
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { mandatoryFields, values, widgets } = this.props;
        if (
            mandatoryFields !== prevProps.mandatoryFields ||
            values !== prevProps.values ||
            widgets !== prevProps.widgets
        ) {
            this.refreshWidgetValidationList();
        }
    }

    refreshWidgetValidationList = () => {
        const { mandatoryFields, values, widgets } = this.props;

        // Generate the validation list
        this.widgetValidationList = this.generateWidgetValidationList(
            mandatoryFields,
            values,
            widgets
        );

        // Validate the validation list
        this.checkValidation();
    };

    generateWidgetValidationList = memoize(
        (
            mandatoryFields?: string[],
            values?: WidgetDataValues,
            widgets?: WidgetData[]
        ) => {
            const { onWidgetChange } = this.props;

            if (!widgets) {
                return {} as WidgetValidationList;
            }

            const list = widgets.reduce((validationList, widget) => {
                const { properties, type: widgetType } = widget;

                switch (widgetType) {
                    case WidgetType.TextField: {
                        const { widgetId } =
                            properties as WidgetDataTextProperties;
                        if (widgetId) {
                            const isMandatory = mandatoryFields
                                ? mandatoryFields.indexOf(widgetId) >= 0
                                : false;
                            const widgetValue =
                                values && values[widgetId]
                                    ? values[widgetId]
                                    : undefined;
                            const isValid =
                                !isMandatory || // If it is mandatory isn't validated yet, if it is not mandatory it is already validated
                                (!!widgetValue &&
                                    widgetValue.trim().length > 0);
                            // Add the widget id to the list for validation
                            const validationData = {
                                widgetType,
                                isValid,
                            } as WidgetValidationData;
                            validationList[widgetId] = validationData;
                        }
                        break;
                    }
                    case WidgetType.Toggle: {
                        const { selectedIndex, segmentValues, widgetId } =
                            properties as WidgetDataToggleProperties;
                        if (widgetId) {
                            const isMandatory = mandatoryFields
                                ? mandatoryFields.indexOf(widgetId) >= 0
                                : false;
                            const isSelectedIndexExisting =
                                selectedIndex !== undefined &&
                                selectedIndex >= 0 &&
                                selectedIndex < segmentValues.length;
                            const defaultValue =
                                isSelectedIndexExisting &&
                                selectedIndex !== undefined
                                    ? segmentValues[selectedIndex]
                                    : '';
                            const widgetValue =
                                values && values[widgetId]
                                    ? values[widgetId]
                                    : defaultValue !== ''
                                        ? defaultValue
                                        : '';
                            // If there is a selected index the field is validated
                            const isDefaultValueExisting =
                                isSelectedIndexExisting ||
                                // If there is a value that corresponds to the possible values
                                // then the field is validated
                                segmentValues.indexOf(widgetValue) >= 0;
                            const isValid =
                                !isMandatory || // If it is mandatory isn't validated yet, if it is not mandatory it is already validated
                                isDefaultValueExisting;
                            // Add the widget id to the list for validation
                            const validationData = {
                                widgetType,
                                isValid,
                            } as WidgetValidationData;
                            validationList[widgetId] = validationData;
                            // Set default value for toggle
                            if (onWidgetChange && isDefaultValueExisting) {
                                onWidgetChange(widgetId, widgetValue);
                            }
                        }
                        break;
                    }
                    case WidgetType.Picker: {
                        const { widgetId } =
                            properties as WidgetDataPickerProperties;
                        if (widgetId) {
                            const isMandatory = mandatoryFields
                                ? mandatoryFields.indexOf(widgetId) >= 0
                                : false;
                            const widgetValue =
                                values && values[widgetId]
                                    ? values[widgetId]
                                    : undefined;
                            const isValid =
                                !isMandatory || // If it is mandatory isn't validated yet, if it is not mandatory it is already validated
                                (!!widgetValue &&
                                    widgetValue.trim().length > 0);
                            // Add the widget id to the list for validation
                            const validationData = {
                                widgetType,
                                isValid,
                            } as WidgetValidationData;
                            validationList[widgetId] = validationData;
                        }
                        break;
                    }
                    default:
                        break;
                }
                return validationList;
            }, {} as WidgetValidationList);

            return list;
        }
    );

    setFormValidation = (isValid: boolean) => {
        const { isFormValid } = this.state;
        if (isValid === isFormValid) {
            return;
        }

        this.setState({ isFormValid: isValid });

        const { onValidationChange } = this.props;
        if (onValidationChange) {
            onValidationChange(isValid);
        }
    };

    checkValidation = () => {
        const { props, widgetValidationList } = this;
        const { mandatoryFields } = props;
        if (!mandatoryFields) {
            return;
        }

        const notValidWidgetId = mandatoryFields.find((widgetId) => {
            const res =
                widgetValidationList[widgetId] !== undefined &&
                widgetValidationList[widgetId].isValid !== true;
            return res;
        });

        // Form is valid if no not-valid widgets were found
        const isFormValidNewValue = !notValidWidgetId;
        this.setFormValidation(isFormValidNewValue);
    };

    renderLabelOrTextFieldWidget = (
        reactKey: string,
        widget: WidgetData,
        renderForPrinting = false
    ): React.ReactNode | WidgetDataForPrinting => {
        const { type: widgetType, properties } = widget;

        if (!properties) {
            return renderForPrinting
                ? ({} as WidgetDataForPrinting)
                : ('' as React.ReactNode);
        }
        const { classes, generatorType, values } = this.props;
        const { placeholder, text, widgetId } =
            properties as WidgetDataTextProperties;
        const isWidgetLabel = widgetType === WidgetType.Label;

        if (
            isWidgetLabel ||
            generatorType === WidgetContentGeneratorType.Read
        ) {
            if (renderForPrinting) {
                return {
                    widgetType,
                    data: text,
                } as WidgetDataForPrinting;
            }

            return (
                <Typography
                    key={reactKey}
                    className={
                        isWidgetLabel
                            ? classes.widgetLabel
                            : classes.widgetTextFieldRead
                    }
                    variant={'body2'}
                >
                    {text}
                </Typography>
            );
        }

        // If we hit this line it means that the generator is
        // in write mode and we need to render the textfield.

        // Render for printing doesn't support write mode.
        if (renderForPrinting) {
            return {} as WidgetDataForPrinting;
        }

        if (!widgetId) {
            // If the widget doesn't have an id it cannot be rendered
            return '' as React.ReactNode;
        }

        const widgetValue = values ? values[widgetId] : '';
        return (
            <TextField
                className={classes.widgetTextFieldWrite}
                InputProps={{
                    className: classes.widgetTextFieldInput,
                }}
                key={reactKey}
                multiline={true}
                rows="10"
                placeholder={
                    placeholder ? placeholder : 'Please describe the issue...'
                }
                margin="normal"
                variant="outlined"
                value={widgetValue}
                onChange={this.onWidgetChanged(widgetId, widgetType)}
            />
        );
    };

    renderPhotoWidget = (
        reactKey: string,
        widget: WidgetData,
        renderForPrinting = false
    ): React.ReactNode | WidgetDataForPrinting => {
        const { properties, type: widgetType } = widget;
        const { height, imageEncoded, width } =
            properties as WidgetDataPhotoProperties;
        if (!imageEncoded) {
            return renderForPrinting
                ? ({} as WidgetDataForPrinting)
                : ('' as React.ReactNode);
        }

        const { classes, generatorType, timeStamp } = this.props;

        if (generatorType === WidgetContentGeneratorType.Read) {
            if (renderForPrinting) {
                const searchArray = regexImageFormat.exec(imageEncoded);
                const typeElements =
                    !!searchArray && searchArray.length > 0
                        ? /\/(.+)/.exec(searchArray[0])
                        : [];
                const format =
                    !!typeElements && typeElements.length > 1
                        ? typeElements[1]
                        : '';

                return {
                    widgetType,
                    data: {
                        height,
                        imageEncoded,
                        format,
                        timeStamp,
                        width,
                    } as WidgetPhotoData,
                } as WidgetDataForPrinting;
            }

            return (
                <div key={reactKey}>
                    <img
                        alt="snapshot"
                        className={classes.widgetPhotoRead}
                        src={imageEncoded}
                    />
                </div>
            );
        }

        // Photo widget it is not supported for writing.
        return renderForPrinting
            ? ({} as WidgetDataForPrinting)
            : ('' as React.ReactNode);
    };

    renderToggle = (
        reactKey: string,
        widget: WidgetData,
        renderForPrinting = false
    ): React.ReactNode | WidgetDataForPrinting => {
        const { type: widgetType, properties } = widget;

        const { segmentTitles, segmentValues, selectedIndex, widgetId } =
            properties as WidgetDataToggleProperties;
        if (!widgetId) {
            return renderForPrinting
                ? ({} as WidgetDataForPrinting)
                : ('' as React.ReactNode);
        }

        const { classes, generatorType, values } = this.props;

        if (
            renderForPrinting ||
            generatorType === WidgetContentGeneratorType.Read
        ) {
            return renderForPrinting
                ? ({} as WidgetDataForPrinting)
                : ('' as React.ReactNode);
        }

        const widgetValue = values ? values[widgetId] : '';
        const widgetValueIndex = segmentValues.indexOf(widgetValue);
        const selectedValueIndex =
            widgetValueIndex >= 0 ? widgetValueIndex : selectedIndex;
        const valuesCount = segmentValues.length;
        const isChecked = selectedValueIndex === 0;

        // Check if the toggle is a classic two way switch
        if (valuesCount < 3) {
            return (
                <div
                    key={reactKey}
                    className={classes.widgetToggle2WaysContainer}
                >
                    <FormLabel focused={false} component="label">
                        {segmentTitles[1]}
                    </FormLabel>
                    <Switch
                        classes={{
                            switchBase: classes.widgetToggle2WaysColorBase,
                            checked: classes.widgetToggle2WaysColorChecked,
                            track: classes.widgetToggle2WaysColorBar,
                        }}
                        checked={isChecked}
                        onChange={this.onWidgetChanged(widgetId, widgetType)}
                        value={isChecked ? segmentValues[1] : segmentValues[0]}
                    />
                    <FormLabel focused={false} component="label">
                        {segmentTitles[0]}
                    </FormLabel>
                </div>
            );
        }

        // The toggle is a radio group
        const selectedValue =
            selectedValueIndex !== undefined && selectedValueIndex >= 0
                ? segmentValues[selectedValueIndex]
                : '';

        return (
            <RadioGroup
                key={reactKey}
                aria-label={widgetId}
                name={widgetId}
                className={classes.widgetToggleMulti}
                value={selectedValue}
                onChange={this.onWidgetChanged(widgetId, widgetType)}
                row={true}
            >
                {segmentValues.map((value: string, index: number) => {
                    return (
                        <FormControlLabel
                            key={`${reactKey}-${index}`}
                            value={value}
                            control={<Radio color="secondary" />}
                            label={segmentTitles[index]}
                            labelPlacement="end"
                        />
                    );
                })}
            </RadioGroup>
        );
    };

    renderPicker = (
        reactKey: string,
        widget: WidgetData,
        renderForPrinting = false
    ): React.ReactNode | WidgetDataForPrinting => {
        const { type: widgetType, properties } = widget;

        const { pickerValues, widgetId } =
            properties as WidgetDataPickerProperties;
        if (!widgetId) {
            return renderForPrinting
                ? ({} as WidgetDataForPrinting)
                : ('' as React.ReactNode);
        }

        const {
            // classes,
            generatorType,
            values,
        } = this.props;

        if (
            renderForPrinting ||
            generatorType === WidgetContentGeneratorType.Read
        ) {
            return renderForPrinting
                ? ({} as WidgetDataForPrinting)
                : ('' as React.ReactNode);
        }

        const PLACEHOLDER_TEXT = 'Select an Option';
        const widgetValue = values ? values[widgetId] : '';

        return (
            <Select
                key={reactKey}
                name={widgetId}
                value={widgetValue}
                placeholder={PLACEHOLDER_TEXT}
                displayEmpty={true}
                onChange={
                    this.onWidgetChanged(widgetId, widgetType) as (
                        event: SelectChangeEvent<string>,
                        child: React.ReactNode
                    ) => void
                }
            >
                {[
                    <MenuItem key={`${reactKey}-0`} value="" disabled={true}>
                        {PLACEHOLDER_TEXT}
                    </MenuItem>,
                ].concat(
                    pickerValues.map((value: string, index: number) => {
                        return (
                            <MenuItem
                                key={`${reactKey}-${index + 1}`}
                                value={value}
                            >
                                {value}
                            </MenuItem>
                        );
                    })
                )}
            </Select>
        );
    };

    renderWidgetChildren = (
        renderForPrinting: boolean
    ): React.ReactNode | WidgetDataForPrinting[] => {
        const { widgets } = this.props;

        if (!widgets) {
            return renderForPrinting
                ? ([] as WidgetDataForPrinting[])
                : ('' as React.ReactNode);
        }

        const children = widgets.map((widget: WidgetData, index: number) => {
            const { type: widgetType } = widget;
            const reactKey = `${WidgetType[widgetType]}-${index}`;
            switch (widgetType) {
                case WidgetType.Label:
                case WidgetType.TextField:
                    return this.renderLabelOrTextFieldWidget(
                        reactKey,
                        widget,
                        renderForPrinting
                    );
                case WidgetType.Photo:
                    return this.renderPhotoWidget(
                        reactKey,
                        widget,
                        renderForPrinting
                    );
                case WidgetType.Toggle:
                    return this.renderToggle(
                        reactKey,
                        widget,
                        renderForPrinting
                    );
                case WidgetType.Picker:
                    return this.renderPicker(
                        reactKey,
                        widget,
                        renderForPrinting
                    );
                default:
                    return renderForPrinting
                        ? ([] as WidgetDataForPrinting[])
                        : ('' as React.ReactNode);
            }
        });

        // Filter the empty values returned during the conversion
        return children.filter((child: unknown) => {
            // Some react nodes throw a circular reference error when stringified
            try {
                const childStringified = JSON.stringify(child);
                return (
                    !!child &&
                    childStringified !== JSON.stringify([]) &&
                    childStringified !== JSON.stringify({})
                );
            } catch (e) {
                return true; // If there was some conversion error we assume that the object isn't empty.
            }
        }) as React.ReactNode | WidgetDataForPrinting[];
    };

    public generateOutputForPrint = () => {
        return this.renderWidgetChildren(true) as WidgetDataForPrinting[];
    };

    renderWidgetChildrenForScreen = (): React.ReactNode => {
        return this.renderWidgetChildren(false) as React.ReactNode;
    };

    //#region Widgets Events
    onWidgetChanged =
        (widgetId: string, widgetType: WidgetType) => (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
            }>
        ) => {
            const finalEvent = event as React.ChangeEvent<
                HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
            >;
            const widgetValue = finalEvent.target.value;
            const { mandatoryFields, onWidgetChange } = this.props;
            const isMandatory = mandatoryFields
                ? mandatoryFields.indexOf(widgetId) >= 0
                : false;

            if (isMandatory) {
                let isValid: boolean;
                switch (widgetType) {
                    case WidgetType.TextField:
                    case WidgetType.Picker:
                        isValid = widgetValue.length > 0;
                        break;
                    case WidgetType.Toggle:
                    default:
                        isValid = true;
                        break;
                }
                this.widgetValidationList[widgetId].isValid = isValid;
            }

            this.checkValidation();

            if (onWidgetChange) {
                onWidgetChange(widgetId, widgetValue);
            }
        };

    //#endregion

    public render() {
        const { classes, className, generatorType } = this.props;
        const rootClasses = `${classes.root}${
            className ? ` ${className}` : ''
        }`;
        const children: React.ReactNode = this.renderWidgetChildrenForScreen();

        return generatorType === WidgetContentGeneratorType.Read ? (
            <div className={rootClasses}>{children}</div>
        ) : (
            <form className={rootClasses}>{children}</form>
        );
    }
}

const MUIComponent = withStyles(styles)(WidgetContentGenerator);
export { MUIComponent as WidgetContentGenerator };
