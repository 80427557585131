import * as React from 'react';

import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import Papa from 'papaparse';
import saveCsv from 'save-csv'; // This import requires to add the flag '"esModuleInterop": true' to tsconfig.json
import { ExportToCSVData } from './export-to-csv-data';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {},
    button: {},
    input: {
        display: 'none',
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    disabled: boolean;
    importData: boolean;
    onClick?: () => Promise<ExportToCSVData | undefined>;
    onCSVImport?: (parsedCSVFileData: string[][], csvFile?: unknown) => void;
    onLoading?: (isLoading: boolean) => void;
    inputRef?: (element: HTMLInputElement) => void;
}

class CSVButton extends React.Component<Props & ButtonProps> {
    public static defaultProps = {
        disabled: false,
        importData: false
    };
    
    OnImportFromCSVClicked = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files || !files.length) {
            return;
        }

        const { onLoading } = this.props;
        if (onLoading) {
            onLoading(true);
        }

        const csvFile = files[0];
        Papa.parse(csvFile, {
            complete: this.OnCSVParsed,
            header: true,
            transformHeader: this.OnCsvTransformHeader,
            skipEmptyLines: 'greedy'
        });
    };
    
    OnCsvTransformHeader = (header: string): string => {
        return header
            .replace(new RegExp(' ', 'g'), '_')
            .toLocaleLowerCase();
    };

    OnCSVParsed = (results: Papa.ParseResult<string[]>, csvFile?: unknown) => {
        const { data: parsedCSVFileData } = results;
        const {
            onCSVImport,
            onLoading
        } = this.props;

        if (onLoading) {
            onLoading(false);
        }

        if (onCSVImport) {
            onCSVImport(parsedCSVFileData, csvFile);
        }
    };

    onExportToCSVClicked = () => {
        const { onClick } = this.props;
        if (!onClick) {
            return;
        }

        onClick()
            .then((results: ExportToCSVData | undefined) => {
                if (!results) {
                    return;
                }

                
                const {
                    data,
                    fileName
                } = results;
                if (!data || (data.length < 1)) {
                    return;
                }
                
                const filename = fileName ? fileName : 'noName.csv';
                saveCsv(data, {
                    filename
                });
            })
            .catch(()=> {
                return;
            });
    };

    public render() {
        const {
            classes,
            className,
            children,
            importData,
            inputRef,
            ...buttonProps
        } = this.props;
        const mainClasses = `${classes.root}${className ? ` ${className}` : ''}`;
        // Remove unnecessary props to avoid bind with javascript events
        const buttonPropsUpdated = {...buttonProps};
        delete buttonPropsUpdated.onCSVImport;
        delete buttonPropsUpdated.onLoading;
        
        return (
            <div className={mainClasses}>
                { importData ?
                    <input
                        id="csv-button"
                        className={classes.input}
                        accept=".csv"
                        type="file"
                        onChange={this.OnImportFromCSVClicked}
                        ref={inputRef}
                    />
                    :''
                }
                { importData ?
                    <label htmlFor="csv-button">
                        <Button
                            {...buttonPropsUpdated}
                            className={classes.button}
                            component="span"
                        >
                            {children}
                        </Button>
                    </label>
                    :
                    <Button
                        {...buttonPropsUpdated}
                        className={classes.button}
                        onClick={this.onExportToCSVClicked}
                    >
                        {children}
                    </Button>
                }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(CSVButton);
export {MUIComponent as CSVButton};
