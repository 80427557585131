export class SimpleListItem {
    constructor (
        public readonly id: string,
        public readonly label: string,
        public readonly secondaryLabel?: string | React.ReactNode,
        public readonly icon?: React.ReactChild,
        public readonly hasProgress?: boolean,
        public readonly progress?: number,
        public readonly data?: {[key:string]: string}
    ) {}
}