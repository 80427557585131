import { ObjectValueUserTeam } from './user.entity';

export class UserTeam {
    static create(userData: ObjectValueUserTeam): UserTeam {
        const { 
            team_id: teamId, 
            timestamp: timestampString,
            name,
            portal
        } = userData;
        const timestamp = new Date(timestampString);
        return new UserTeam (teamId, timestamp, portal, name);
    }

    static createArray(userDataArray: ObjectValueUserTeam[]) : UserTeam[] {
        const userTeams:UserTeam[] = [];

        for(const userTeam of userDataArray) {
            userTeams.push(this.create(userTeam));
        }

        return userTeams;
    }

    static createFromSerializedData(serializedData: string): UserTeam | undefined {
        try {
            const data = JSON.parse(serializedData);
            const {
                name,
                portal,
                teamId,
                timestamp: serializedTimeStamp
            } = data;
            
            const timestamp = new Date(serializedTimeStamp);
            return new UserTeam (teamId, timestamp, portal, name);
        } catch(e) {
            return;
        }
    }

    constructor (
        public readonly teamId: string,
        public readonly timestamp: Date,
        public readonly portal: boolean,
        public readonly name?: string
    ) {}

    public serialize(): string {
        const {
            name,
            portal,
            teamId,
            timestamp
        } = this;

        const objectToSerialize = {
            portal,
            teamId,
            timestamp: timestamp.getTime()
        };
        if (name) {
            Object.assign(objectToSerialize, {name});
        }

        return JSON.stringify(objectToSerialize);
    }
}
