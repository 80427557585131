import { createTheme } from '@mui/material/styles';
import { Theme, ThemeOptions } from '@mui/material/styles';
import { PaletteColor, PaletteColorOptions } from '@mui/material/styles/createPalette';

// Extends the Theme interface using Typescript composition
declare module '@mui/material/styles' {
    interface Theme {
        ccPalette: CCPalette;
    }

    interface ThemeOptions {
        ccPalette?: CCPaletteOptions;
    }
}

// Extends the Breakpoint interface using Typescript composition - createBreakpoints
declare module '@mui/material/styles' {
    interface BreakpointOverrides {
      iPhone8: true; // adds the 'iPhone8' breakpoint
      iPhone8_Landscape: true; // adds the 'iPhone8_Landscape' breakpoint
      iPhone12ProMax: true;
      iPhone12ProMax_Landscape: true;
    }
}

export interface CCPaletteColor extends PaletteColor {
    [key: string]: string;
}

// New Them property for CrowdComfort Palette
export interface CCPalette {
    disabled: CCPaletteColor,
    cancel: CCPaletteColor,
    warning: CCPaletteColor,
    cc_colors: {
        solid: {
            ccLeftsilhouette: CCPaletteColor,
            ccCentersilhouette: CCPaletteColor,
            ccRightsilhouette: CCPaletteColor
        },
        translucent: {
            ccLeftsilhouette: CCPaletteColor,
            ccCentersilhouette: CCPaletteColor,
            ccRightsilhouette: CCPaletteColor
        }
    }
}

export interface CCPaletteOptions {
    disabled: PaletteColorOptions,
    cancel: PaletteColorOptions,
    warning: PaletteColorOptions,
    cc_colors: {
        solid: {
            ccLeftsilhouette: PaletteColorOptions,
            ccCentersilhouette: PaletteColorOptions,
            ccRightsilhouette: PaletteColorOptions
        },
        translucent: {
            ccLeftsilhouette: PaletteColorOptions,
            ccCentersilhouette: PaletteColorOptions,
            ccRightsilhouette: PaletteColorOptions
        }
    }
}
export const CC_WARNING_RGB = '255, 160, 0';
export const CC_WARNING_RGB_HEX = '#ff9800';

export const CC_DISABLED = 'rgba(0,0,0,0.5)';
export const CC_DISABLED_DARK = 'rgba(0,0,0,0.8)';
export const CC_DISABLED_LIGHT = 'rgba(0,0,0,0.2)';

export const CC_RED_RGB = '255,15,0'; // Crowd Comfort Red decimal representation
export const CC_RED_RGB_HEX = '#ff0f00';// Crowd Comfort Red hexadecimal representation
export const CC_RED_DARK_RGB = '87, 6, 0'; // Crowd Comfort Dark Red decimal representation
export const CC_RED_DARK_RGB_HEX = '#570600';// Crowd Comfort Dark Red hexadecimal representation
export const CC_RED_LIGHT_RGB = '255, 207, 204'; // Crowd Comfort Light Red decimal representation
export const CC_RED_LIGHT_RGB_HEX = '#ffcfcc';// Crowd Comfort Light Red hexadecimal representation

export const CC_GREEN_RGB = '0,153,51'; // Crowd Comfort Green decimal representation
export const CC_GREEN_RGB_HEX = '#009933'; // Crowd Comfort Green hexadecimal representation

export const CC_BLUE_RGB = '4,135,184'; // Crowd Comfort Blue decimal representation
export const CC_BLUE_RGB_HEX = '#0487b8'; // Crowd Comfort Blue hexadecimal representation
export const CC_BLUE_DARK_RGB = '2,63,85'; // Crowd Comfort Dark Blue decimal representation
export const CC_BLUE_DARK_RGB_HEX = '#023f55'; // Crowd Comfort Light Blue hexadecimal representation
export const CC_BLUE_LIGHT_RGB = '205,241,254'; // Crowd Comfort Light Blue decimal representation
export const CC_BLUE_LIGHT_RGB_HEX = '#cdf1fe'; // Crowd Comfort Dark Blue hexadecimal representation

export const CC_GRAY_RGB = '213,213,213'; // Crowd Comfort Gray decimal representation
export const CC_GRAY_HEX = '#d5d5d5'; // Crowd Comfort Gray hexadecimal representation
export const CC_GRAY_EXTRA_LIGHT_HEX = '#fafafa'; // Crowd Comfort Extra Light Gray hexadecimal representation
export const CC_WHITE_HEX = '#fff'; // Crowd Comfort White hexadecimal representation
export const CC_BLACK_RGB = '0,0,0'; // Crowd Comfort Black decimal representation

export const RESPONSIVE_BREAKPOINTS = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
    iPhone8: 376,
    iPhone8_Landscape: 668,
    iPhone12ProMax: 429,
    iPhone12ProMax_Landscape: 927
};

export function getMUITheme(): Theme {
    const theme: ThemeOptions = {
        breakpoints: {
            values: RESPONSIVE_BREAKPOINTS
        },
        palette: {
            primary: {
                main: CC_GREEN_RGB_HEX,
                dark: `rgba(${CC_GREEN_RGB},0.8)`,
                light: `rgba(${CC_GREEN_RGB}, 0.5)`
            },
            secondary: {
                main: CC_BLUE_RGB_HEX,
                dark: `rgba(${CC_BLUE_RGB},0.8)`,
                light: `rgba(${CC_BLUE_RGB}, 0.5)`
            },
            info: {
                main: CC_GRAY_HEX,
                dark: `rgba(${CC_GRAY_RGB},0.8)`,
                light: `rgba(${CC_GRAY_RGB}, 0.5)`,
                contrastText: `rgba(${CC_BLACK_RGB}, 0.87)`
            },
            error: {
                main: CC_RED_RGB_HEX,
                dark: `rgba(${CC_RED_RGB},0.8)`,
                light: `rgba(${CC_RED_RGB}, 0.5)`
            },
            background: {
                default: CC_GRAY_EXTRA_LIGHT_HEX,
                paper: CC_WHITE_HEX
            }
        },
        ccPalette: {
            disabled: {
                main: CC_DISABLED,
                dark: CC_DISABLED_DARK,
                light: CC_DISABLED_LIGHT
            },
            cancel: {
                main: CC_RED_RGB_HEX
            },
            warning: {
                main: CC_WARNING_RGB_HEX
            },
            cc_colors: {
                solid: {
                    ccLeftsilhouette: {
                        main: CC_RED_RGB_HEX,
                        dark: CC_RED_LIGHT_RGB_HEX,
                        light: CC_RED_LIGHT_RGB_HEX
                    },
                    ccCentersilhouette: {
                        main: CC_GREEN_RGB_HEX
                    },
                    ccRightsilhouette: {
                        main: CC_BLUE_RGB_HEX,
                        dark: CC_BLUE_DARK_RGB_HEX,
                        light: CC_BLUE_LIGHT_RGB_HEX
                    }
                },
                translucent: {
                    ccLeftsilhouette: {
                        main: `rgba(${CC_RED_RGB},0.5)`,
                        dark: `rgba(${CC_RED_RGB},0.8)`,
                        light: `rgba(${CC_RED_RGB},0.2)`,
                        50: `rgba(${CC_RED_RGB},0.05)`,
                        100: `rgba(${CC_RED_RGB},0.1)`,
                        200: `rgba(${CC_RED_RGB},0.2)`,
                        300: `rgba(${CC_RED_RGB},0.3)`,
                        400: `rgba(${CC_RED_RGB},0.4)`,
                        500: `rgba(${CC_RED_RGB},0.5)`,
                        600: `rgba(${CC_RED_RGB},0.6)`,
                        700: `rgba(${CC_RED_RGB},0.7)`,
                        800: `rgba(${CC_RED_RGB},0.8)`,
                        900: `rgba(${CC_RED_RGB},0.9)`
                    },
                    ccCentersilhouette: {
                        main: `rgba(${CC_GREEN_RGB},0.5)`,
                        dark: `rgba(${CC_GREEN_RGB},0.8)`,
                        light: `rgba(${CC_GREEN_RGB},0.2)`,
                        50: `rgba(${CC_GREEN_RGB},0.05)`,
                        100: `rgba(${CC_GREEN_RGB},0.1)`,
                        200: `rgba(${CC_GREEN_RGB},0.2)`,
                        300: `rgba(${CC_GREEN_RGB},0.3)`,
                        400: `rgba(${CC_GREEN_RGB},0.4)`,
                        500: `rgba(${CC_GREEN_RGB},0.5)`,
                        600: `rgba(${CC_GREEN_RGB},0.6)`,
                        700: `rgba(${CC_GREEN_RGB},0.7)`,
                        800: `rgba(${CC_GREEN_RGB},0.8)`,
                        900: `rgba(${CC_GREEN_RGB},0.9)`
                    },
                    ccRightsilhouette: {
                        main: `rgba(${CC_BLUE_RGB},0.5)`,
                        dark: `rgba(${CC_BLUE_RGB},0.8)`,
                        light: `rgba(${CC_BLUE_RGB},0.2)`,
                        50: `rgba(${CC_BLUE_RGB},0.05)`,
                        100: `rgba(${CC_BLUE_RGB},0.1)`,
                        200: `rgba(${CC_BLUE_RGB},0.2)`,
                        300: `rgba(${CC_BLUE_RGB},0.3)`,
                        400: `rgba(${CC_BLUE_RGB},0.4)`,
                        500: `rgba(${CC_BLUE_RGB},0.5)`,
                        600: `rgba(${CC_BLUE_RGB},0.6)`,
                        700: `rgba(${CC_BLUE_RGB},0.7)`,
                        800: `rgba(${CC_BLUE_RGB},0.8)`,
                        900: `rgba(${CC_BLUE_RGB},0.9)`
                    }
                }
            }
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    outlinedInfo: {
                        color: `rgba(${CC_BLACK_RGB}, 0.87)`,
                        borderColor: `rgba(${CC_BLACK_RGB}, 0.23)`,
                        '&:hover': {
                            backgroundColor: `rgba(${CC_BLACK_RGB}, 0.04)`
                        }
                    },
                    textInfo: {
                        color: `rgba(${CC_BLACK_RGB}, 0.87)`,
                        '&:hover': {
                            backgroundColor: `rgba(${CC_BLACK_RGB}, 0.04)`
                        }
                    }
                }
            }
        }
    };
    return createTheme(theme);
}
