import * as React from 'react';

import {
    Button,
    Tooltip,
    Typography
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import {
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import { withPasswordShowHide } from '../with-password-show-hide';

const TOOLTIP_TEXT_PASSWORD = `Password must have at least 8 characters, including an uppercase letter,
a lowercase letter, a number, and a special character (!@#$%^&*()-_=+).`;

const TextValidatorWithPasswordShowHide = withPasswordShowHide(TextValidator);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        minWidth: '20em'
    },
    title: {},
    error: {
        fontSize: '1em',
        color: theme.ccPalette.cc_colors.solid.ccLeftsilhouette.main,
        marginBottom: '0.3em'
    },
    input: {
        height: '2.3em',
        padding: '0 1em',
        fontSize: '1em'
    },
    inputLabel: {
        fontSize: '1em',
        top: '-0.5em',
        '&.MuiFormLabel-filled': {
            top: '0'
        },
        '&.Mui-focused': {
            top: '0'
        }
    },
    formContainer: {},
    textField: {
        margin: '0.6em 0 0',
        width: '100%',
        '& .MuiInputBase-root': {
            marginBottom: '1.3em'
        }
    },
    tooltip: {
        fontSize: '0.9em'
    },
    validationError: {
        position: 'relative',
        marginTop: '-1.3em'
    },
    hidePasswordButton: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    submitButton: {
        marginTop: '1em'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    error?: string;
    title?: string;
    buttonText?: string;
    onChangePasswordSubmitted?: (oldPassword: string, newPassword: string) => void;
}

interface States {
    currentPassword: string;
    password: string;
    repeatPassword: string;
}

class PasswordChange extends React.Component<Props, States> {
    public static defaultProps = {
        buttonText: 'Change Password',
        title: 'Change Password'
    };

    state = {
        currentPassword: '',
        password: '',
        repeatPassword: ''
    };

    componentDidMount() {
        ValidatorForm.addValidationRule('isCurrentPasswordNotMatch', (value) => {
            return (value !== this.state.currentPassword);
        });

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            return (value === this.state.password);
        });

        ValidatorForm.addValidationRule('isPasswordRulesValid', (value) => {
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*)(\-_=+])/.test(value);
        });
    }

    onTextFieldChanged = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = {} as States;
        newState[name as keyof States] =  event.target.value as string;
        this.setState(newState);
    };

    onChangePasswordSubmitted = (event: React.FormEventHandler | React.SyntheticEvent<unknown>) => {
        const {
            onChangePasswordSubmitted
        } = this.props;
        const {
            currentPassword,
            password
        } = this.state;

        (event as React.SyntheticEvent<unknown>).preventDefault();

        if (onChangePasswordSubmitted) {
            onChangePasswordSubmitted(currentPassword, password);
        }
    };

    public render() {
        const {
            classes,
            className,
            error,
            buttonText,
            title
        } = this.props;
        const {
            currentPassword,
            password,
            repeatPassword
        } = this.state;

        const INPUT_PROPS_CLASSES = { 
            classes: { 
                input: classes.input
            }
        };
        const INPUT_LABEL_PROPS_CLASSES = {
            classes: {
                root: classes.inputLabel
            }
        };
        const FORM_HELPER_TEXT_PROPS = {
            classes: {
                error: classes.validationError
            }
        };

        const rootClassNames = classes.root + (className ? ` ${className}` : '');
        return (
            <div className={rootClassNames}>
                <Typography
                    variant="h6"
                    className={classes.title}
                >
                    {title}
                </Typography>
                <Typography
                    variant="h6"
                    className={classes.error}
                >
                    {((error!== undefined) && (error.length > 0)) ? error : '\u00a0'} {/* unicode non breaking space */}
                </Typography>
                <ValidatorForm
                    className={classes.formContainer}
                    instantValidate={true}
                    onSubmit={this.onChangePasswordSubmitted}
                >
                    <Tooltip
                        title={TOOLTIP_TEXT_PASSWORD}
                        classes={{tooltip: classes.tooltip}}
                    >
                        <>
                            <TextValidatorWithPasswordShowHide
                                id="currentPassword"
                                className={classes.textField}
                                iconClassName={classes.hidePasswordButton}
                                label="Current Password"
                                type="password"
                                name="currentPassword"
                                autoComplete="current-password"
                                margin="normal"
                                value={currentPassword}
                                onChange={this.onTextFieldChanged('currentPassword')}
                                InputProps={INPUT_PROPS_CLASSES}
                                InputLabelProps={INPUT_LABEL_PROPS_CLASSES}
                                FormHelperTextProps={FORM_HELPER_TEXT_PROPS}
                                validators={['required', 'minStringLength:8', 'isPasswordRulesValid']}
                                errorMessages={[
                                    'Password is required',
                                    'Must be at least 8 characters',
                                    'Must include an uppercase letter, a lowercase letter, a number, and a special character (!@#$%^&*()-_=+)'
                                ]}
                            />
                        </>
                    </Tooltip>
                    <Tooltip
                        title={TOOLTIP_TEXT_PASSWORD}
                        classes={{tooltip: classes.tooltip}}
                    >
                        <>
                            <TextValidatorWithPasswordShowHide
                                id="password"
                                className={classes.textField}
                                iconClassName={classes.hidePasswordButton}
                                label="New Password"
                                type="password"
                                name="password"
                                autoComplete="current-password"
                                margin="normal"
                                value={password}
                                onChange={this.onTextFieldChanged('password')}
                                InputProps={INPUT_PROPS_CLASSES}
                                InputLabelProps={INPUT_LABEL_PROPS_CLASSES}
                                FormHelperTextProps={FORM_HELPER_TEXT_PROPS}
                                validators={['required', 'minStringLength:8', 'isPasswordRulesValid', 'isCurrentPasswordNotMatch']}
                                errorMessages={[
                                    'Password is required',
                                    'Must be at least 8 characters',
                                    'Must include an uppercase letter, a lowercase letter, a number, and a special character (!@#$%^&*()-_=+)',
                                    'New password is the same as Current password'
                                ]}
                            />
                        </>
                    </Tooltip>
                    <Tooltip
                        title={TOOLTIP_TEXT_PASSWORD}
                        classes={{tooltip: classes.tooltip}}
                    >
                        <>
                            <TextValidatorWithPasswordShowHide
                                id="confirmPassword"
                                className={classes.textField}
                                iconClassName={classes.hidePasswordButton}
                                label="Confirm Password"
                                type="password"
                                name="confirmPassword"
                                autoComplete="current-password"
                                margin="normal"
                                value={repeatPassword}
                                onChange={this.onTextFieldChanged('repeatPassword')}
                                InputProps={INPUT_PROPS_CLASSES}
                                InputLabelProps={INPUT_LABEL_PROPS_CLASSES}
                                FormHelperTextProps={FORM_HELPER_TEXT_PROPS}
                                validators={['required', 'minStringLength:8', 'isPasswordMatch']}
                                errorMessages={[
                                    'Password is required',
                                    'Must be at least 8 characters',
                                    'Passwords do not match'
                                ]}
                            />
                        </>
                    </Tooltip>
                    <Button
                        className={classes.submitButton}
                        variant="contained"
                        color="secondary"
                        type="submit"
                    >
                        {buttonText}
                    </Button>
                </ValidatorForm>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(PasswordChange);
export {MUIComponent as PasswordChange};
