import { ManagerStore } from '../../stores/manager.store';
import {
    WidgetDataValues,
    ReportModuleGroup,
    ReportStatus,
    WidgetData
} from '../../models/report';
import { Marker } from '../../models/marker';

export class ReportsTabUtilities {
    constructor(
        public readonly managerStore: ManagerStore
    ) { }

    submitReport = (
        widgetDataValues: WidgetDataValues,
        selectedMarker: Marker | undefined,
        moduleGroup: ReportModuleGroup | undefined
    ): Promise<void> => {
        const { managerStore } = this;
        const {
            reportModules,
            // userModuleGroups
        } = managerStore;

        if (!selectedMarker || !moduleGroup) {
            return Promise.reject('Submit report call was invoked with invalid parameters.');
        }

        const reportModuleGroupId = moduleGroup.id.toLocaleLowerCase();
        // const reportModuleGroup: ReportModuleGroup | undefined =
        //     userModuleGroups.find((currentModuleGroup) => {
        //         return currentModuleGroup.id.toLocaleLowerCase().indexOf(reportModuleGroupId) >= 0;
        //     });

        // if (!reportModuleGroup) {
        //     return Promise.reject('User is not authorized to submit the report.');
        // }

        // Remove the blank fields from the payload
        const widgetValues: WidgetDataValues =
            Object.keys(widgetDataValues)
                .filter((key: string) => {
                    return !!widgetDataValues[key];
                })
                .reduce((newObject: WidgetDataValues, key: string) => {
                    newObject[key] = widgetDataValues[key];
                    return newObject;
                }, {} as WidgetDataValues);

        const URGENT_VALUE_NAME = 'toggle';
        const statusUnresolved = ReportStatus.Unresolved;
        const status = 
            (reportModuleGroupId === 'maintenance') ?// TO-DO: This should be moduleID
                ((widgetValues[URGENT_VALUE_NAME]) &&
                ((widgetValues[URGENT_VALUE_NAME] as string).toLocaleLowerCase() === 'yes')) ? 
                    ReportStatus.Urgent :
                    statusUnresolved
                : statusUnresolved;

        const currentReportModule = reportModules[0];
        const summary = WidgetData.parseTagsFromString(currentReportModule.summary, widgetValues);

        return managerStore
            .submitReport(
                selectedMarker.id,
                currentReportModule.id,
                moduleGroup.id,
                widgetValues,
                status,
                summary
            );
    };
}
