import {
    Button,
    Divider,
    Paper,
    TextField,
    Typography,
    Tooltip,
} from '@mui/material';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Build } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import GetApp from '@mui/icons-material/GetApp';
import memoize from 'memoize-one';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
    SearchResults,
    SearchResultsWithAccount,
} from '../../adapters/report-base-adapter';
import {
    AddReportWizard,
    AddReportWizardResult,
    AddReportWizardSteps,
} from '../../components/add-report-wizard';
import {
    CCDatePickerButton,
    CCDatePickerDateRange,
} from '../../components/cc-date-picker';
import {
    DropdownChips,
    TypeSelectorData,
} from '../../components/dropdown-chips';
import {
    LocationFilter,
    LocationFilterUtilityFunctions,
    OnDataRequestProps,
} from '../../components/location-filter';
import { NavigationListItem } from '../../components/navigation-list';
import { SummaryGrid } from '../../components/summary-grid';
import {
    Typeahead,
    TypeaheadData,
    TypeaheadType,
} from '../../components/typeahead';
import { VocPopup, WidgetValues } from '../../components/voc-popup';
import { WorkOrderDetails } from '../../components/work-order-details';
import { REPORTS } from '../../constants';
import { Marker } from '../../models/marker';
import {
    Report,
    ReportDetail,
    ReportModuleGroup,
    ReportStatus,
    submitVocWidgetData,
    VocFeedbackModule,
    VocFeedbackModuleContent,
    VocFeedbackModuleContentImageToggleOptionSubmit,
    VocWidgetType,
    WidgetDataValues,
} from '../../models/report';
import {
    CCSnackbar,
    CCSnackbarVariant,
} from '../../shared/components/cc-snackbar/cc-snackbar';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { CSVButton, ExportToCSVData } from '../../shared/components/csv-button';
import QrCode from '../../shared/components/icons/qr-code';
import { SearchInput } from '../../shared/components/search-input';
import { SideNavbar } from '../../shared/components/side-navbar';
import { SimpleGridUtilityFunctions } from '../../shared/components/simple-grid-pagination';
import { PageBoundary } from '../../shared/components/simple-list-pagination';
import { RenderTree } from '../../shared/components/node-tree';
import {
    EXPORT_TEAM_REPORTS_CAP,
    EXPORT_TEAM_REPORTS_LIMIT,
} from '../../shared/constants';
import {
    Folder,
    Floorplan,
    EntityFloorplan,
    UserTeam,
} from '../../shared/domain';
import { RootStore } from '../../stores/root.store';
import { camelToSpace } from '../../utils/camel-to-space';
import { DateFormatting } from '../../utils/date-formatting';
import { ReportsTabUtilities } from './reports-tabs-utilities';
import { UpdateCategoryWizard } from '../../components/update-category-wizard';

const CHIP_BACKGROUND_COLOR = '#D5E7ED';
const REPORT_CREATED_SUCCESSFULLY_PORTAL =
    'Report added successfully but may be hidden due to filters';
const REPORT_CREATED_SUCCESSFULLY_END_USER = 'Report added successfully';
const CATEGORY_UPDATED_SUCCESFULLY = 'Category updated successfully';
const VOC_FEEDBACK_MULTIPLE_RATINGS =
    'Please rate your experience for multiple closed reports';
const STATUS_DELAY_INTERVAL = 1000;
const STATUSES = Object.keys(ReportStatus)
    .map((key: string) => {
        return {
            id: key,
            name: ReportStatus[key as keyof typeof ReportStatus],
        } as TypeSelectorData;
    })
    .sort((a: TypeSelectorData, b: TypeSelectorData) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

interface LocationFilterCallbackType<T> {
    data: ReadonlyArray<T>;
    total: number;
}

interface LocationFilterCallbackTypeWithAccount<T> {
    data: ReadonlyArray<T>;
    total: number;
    accountId: string;
    accountName: string;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            backgroundColor: 'white',
            '& $chip, $chip:focus': {
                backgroundColor: CHIP_BACKGROUND_COLOR,
                color: theme.palette.secondary.dark,
            },
        },
        divider: {
            margin: '1.6em 0 1.55em 0',
        },
        contentContainer: {
            '&, &$navbarContent': {
                padding: '1.45em',
            },
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                padding: '0',
            },
        },
        contentSearchContainer: {
            padding: '1.45em',
            [theme.breakpoints.only('xs')]: {
                padding: '1em',
            },
        },
        drawerPaper: {
            minWidth: 250,
        },
        drawerCollapsed: {
            marginLeft: -250,
        },
        navbarIconContainer: {
            padding: '0.3em 0.4em',
            backgroundColor: theme.palette.secondary.main,
        },
        navbarIconContainerSmall: {
            transform: 'scale(0.7)',
            margin: '0.7em',
        },
        navbarIcon: {
            color: 'white',
        },
        navbarContent: {
            display: 'flex',
            flex: '1 0 0',
            flexDirection: 'column',
            paddingLeft: '0.9em',
            paddingRight: '0.9em',
            width: '18.1em',
        },
        navbarTitleContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        navbarTitle: {
            fontWeight: 'bold',
            marginLeft: '0.4em',
        },
        navbarComponentTitle: {
            color: theme.palette.grey[500],
            fontSize: '0.9em',
            fontWeight: 600,
            marginBottom: '0.3em',
        },
        navbarInput: {
            width: '100%',
            paddingLeft: '0.4em',
        },
        navbarPlaceholder: {
            fontSize: '0.9em',
        },
        datePicker: {
            marginBottom: '1.6em',
        },
        chipContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1.6em',
            minHeight: '8.5em',
        },
        chip: {},
        pageContent: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        pageContentWithDrawerOpen: {
            minWidth: 0,
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                minWidth: theme.breakpoints.values.iPhone8_Landscape - 1,
            },
        },
        workOrderFilterContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            minHeight: '5.6em',
            [theme.breakpoints.only('xs')]: {
                padding: '1em 0.5em 1em 1em',
            },
        },
        addReportContainer: {
            display: 'inline-flex',
        },
        addReportWizardListItems: {
            marginTop: '1.5em',
            maxHeight: '26em',
            overflowY: 'auto',
            height: 'calc(100% - 3.7em)',
        },
        snackbar: {
            top: '7em',
        },
        spinner: {
            zIndex: 1,
            position: 'fixed',
        },
        searchInputContainer: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            minWidth: '1em',
            marginRight: '1em',
        },
        searchInput: {
            flexGrow: 2,
            minWidth: '12em',
            maxWidth: '40em',
            [theme.breakpoints.only('xs')]: {
                minWidth: 'auto',
            },
        },
        summaryGridDivider: {
            marginTop: 0,
        },
        csvExportButton: {
            
            [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone12ProMax)]: {
                display: 'none',
            },
        },
        qrCodeIcon: {
            width: '2em',
            marginRight: '0.2em',
        },
        qrScanButton: {
            display: 'none',
            [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone12ProMax)]: {
                display: 'inline-flex',
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    isPortal: boolean;
    userTeam?: UserTeam;
}
interface States {
    addReportWizardOpen: boolean;
    addReportWizardSteps: AddReportWizardSteps[];
    addReportWizardCurrentStep: AddReportWizardSteps;
    addReportSelectedMarker: Marker | undefined;
    addReportModuleGroup: ReportModuleGroup | undefined;
    searchTerms: string;
    searchOffset: number;
    searchPage: number;
    snackbarVariant: CCSnackbarVariant;
    snackbarMessage: string;
    snackbarOpen: boolean;
    teamReportsOffset: number;
    teamReportsPage: number;
    locationFilterData: NavigationListItem | undefined;
    locationFilterFoldersOffset: number;
    locationFilterMarkersOffset: number;
    locationFilterEnabled: boolean;
    workOrderDetailsOpen: boolean;
    updateCategoryOpen: boolean;
    isUpdateCategoryLoading: boolean;
    updateReportModuleGroup: ReportModuleGroup | undefined;
    updateCategorySubmitDisabled: boolean;
    showUpdateCategory: boolean;
    csvExportCompletion: number;
    isCSVLoading: boolean;
    assignedTo: string;
    currentVocFeedbackModule: VocFeedbackModule | undefined;
    currentReportId: string | undefined;
    isVocPopupOpen: boolean;
    isVocReadOnly: boolean;
    openDetailsOnVocClose: boolean;
    treeFolders: RenderTree;
    treeFoldersClicked: string[];
    treeFoldersExpanded: string[];
    selectedFloorplan: EntityFloorplan | undefined;
}

interface changeModulePermission {
    data: { change_module_group_permission: boolean };
    status: boolean;
}

@inject('rootStore')
@observer
class ReportsTab extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {};

    state = {
        addReportWizardOpen: false,
        addReportWizardSteps: [],
        addReportWizardCurrentStep: AddReportWizardSteps.FolderTree,
        addReportSelectedMarker: undefined as Marker | undefined,
        searchTerms: '',
        searchOffset: 0,
        searchPage: 0,
        addReportModuleGroup: undefined as ReportModuleGroup | undefined,
        snackbarVariant: 'info' as CCSnackbarVariant,
        snackbarMessage: '',
        snackbarOpen: false,
        teamReportsOffset: 0,
        teamReportsPage: 0,
        locationFilterData: undefined as NavigationListItem | undefined,
        locationFilterFoldersOffset: 0,
        locationFilterMarkersOffset: 0,
        locationFilterEnabled: true,
        workOrderDetailsOpen: false,
        updateCategoryOpen: false,
        isUpdateCategoryLoading: false,
        updateReportModuleGroup: undefined as ReportModuleGroup | undefined,
        updateCategorySubmitDisabled: true,
        showUpdateCategory: false,
        csvExportCompletion: 0,
        isCSVLoading: false,
        assignedTo: '',
        currentVocFeedbackModule: undefined as VocFeedbackModule | undefined,
        currentReportId: undefined as string | undefined,
        isVocPopupOpen: false,
        isVocReadOnly: true,
        openDetailsOnVocClose: false,
        treeFolders: {
            id: 'treeRoot',
            name: 'Folders',
            children: [],
        },
        treeFoldersClicked: [],
        treeFoldersExpanded: [],
        selectedFloorplan: undefined,
    };

    reportsTabUtilities: ReportsTabUtilities | undefined = undefined;

    getTeamReportsForCsvExport = memoize(
        async (
            statusIds: string[],
            typeIds: string[],
            assignedTo: string,
            search: string,
            fromDate?: Date,
            toDate?: Date,
            teamId?: string,
            markerId?: string,
            folderId?: string
        ): Promise<Report[]> => {
            return this.fetchTeamReportsForCsvExport(
                statusIds,
                [...typeIds],
                assignedTo,
                search,
                fromDate,
                toDate,
                teamId,
                markerId,
                folderId
            );
        }
    );

    gridUtilities = null as SimpleGridUtilityFunctions | null;
    locationFilterUtilities = null as LocationFilterUtilityFunctions | null;

    getStatusSelectedIds = memoize((statusSelectedIds: string[]) => {
        const sortedArray = statusSelectedIds.slice().sort();
        const isUnresolvedPresent =
            sortedArray.findIndex(
                (element) => element === ReportStatus.Unresolved
            ) >= 0;
        if (isUnresolvedPresent) {
            const isUrgentPresent =
                sortedArray.findIndex(
                    (element) => element === ReportStatus.Urgent
                ) >= 0;
            if (isUrgentPresent) {
                sortedArray.push(
                    sortedArray.splice(sortedArray.length - 2, 1)[0]
                );
            }
        }
        return sortedArray;
    });

    generateLocationFilterData = (
        folders: ReadonlyArray<Folder>,
        markers: ReadonlyArray<Marker> = []
    ): NavigationListItem[] => {
        const listItemsFromFolders = folders.map((folder: Folder) => {
            return {
                id: folder.id,
                label: folder.name,
                isFolder: true,
            } as NavigationListItem;
        });
        const listItemsFromMarkers = markers.map((marker: Marker) => {
            return {
                id: marker.id,
                label: marker.name,
                isFolder: false,
            } as NavigationListItem;
        });

        return [...listItemsFromFolders, ...listItemsFromMarkers];
    };

    componentDidMount() {
        const {
            rootStore: { managerStore },
            userTeam,
        } = this.props;
        const {
            reportFilters: { assignedTo },
            error,
        } = managerStore;

        this.setState({
            assignedTo,
        });

        this.reportsTabUtilities = new ReportsTabUtilities(managerStore);

        if (userTeam) {
            this.refreshTeamReports(userTeam);
            managerStore
                .checkChangeCategoryByTeam(userTeam.teamId)
                .then((response) => {
                    const { data } =
                        response as unknown as changeModulePermission;
                    if (data.change_module_group_permission) {
                        this.setState({ showUpdateCategory: true });
                    } else {
                        this.setState({ showUpdateCategory: false });
                    }
                })
                .catch((error) => {
                    this.showError(error);
                });
        }

        if (error) {
            this.showError(error);
            managerStore.error = '';
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { userTeam: currentUserTeam } = this.props;
        if (currentUserTeam && prevProps.userTeam !== currentUserTeam) {
            this.resetLocationFilter().then(() => {
                this.refreshTeamReports(currentUserTeam);
            });
        }
    }

    onGridMounted = (utilities: SimpleGridUtilityFunctions) => {
        this.gridUtilities = utilities;
    };

    onLocationFilterMounted = (utilities: LocationFilterUtilityFunctions) => {
        this.locationFilterUtilities = utilities;
    };

    resetLocationFilter = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!this.locationFilterUtilities) {
                return reject();
            }

            const {
                rootStore: {
                    managerStore: { updateReportFilter },
                },
            } = this.props;

            this.locationFilterUtilities.resetFilter();
            updateReportFilter({
                markerId: '',
                folderId: '',
            });

            return resolve();
        });
    };

    refreshTeamReports = (userTeam: UserTeam | undefined) => {
        const {
            locationFilterFoldersOffset,
            treeFoldersExpanded,
            treeFoldersClicked,
        } = this.state;

        this.refreshAllReports();
        this.getVocFeedback();

        if (userTeam) {
            this.getTeamFolders(userTeam.teamId)
                .then(
                    (
                        results: LocationFilterCallbackTypeWithAccount<Folder>
                    ) => {
                        const { data, total, accountId, accountName } = results;
                        const locationFilterData = {
                            id: '',
                            label: '',
                            isFolder: true,
                            folderData: this.generateLocationFilterData(data),
                            folderDataTotal: total,
                        } as NavigationListItem;
                        const { LOCATION_FILTER_BUFFER_LIMIT: BUFFER_LIMIT } =
                            REPORTS;

                        if (data.length) {
                            // Use root node with account data
                            // if children has multiple folders
                            // otherwise user the folder data as root node
                            const treeHasRootNode = total > 1;
                            const treeFolders = {
                                id: treeHasRootNode ? accountId : data[0].id,
                                name: treeHasRootNode ? accountName : data[0].name,
                                children: treeHasRootNode ? [...data] : [],
                            };

                            this.setState({
                                locationFilterData,
                                locationFilterFoldersOffset:
                                    locationFilterFoldersOffset + BUFFER_LIMIT,
                                treeFolders: treeFolders,
                                treeFoldersExpanded: treeHasRootNode
                                    ? [...treeFoldersExpanded, accountId]
                                    : treeFoldersExpanded,
                                treeFoldersClicked: treeHasRootNode
                                    ? [...treeFoldersClicked, accountId]
                                    : treeFoldersClicked,
                            });
                        }
                    }
                )
                .catch((error) => {
                    this.setState({ locationFilterEnabled: false });
                    this.showError(
                        `${error} Location filter will be disabled.`
                    );
                });
        }
    };

    getTeamFolders = (
        teamId: string,
        folder: NavigationListItem | undefined = this.state.locationFilterData
    ): Promise<LocationFilterCallbackTypeWithAccount<Folder>> => {
        const {
            rootStore: { managerStore },
        } = this.props;
        const { locationFilterFoldersOffset } = this.state;
        const foldersTotal =
            folder && folder.folderDataTotal ? folder.folderDataTotal : -1;
        if (foldersTotal > 0 && foldersTotal <= locationFilterFoldersOffset) {
            return Promise.resolve({
                data: [],
                total: 0,
                accountName: '',
                accountId: '',
            });
        }

        const folderId = folder ? folder.id : '';
        const { LOCATION_FILTER_BUFFER_LIMIT: BUFFER_LIMIT } = REPORTS;
        return new Promise<LocationFilterCallbackTypeWithAccount<Folder>>(
            (resolve, reject) => {
                managerStore
                    .getTeamFolders(
                        teamId,
                        folderId,
                        BUFFER_LIMIT,
                        locationFilterFoldersOffset
                    )
                    .then(
                        (
                            teamFolderResults: SearchResultsWithAccount<Folder>
                        ) => {
                            const {
                                rows: folders,
                                accountId,
                                accountName,
                                meta: { totalCount: total },
                            } = teamFolderResults;

                            const results = {
                                data: folders,
                                accountId,
                                accountName,
                                total,
                            } as LocationFilterCallbackTypeWithAccount<Folder>;
                            // If the result set is empty we don't increase the offset
                            // because we reached the end of the data set.
                            if (total < 1) {
                                return resolve(results);
                            }

                            this.setState(
                                {
                                    locationFilterFoldersOffset:
                                        locationFilterFoldersOffset +
                                        BUFFER_LIMIT,
                                },
                                () => {
                                    resolve(results);
                                }
                            );
                        }
                    )
                    .catch((error) => {
                        return reject(error);
                    });
            }
        );
    };

    getTeamMarkersInFolder = (
        teamId: string,
        folder: NavigationListItem | undefined = this.state.locationFilterData
    ): Promise<LocationFilterCallbackType<Marker>> => {
        const {
            rootStore: { managerStore },
        } = this.props;
        const { locationFilterMarkersOffset } = this.state;
        const foldersTotal =
            folder && folder.folderDataTotal ? folder.folderDataTotal : -1;
        if (foldersTotal > 0 && foldersTotal <= locationFilterMarkersOffset) {
            return Promise.resolve({
                data: [],
                total: 0,
            });
        }

        const folderId = folder ? folder.id : '';
        const { LOCATION_FILTER_BUFFER_LIMIT: BUFFER_LIMIT } = REPORTS;
        return new Promise<LocationFilterCallbackType<Marker>>(
            (resolve, reject) => {
                managerStore
                    .getTeamMarkersInFolder(
                        teamId,
                        folderId,
                        BUFFER_LIMIT,
                        locationFilterMarkersOffset
                    )
                    .then((teamMarkerResults: SearchResults<Marker>) => {
                        const {
                            rows: markers,
                            meta: { totalCount: total },
                        } = teamMarkerResults;
                        const results = {
                            data: markers,
                            total,
                        };
                        // If the result set is empty we don't increase the offset
                        // because we reached the end of the data set.
                        if (total < 1) {
                            return resolve(results);
                        }

                        this.setState(
                            {
                                locationFilterMarkersOffset:
                                    locationFilterMarkersOffset + BUFFER_LIMIT,
                            },
                            () => {
                                return resolve(results);
                            }
                        );
                    })
                    .catch((error) => {
                        return reject(error);
                    });
            }
        );
    };

    getFoldersAndMarkers = (
        folder: NavigationListItem
    ): Promise<OnDataRequestProps> => {
        const { userTeam } = this.props;
        const { id: folderId } = folder;

        if (!userTeam || !folderId) {
            return Promise.reject();
        }

        const { teamId } = userTeam;

        return new Promise((resolve, reject) => {
            const getMarkers = () => {
                this.getTeamMarkersInFolder(teamId, folder)
                    .then(
                        (
                            teamMarkerResults: LocationFilterCallbackType<Marker>
                        ) => {
                            const {
                                data: markers,
                                total: locationFilterMarkersTotal,
                            } = teamMarkerResults;

                            return resolve({
                                data: this.generateLocationFilterData(
                                    [],
                                    markers
                                ),
                                total: locationFilterMarkersTotal,
                            });
                        }
                    )
                    .catch((error) => {
                        this.showError(error);
                        return reject(error);
                    });
            };

            const { locationFilterFoldersOffset, locationFilterMarkersOffset } =
                this.state;

            // If the marker offset is grater than the folder offset
            // it means that we have exhausted the data for the folders
            // and we need to request just the markers.
            if (locationFilterMarkersOffset > locationFilterFoldersOffset) {
                getMarkers();
                return;
            }

            this.getTeamFolders(teamId, folder)
                .then(
                    (teamFolderResults: LocationFilterCallbackType<Folder>) => {
                        const {
                            data: folders,
                            total: locationFilterFoldersTotal,
                        } = teamFolderResults;

                        if (!folders) {
                            return resolve({
                                data: [],
                                total: 0,
                            });
                        }

                        if (folders.length < 1) {
                            getMarkers();
                        } else {
                            return resolve({
                                data: this.generateLocationFilterData(folders),
                                total: locationFilterFoldersTotal,
                            });
                        }
                    }
                )
                .catch((error) => {
                    this.showErrorFromStore();
                    return reject(error);
                });
        });
    };

    getTeamReports = (
        statusIds: string[],
        typeIds: string[],
        assignedTo: string,
        search: string,
        offset = 0,
        fromDate?: Date,
        toDate?: Date,
        markerId?: string,
        folderId?: string
    ): Promise<Report[]> => {
        const {
            rootStore: { managerStore },
            isPortal,
        } = this.props;
        const statuses = statusIds.map((statusId: string) => {
            return ReportStatus[statusId as keyof typeof ReportStatus];
        });

        if (isPortal) {
            return managerStore.getTeamReports(
                REPORTS.TEAM_REPORTS_RECORD_LIMIT,
                statuses,
                [...typeIds],
                assignedTo,
                search,
                offset,
                fromDate,
                toDate,
                markerId,
                folderId
            );
        }

        return managerStore.getReportsByUser(
            REPORTS.TEAM_REPORTS_RECORD_LIMIT,
            offset
        );
    };

    getMarkerById = async (markerId: string): Promise<Marker | undefined> => {
        const {
            rootStore: { managerStore },
        } = this.props;

        const marker = await managerStore.getMarkerById(markerId);
        return marker;
    };

    fetchTeamReportsForCsvExport = async (
        statusIds: string[],
        typeIds: string[],
        assignedTo: string,
        search: string,
        fromDate?: Date,
        toDate?: Date,
        teamId?: string,
        markerId?: string,
        folderId?: string
    ): Promise<Report[]> => {
        const {
            rootStore: { managerStore },
        } = this.props;
        const statuses = statusIds.map((statusId: string) => {
            return ReportStatus[statusId as keyof typeof ReportStatus];
        });

        let totalRecords = 1;
        let csvRows: Report[] = [];
        const limit = EXPORT_TEAM_REPORTS_LIMIT;
        try {
            for (let offset = 0; offset < totalRecords; offset += limit) {
                const results: SearchResults<Report> = await managerStore
                    .getTeamReportsForCsvExport(
                        EXPORT_TEAM_REPORTS_LIMIT,
                        statuses,
                        typeIds,
                        assignedTo,
                        search,
                        offset,
                        fromDate,
                        toDate,
                        teamId,
                        markerId,
                        folderId
                    )
                    .catch(() => {
                        return Promise.reject();
                    });
                const {
                    rows: reports,
                    meta: { totalCount },
                } = results;
                const totalSteps = Math.ceil(
                    totalCount / EXPORT_TEAM_REPORTS_LIMIT
                );
                const increments = Math.round(100 / totalSteps);
                const percentage =
                    ((offset + EXPORT_TEAM_REPORTS_LIMIT) /
                        EXPORT_TEAM_REPORTS_LIMIT) *
                    increments;

                totalRecords = Math.min(totalCount, EXPORT_TEAM_REPORTS_CAP);
                this.setState({
                    csvExportCompletion: percentage < 100 ? percentage : 100,
                });

                csvRows = csvRows.concat(reports);
            }
        } catch (e) {
            return [];
        }

        return csvRows.length > 0 ? csvRows : [];
    };

    getVocFeedback = () => {
        const {
            isPortal,
            rootStore: { managerStore },
        } = this.props;

        if (isPortal) {
            return;
        }
        const loggedInUser = managerStore.loggedInUser;
        if (!loggedInUser) {
            return;
        }

        const { userId } = loggedInUser;
        managerStore
            .getNewFeedbackForUser(userId)
            .then((response) => {
                const { feedbackModules, reports } = response;

                const reportsLength = reports.length;
                const moduleLength = feedbackModules.length;
                if (reportsLength < 1 || moduleLength < 1) {
                    return;
                }
                const isVocPopupOpen = reportsLength === 1;
                const currentVocFeedbackModule = isVocPopupOpen
                    ? feedbackModules[0]
                    : undefined;
                const currentReportId = isVocPopupOpen
                    ? reports[0].id
                    : undefined;
                this.setState({
                    currentVocFeedbackModule,
                    currentReportId,
                    isVocPopupOpen,
                    // If there are no VOC to comment it goes to read only mode
                    isVocReadOnly: !isVocPopupOpen,
                });
                if (!isVocPopupOpen) {
                    this.showSnackbar(
                        VOC_FEEDBACK_MULTIPLE_RATINGS,
                        'info' as CCSnackbarVariant
                    );
                }
            })
            .catch(() => {
                this.showErrorFromStore();
            });
    };

    getVocReportAndFeedbackModuleFromId = (
        reportId: string
    ): { feedbackModule: VocFeedbackModule; report: Report } | undefined => {
        if (!reportId) {
            return;
        }
        const {
            rootStore: { managerStore },
        } = this.props;
        const { vocFeedback } = managerStore;
        const report = vocFeedback?.reports.find((currentReport) => {
            return this.getFullReportId(currentReport.id) === this.getFullReportId(reportId);
        });
        const currentReportId = report?.id;
        if (!vocFeedback || !report || !currentReportId) {
            return;
        }
        const { userFeedbackModuleId } = report;
        const feedbackModule = vocFeedback.feedbackModules.find(
            (currentFeedbackModule) => {
                return (
                    currentFeedbackModule.userFeedbackModuleId ===
                    userFeedbackModuleId
                );
            }
        );
        if (!feedbackModule) {
            return;
        }

        return {
            feedbackModule,
            report,
        };
    };

    showSnackbar = (
        snackbarMessage: string,
        snackbarVariant: CCSnackbarVariant
    ) => {
        this.setState({
            snackbarVariant,
            snackbarMessage,
            snackbarOpen: true,
        });
    };

    showError = (errorMessage: string | undefined) => {
        let message;
        if (errorMessage) {
            message = errorMessage;
        } else {
            message = 'Unknown Error';
        }

        this.showSnackbar(message, 'error' as CCSnackbarVariant);
    };

    showWarning = (warningMessage: string | undefined) => {
        if (!warningMessage) {
            return;
        }

        this.showSnackbar(warningMessage, 'warning' as CCSnackbarVariant);
    };

    showErrorFromStore = () => {
        const {
            rootStore: {
                managerStore: { error },
            },
        } = this.props;

        this.showError(error);
    };

    showWarningFromStore = () => {
        const {
            rootStore: {
                managerStore: { error },
            },
        } = this.props;

        this.showWarning(error);
    };

    submitReport = (widgetDataValues: WidgetDataValues) => {
        if (!this.reportsTabUtilities) {
            return;
        }

        const { isPortal } = this.props;

        const { addReportSelectedMarker, addReportModuleGroup } = this.state;

        this.reportsTabUtilities
            .submitReport(
                widgetDataValues,
                addReportSelectedMarker,
                addReportModuleGroup
            )
            .then(() => {
                this.refreshAllReports().then(() => {
                    this.showSnackbar(
                        isPortal
                            ? REPORT_CREATED_SUCCESSFULLY_PORTAL
                            : REPORT_CREATED_SUCCESSFULLY_END_USER,
                        'info' as CCSnackbarVariant
                    );
                });
            })
            .catch((errorMessage: string) => {
                const {
                    rootStore: { managerStore },
                } = this.props;
                const { error: errorMessageFromStore } = managerStore;
                let message = errorMessageFromStore
                    ? errorMessageFromStore
                    : errorMessage;
                if (!errorMessage) {
                    message = 'Error submitting the report.';
                }
                this.showError(message);
                managerStore.error = '';
            });
    };

    resetAddReportState = () => {
        const {
            rootStore: { managerStore },
        } = this.props;
        managerStore.clearTeamMarkersSearch();

        this.setState({
            addReportWizardOpen: false,
            addReportSelectedMarker: undefined,
            addReportWizardCurrentStep: AddReportWizardSteps.FolderTree,
            addReportModuleGroup: undefined,
            searchTerms: '',
        });
    };

    resetReportGrid = () => {
        const {
            rootStore: { managerStore },
        } = this.props;
        if (this.gridUtilities) {
            this.gridUtilities.resetGrid();
        }

        managerStore.resetTeamReportsTotal();

        this.setState({
            teamReportsOffset: 0,
            teamReportsPage: 0,
        });
    };

    getReportDetailsFromServer = (reportId: string): Promise<ReportDetail> => {
        return new Promise<ReportDetail>((resolve, reject) => {
            const {
                rootStore: { managerStore },
            } = this.props;

            managerStore
                .getReportDetail(reportId)
                .then((reportResults) => {
                    const { errors, results: reportDetail } = reportResults;

                    if (errors.length > 0) {
                        this.showWarningFromStore();
                    }

                    managerStore
                        .getReportComments(reportDetail, 100)
                        .then((response) => {
                            if (response.errors.length > 0) {
                                this.showWarningFromStore();
                            }
                            resolve(reportDetail);
                        })
                        .catch(() => {
                            this.showWarningFromStore();
                            resolve(reportDetail);
                        });
                })
                .catch(() => {
                    reject();
                });
        });
    };

    getFullReportId = (reportId: string) => {
        // Checks if the report id is full or partial
        const reportPrefix = 'report::';
        const fullReportId = reportId.startsWith(reportPrefix)
            ? reportId
            : `${reportPrefix}${reportId}`;

        return fullReportId;
    };

    getReportDetails = (reportId: string) => {
        const {
            rootStore: {
                managerStore: { currentReportDetail },
            },
            isPortal,
        } = this.props;
        // It checks if the report with the specified id
        // was previously loaded.
        if (
            !isPortal &&
            currentReportDetail &&
            reportId === currentReportDetail.fullId
        ) {
            this.setState({
                workOrderDetailsOpen: true,
            });
            return;
        }

        this.getReportDetailsFromServer(reportId)
            .then((newReportDetail: ReportDetail) => {
                let stateToUpdate = {
                    workOrderDetailsOpen: true,
                } as States;
                if (
                    newReportDetail &&
                    newReportDetail.userFeedback &&
                    newReportDetail.userFeedback.length > 0
                ) {
                    const userFeedbackLength =
                        newReportDetail.userFeedback.length;
                    const newProp = {
                        currentReportId: this.getFullReportId(
                            newReportDetail.id
                        ),
                        currentVocFeedbackModule:
                            newReportDetail.userFeedback[
                                userFeedbackLength - 1
                            ],
                        isVocReadOnly:
                            newReportDetail.userCanLeaveFeedback === false,
                    };
                    stateToUpdate = { ...stateToUpdate, ...newProp };
                }
                this.setState(stateToUpdate);
            })
            .catch(() => {
                this.showErrorFromStore();
            });
    };

    refreshAllReports = (
        items?: string[],
        typeSelectedIds?: string[],
        searchText?: string,
        datePickerDateRange?: CCDatePickerDateRange,
        markerId?: string,
        folderId?: string
    ): Promise<Report[]> => {
        const {
            rootStore: {
                managerStore: { reportFilters },
            },
        } = this.props;
        const {
            assignedTo,
            statusSelectedIds,
            typeSelectedIds: typeSelectedIdsState,
            datePickerDateRange: datePickerDateRangeState,
            searchReportText,
            markerId: markerIdState,
            folderId: folderIdState,
        } = reportFilters;
        const currentDatePickerDateRange = datePickerDateRange
            ? datePickerDateRange
            : datePickerDateRangeState;
        const fromDate =
            !!currentDatePickerDateRange && currentDatePickerDateRange.from
                ? currentDatePickerDateRange.from
                : undefined;
        const toDate =
            !!currentDatePickerDateRange && currentDatePickerDateRange.to
                ? currentDatePickerDateRange.to
                : undefined;

        const results = this.getTeamReports(
            items !== undefined ? items : statusSelectedIds,
            typeSelectedIds !== undefined
                ? typeSelectedIds
                : typeSelectedIdsState,
            assignedTo,
            searchText !== undefined ? searchText : searchReportText,
            0,
            fromDate,
            toDate,
            markerId !== undefined ? markerId : markerIdState,
            folderId !== undefined ? folderId : folderIdState
        );
        results.catch(() => {
            this.showErrorFromStore();
        });

        return results;
    };

    refreshAllReportsWithDetailReport = (reportId: string) => {
        const {
            rootStore: { managerStore },
        } = this.props;

        managerStore.invalidateCurrentReportDetail();
        this.getReportDetails(reportId);
        this.refreshAllReports();
    };

    resetVocStates = () => {
        const {
            rootStore: { managerStore },
        } = this.props;

        this.setState({
            currentReportId: undefined,
            currentVocFeedbackModule: undefined,
            isVocPopupOpen: false,
            openDetailsOnVocClose: false,
        });
        managerStore.invalidateCurrentReportDetail();
    };

    closeWorkOrderDetails = () => {
        this.setState({
            workOrderDetailsOpen: false,
        });
    };

    //#region Events

    onSnackbarClosed = () => {
        this.setState({ snackbarOpen: false });
    };

    onExpanderClicked = () => {
        const {
            rootStore: { managerStore },
        } = this.props;

        managerStore.setIsReportDrawerOpen(!managerStore.isReportDrawerOpen);
    };

    statusChangeDelayTimer: ReturnType<typeof setTimeout> | null = null;
    onStatusSelectedIdsChanged = (items: string[]) => {
        const {
            rootStore: {
                managerStore: { updateReportFilter },
            },
        } = this.props;

        items.sort();

        this.resetReportGrid();
        updateReportFilter({ statusSelectedIds: items });

        // Setup a delay
        if (this.statusChangeDelayTimer != null) {
            clearTimeout(this.statusChangeDelayTimer as unknown as number);
        }

        this.statusChangeDelayTimer = setTimeout(() => {
            this.statusChangeDelayTimer = null;
            this.refreshAllReports(items);
        }, STATUS_DELAY_INTERVAL);
    };

    onStatusDeleted = (itemId: string) => {
        const {
            rootStore: {
                managerStore: {
                    reportFilters: { statusSelectedIds: currentIds },
                    updateReportFilter,
                },
            },
        } = this.props;

        if (currentIds.length < 2) {
            return;
        }

        const itemPosition = currentIds.indexOf(itemId);

        if (itemPosition < 0) {
            return;
        }
        this.resetReportGrid();

        const statusSelectedIds = currentIds.slice(0);
        statusSelectedIds.splice(itemPosition, 1);

        updateReportFilter({ statusSelectedIds });

        // Setup a delay
        if (this.statusChangeDelayTimer != null) {
            clearTimeout(this.statusChangeDelayTimer);
        }

        this.statusChangeDelayTimer = setTimeout(() => {
            this.statusChangeDelayTimer = null;

            this.refreshAllReports(statusSelectedIds);
        }, STATUS_DELAY_INTERVAL);
    };

    onTypeAdded = (type: TypeaheadData) => {
        const {
            rootStore: {
                managerStore: {
                    reportFilters: { typeSelectedIds: typeIds },
                    updateReportFilter,
                },
            },
        } = this.props;

        if (typeIds.indexOf(type.id) >= 0) {
            return;
        }
        this.resetReportGrid();

        const typeSelectedIds = [...typeIds, type.id];
        updateReportFilter({ typeSelectedIds });

        this.refreshAllReports(undefined, typeSelectedIds);
    };

    onTypeDeleted = (type: TypeaheadData) => {
        const {
            rootStore: {
                managerStore: {
                    reportFilters: { typeSelectedIds: currentIds },
                    updateReportFilter,
                },
            },
        } = this.props;
        const itemId = type.id;
        const itemPosition = currentIds.indexOf(itemId);

        if (itemPosition < 0) {
            return;
        }
        this.resetReportGrid();

        const typeSelectedIds = currentIds.slice(0);
        typeSelectedIds.splice(itemPosition, 1);
        updateReportFilter({ typeSelectedIds });

        this.refreshAllReports(undefined, typeSelectedIds);
    };

    onAssignedToChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        const newValueLength = newValue.length;
        const {
            rootStore: {
                managerStore: { updateReportFilter },
            },
        } = this.props;

        if (newValueLength > 0) {
            const lastCharacter = newValue[newValueLength - 1];

            // If the user press enter we refresh the reports
            // and deselect the component.
            if (lastCharacter === '\r' || lastCharacter === '\n') {
                event.target.blur();
                updateReportFilter({
                    assignedTo: newValue
                        .replaceAll('\r', '')
                        .replaceAll('\n', ''),
                });
                this.resetReportGrid();
                this.refreshAllReports();
                return;
            }
        }

        this.setState({
            assignedTo: newValue.replaceAll('\r', '').replaceAll('\n', ''),
        });
    };

    onSearchClicked = (searchReportText: string) => {
        const {
            rootStore: {
                managerStore: { updateReportFilter },
            },
        } = this.props;

        updateReportFilter({ searchReportText });
        this.resetReportGrid();
        this.refreshAllReports(undefined, undefined, searchReportText);
    };

    onExportToCSVClicked = (): Promise<ExportToCSVData | undefined> => {
        this.setState({ isCSVLoading: true });

        return new Promise((resolve, reject) => {
            const {
                rootStore: {
                    managerStore: { currentUserTeam, reportFilters },
                },
            } = this.props;
            const {
                assignedTo,
                statusSelectedIds,
                typeSelectedIds,
                datePickerDateRange,
                searchReportText,
                markerId,
                folderId,
            } = reportFilters;
            const fromDate =
                !!datePickerDateRange && datePickerDateRange.from
                    ? datePickerDateRange.from
                    : undefined;
            const toDate =
                !!datePickerDateRange && datePickerDateRange.to
                    ? datePickerDateRange.to
                    : undefined;

            this.getTeamReportsForCsvExport(
                statusSelectedIds,
                typeSelectedIds,
                assignedTo,
                searchReportText,
                fromDate,
                toDate,
                currentUserTeam ? currentUserTeam.teamId : '',
                markerId,
                folderId
            )
                .then((reports: Report[]) => {
                    this.setState({
                        isCSVLoading: false,
                        csvExportCompletion: 0,
                    });
                    if (reports.length < 1) {
                        return reject();
                    }
                    const currentDate = new Date();
                    const monthRaw = `${currentDate.getMonth() + 1}`;
                    const month =
                        monthRaw.length < 2 ? `0${monthRaw}` : monthRaw;
                    const teamName = currentUserTeam
                        ? currentUserTeam.name
                            ? '-' + currentUserTeam.name.replace(/ /g, '_')
                            : '-' + currentUserTeam.teamId
                        : '';
                    const fileName = `reports-${currentDate.getFullYear()}-${month}${teamName}.csv`;
                    const excludedKeys = [
                        'folderId',
                        'id',
                        'markerId',
                        'moduleId',
                        'moduleType',
                        'moduleGroupId',
                        'photoS3Key',
                    ];
                    const updatedReports = reports.map((report) => {
                        let updatedReport = {};
                        // eslint-disable-next-line prefer-const
                        for (let [key, value] of Object.entries(report)) {
                            switch (key) {
                                case 'timeStamp': {
                                    // Get submitted Date and Time
                                    const timeStamp = value
                                        .toLocaleString()
                                        .split(',');
                                    // Calculate the number of days, since reported
                                    const days =
                                        DateFormatting.differenceInDays(value);
                                    updatedReport = {
                                        ...updatedReport,
                                        ...{
                                            'DATE SUBMITTED': timeStamp[0],
                                            'TIME SUBMITTED': timeStamp[1],
                                            'DAYS SINCE REPORTED': days,
                                        },
                                    };
                                    break;
                                }
                                case 'agrees':
                                    if (value && Array.isArray(value)) {
                                        value = (value as string[]).length;
                                    } else {
                                        value = 0;
                                    }
                                    break;
                            }

                            const isExcludedKey = !!excludedKeys.filter(
                                (excludedKey) => key === excludedKey
                            ).length;
                            if (!isExcludedKey) {
                                const updatedKey =
                                    camelToSpace(key).toUpperCase();
                                const updatedValue =
                                    value !== undefined
                                        ? `${value}`.trim().replace(/\n/g, ' ')
                                        : '';
                                const updatedObj = {
                                    [updatedKey]: updatedValue,
                                };
                                updatedReport = {
                                    ...updatedReport,
                                    ...updatedObj,
                                };
                            }
                        }

                        return updatedReport;
                    });

                    return resolve({
                        data: updatedReports,
                        fileName,
                    } as ExportToCSVData);
                })
                .catch(() => {
                    this.setState({
                        isCSVLoading: false,
                        csvExportCompletion: 0,
                    });
                    this.showSnackbar(
                        'Unable to export CSV file',
                        'error' as CCSnackbarVariant
                    );
                });
        });
    };

    onDatePickerDateRangeChanged = (
        datePickerDateRange: CCDatePickerDateRange
    ) => {
        const {
            rootStore: {
                managerStore: { updateReportFilter },
            },
        } = this.props;

        updateReportFilter({ datePickerDateRange });
        this.resetReportGrid();
        this.refreshAllReports(undefined, undefined, '', datePickerDateRange);
    };

    onQRScanned = async (markerId: string): Promise<boolean> => {
        const marker = await this.getMarkerById(markerId);

        if (!marker) {
            return false;
        }

        this.onAddReportSelectedMarker(marker);
        this.onAddReportModalResult(AddReportWizardResult.Continue);
        return true;
    };

    //#region VOC events
    onVocPopupSubmitted = (values: WidgetValues) => {
        const { currentReportId } = this.state;
        if (!currentReportId) {
            return;
        }
        const response =
            this.getVocReportAndFeedbackModuleFromId(currentReportId);
        if (!response) {
            return;
        }

        const { feedbackModule, report: reportFound } = response;

        const reportId = reportFound?.id;
        if (!feedbackModule || !reportFound || !reportId) {
            return;
        }
        const { userFeedbackModuleId } = reportFound;
        const widgets = feedbackModule.content;

        if (widgets && reportId && userFeedbackModuleId) {
            const widgetData: submitVocWidgetData[] = widgets.reduce(
                (
                    returnedArray: submitVocWidgetData[],
                    widget: VocFeedbackModuleContent
                ) => {
                    switch (widget.type) {
                        case VocWidgetType.Label: {
                            const { text } = widget;
                            returnedArray.push({
                                text,
                                type: 'label',
                            });
                            break;
                        }
                        case VocWidgetType.ImageToggle: {
                            const { options, widgetId } = widget;
                            const imageValue = +values[widgetId];
                            if (!isNaN(imageValue)) {
                                const foundOption = options.find((option) => {
                                    return option.value === imageValue;
                                });
                                if (foundOption) {
                                    returnedArray.push({
                                        image: foundOption.image,
                                        text: '',
                                        type: 'image_toggle',
                                        value: foundOption.value,
                                        widget_id: widgetId,
                                    } as VocFeedbackModuleContentImageToggleOptionSubmit);
                                }
                            }
                            break;
                        }
                        case VocWidgetType.Textfield: {
                            const { widgetId } = widget;
                            const text = values[widgetId];
                            returnedArray.push({
                                text: text ? text : '',
                                type: 'textfield',
                                widget_id: widgetId,
                            });
                            break;
                        }
                    }
                    return returnedArray;
                },
                [] as submitVocWidgetData[]
            );
            if (widgetData.length > 0) {
                const {
                    rootStore: { managerStore },
                } = this.props;
                managerStore
                    .submitVocFeedback(
                        widgetData,
                        userFeedbackModuleId,
                        reportId
                    )
                    .then(() => {
                        this.refreshAllReports();
                    })
                    .catch(() => {
                        this.showErrorFromStore();
                    });
            }
        }
        this.resetVocStates();
    };

    onVocPopupCancelled = () => {
        const { isVocReadOnly, openDetailsOnVocClose } = this.state;
        if (isVocReadOnly) {
            this.setState({ isVocPopupOpen: false });
            // If the VOC popup was opened by clicking
            // on the faces button on report details
            // it reopens the report details again.
            if (openDetailsOnVocClose) {
                this.setState({ openDetailsOnVocClose: false });
                this.onVocPopupDetailClicked();
            }
            return;
        }

        // If we reach this point it means that
        // the user has pressed "no thank you" button

        const { currentReportId } = this.state;
        if (!currentReportId) {
            return;
        }
        const response =
            this.getVocReportAndFeedbackModuleFromId(currentReportId);
        if (!response) {
            return;
        }

        const { feedbackModule, report: reportFound } = response;

        const reportId = reportFound?.id;
        if (!feedbackModule || !reportFound) {
            return;
        }
        const { userFeedbackModuleId } = reportFound;
        if (!reportId || !userFeedbackModuleId) {
            return;
        }

        const {
            rootStore: { managerStore },
        } = this.props;
        const widgetData: submitVocWidgetData[] = [
            {
                type: 'textfield',
                text: 'User opted to not leave feedback.',
            },
        ];
        managerStore
            .submitVocFeedback(widgetData, userFeedbackModuleId, reportId)
            .then(() => {
                this.refreshAllReports();
            })
            .catch(() => {
                this.showErrorFromStore();
            });

        this.resetVocStates();
    };

    onVocPopupDetailClicked = () => {
        const { currentReportId } = this.state;
        if (!currentReportId) {
            return;
        }
        this.setState({ isVocPopupOpen: false }, () => {
            this.getReportDetails(currentReportId);
        });
    };

    onReturnToVocClicked = () => {
        const {
            rootStore: {
                managerStore: { currentReportDetail },
            },
        } = this.props;

        let currentVocFeedbackModule: VocFeedbackModule | undefined;
        if (currentReportDetail && currentReportDetail.userFeedback) {
            const userFeedbackLength = currentReportDetail.userFeedback.length;
            currentVocFeedbackModule =
                currentReportDetail.userFeedback[userFeedbackLength - 1];
        }

        if (
            !currentVocFeedbackModule ||
            currentVocFeedbackModule.content.length < 1
        ) {
            const userFeedbackModuleId =
                currentReportDetail?.userFeedbackModuleId;
            if (userFeedbackModuleId) {
                currentVocFeedbackModule =
                    this.getVocFeedbackModuleFromNewFeedback(
                        userFeedbackModuleId
                    );
            }
        }
        const isVocReadOnly = currentReportDetail
            ? currentReportDetail.status !== ReportStatus.Closed ||
              currentReportDetail.userCanLeaveFeedback !== true
            : this.state.isVocReadOnly;
        this.setState({
            currentReportId: currentReportDetail
                ? this.getFullReportId(currentReportDetail.id)
                : undefined,
            currentVocFeedbackModule,
            isVocPopupOpen: true,
            isVocReadOnly,
            openDetailsOnVocClose: true,
        });

        this.closeWorkOrderDetails();
    };

    getVocFeedbackModuleFromNewFeedback(
        userFeedbackModuleId: string
    ): VocFeedbackModule | undefined {
        const {
            rootStore: {
                managerStore: { vocFeedback },
            },
        } = this.props;
        if (!vocFeedback) {
            return;
        }
        // It find the feedback module that the current report is using
        const { feedbackModules } = vocFeedback;
        const feedbackModuleFound = feedbackModules.find((feedbackModule) => {
            return feedbackModule.userFeedbackModuleId === userFeedbackModuleId;
        });
        if (!feedbackModuleFound) {
            return;
        }
        // It setups the variable with the feedback module
        return feedbackModuleFound;
    }

    onGridOpenVocClicked = (report: Report) => {
        const {
            id,
            userFeedback,
            userFeedbackModuleId,
            status,
            userCanLeaveFeedback,
        } = report;
        const isVocReadOnly =
            status !== ReportStatus.Closed || userCanLeaveFeedback !== true;
        let currentVocFeedbackModule: VocFeedbackModule | undefined;

        // If the report has feedback, it setups the necessary variables
        // to be saved on the state
        if (isVocReadOnly && userFeedback) {
            const userFeedbackLength = userFeedback.length;
            if (userFeedbackLength > 0) {
                currentVocFeedbackModule = userFeedback[userFeedbackLength - 1];
            }
        } else {
            // If the report is newly closed and doesn't have feedback...
            // It grabs the feedback from the new reports
            // downloaded previously from the API

            // It setups the variable with the feedback module
            currentVocFeedbackModule = userFeedbackModuleId
                ? this.getVocFeedbackModuleFromNewFeedback(userFeedbackModuleId)
                : undefined;
        }

        this.setState({
            currentReportId: this.getFullReportId(id),
            currentVocFeedbackModule,
            isVocPopupOpen: true,
            isVocReadOnly,
        });
    };
    //#endregion

    //#region Add Report events
    onAddReportClicked = () => {
        this.setState({
            addReportWizardOpen: true,
            addReportWizardSteps: [
                AddReportWizardSteps.FolderTree,
                AddReportWizardSteps.Location,
                AddReportWizardSteps.SubmitReport,
            ],
            addReportWizardCurrentStep: AddReportWizardSteps.FolderTree,
            searchOffset: 0,
        });
    };

    onAddReportByQRClicked = () => {
        this.onAddReportClicked();
        this.setState({
            addReportWizardSteps: [
                AddReportWizardSteps.QRLocation,
                AddReportWizardSteps.SubmitReport,
            ],
            addReportWizardCurrentStep: AddReportWizardSteps.QRLocation,
        });
    };

    onSearchBufferOverBoundaryReached = (
        boundary: PageBoundary,
        nextPage: number
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            const {
                rootStore: { managerStore },
            } = this.props;
            const { floorplanMarkersTotal } = managerStore;
            const {
                searchTerms,
                searchOffset: currentOffset,
                selectedFloorplan,
            } = this.state;

            if (!selectedFloorplan) {
                this.showError('There is no floorplan selected to get markers');
                return;
            }

            const { MARKERS_RECORD_LIMIT, MARKERS_MAX_ROWS } = REPORTS;

            const isFirstPage = nextPage === 0;
            const isLastPage =
                nextPage ===
                Math.ceil(floorplanMarkersTotal / MARKERS_MAX_ROWS) - 1;
            let newOffset = isFirstPage
                ? 0
                : isLastPage
                    ? MARKERS_RECORD_LIMIT * Math.floor(floorplanMarkersTotal / MARKERS_RECORD_LIMIT)
                    : boundary === PageBoundary.Upper
                        ? currentOffset + MARKERS_RECORD_LIMIT
                        : currentOffset - MARKERS_RECORD_LIMIT;

            // If we reached exactly the end of the buffer we go one page back
            if (isLastPage && newOffset === floorplanMarkersTotal) {
                newOffset -= MARKERS_RECORD_LIMIT;
            }

            this.setState({
                searchOffset: newOffset,
                searchPage: nextPage,
            });

            const { floorplan_id: floorplanId } =
                selectedFloorplan as unknown as EntityFloorplan;

            if (searchTerms) {
                managerStore
                    .getFloorplanMarkersSearch(
                        floorplanId,
                        searchTerms,
                        MARKERS_RECORD_LIMIT,
                        newOffset
                    )
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        this.showErrorFromStore();
                        reject(error);
                    });
            } else {
                managerStore
                    .getFloorplanMarkers(
                        floorplanId,
                        MARKERS_RECORD_LIMIT,
                        newOffset
                    )
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        this.showErrorFromStore();
                        reject(error);
                    });
            }
        });
    };

    onAddReportSearchClicked = (searchTerms: string) => {
        const {
            rootStore: { managerStore },
        } = this.props;
        const { selectedFloorplan } = this.state;
        const { MARKERS_RECORD_LIMIT } = REPORTS;

        if (!selectedFloorplan) {
            this.showError('There is no floorplan selected to get markers');
            return;
        }

        const { floorplan_id: floorplanId } =
            selectedFloorplan as unknown as EntityFloorplan;

        this.setState(
            {
                searchTerms,
                searchOffset: 0,
                searchPage: 0,
            },
            () => {
                if (searchTerms) {
                    managerStore
                        .getFloorplanMarkersSearch(
                            floorplanId,
                            searchTerms,
                            MARKERS_RECORD_LIMIT
                        )
                        .catch(() => {
                            this.showErrorFromStore();
                        });
                } else {
                    managerStore
                        .getFloorplanMarkers(
                            floorplanId,
                            MARKERS_RECORD_LIMIT,
                            0
                        )
                        .catch(() => {
                            this.showErrorFromStore();
                        });
                }
            }
        );
    };

    onAddReportSelectedMarker = (addReportSelectedMarker: Marker) => {
        this.setState({ addReportSelectedMarker });
    };

    onAddReportModuleGroupSelected = (
        addReportModuleGroup: ReportModuleGroup
    ) => {
        this.setState({ addReportModuleGroup });
        const { addReportSelectedMarker } = this.state;
        const markerId: string = addReportSelectedMarker
            ? addReportSelectedMarker.id
            : '';
        const moduleGroupId: string = addReportModuleGroup
            ? addReportModuleGroup.id
            : '';
        const {
            rootStore: { managerStore },
        } = this.props;
        managerStore.getModuleInfo(markerId, moduleGroupId).catch(() => {
            this.showErrorFromStore();
        });
    };

    onUpdateReportModuleGroupSelected = (
        updateReportModuleGroup: ReportModuleGroup
    ) => {
        this.setState({ updateReportModuleGroup });
        const moduleGroupId: string = updateReportModuleGroup
            ? updateReportModuleGroup.id
            : '';
        const {
            rootStore: { managerStore },
        } = this.props;
        const { currentReportDetail } = managerStore;
        const markerId: string = currentReportDetail
            ? currentReportDetail.markerId
            : '';

        if (
            currentReportDetail &&
            currentReportDetail.moduleGroupId !== moduleGroupId
        ) {
            managerStore
                .getModuleInfo(markerId, moduleGroupId)
                .then(() => {
                    this.setState({ updateCategorySubmitDisabled: false });
                })
                .catch(() => {
                    this.showErrorFromStore();
                });
        } else {
            this.setState({ updateCategorySubmitDisabled: true });
        }
    };

    onAddReportModalResult = (
        result: AddReportWizardResult,
        widgetValues?: WidgetDataValues
    ) => {
        switch (result) {
            case AddReportWizardResult.Cancel: {
                this.resetAddReportState();
                break;
            }
            case AddReportWizardResult.Continue: {
                const { addReportWizardCurrentStep } = this.state;
                const {
                    rootStore: { managerStore },
                } = this.props;

                switch (addReportWizardCurrentStep) {
                    case AddReportWizardSteps.Location: {
                        const { addReportSelectedMarker } = this.state;
                        managerStore
                            .getModulesByMarker(
                                addReportSelectedMarker
                                    ? addReportSelectedMarker.id
                                    : ''
                            )
                            .then(() => {
                                this.setState({
                                    addReportWizardCurrentStep:
                                        AddReportWizardSteps.SubmitReport,
                                });
                            })
                            .catch(() => {
                                this.showErrorFromStore();
                            });
                        break;
                    }
                    case AddReportWizardSteps.QRLocation: {
                        const { addReportSelectedMarker } = this.state;
                        managerStore
                            .getModulesByMarker(
                                addReportSelectedMarker
                                    ? addReportSelectedMarker.id
                                    : ''
                            )
                            .then(() => {
                                this.setState({
                                    addReportWizardCurrentStep:
                                        AddReportWizardSteps.SubmitReport,
                                });
                            })
                            .catch(() => {
                                this.showErrorFromStore();
                            });
                        break;
                    }
                    case AddReportWizardSteps.ReportType: {
                        const {
                            addReportModuleGroup,
                            addReportSelectedMarker,
                        } = this.state;
                        const markerId: string = addReportSelectedMarker
                            ? addReportSelectedMarker.id
                            : '';
                        const moduleGroupId: string = addReportModuleGroup
                            ? addReportModuleGroup.id
                            : '';

                        managerStore
                            .getModuleInfo(markerId, moduleGroupId)
                            .then(() => {
                                this.setState({
                                    addReportWizardCurrentStep:
                                        AddReportWizardSteps.SubmitReport,
                                });
                            })
                            .catch(() => {
                                this.showErrorFromStore();
                            });
                        break;
                    }
                    default:
                        this.setState({
                            addReportWizardCurrentStep:
                                AddReportWizardSteps.FolderTree,
                        });
                        break;
                }
                break;
            }
            case AddReportWizardResult.Back: {
                const { addReportWizardCurrentStep, addReportWizardSteps } =
                    this.state;

                let nextStep: AddReportWizardSteps;
                let isPreviousSubmit = false;
                if (
                    addReportWizardCurrentStep ===
                    AddReportWizardSteps.SubmitReport
                ) {
                    nextStep = addReportWizardSteps[1];
                    isPreviousSubmit = true;
                } else if (
                    addReportWizardCurrentStep ===
                    AddReportWizardSteps.IssueDetails
                ) {
                    nextStep = AddReportWizardSteps.SubmitReport;
                } else {
                    nextStep = addReportWizardCurrentStep;
                }

                // Reset the state for step #2
                let newState = { addReportWizardCurrentStep: nextStep };
                if (nextStep === AddReportWizardSteps.Location) {
                    newState = Object.assign(newState, {
                        addReportModuleGroup: '',
                        addReportWizardCurrentStep: isPreviousSubmit
                            ? nextStep
                            : nextStep - 1,
                    });
                }

                this.setState(newState);
                break;
            }
            case AddReportWizardResult.Submit: {
                if (widgetValues) {
                    this.submitReport(widgetValues);
                    this.resetAddReportState();
                }
            }
        }
    };
    //#endregion

    //#region Team Reports events
    onTeamReportsPageOverBoundary = (
        boundary: PageBoundary,
        nextPage: number
    ) => {
        const {
            rootStore: { managerStore },
        } = this.props;
        const { teamReportsTotal, reportFilters } = managerStore;
        const {
            assignedTo,
            statusSelectedIds,
            typeSelectedIds,
            datePickerDateRange,
            searchReportText,
            markerId,
            folderId,
        } = reportFilters;
        const { teamReportsOffset: currentOffset } = this.state;
        const { TEAM_REPORTS_RECORD_LIMIT, TEAM_REPORTS_MAX_ROWS } = REPORTS;
        const isFirstPage = nextPage === 0;
        const isLastPage =
            nextPage ===
            Math.ceil(teamReportsTotal / TEAM_REPORTS_MAX_ROWS) - 1;
        let newOffset = isFirstPage
            ? 0
            : isLastPage
                ? TEAM_REPORTS_RECORD_LIMIT * Math.floor(teamReportsTotal / TEAM_REPORTS_RECORD_LIMIT)
                : boundary === PageBoundary.Upper
                    ? currentOffset + TEAM_REPORTS_RECORD_LIMIT
                    : currentOffset - TEAM_REPORTS_RECORD_LIMIT;

        // If we reached exactly the end of the buffer we go one page back
        if (isLastPage && newOffset === teamReportsTotal) {
            newOffset -= TEAM_REPORTS_RECORD_LIMIT;
        }

        this.setState({
            teamReportsOffset: newOffset,
            teamReportsPage: nextPage,
        });

        const fromDate =
            !!datePickerDateRange && datePickerDateRange.from
                ? datePickerDateRange.from
                : undefined;
        const toDate =
            !!datePickerDateRange && datePickerDateRange.to
                ? datePickerDateRange.to
                : undefined;
        this.getTeamReports(
            statusSelectedIds,
            typeSelectedIds,
            assignedTo,
            searchReportText,
            newOffset,
            fromDate,
            toDate,
            markerId,
            folderId
        );
    };

    onTeamReportsClicked = (currentTeamReport: Report) => {
        this.getReportDetails(currentTeamReport.id);
    };

    onTeamReportsAgreeClicked = (report: Report) => {
        const {
            rootStore: { managerStore },
        } = this.props;

        const loggedInUser = managerStore.loggedInUser;
        if (!loggedInUser) {
            return;
        }
        const { id: reportId, agrees: reportAgrees } = report;
        const { userId } = loggedInUser;
        const userAgrees = reportAgrees.indexOf(userId) >= 0;
        managerStore
            .changeAgreeInReport(reportId, !userAgrees)
            .then((agree: boolean) => {
                if (agree === !userAgrees) {
                    this.refreshAllReports();
                }
            })
            .catch(() => {
                this.showErrorFromStore();
            });
    };
    //#endregion

    //#region Work Order Details events
    onWorkOrderDetailsStatusChanged = (
        reportId: string,
        status: ReportStatus
    ) => {
        const {
            rootStore: { managerStore },
        } = this.props;

        managerStore
            .changeReportStatus(reportId, status)
            .then(() => {
                this.refreshAllReportsWithDetailReport(reportId);
            })
            .catch(() => {
                this.showErrorFromStore();
            });
    };

    onWorkOrderDetailsAssigneeChanged = (reportId: string, email: string) => {
        const {
            rootStore: { managerStore },
        } = this.props;

        managerStore
            .assignReport(reportId, email)
            .then(() => {
                this.refreshAllReportsWithDetailReport(reportId);
            })
            .catch(() => {
                this.showErrorFromStore();
            });
    };

    onCommentPosted = (
        reportId: string,
        commentText: string
    ): Promise<void> => {
        const {
            rootStore: { managerStore },
        } = this.props;
        return new Promise((resolve, reject) => {
            managerStore
                .postReportComment(reportId, commentText)
                .then(() => {
                    managerStore.invalidateCurrentReportDetail();
                    this.getReportDetails(reportId);
                    resolve();
                })
                .catch(() => {
                    this.showErrorFromStore();
                    reject();
                });
        });
    };

    onWorkOrderDetailsDeleteReport = (reportId: string) => {
        const {
            rootStore: { managerStore },
            userTeam,
        } = this.props;

        managerStore
            .deleteReport(reportId)
            .catch(() => {
                this.showErrorFromStore();
            })
            .finally(() => {
                this.closeWorkOrderDetails();
                this.refreshTeamReports(userTeam);
            });
    };
    //#endregion

    //#region Location Filter events
    onLocationFilterDataRequested = (
        item: NavigationListItem
    ): Promise<OnDataRequestProps> => {
        return new Promise<OnDataRequestProps>((resolve, reject) => {
            this.setState(
                {
                    locationFilterFoldersOffset: 0,
                    locationFilterMarkersOffset: 0,
                },
                () => {
                    this.getFoldersAndMarkers(item)
                        .then((results: OnDataRequestProps) => {
                            return resolve(results);
                        })
                        .catch((error) => {
                            this.showError(error);
                            return reject(error);
                            // return resolve({
                            //     data: [],
                            //     total: 0
                            // });
                        });
                }
            );
        });
    };

    onLocationFilterItemClicked = (item: NavigationListItem | undefined) => {
        const {
            rootStore: {
                managerStore: {
                    reportFilters: {
                        markerId: markerIdState,
                        folderId: folderIdState,
                    },
                    updateReportFilter,
                },
            },
        } = this.props;
        let markerId = '';
        let folderId = '';
        if (item) {
            if (item.isFolder === true) {
                folderId = item.id;
            } else {
                markerId = item.id;
            }
        }
        if (markerIdState === markerId && folderIdState === folderId) {
            return;
        }

        updateReportFilter({
            markerId,
            folderId,
            initialSelectedItem: item,
        });

        this.resetReportGrid();
        this.refreshAllReports(
            undefined,
            undefined,
            '',
            undefined,
            markerId,
            folderId
        );
    };

    onLocationFilterLoadNextPage = (
        item: NavigationListItem | undefined
    ): Promise<OnDataRequestProps> => {
        if (!item) {
            return Promise.resolve({ data: [], total: 0 });
        }

        return this.getFoldersAndMarkers(item);
    };
    //#endregion

    onSelectFloorplan = (selectedFloorplan: EntityFloorplan | undefined) => {
        const { LOCATION_FILTER_BUFFER_LIMIT: BUFFER_LIMIT } = REPORTS;
        const {
            rootStore: { managerStore },
        } = this.props;

        if (!selectedFloorplan) {
            // Remove selected floorplan when
            // Add Report Wizard is closed
            this.setState({ selectedFloorplan });
        } else {
            const { floorplan_id: floorplanId } =
                selectedFloorplan as EntityFloorplan;
            managerStore
                .getFloorplanMarkers(floorplanId, BUFFER_LIMIT)
                .then((results: SearchResults<Marker>) => {
                    const {
                        meta: { totalCount: totalMarkers },
                    } = results;

                    if (!totalMarkers) {
                        this.showWarning(
                            'There are no markers associated to this floorplan'
                        );
                    } else {
                        this.setState({
                            addReportWizardCurrentStep:
                                AddReportWizardSteps.Location,
                            selectedFloorplan,
                        });
                    }
                });
        }
    };

    getFolderNodes = (
        teamId: string,
        folderId: string
    ): Promise<RenderTree[]> => {
        const { LOCATION_FILTER_BUFFER_LIMIT: BUFFER_LIMIT } = REPORTS;
        const {
            rootStore: { managerStore },
        } = this.props;
        return managerStore
            .getFolderFloorplans(teamId, folderId, BUFFER_LIMIT)
            .then((results: SearchResults<Floorplan>) => {
                const {
                    rows: floorplans,
                    meta: { totalCount: totalFloorplans },
                } = results;

                if (totalFloorplans) {
                    return floorplans.map((floorplan: Floorplan) => {
                        // Add prefix to differentiate floorplans
                        // from the rest of the folder nodes
                        return {
                            id: `floorplan::${floorplan.id}`,
                            name: floorplan.name,
                        };
                    });
                } else {
                    return managerStore
                        .getTeamFolders(teamId, folderId, BUFFER_LIMIT)
                        .then((results: SearchResults<Folder>) => {
                            const {
                                rows: folders,
                                meta: { totalCount: totalFolders },
                            } = results;

                            if (!totalFolders) {
                                this.showWarning(
                                    'There are no folders or floorplans associated to this folder'
                                );
                            }

                            return folders;
                        });
                }
            });
    };

    onFolderTreeClick = async (folderId: string): Promise<RenderTree[]> => {
        const { userTeam } = this.props;
        const { treeFoldersClicked } = this.state;

        let folderNodes = [] as RenderTree[];

        if (userTeam) {
            folderNodes = await this.getFolderNodes(userTeam.teamId, folderId);
            if (folderNodes.length) {
                this.setState({
                    treeFoldersClicked: [...treeFoldersClicked, folderId],
                });
            }
        }

        return folderNodes;
    };

    onFolderTreeToggle = (folderIds: string[]) => {
        this.setState({ treeFoldersExpanded: folderIds });
    };

    getCategoryList = (markerId: string) => {
        const {
            rootStore: { managerStore },
        } = this.props;

        this.setState({
            updateCategoryOpen: true,
            isUpdateCategoryLoading: true,
        });

        managerStore
            .getModulesByMarker(markerId)
            .then(() => {
                this.setState({
                    isUpdateCategoryLoading: false,
                });
            })
            .catch(() => {
                this.showErrorFromStore();
            });
    };

    updateCategory = () => {
        const {
            rootStore: { managerStore },
            userTeam,
        } = this.props;

        const { updateReportModuleGroup } = this.state;

        const { currentReportDetail } = managerStore;

        this.setState({ isUpdateCategoryLoading: true });

        if (userTeam && currentReportDetail && updateReportModuleGroup) {
            const { teamId } = userTeam;
            const { id: reportId } = currentReportDetail;
            const { id: moduleGroupId } = updateReportModuleGroup;
            const fullReportId = this.getFullReportId(reportId);
            const partialReportId = reportId.replace('report::', '');

            managerStore
                .changeReportCategory(teamId, partialReportId, moduleGroupId)
                .then(() => {
                    this.getReportDetailsFromServer(fullReportId)
                        .then(() => {
                            this.cancelCategoryUpdate();
                            this.showSnackbar(
                                CATEGORY_UPDATED_SUCCESFULLY,
                                'info' as CCSnackbarVariant
                            );
                        })
                        .catch(() => {
                            this.cancelCategoryUpdate();
                            this.showErrorFromStore();
                        });
                })
                .catch(() => {
                    this.cancelCategoryUpdate();
                    this.showErrorFromStore();
                });
        }
    };

    cancelCategoryUpdate = () => {
        this.setState({
            updateCategoryOpen: false,
            isUpdateCategoryLoading: false,
            updateCategorySubmitDisabled: true,
            updateReportModuleGroup: undefined,
        });
    };

    public render() {
        const {
            classes,
            rootStore: { managerStore },
            isPortal,
        } = this.props;

        const {
            currentReportDetail,
            reportDetailComments,
            isReportDrawerOpen,
            isReportChangeAgreeLoading,
            isReportDetailAssignLoading,
            isReportDetailDeleteLoading,
            isReportDetailLoading,
            isReportDetailCommentsLoading,
            isReportDetailPostCommentLoading,
            isReportDetailStatusChangeLoading,
            isReportModulesLoading,
            isReportModuleGroupsLoading,
            isTeamMarkersLoading,
            isFloorplanMarkersLoading,
            isFolderFloorplansLoading,
            isTeamReportsLoading,
            isTeamFoldersLoading,
            isVocFeedbackLoading,
            reportModules,
            reportModuleGroups,
            floorplanMarkers,
            floorplanMarkersTotal,
            teamReports,
            teamReportsTotal,
            userModuleGroups,
            vocFeedback,
            reportFilters,
        } = managerStore;

        const {
            statusSelectedIds,
            typeSelectedIds,
            datePickerDateRange,
            searchReportText,
            initialSelectedItem,
        } = reportFilters;

        const {
            addReportModuleGroup,
            addReportSelectedMarker,
            addReportWizardOpen,
            addReportWizardCurrentStep,
            searchOffset,
            snackbarVariant,
            snackbarMessage,
            snackbarOpen,
            teamReportsOffset,
            workOrderDetailsOpen,
            updateCategoryOpen,
            isUpdateCategoryLoading,
            updateReportModuleGroup,
            updateCategorySubmitDisabled,
            showUpdateCategory,
            csvExportCompletion,
            isCSVLoading,
            locationFilterData,
            assignedTo,
            isVocPopupOpen,
            isVocReadOnly,
            currentVocFeedbackModule,
            locationFilterEnabled,
            treeFolders,
            treeFoldersClicked,
            treeFoldersExpanded,
            selectedFloorplan,
        } = this.state;

        const { MARKERS_MAX_ROWS, TEAM_REPORTS_MAX_ROWS } = REPORTS;

        const types = userModuleGroups.map((moduleGroup) => {
            const { id, name } = moduleGroup;

            return {
                id,
                name,
            };
        });
        const areReportsComponentsDisabled =
            isTeamReportsLoading ||
            isReportDetailLoading ||
            isReportDetailCommentsLoading ||
            isReportChangeAgreeLoading ||
            isVocFeedbackLoading;

        return (
            <div className={classes.root}>
                {isPortal ? (
                    <SideNavbar
                        variant="persistent"
                        isDrawerOpen={isReportDrawerOpen}
                        onExpanderClick={this.onExpanderClicked}
                        collapsedArea={
                            <div
                                className={`${classes.navbarIconContainer} ${classes.navbarIconContainerSmall}`}
                            >
                                <Build className={classes.navbarIcon} />
                            </div>
                        }
                    >
                        <div
                            className={`${classes.contentContainer} ${classes.navbarContent}`}
                        >
                            <div className={classes.navbarTitleContainer}>
                                <div className={classes.navbarIconContainer}>
                                    <Build className={classes.navbarIcon} />
                                </div>
                                <Typography
                                    className={classes.navbarTitle}
                                    variant="subtitle1"
                                >
                                    Report Filters
                                </Typography>
                            </div>
                            <Divider className={classes.divider} />
                            <CCDatePickerButton
                                className={classes.datePicker}
                                dateRange={datePickerDateRange}
                                onDateRangeChange={
                                    this.onDatePickerDateRangeChanged
                                }
                            />
                            <Typography
                                className={classes.navbarComponentTitle}
                                variant="button"
                            >
                                Location
                            </Typography>
                            <LocationFilter
                                enabled={locationFilterEnabled}
                                initialSelectedItem={initialSelectedItem}
                                loading={isTeamFoldersLoading}
                                data={locationFilterData}
                                folderIsSelectable={true}
                                filterPlaceholderText="Filter Locations"
                                onComponentMount={this.onLocationFilterMounted}
                                onClick={this.onLocationFilterItemClicked}
                                onDataRequest={
                                    this.onLocationFilterDataRequested
                                }
                                onNextPageLoad={
                                    this.onLocationFilterLoadNextPage
                                }
                            />
                            <DropdownChips
                                className={classes.chipContainer}
                                classes={{
                                    chip: classes.chip,
                                    title: classes.navbarComponentTitle,
                                }}
                                title="STATUS"
                                placeholder="No selected statuses"
                                selectedItemsIds={this.getStatusSelectedIds(
                                    statusSelectedIds
                                )}
                                data={STATUSES}
                                onSelectedItemsChanged={
                                    this.onStatusSelectedIdsChanged
                                }
                                onItemDelete={this.onStatusDeleted}
                            />
                            <Typeahead
                                className={classes.chipContainer}
                                classes={{
                                    chip: classes.chip,
                                    title: classes.navbarComponentTitle,
                                }}
                                title="MODULE"
                                placeholder={'Start typing or click'}
                                type={TypeaheadType.DropdownChips}
                                multiple={true}
                                selectedItemsIds={typeSelectedIds}
                                data={types}
                                onItemAdded={this.onTypeAdded}
                                onItemDelete={this.onTypeDeleted}
                            />
                            <div className={classes.chipContainer}>
                                <Typography
                                    className={classes.navbarComponentTitle}
                                    variant="button"
                                >
                                    ASSIGNED TO
                                </Typography>
                                <Paper>
                                    <TextField
                                        className={classes.navbarInput}
                                        type="email"
                                        placeholder="Type the email and press enter"
                                        variant="standard"
                                        multiline={true}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                input: classes.navbarPlaceholder,
                                            },
                                        }}
                                        maxRows="2"
                                        value={assignedTo}
                                        onChange={this.onAssignedToChanged}
                                    />
                                </Paper>
                            </div>
                        </div>
                    </SideNavbar>
                ) : (
                    ''
                )}
                <div
                    className={`${classes.pageContent} ${
                        isPortal && isReportDrawerOpen
                            ? ` ${classes.pageContentWithDrawerOpen}`
                            : ''
                    }`}
                >
                    <div
                        className={`${classes.contentSearchContainer} ${classes.workOrderFilterContainer}`}
                    >
                        <div className={classes.searchInputContainer}>
                            <SearchInput
                                disabled={areReportsComponentsDisabled}
                                className={classes.searchInput}
                                placeholder="Find a Report..."
                                onSearchClick={this.onSearchClicked}
                                initialSearchValue={searchReportText}
                            />
                        </div>
                        <Button
                            className={classes.qrScanButton}
                            disabled={areReportsComponentsDisabled}
                            onClick={this.onAddReportByQRClicked}
                            color="info"
                        >
                            <QrCode className={classes.qrCodeIcon} /> SCAN
                        </Button>
                        <Tooltip
                            className={classes.addReportContainer}
                            title="Add Report"
                        >
                            <div>
                                <Button
                                    disabled={areReportsComponentsDisabled}
                                    onClick={this.onAddReportClicked}
                                    color="info"
                                >
                                    <AddIcon /> ADD
                                </Button>
                            </div>
                        </Tooltip>
                        {isPortal ? (
                            <Tooltip title="Export to CSV">
                                <div>
                                    <CSVButton
                                        disabled={areReportsComponentsDisabled}
                                        onClick={this.onExportToCSVClicked}
                                        className={classes.csvExportButton}
                                        color="info"
                                    >
                                        <GetApp /> EXPORT
                                    </CSVButton>
                                </div>
                            </Tooltip>
                        ) : (
                            ''
                        )}
                    </div>
                    <Divider
                        className={`${classes.divider} ${classes.summaryGridDivider}`}
                    />
                    <SummaryGrid
                        className={classes.contentContainer}
                        reports={teamReports}
                        isPortal={isPortal}
                        rowsPerPage={TEAM_REPORTS_MAX_ROWS}
                        totalRows={teamReportsTotal}
                        offset={teamReportsOffset}
                        emptyGridTitle="No matching work orders"
                        emptyGridSubTitle={
                            searchReportText ? 'Please adjust your search' : ''
                        }
                        onComponentMount={this.onGridMounted}
                        onPageOverBoundary={this.onTeamReportsPageOverBoundary}
                        onClick={this.onTeamReportsClicked}
                        onAgreesClick={this.onTeamReportsAgreeClicked}
                        onOpenVocClick={this.onGridOpenVocClicked}
                    />
                    <AddReportWizard
                        classes={{
                            listItems: classes.addReportWizardListItems,
                        }}
                        open={addReportWizardOpen}
                        steps={[
                            AddReportWizardSteps.FolderTree,
                            AddReportWizardSteps.Location,
                            AddReportWizardSteps.SubmitReport,
                        ]}
                        currentStep={addReportWizardCurrentStep}
                        showStepper={false}
                        isLoading={
                            isTeamMarkersLoading ||
                            isReportModuleGroupsLoading ||
                            isReportModulesLoading ||
                            isFloorplanMarkersLoading ||
                            isFolderFloorplansLoading
                        }
                        reportModules={reportModules}
                        reportModuleGroups={reportModuleGroups}
                        searchResults={floorplanMarkers}
                        searchResultsTotal={floorplanMarkersTotal}
                        searchRowsPerPage={MARKERS_MAX_ROWS}
                        selectedSearchItemId={
                            addReportSelectedMarker
                                ? addReportSelectedMarker.id
                                : undefined
                        }
                        selectedSearchItem={
                            addReportSelectedMarker
                                ? addReportSelectedMarker
                                : undefined
                        }
                        searchOffset={searchOffset}
                        selectedReportModuleGroupId={
                            addReportModuleGroup ? addReportModuleGroup.id : ''
                        }
                        onSearchBufferOverBoundary={
                            this.onSearchBufferOverBoundaryReached
                        }
                        onSearchClick={this.onAddReportSearchClicked}
                        onSelectedMarker={this.onAddReportSelectedMarker}
                        onWizardResult={this.onAddReportModalResult}
                        onReportModuleGroupSelect={
                            this.onAddReportModuleGroupSelected
                        }
                        onQRScan={this.onQRScanned}
                        folderTreeData={treeFolders}
                        onFolderTreeClick={this.onFolderTreeClick}
                        onFolderTreeToggle={this.onFolderTreeToggle}
                        foldersClicked={treeFoldersClicked}
                        foldersExpanded={treeFoldersExpanded}
                        selectedFloorplan={selectedFloorplan}
                        onSelectFloorplan={this.onSelectFloorplan}
                    />
                    <WorkOrderDetails
                        open={workOrderDetailsOpen}
                        reportDetail={currentReportDetail}
                        comments={reportDetailComments}
                        isPortal={isPortal}
                        isStatusRefreshing={
                            isReportDetailStatusChangeLoading ||
                            isReportDetailAssignLoading ||
                            isReportDetailDeleteLoading
                        }
                        showUpdateCategory={showUpdateCategory}
                        areCommentsLoading={
                            isReportDetailLoading ||
                            isReportDetailPostCommentLoading
                        }
                        vocFeedbackReports={
                            vocFeedback ? vocFeedback.reports : ([] as Report[])
                        }
                        onClose={this.closeWorkOrderDetails}
                        onStatusChange={this.onWorkOrderDetailsStatusChanged}
                        onAssigneeChange={
                            this.onWorkOrderDetailsAssigneeChanged
                        }
                        onDeleteReport={this.onWorkOrderDetailsDeleteReport}
                        onCommentPost={this.onCommentPosted}
                        onReturnToVoc={this.onReturnToVocClicked}
                        getCategoryList={this.getCategoryList}
                    />
                    {!isPortal && isVocPopupOpen ? (
                        <VocPopup
                            open={isVocPopupOpen}
                            moduleContent={currentVocFeedbackModule?.content}
                            readonly={isVocReadOnly}
                            onSubmit={this.onVocPopupSubmitted}
                            onCancel={this.onVocPopupCancelled}
                            onShowDetailClick={this.onVocPopupDetailClicked}
                        />
                    ) : (
                        ''
                    )}
                </div>
                <CCSnackbar
                    className={classes.snackbar}
                    variant={snackbarVariant}
                    message={snackbarMessage}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transition="right"
                    open={snackbarOpen && !areReportsComponentsDisabled}
                    onClose={this.onSnackbarClosed}
                />
                <UpdateCategoryWizard
                    reportModuleGroups={reportModuleGroups}
                    selectedReportModuleGroupId={
                        updateReportModuleGroup
                            ? updateReportModuleGroup.id
                            : currentReportDetail
                                ? currentReportDetail.moduleGroupId
                                : ''
                    }
                    open={updateCategoryOpen}
                    cancelCategoryUpdate={this.cancelCategoryUpdate}
                    onReportModuleGroupSelect={
                        this.onUpdateReportModuleGroupSelected
                    }
                    isLoading={isUpdateCategoryLoading}
                    submitDisabled={updateCategorySubmitDisabled}
                    submitCategoryUpdate={this.updateCategory}
                />
                {isCSVLoading ? (
                    <CCSpinner
                        className={classes.spinner}
                        loading={isCSVLoading}
                        overlayVisible={true}
                        showCompletion={true}
                        completion={csvExportCompletion}
                        label={'Converting Data to CSV. Please Wait...'}
                    />
                ) : (
                    <CCSpinner
                        className={classes.spinner}
                        loading={areReportsComponentsDisabled}
                        overlayVisible={true}
                        size={100}
                    />
                )}
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(ReportsTab);
export { MUIComponent as ReportsTab };
