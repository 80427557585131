export interface MouduleGroup {
    id: string;
    name: string;
}

export interface EntityAccount {
    account_id: string,
    name: string,
    structures: string[],
    timestamp: Date,
    web_idle_session_timeout_seconds?: number,
    scheduling: object | undefined,
    schedulingRoutes: object | undefined,
    tasking: object | undefined,
    annotation: object | undefined,
    moduleGroups: MouduleGroup[] | undefined
}

export class Account {
    static create (userData: EntityAccount): Account {
        const {
            account_id: accountId,
            name,
            structures,
            timestamp: timestampString,
            web_idle_session_timeout_seconds: webIdleSessionTimeoutSecondsRaw,
            scheduling,
            schedulingRoutes,
            tasking,
            annotation,
            moduleGroups
        } = userData;
        
        const timestamp = new Date(timestampString);
        const webIdleSessionTimeoutSeconds = webIdleSessionTimeoutSecondsRaw ? webIdleSessionTimeoutSecondsRaw : -1;

        return new Account(
            accountId,
            name,
            structures,
            timestamp,
            webIdleSessionTimeoutSeconds,
            scheduling,
            schedulingRoutes,
            tasking,
            annotation,
            moduleGroups
        );
    }

    constructor (
        public readonly accountId: string,
        public readonly name: string,
        public readonly structures: string[],
        public readonly timestamp: Date,
        public readonly webIdleSessionTimeoutSeconds: number,
        public readonly scheduling: object | undefined,
        public readonly schedulingRoutes: object | undefined,
        public readonly tasking: object | undefined,
        public readonly annotation: object | undefined,
        public readonly moduleGroups: MouduleGroup[] | undefined
    ) {
    }
}
