import {
    Button,
    TextField,
    Typography
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {},
    title: {},
    footerButton: {},
    subtitle: {},
    error: {},
    textField: {},
    wizardContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    input: {},
    inputLabel: {},
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    title: string;
    subtitle?: string;
    error?: string;
    footerButtonText: string;
    onFooterButtonClick?: (validationCode: number) => void;
}

interface States {
    validationCode: string;
}

class LoginWizardStepValidateAccount extends React.Component<Props, States> {
    public static defaultProps = {
    };

    state = {
        validationCode: ''
    };

    onValidationCodeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const validationCode = event.target.value as string;
        this.setState({validationCode});
    };

    onFooterButtonClicked = () => {
        const { onFooterButtonClick } = this.props;
        if (!onFooterButtonClick) {
            return;
        }
        const { validationCode } = this.state;
        onFooterButtonClick(parseInt(validationCode, 10));
    };

    public render() {
        const {
            classes,
            className,
            title,
            subtitle,
            error,
            footerButtonText
        } = this.props;
        const { validationCode } = this.state;

        const rootClassNames = classes.root + (className ? ` ${className}` : '');
        return (
            <div className={rootClassNames}>
                <div className={classes.wizardContainer}>
                    <Typography
                        className={classes.title}
                        variant="h4"
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="h6"
                        className={classes.error}
                    >
                        {((error!== undefined) && (error.length > 0)) ? error : '\u00a0'} {/* unicode non breaking space */}
                    </Typography>
                    <Typography
                        className={classes.subtitle}
                        variant="subtitle1"
                    >
                        {subtitle}
                    </Typography>
                    <TextField
                        id="validation_code"
                        className={classes.textField}
                        label="Validation Code"
                        type="code"
                        name="validation_code"
                        margin="normal"
                        variant="outlined"
                        value={validationCode}
                        onChange={this.onValidationCodeChanged}
                        InputProps={{ 
                            classes: { 
                                input: classes.input
                            }
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel
                            }
                        }}
                    />
                </div>
                <Button
                    className={classes.footerButton}
                    variant="contained"
                    color="secondary"
                    onClick={this.onFooterButtonClicked}
                >
                    {footerButtonText}
                </Button>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(LoginWizardStepValidateAccount);
export {MUIComponent as LoginWizardStepValidateAccount};
