export interface EntityFolder {
    folder_id: string;
    folder_name: string;
}

export class Folder {
    static create (userData: EntityFolder): Folder {
        const {
            folder_id: id,
            folder_name: name
        } = userData;

        return new Folder(
            id,
            name
        );
    }

    constructor (
        public readonly id: string,
        public readonly name: string
    ) {}
}
