// "feedback_modules":[
//     {
//        "summary_widget_id":"toggle_1",
//        "name":"Sample 1",
//        "timestamp":"2019-11-06T15:25:31.837598",
//        "_rev":"1-add8364832dfb6235bf4ca928e43a072",
//        "user_feedback_module_id":"sample_1",
//        "content":[
//           {
//              "text":"Was the service issue resolved and your needs met?",
//              "type":"label"
//           },
//           {
//              "default_selected_index":-1,
//              "required":true,
//              "selected_index":-1,
//              "widget_id":"toggle_1",
//              "type":"image_toggle",
//              "options":[
//                 {
//                    "text":"",
//                    "image":"happy",
//                    "value":1
//                 },
//                 {
//                    "text":"",
//                    "image":"neutral",
//                    "value":0
//                 },
//                 {
//                    "text":"",
//                    "image":"sad",
//                    "value":-1
//                 }
//              ]
//           },
//           {
//              "text":"Was using CrowdComfort to report the issue easy and efficient?",
//              "type":"label"
//           },
//           {
//              "default_selected_index":-1,
//              "required":true,
//              "selected_index":-1,
//              "widget_id":"toggle_2",
//              "type":"image_toggle",
//              "options":[
//                 {
//                    "text":"",
//                    "image":"happy",
//                    "value":1
//                 },
//                 {
//                    "text":"",
//                    "image":"neutral",
//                    "value":0
//                 },
//                 {
//                    "text":"",
//                    "image":"sad",
//                    "value":-1
//                 }
//              ]
//           },
//           {
//              "required":false,
//              "type":"textfield",
//              "widget_id":"comments",
//              "hint":"Add your comments here..."
//           }
//        ],
//        "_id":"user_feedback_module::sample_1",
//        "type":"user_feedback_module"
//     }
//  ]

export enum VocWidgetType {
    Label = 0,
    ImageToggle,
    Textfield,
}
export type submitVocWidgetData =
    | VocFeedbackModuleContentLabelModel
    | VocFeedbackModuleContentImageToggleOptionSubmit
    | VocFeedbackModuleContentTextfieldModelSubmit;

export interface VocFeedbackModuleContentLabelModel {
    text: string;
    type: 'label';
}
export interface VocFeedbackModuleContentLabel {
    text: string;
    type: VocWidgetType.Label;
}

export interface VocFeedbackModuleContentImageToggleOption {
    image: string;
    text: string;
    value: number;
}
export interface VocFeedbackModuleContentImageToggleOptionSubmit
    extends VocFeedbackModuleContentImageToggleOption {
    type: 'image_toggle';
    widget_id: string;
}
export interface VocFeedbackModuleContentImageToggleModel {
    image: string;
    default_selected_index: number;
    options: VocFeedbackModuleContentImageToggleOption[];
    required: boolean;
    selected_index: number;
    type: 'image_toggle';
    value?: number;
    widget_id: string;
}
export interface VocFeedbackModuleContentImageToggle {
    image: string;
    defaultSelectedIndex: number;
    options: VocFeedbackModuleContentImageToggleOption[];
    required: boolean;
    selectedIndex: number;
    type: VocWidgetType.ImageToggle;
    value?: number;
    widgetId: string;
}

export interface VocFeedbackModuleContentTextfieldModelSubmit {
    text: string;
    type: 'textfield';
    widget_id?: string;
}
export interface VocFeedbackModuleContentTextfieldModel {
    hint: string;
    text?: string;
    required: boolean;
    type: 'textfield';
    widget_id: string;
}
export interface VocFeedbackModuleContentTextfield {
    hint: string;
    text?: string;
    required: boolean;
    type: VocWidgetType.Textfield;
    widgetId: string;
}

export interface VocFeedbackModuleModel {
    content?: VocFeedbackModuleContentModel[];
    details?: VocFeedbackModuleContentModel[];
    name: string;
    summary_widget_id: string;
    timestamp: string;
    type: string;
    user_feedback_module_id: string;
    _id?: string;
}

export type VocFeedbackModuleContentModel =
    | VocFeedbackModuleContentLabelModel
    | VocFeedbackModuleContentImageToggleModel
    | VocFeedbackModuleContentTextfieldModel;

export type VocFeedbackModuleContent =
    | VocFeedbackModuleContentLabel
    | VocFeedbackModuleContentImageToggle
    | VocFeedbackModuleContentTextfield;

export class VocFeedbackModule {
    static create(model: VocFeedbackModuleModel): VocFeedbackModule | null {
        const {
            content: contentModel,
            details: detailsModel,
            name,
            summary_widget_id: summaryWidgetId,
            user_feedback_module_id: userFeedbackModuleId,
            timestamp: serializedTimestamp,
            type: typeModel,
        } = model;

        // TODO: Ask scott to include type into details key
        let type = typeModel;
        if (!type && detailsModel) {
            type = 'user_feedback_module';
        }

        if (type !== 'user_feedback_module') {
            return null;
        }

        const timestamp = new Date(serializedTimestamp);
        let finalContentModel: VocFeedbackModuleContentModel[] =
            [] as VocFeedbackModuleContentModel[];
        if (contentModel) {
            finalContentModel = contentModel;
        } else if (detailsModel) {
            finalContentModel = detailsModel;
        }

        const content = finalContentModel.map(
            (contentItem: VocFeedbackModuleContentModel) =>
                VocFeedbackModule.parseContentModel(contentItem)
        );

        return new VocFeedbackModule(
            content,
            name,
            summaryWidgetId,
            timestamp,
            userFeedbackModuleId
        );
    }

    private static parseContentModel(
        contentModel: VocFeedbackModuleContentModel
    ): VocFeedbackModuleContent {
        let result: VocFeedbackModuleContent;
        switch (contentModel.type) {
            case 'label': {
                const { text } =
                    contentModel as VocFeedbackModuleContentLabelModel;
                result = {
                    text,
                    type: VocWidgetType.Label,
                } as VocFeedbackModuleContentLabel;
                break;
            }
            case 'image_toggle': {
                const {
                    image,
                    default_selected_index: defaultSelectedIndex,
                    options,
                    required,
                    selected_index: selectedIndex,
                    value,
                    widget_id: widgetId,
                } = contentModel as VocFeedbackModuleContentImageToggleModel;

                let currentImage = image;
                if (
                    !currentImage &&
                    options &&
                    options.length > 0 &&
                    selectedIndex >= 0
                ) {
                    currentImage = options[selectedIndex].image;
                }

                result = {
                    image: currentImage,
                    defaultSelectedIndex,
                    options,
                    required,
                    selectedIndex,
                    widgetId,
                    value,
                    type: VocWidgetType.ImageToggle,
                } as VocFeedbackModuleContentImageToggle;
                break;
            }
            case 'textfield': {
                const {
                    hint,
                    text,
                    required,
                    widget_id: widgetId,
                } = contentModel as VocFeedbackModuleContentTextfieldModel;
                result = {
                    hint,
                    text,
                    required,
                    widgetId,
                    type: VocWidgetType.Textfield,
                } as VocFeedbackModuleContentTextfield;
                break;
            }
        }
        return result;
    }

    constructor(
        public readonly content: VocFeedbackModuleContent[],
        public readonly name: string,
        public readonly summaryWidgetId: string,
        public readonly timestamp: Date,
        public readonly userFeedbackModuleId: string
    ) {}
}
