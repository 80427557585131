/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

export type WithMediaQuery<
  Props extends Record<string, unknown>
> = Props & {
    mediaQuery?: boolean;
};

export function withMediaQuery (breakpoint: string): (Component: any) => (props: any) => JSX.Element {
    const returnFunction = (Component: any) => {
        const innerFunction = (props: any) => {
            const mediaQuery = useMediaQuery(breakpoint);
            return <Component mediaQuery={mediaQuery} {...props} />;
        };
        return innerFunction;
    };
    return returnFunction;
}
