import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
import {
    CameraType,
    QRScanner
} from '../../../shared/components/qr-scanner-v3';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '87%'
    }
});

interface Props {
    onQRScan?: (markerId: string) => Promise<boolean>;
    facingMode?: CameraType;
}

interface States {
    resultsQRText: string;
}

class AddReportWizardStepQrLocation extends React.Component<Props & WithStyles<typeof styles>, States> {
    public static defaultProps = {};

    state = {
        resultsQRText: 'Validating QR code...'
    };

    onQRScan = async (result: string) => {
        // Check if is a valid cc QR code
        if (result.includes('marker_id=')) {
            const { onQRScan } = this.props;
            if (!onQRScan) {
                return;
            }

            const index = result.indexOf('?');
            const queryStrings = result.substr(index, result.length).replace('?', '').split('&');
            const marker = queryStrings.filter((queryString) => queryString.includes('marker_id='));
            const markerId = marker[0].replace('marker_id=', '');
            onQRScan(markerId).then((foundItem) => {
                if (!foundItem) {
                    this.setState({
                        resultsQRText: 'You do not have permissions to add report at this marker, try with another one'
                    });
                }
            });
        } else {
            this.setState({
                resultsQRText: 'Invalid QR code - Check if this Marker QR code, belongs to CrowdComfort'
            });
        }
    };

    public render() {
        const {
            classes,
            facingMode
        } = this.props;

        const {
            resultsQRText
        } = this.state;

        return (
            <div className={classes.root}>
                <QRScanner
                    onQRScan={this.onQRScan}
                    noResultsText="Please scan your Marker's QR code"
                    resultsText={resultsQRText}
                    facingMode={facingMode}
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(AddReportWizardStepQrLocation);
export {MUIComponent as AddReportWizardStepQrLocation};
export type AddReportWizardStepQrLocationProps = Props;
