import * as React from 'react';

import { Button, IconButton, Typography } from '@mui/material';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import memoize from 'memoize-one';
import { inject, observer } from 'mobx-react';
import { HttpConnectorAdapter } from '../../adapters/http-connector-adapter';
import { CCIdleTimer } from '../../components/cc-idle-timer';
import { CCToolbar } from '../../components/cc-toolbar';
import { CCOverlay } from '../../shared/components/cc-overlay';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { TabControl, TabDescriptor } from '../../shared/components/tab-control';
import {
    AnalyticsTab as AnalyticsTabDomain,
    UserAnalyticsTab,
    UserTeam,
} from '../../shared/domain';
import { MainTabs } from '../../stores/manager.store';
import { RootStore } from '../../stores/root.store';
import { ROUTE_NAMES } from '../../stores/routes';
import { AnalyticsTab } from './analytics-tab';
import { ReportsTab } from './reports-tab';
import { SchedulesTab } from './schedules-tab';
import { RoutesTab } from './routes-tab';
import { TasksTab } from './tasks-tab';
import { isMobile } from '../../shared/utils/browser-check';
import { SimpleModal } from '../../shared/components/simple-modal';
import { PasswordChange } from '../../shared/components/password-change';
import { Close } from '@mui/icons-material';
import { CCSnackbar, CCSnackbarVariant } from '../../shared/components/cc-snackbar';
import { AssignmentsTab } from './assignments-tab';

const UserAnalyticsTabType = 'uat';
const ReportTabType = 'rt';
const RoutesTabType = 'rot';
const SchedulesTabType = 'sch';
const TasksTabType = 'tsk';
const AssignmentsTabType = 'asg';
interface DataUserAnalyticsTab {
    tab?: UserAnalyticsTab;
    tabOrder: number;
    type: 'uat' | 'rt' | 'rot' | 'sch' | 'tsk' | 'asg';
}

const scheduleTabDescriptor: TabDescriptor = {
    label: 'Schedule',
    data: {
        tab: {
            id: 'sch',
            name: 'Schedules',
        },
        tabOrder: 100,
        type: SchedulesTabType,
    } as DataUserAnalyticsTab
};

const routesTabDescriptor: TabDescriptor = {
    label: 'Routes',
    data: {
        tab: {
            id: 'rot',
            name: 'Routes',
        },
        tabOrder: 100,
        type: RoutesTabType,
    } as DataUserAnalyticsTab
};

const assignmentsTabDescriptor: TabDescriptor = {
    label: 'Assignments',
    data: {
        tab: {
            id: 'asg',
            name: 'Assignments',
        },
        tabOrder: 100,
        type: AssignmentsTabType,
    } as DataUserAnalyticsTab
};

const taskTabDescriptor: TabDescriptor = {
    label: 'Task',
    data: {
        tab: {
            id: 'tsk',
            name: 'Tasks',
        },
        tabOrder: 100,
        type: TasksTabType,
    } as DataUserAnalyticsTab
};

const baseTabsDescriptors: TabDescriptor[] = [
    {
        label: 'Reports',
        data: {
            tab: {
                id: 'rep',
                name: 'Reports',
            },
            tabOrder: 100,
            type: ReportTabType,
        } as DataUserAnalyticsTab,
    }
];

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: 'white',
            overflowX: 'hidden',
        },
        error: {
            fontWeight: 'bolder',
            color: 'red',
            margin: 'auto auto',
        },
        tabContentContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        tabsPaper: {
            [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8_Landscape)]: {
                paddingLeft: '2.5em'
            }
        },
        overlay: {
            position: 'fixed',
            backgroundColor: theme.ccPalette.disabled.dark,
            zIndex: 1400,
            '& > h1, & > h2': {
                textAlign: 'center',
                color: '#e0e0e0',
                textShadow: '1px 1px 2px #000000',
                cursor: 'default',
            },
            '& > button': {
                marginTop: '1em',
            },
        },
        analyticsTab: {
            '$isMobile &': {
                display: 'none',
            },
            [theme.breakpoints.only('xs')]: {
                display: 'none',
            },
        },
        isMobile: {},
        changePasswordModal: {
            minWidth: '30em',
            padding: '1.5em',
            width: '30%',
            position: 'relative',
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                minWidth: 'auto',
            },
        },
        closeButton: {
            position: 'absolute',
            right: '0.5em',
            top: '0.5em'
        },
        snackbar: {
            top: '7em',
        },
    });

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
}

interface States {
    logoutButtonDisabled: boolean;
    loginTimeout: boolean;
    dataIsLoading: boolean;
    loadingErrorMessage: string | undefined;
    isChangePasswordOpen: boolean;
    isPasswordChanged: string | undefined;
    snackbarVariant: CCSnackbarVariant;
    snackbarMessage: string;
    snackbarOpen: boolean;
}

@inject('rootStore')
@observer
class Home extends React.Component<Props, States> {
    // DO NOT REMOVE Partial<Props>. It is needed by the rootStore
    public static defaultProps: Partial<Props> = {};

    state = {
        dataIsLoading: true,
        loadingErrorMessage: undefined as string | undefined,
        logoutButtonDisabled: false,
        loginTimeout: false,
        isChangePasswordOpen: false,
        isPasswordChanged: undefined,
        snackbarVariant: 'info' as CCSnackbarVariant,
        snackbarMessage: '',
        snackbarOpen: false,
    };
    updateTabs = undefined as { [key: string]: unknown } | undefined;

    sortTabDescriptors = memoize((tabDescriptors: TabDescriptor[]) => {
        const tabDescriptorsSorted = tabDescriptors?.sort(
            (a: TabDescriptor, b: TabDescriptor): number => {
                const aTabData = a?.data as DataUserAnalyticsTab;
                const bTabData = b?.data as DataUserAnalyticsTab;
                const aTabOrder = aTabData ? aTabData.tabOrder : 0;
                const bTabOrder = bTabData ? bTabData.tabOrder : 0;
                if (aTabOrder < bTabOrder) {
                    return -1;
                }
                if (aTabOrder > bTabOrder) {
                    return 1;
                }
                // a must be equal to b
                return 0;
            }
        );

        return tabDescriptorsSorted
            ? tabDescriptorsSorted
            : ([] as TabDescriptor[]);
    });

    createTabsList = memoize(
        (userTabs: UserAnalyticsTab[] | undefined): TabDescriptor[] => {
            if (!userTabs) {
                return [];
            }
            const { classes } = this.props;
            const results = this.sortAnalyticsTabs(userTabs).map(
                (tab: UserAnalyticsTab) => {
                    return {
                        className: classes.analyticsTab,
                        label: tab.name,
                        data: {
                            tab,
                            tabOrder: tab.tab_order,
                            type: UserAnalyticsTabType,
                        } as DataUserAnalyticsTab,
                    } as TabDescriptor;
                }
            );
            return results;
        }
    );

    createTabsChildrenFromUserAnalyticsTab = memoize(
        (
            userTabsDescriptors: TabDescriptor[] | undefined,
            currentUserTeam: UserTeam | undefined
        ): JSX.Element[] => {
            if (!userTabsDescriptors) {
                return [];
            }
            const { rootStore } = this.props;
            const results = userTabsDescriptors.map((tabDescriptor) => {
                const { tab, type } =
                    tabDescriptor.data as DataUserAnalyticsTab;
                switch (type) {
                    case ReportTabType: {
                        const isPortal = currentUserTeam
                            ? currentUserTeam.portal
                            : false;
                        return (
                            <ReportsTab
                                key={
                                    currentUserTeam
                                        ? currentUserTeam.teamId
                                        : 'rt'
                                }
                                userTeam={currentUserTeam}
                                isPortal={isPortal}
                            />
                        );
                    }
                    case SchedulesTabType: {
                        return (
                            <SchedulesTab
                                key={`schedule-${tab ? tab.id : '0'}`}
                                rootStore={rootStore}
                            />
                        );
                    }
                    case TasksTabType: {
                        return (
                            <TasksTab
                                key={`task-${tab ? tab.id : '0'}`}
                                rootStore={rootStore}
                            />
                        );
                    }
                    case RoutesTabType: {
                        return (
                            <RoutesTab
                                key={`routes-${tab ? tab.id : '0'}`}
                                userTeam={currentUserTeam}
                                rootStore={rootStore}
                            />
                        );
                    }
                    case AssignmentsTabType: {
                        return (
                            <AssignmentsTab
                                key={`assignments-${tab ? tab.id : '0'}`}
                                userTeam={currentUserTeam}
                                rootStore={rootStore}
                            />
                        );
                    }
                    case UserAnalyticsTabType: {
                        return (
                            <AnalyticsTab
                                key={`at-${tab ? tab.id : '0'}`}
                                rootStore={rootStore}
                            />
                        );
                    }
                }
            });
            return results;
        }
    );

    getTabIndexFromAnalyticsTab = (
        (
            userTabs: TabDescriptor[] | undefined,
            selectedUserTab: AnalyticsTabDomain | undefined
        ): number => {
            const userTabLength = userTabs ? userTabs.length : 0;
            if (!userTabs || userTabLength < 2) {
                return 0;
            }

            const {
                rootStore: { managerStore },
            } = this.props;

            let results = -1;
            // If there are no selected user tabs
            // It return the index of the tab on the left
            // which is the one with the lowest index value.
            if (!selectedUserTab) {
                const { currentSelectedTabIndex, currentRouteName } =
                    managerStore;

                if (currentSelectedTabIndex >= 0) {
                    return currentSelectedTabIndex;
                }

                if (currentRouteName === ROUTE_NAMES.REPORTS) {
                    results = userTabs.findIndex((tab) => {
                        const data = tab.data as DataUserAnalyticsTab;
                        if (!data) {
                            return false;
                        }
                        return data.type === ReportTabType;
                    });
                }
                if (currentRouteName === ROUTE_NAMES.ROUTES) {
                    results = userTabs.findIndex((tab) => {
                        const data = tab.data as DataUserAnalyticsTab;
                        if (!data) {
                            return false;
                        }
                        return data.type === RoutesTabType;
                    });
                }
                if (currentRouteName === ROUTE_NAMES.ASSIGNMENTS) {
                    results = userTabs.findIndex((tab) => {
                        const data = tab.data as DataUserAnalyticsTab;
                        if (!data) {
                            return false;
                        }
                        return data.type === AssignmentsTabType;
                    });
                }
                if (currentRouteName === ROUTE_NAMES.SCHEDULE) {
                    results = userTabs.findIndex((tab) => {
                        const data = tab.data as DataUserAnalyticsTab;
                        if (!data) {
                            return false;
                        }
                        return data.type === SchedulesTabType;
                    });
                }
                if (currentRouteName === ROUTE_NAMES.TASK) {
                    results = userTabs.findIndex((tab) => {
                        const data = tab.data as DataUserAnalyticsTab;
                        if (!data) {
                            return false;
                        }
                        return data.type === TasksTabType;
                    });
                }
                if (results < 0) {
                    let minimumValue = 100000;
                    userTabs.forEach((tab, index) => {
                        if (!tab.data) {
                            return;
                        }
                        const tabOrder = (tab.data as DataUserAnalyticsTab)
                            .tabOrder;
                        if (tabOrder < minimumValue) {
                            minimumValue = tabOrder;
                            results = index;
                        }
                    });
                }
            } else {
                // If we have a selected user tab we figure out its index.
                const { id: CurrentTabId } = selectedUserTab;
                results = userTabs.findIndex((tab) => {
                    if (!tab.data) {
                        return false;
                    }
                    const { tab: tabData } = tab.data as DataUserAnalyticsTab;
                    return tabData?.id === CurrentTabId;
                });
            }

            if (results < 0) {
                results = 0;
            }

            return results;
        }
    );

    sortAnalyticsTabs = (analyticsTabs: UserAnalyticsTab[]) => {
        if (!analyticsTabs) {
            return [];
        }

        return analyticsTabs.sort(
            (a: UserAnalyticsTab, b: UserAnalyticsTab): number => {
                const nameA = a.name.trim().toLocaleLowerCase();
                const nameB = b.name.trim().toLocaleLowerCase();
                if (nameA < nameB) {
                    return -1;
                }

                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
        );
    };

    componentDidMount() {
        const {
            rootStore: { managerStore },
        } = this.props;

        // Force to load the login authorization values
        const loginAuthorization = managerStore.loginAuthorization;
        if (loginAuthorization) {
            HttpConnectorAdapter.setAuthorization(loginAuthorization);
        }

        const loggedInUser = managerStore.loggedInUser;
        this.setState({ dataIsLoading: true });

        if (!managerStore.user && loggedInUser) {
            managerStore
                .getUser(loggedInUser.userId)
                .then(() => {
                    managerStore
                        .getUserTeams()
                        .then(() => {
                            this.setState({ dataIsLoading: false });
                        })
                        .catch(() => {
                            const { error } = managerStore;
                            this.setState({
                                dataIsLoading: false,
                                loadingErrorMessage: error
                                    ? error
                                    : "Error loading User's Team",
                            });
                        });
                })
                .catch(({ errorStatus, errorData }) => {
                    const { errorMessage } = errorData;
                    this.setState({ dataIsLoading: false });
                    if (errorStatus === 400 || errorStatus === 401) {
                        this.onSessionExpired();
                    } else if (errorMessage === 'Refresh token expired') {
                        managerStore.logout();
                    } else {
                        const { error } = managerStore;
                        this.setState({
                            dataIsLoading: false,
                            loadingErrorMessage: error
                                ? error
                                : 'Error loading User',
                        });
                    }
                });
        } else {
            managerStore
                .getUserTeams()
                .then(() => {
                    this.setState({ dataIsLoading: false });
                })
                .catch(() => {
                    const { error } = managerStore;
                    this.setState({
                        dataIsLoading: false,
                        loadingErrorMessage: error
                            ? error
                            : 'Error loading team',
                    });
                });
        }
    }

    componentDidUpdate() {
        if (!this.updateTabs) {
            return;
        }
        const { selectedTabIndex, currentTabDescriptor } = this.updateTabs;
        this.onTabChanged(
            selectedTabIndex as number,
            currentTabDescriptor as TabDescriptor
        );
        this.updateTabs = undefined;
    }

    getMainTabFromTabDescriptor(selectedTab: TabDescriptor): MainTabs {
        const { data } = selectedTab;
        if (
            !!data &&
            (data as DataUserAnalyticsTab).type === UserAnalyticsTabType
        ) {
            return MainTabs.Analytics;
        } else if (
            !!data &&
            (data as DataUserAnalyticsTab).type === RoutesTabType
        ) {
            return MainTabs.Routes;
        } else if (
            !!data &&
            (data as DataUserAnalyticsTab).type === AssignmentsTabType
        ) {
            return MainTabs.Assignments;
        } else if (
            !!data &&
            (data as DataUserAnalyticsTab).type === SchedulesTabType
        ) {
            return MainTabs.Schedule;
        } else if (
            !!data &&
            (data as DataUserAnalyticsTab).type === TasksTabType
        ) {
            return MainTabs.Task;
        }

        return MainTabs.Reports;
    }

    showSnackbar = (
        snackbarMessage: string,
        snackbarVariant: CCSnackbarVariant
    ) => {
        this.setState({
            snackbarVariant,
            snackbarMessage,
            snackbarOpen: true,
        });
    };

    onSnackbarClosed = () => {
        this.setState({ snackbarOpen: false });
    };

    //#region Events
    onTeamClicked = (userTeam: UserTeam) => {
        const {
            rootStore: { managerStore },
        } = this.props;

        // Reset any error present with the previous team.
        managerStore.error = '';
        //Set the selected team.
        managerStore.setCurrentUserTeam(userTeam);
    };

    onLogoutClicked = () => {
        const {
            rootStore: { managerStore },
        } = this.props;

        this.setState({ logoutButtonDisabled: true });
        managerStore.logout();
    };

    onTabChanged = (selectedTabIndex: number, selectedTab: TabDescriptor) => {
        const {
            rootStore: { routerStore, managerStore },
        } = this.props;
        const currentTabType = this.getMainTabFromTabDescriptor(selectedTab);

        managerStore.setCurrentSelectedTabIndex(selectedTabIndex);

        switch (currentTabType) {
            case MainTabs.Analytics: {
                const tabData = (selectedTab.data as DataUserAnalyticsTab).tab;
                const tabId = tabData?.id ? tabData.id : '';
                if (tabId) {
                    routerStore.goTo(ROUTE_NAMES.ANALYTICS, { tabId });
                } else {
                    routerStore.goTo(ROUTE_NAMES.REPORTS);
                }
                break;
            }
            case MainTabs.Routes: {
                managerStore.clearCurrentAnalyticsTab();
                managerStore.getUserTeams();
                routerStore.goTo(ROUTE_NAMES.ROUTES);
                break;
            }
            case MainTabs.Assignments: {
                managerStore.clearCurrentAnalyticsTab();
                managerStore.getUserTeams();
                routerStore.goTo(ROUTE_NAMES.ASSIGNMENTS);
                break;
            }
            case MainTabs.Schedule: {
                managerStore.clearCurrentAnalyticsTab();
                managerStore.getUserTeams();
                routerStore.goTo(ROUTE_NAMES.SCHEDULE);
                break;
            }
            case MainTabs.Task: {
                managerStore.clearCurrentAnalyticsTab();
                managerStore.getUserTeams();
                routerStore.goTo(ROUTE_NAMES.TASK);
                break;
            }
            default:
                managerStore.clearCurrentAnalyticsTab();
                managerStore.getUserTeams();
                routerStore.goTo(ROUTE_NAMES.REPORTS);
                break;
        }
    };

    onSessionExpired = () => {
        this.setState({ loginTimeout: true });
    };
    //#endregion

    onChangePasswordClicked = () => {
        this.setState({ isChangePasswordOpen: true });
    };

    onChangePasswordSubmitted = (oldPassword: string, newPassword: string) => {
        const {
            rootStore: { managerStore },
        } = this.props;

        managerStore.changePassword(oldPassword, newPassword).then((response) => {
            this.setState({
                isPasswordChanged: response,
                isChangePasswordOpen: false
            });
        }).catch((error) => {
            this.setState({ isChangePasswordOpen: false });
            this.showSnackbar(error, 'error' as CCSnackbarVariant);
        });
    };

    onCloseClicked = () => {
        this.setState({ isChangePasswordOpen: false });
    };

    public render() {
        const {
            classes,
            rootStore: { managerStore },
        } = this.props;

        const {
            dataIsLoading,
            loadingErrorMessage,
            logoutButtonDisabled,
            loginTimeout,
            isChangePasswordOpen,
            isPasswordChanged,
            snackbarVariant,
            snackbarMessage,
            snackbarOpen
        } = this.state;

        const {
            currentAccount,
            currentUserTeam,
            currentAnalyticsTab,
            currentSelectedTabIndex,
            isSessionExpired,
            isTeamLoading,
            isLoginLoading,
            loggedInUser,
            userTeams,
            user,
        } = managerStore;

        const webIdleSessionTimeoutSeconds = currentAccount
            ? currentAccount.webIdleSessionTimeoutSeconds
            : -1;
        let tabsDescriptors = [] as TabDescriptor[];
        let selectedTabIndex = 0;

        if (!dataIsLoading) {
            const analytics = user ? user.analytics : undefined;
            const analyticsTabs =
                !!analytics && !!analytics.tabs ? analytics.tabs : undefined;
            const isAnalyticsEnabled = analyticsTabs !== undefined;

            // Check to adding schedule tab
            let tabsDescriptorsUpdated = [...baseTabsDescriptors];
            if (currentAccount?.scheduling !== undefined) {
                tabsDescriptorsUpdated = [...tabsDescriptorsUpdated, scheduleTabDescriptor];
            }

            if (currentAccount?.schedulingRoutes !== undefined) {
                tabsDescriptorsUpdated = [...tabsDescriptorsUpdated, routesTabDescriptor, assignmentsTabDescriptor];
            }

            if (currentAccount?.tasking !== undefined) {
                tabsDescriptorsUpdated = [...tabsDescriptorsUpdated, taskTabDescriptor];
            }

            if (isAnalyticsEnabled) {
                tabsDescriptorsUpdated = [...tabsDescriptorsUpdated, ...this.createTabsList(analyticsTabs)];
            }

            tabsDescriptors = this.sortTabDescriptors(tabsDescriptorsUpdated);
            selectedTabIndex = this.getTabIndexFromAnalyticsTab(
                tabsDescriptors,
                currentAnalyticsTab
            );
            const currentTabDescriptor =
                selectedTabIndex >= 0
                    ? tabsDescriptors[selectedTabIndex]
                    : undefined;
            if (
                currentSelectedTabIndex !== selectedTabIndex &&
                currentTabDescriptor &&
                (currentTabDescriptor?.data as DataUserAnalyticsTab).type ===
                    UserAnalyticsTabType
            ) {
                this.updateTabs = { selectedTabIndex, currentTabDescriptor };
            }
        }

        const rootClasses = `${classes.root}${
            isMobile() ? ` ${classes.isMobile}` : ''
        }`;
        return (
            <div className={rootClasses}>
                <CCIdleTimer
                    enabled={webIdleSessionTimeoutSeconds > 0}
                    timeout={webIdleSessionTimeoutSeconds}
                    onTimeout={this.onSessionExpired}
                />
                <CCToolbar
                    userName={loggedInUser ? loggedInUser.username : ''}
                    teams={userTeams}
                    teamSelectedId={
                        currentUserTeam ? currentUserTeam.teamId : ''
                    }
                    onLogout={this.onLogoutClicked}
                    onTeamClick={this.onTeamClicked}
                    onChangePasswordClicked={this.onChangePasswordClicked}
                />
                <CCSpinner
                    loading={dataIsLoading || isTeamLoading || isLoginLoading}
                    overlayVisible={true}
                    size={100}
                >
                    {loadingErrorMessage ? (
                        <Typography className={classes.error} variant="h2">
                            {loadingErrorMessage}
                        </Typography>
                    ) : (
                        <div data-testId="container-tabControl">
                            <TabControl
                                tabsDescriptors={tabsDescriptors}
                                classes={{
                                    tabContentContainer: classes.tabContentContainer,
                                    paper: classes.tabsPaper
                                }}
                                selectedTab={selectedTabIndex}
                                onTabChange={this.onTabChanged}
                            >
                                {this.createTabsChildrenFromUserAnalyticsTab(
                                    tabsDescriptors,
                                    currentUserTeam
                                )}
                            </TabControl>
                            <SimpleModal
                                className={classes.changePasswordModal}
                                open={isChangePasswordOpen}
                                buttonOkLabel=""
                                buttonCancelLabel="">
                                <div data-testId="container-changePassword">
                                    <IconButton
                                        className={classes.closeButton}
                                        onClick={this.onCloseClicked}
                                    >
                                        <Close />
                                    </IconButton>
                                    <PasswordChange
                                        onChangePasswordSubmitted={this.onChangePasswordSubmitted}
                                    />
                                </div>
                            </SimpleModal>
                        </div>
                    )}
                </CCSpinner>
                <CCOverlay
                    className={classes.overlay}
                    hideChildren={true}
                    visible={isSessionExpired || loginTimeout || isPasswordChanged}
                >
                    <Typography variant="h3" component="h1" gutterBottom={true}>
                        { isPasswordChanged ? 'Password Changed Successfully' : 'Session Timed Out' }
                    </Typography>
                    <Typography variant="h4" component="h2" gutterBottom={true}>
                        Please login again
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={logoutButtonDisabled}
                        onClick={this.onLogoutClicked}
                        data-testId="overlay-userLogout"
                    >
                        Log out
                    </Button>
                </CCOverlay>
                <CCSnackbar
                    className={classes.snackbar}
                    variant={snackbarVariant}
                    message={snackbarMessage}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transition="right"
                    open={snackbarOpen}
                    onClose={this.onSnackbarClosed}
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(Home);
export { MUIComponent as Home };
