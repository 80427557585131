import * as React from 'react';

import {
    Close,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Menu
} from '@mui/icons-material';
import {
    Drawer,
    IconButton
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';

import { RESPONSIVE_BREAKPOINTS } from '../../utils/mui-utils';
import {
    withMediaQuery,
    WithMediaQuery
} from '../with-media-query/with-media-query';

const PAGE_BACKGROUND_COLOR = '#FFFFFF';
const DRAWER_BACKGROUND_COLOR = '#F9F9F9';
const ARROW_COLOR = '#686868';
const DRAWER_MIN_WIDTH = 200;

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        position: 'relative',
        height: '100%',
        '&$persistent $drawer $drawerPaper': {
            position: 'inherit'
        }
    },
    drawer: {
        overflow: 'visible',
        marginLeft: 0,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: 1
    },
    drawerCollapsed: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        zIndex: 'auto'
    },
    collapsedDrawerArea: {
        backgroundColor: DRAWER_BACKGROUND_COLOR,
        position: 'absolute',
        height: '100%',
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8_Landscape)]: {
            display: 'none'
        }
    },
    persistent: {},
    drawerPaper: {
        overflow: 'visible',
        backgroundColor: DRAWER_BACKGROUND_COLOR,
        minWidth: DRAWER_MIN_WIDTH
    },
    drawerToggleButton: {
        position: 'absolute',
        top: '50%',
        right: '-14px',
        padding: 0,
        color: theme.palette.getContrastText(PAGE_BACKGROUND_COLOR),
        backgroundColor: PAGE_BACKGROUND_COLOR,
        width: '1em',
        '&:hover': {
            backgroundColor: PAGE_BACKGROUND_COLOR,
            color: ARROW_COLOR
        },
        '&.btnSelected': {
            color: theme.palette.getContrastText(DRAWER_BACKGROUND_COLOR),
            backgroundColor: DRAWER_BACKGROUND_COLOR,
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
            '&:hover': {
                backgroundColor: DRAWER_BACKGROUND_COLOR,
                color: ARROW_COLOR
            },
            [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8_Landscape)]: {
                border: 0
            }
        },
        '&.btnCollapsed, &.btnCollapsedArea': {
            backgroundColor: 'transparent',
        },
        '&.btnCollapsed': {
            left: 0
        },
        '&.btnCollapsedArea': {
            right: 0
        },
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8_Landscape)]: {
            top: '-1.5em',
            left: 0,
            marginLeft: '0.4em',
            border: 0,
            borderRadius: 0
        }
    },
    largeScreenToggleButton: {
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8_Landscape)]: {
            display: 'none'
        }
    },
    smallScreenToggleButton: {
        [theme.breakpoints.up(theme.breakpoints.values.iPhone8_Landscape)]: {
            display: 'none'
        }
    },
    drawerContent: {
        flex: '1 0 0',
        position: 'relative'
    }
});
const PERSISTENT = 'persistent';
type Persistent = 'persistent';

interface Props extends WithMediaQuery<WithStyles<typeof styles>> {
    isDrawerOpen: boolean;
    variant?: 'permanent' | Persistent | 'temporary';
    collapsedArea?: React.ReactNode;
    onExpanderClick?: () => void;
    children?: React.ReactNode;
}

class SideNavbar extends React.Component<Props> {
    public static defaultProps = {
        isDrawerOpen: false
    };

    private drawerReference = React.createRef() as React.RefObject<HTMLDivElement>;
    private collapsedDrawerAreaReference = React.createRef() as React.RefObject<HTMLDivElement>;

    toggleDrawer() {
        const {
            onExpanderClick
        } = this.props;

        if (onExpanderClick) {
            onExpanderClick();
        }
    }

    onClosed = () => {
        this.toggleDrawer();
    };

    onDrawerClicked = () => {
        this.toggleDrawer();
    };

    public render() {
        const {
            children,
            classes,
            isDrawerOpen,
            variant,
            collapsedArea,
            mediaQuery
        } = this.props;
        
        const {current: drawerReference} = this.drawerReference;
        const {current: collapsedDrawerAreaReference} = this.collapsedDrawerAreaReference;
        const drawerLeftMargin =
            drawerReference ?
                drawerReference.offsetWidth -
                    (collapsedDrawerAreaReference ? collapsedDrawerAreaReference.offsetWidth : 0)
                : -1;


        return (
            <div 
                key={`mobile-${mediaQuery}`}
                className={`${classes.root}${(variant === PERSISTENT ? ` ${classes.persistent}` : '')}`}
            >
                <Drawer
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    ref={this.drawerReference}
                    key={`drawer-${mediaQuery ? 1 : 0}`}
                    className={`${classes.drawer}${(!isDrawerOpen ? ` ${classes.drawerCollapsed}` : '')}`}
                    // The style below is used by the closing transition in drawerCollapsed style
                    style={!isDrawerOpen ? {marginLeft: -drawerLeftMargin} : undefined}
                    variant={variant}
                    anchor="left"
                    open={isDrawerOpen}
                    onClose={this.onClosed}
                >
                    <div className={classes.drawerContent}>
                        <IconButton
                            className={`${classes.drawerToggleButton} btnSelected`}
                            onClick={this.onDrawerClicked}
                        >
                            <KeyboardArrowLeft className={classes.largeScreenToggleButton} />
                            <Close className={classes.smallScreenToggleButton}/>
                        </IconButton>
                        { children }
                    </div>
                </Drawer>
                { collapsedArea ?
                    <div
                        ref={this.collapsedDrawerAreaReference}
                        className={classes.collapsedDrawerArea}>
                        {collapsedArea}
                    </div>
                    : ''
                }
                <IconButton
                    className={`${classes.drawerToggleButton} btnCollapsed${collapsedArea ? 'Area' : ''}`}
                    onClick={this.onDrawerClicked}
                >
                    <KeyboardArrowRight className={classes.largeScreenToggleButton}/>
                    <Menu className={classes.smallScreenToggleButton} />
                </IconButton>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(withMediaQuery(`(max-width:${RESPONSIVE_BREAKPOINTS.iPhone8_Landscape}px)`)(SideNavbar));
export {MUIComponent as SideNavbar};
