import { EnumerationHelper } from '../../shared/utils';
import {
    Report,
    ReportModel
} from './report';
import { ReportStatus } from './report-status';
import {
    VocFeedbackModule,
    VocFeedbackModuleModel
} from './voc-feedback-module';
import {
    WidgetData,
    WidgetDataModel,
    WidgetDataValues
} from './widget-data';

export type GPSPointLocation = [number, number];

export interface ReportDetailModel extends ReportModel {
    agree_allowed: boolean,
    assigned_by: string,
    channels: string[],
    // closed: string[], // array of timestamps
    comments_allowed: boolean,
    details: string,
    device_id: string,
    gps_enabled: boolean,
    id: string,
    location?: {type: string, coordinates: GPSPointLocation},
    meta: unknown,
    minimap_s3key?: string,
    module_group_id: string,
    num_comments: number,
    off_site: boolean,
    // reopened: string[], // array of timestamps
    report: {[key: string]: string},
    resolved: boolean,
    single_view: { widgets: WidgetDataModel[] },
    time_open: number,
    user_id: string,
    // user_module_id: string,
    username: string,
    voters: string[]
    _id: string;
}

export class ReportDetail extends Report {
    static create (model: ReportDetailModel): ReportDetail {
        const {
            agree_allowed: agreeAllowed,
            assigned_by: assignedBy,
            assigned_to: assignedTo,
            channels,
            comments_allowed: commentsAllowed,
            details,
            device_id: deviceId,
            email,
            folder_id: folderId,
            folder_name: folderName,
            gps_enabled: gpsEnabled,
            id,
            location: locationModel,
            marker_id: markerId,
            marker_name: markerName,
            meta,
            minimap_s3key: miniMapS3key,
            module_group_id: moduleGroupId,
            module_id: moduleId,
            module_name: moduleName,
            num_comments: numberOfComments,
            off_site: offSite,
            report,
            report_seq_number: reportSequenceNumber,
            resolved,
            single_view: { widgets: widgetModels },
            status: statusRaw,
            summary,
            time_open: timeOpen,
            timestamp: serializedTimeStamp,
            user_id: userId,
            user_can_leave_feedback: userCanLeaveFeedback,
            user_feedback: userFeedbackModel,
            user_feedback_module_id: userFeedbackModuleId,
            username,
            voters: votersRaw,
            _id: fullId
        } = model;
        const widgets: WidgetData[] = widgetModels.map((widgetModel) => {
            return WidgetData.create(widgetModel);
        });

        let location: GPSPointLocation | undefined;
        if (locationModel) {
            const {
                type,
                coordinates
            } = locationModel;

            location = (type && type.toLowerCase() === 'point') ? coordinates : undefined;
        }
        const statusKey: string | undefined =
            EnumerationHelper.getEnumKeyFromValue(ReportStatus, statusRaw);
        const status: ReportStatus =
            statusKey ? ReportStatus[statusKey as keyof typeof ReportStatus] : ReportStatus.Unresolved;
        const timeStamp = new Date(serializedTimeStamp);
        const agrees = votersRaw && Array.isArray(votersRaw) ? votersRaw : [];
        let userFeedback: VocFeedbackModule[] | undefined = userFeedbackModel ?
            userFeedbackModel.reduce((returnedArray: VocFeedbackModule[], moduleModel: VocFeedbackModuleModel) => {
                const createdModule = VocFeedbackModule.create(moduleModel);
                if (createdModule) {
                    returnedArray.push(createdModule);
                }
                return returnedArray;
            }, [] as VocFeedbackModule[])
            : undefined;
        if (userFeedback && (userFeedback.length < 1)) {
            userFeedback = undefined;
        }
        
        return new ReportDetail(
            !!agreeAllowed,
            agrees,
            assignedBy,
            assignedTo,
            channels,
            !!commentsAllowed,
            details,
            deviceId,
            email,
            folderId,
            folderName,
            !!gpsEnabled,
            id ? id : fullId,
            markerId,
            markerName,
            meta,
            miniMapS3key ? miniMapS3key : '',
            moduleGroupId,
            moduleId,
            moduleName,
            numberOfComments,
            !!offSite,
            report,
            reportSequenceNumber,
            !!resolved,
            status,
            summary,
            timeOpen,
            timeStamp,
            widgets,
            userId,
            username,
            fullId,
            userCanLeaveFeedback,
            userFeedback,
            userFeedbackModuleId,
            location
        ); 
    }

    public miniMapImageEncoded: string | undefined;

    constructor (
        public readonly agreeAllowed: boolean,
        public readonly agrees: string[],
        public readonly assignedBy: string,
        public readonly assignedTo: string,
        public readonly channels: string[],
        // public readonly closed: any[], // ??
        public readonly commentsAllowed: boolean,
        public readonly details: string,
        public readonly deviceId: string,
        public readonly email: string,
        public readonly folderId: string,
        public readonly folderName: string,
        public readonly gpsEnabled: boolean,
        public readonly id: string,
        public readonly markerId: string,
        public readonly markerName: string,
        public readonly meta: unknown, // To-Do: Define the different meta types
        public readonly miniMapS3key: string,
        public readonly moduleGroupId: string,
        public readonly moduleId: string,
        public readonly moduleName: string,
        public readonly numberOfComments: number,
        public readonly offSite: boolean,
        // public readonly reopened: any[], // ??
        public readonly report: WidgetDataValues,
        public readonly reportSequenceNumber: number,
        public readonly resolved: boolean,
        public readonly status: ReportStatus | undefined,
        public readonly summary: string,
        public readonly timeOpen: number,
        public readonly timeStamp: Date,
        public readonly widgets: WidgetData[],
        public readonly userId: string,
        // public readonly userModuleId: string,
        public readonly username: string,
        public readonly fullId: string,
        public readonly userCanLeaveFeedback?: boolean,
        public readonly userFeedback?: VocFeedbackModule[],
        public readonly userFeedbackModuleId?: string,
        // public readonly voters: string[],
        public readonly location?: GPSPointLocation,
    ){
        super(
            agrees,
            assignedTo,
            email,
            folderId,
            folderName,
            id,
            markerId,
            markerName,
            moduleId,
            moduleName,
            moduleGroupId,
            moduleGroupId,
            '',
            reportSequenceNumber,
            status,
            summary,
            timeStamp,
            userCanLeaveFeedback,
            userFeedback,
            userFeedbackModuleId,
            username
        );
    }
}
