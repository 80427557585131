import Cookies from 'universal-cookie';

export class CookieHelper {
    static cookiesContext = new Cookies();

    static setCookie(cookieName: string, cookieValue: string): void {
        const expires = new Date((new Date()).getTime()+(12*3600000)); // Expires after 12 hours
        const COOKIE_OPTIONS = {
            path: '/',
            expires
        };
        
        
        this.cookiesContext.remove(cookieName, {path: '/'});
        this.cookiesContext.set(cookieName, cookieValue, COOKIE_OPTIONS);
    }

    static getCookie(cookieName: string): string {
        return this.cookiesContext.get(cookieName);
    }

    static removeCookie(cookieName: string): void {
        this.cookiesContext.remove(cookieName, {path: '/'});
    }
}