import {
    Report,
    ReportModel
} from './report';
import {
    VocFeedbackModule,
    VocFeedbackModuleModel
} from './voc-feedback-module';

export interface VocFeedbackModel {
    feedback_modules: VocFeedbackModuleModel[];
    reports: ReportModel[];
}

export class VocFeedback {
    static create (model: VocFeedbackModel): VocFeedback {
        const {
            feedback_modules: feedbackModulesModel,
            reports: reportsModel
        } = model;

        const feedbackModules = feedbackModulesModel
            .reduce((modules: VocFeedbackModule[], feedbackModuleModel: VocFeedbackModuleModel) => {
                const feedbackModule = VocFeedbackModule.create(feedbackModuleModel);
                if (feedbackModule !== null) {
                    modules.push(feedbackModule as VocFeedbackModule);
                }
                return modules;
            }, [] as VocFeedbackModule[]);

        const reports = reportsModel.map((reportModel: ReportModel)=>{
            return Report.create(reportModel);
        });

        return new VocFeedback(
            feedbackModules,
            reports
        );
    }

    constructor (
        public readonly feedbackModules: VocFeedbackModule[],
        public readonly reports: Report[]
    ) {}
}