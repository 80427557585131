import { EntityFloorplan } from './../shared/domain/floorplan';
import {
    API,
    CC_APP_TYPE
} from '../constants';
import {
    Marker,
    MarkerModel
} from '../models/marker';
import { RegisteredUser } from '../models/registered-user';
import {
    CommentImage,
    Report,
    ReportDetailsImages,
    ReportDetailsImagesModel,
    ReportModel,
    ReportModule,
    ReportModuleGroup,
    ReportModuleGroupModel,
    ReportModuleModel,
    submitVocWidgetData,
    VocFeedback,
    VocFeedbackModel,
    WidgetData,
    WidgetDataPhotoProperties,
    WidgetDataValues,
    WidgetType
} from '../models/report';
import {
    ReportComment,
    ReportCommentModel
} from '../models/report/report-comment';
import {
    ReportDetail,
    ReportDetailModel
} from '../models/report/report-detail';
import { 
    EntityFolder,
    Folder,
    Floorplan,
    UserTeam
} from '../shared/domain';
import { HttpConnectorAdapter } from './http-connector-adapter';
import {
    ReportBaseAdapter,
    SearchResults,
    ReportResults,
    SearchResultsUser
} from './report-base-adapter';
import { testFloorPlanImage } from './test-image';
import { RenderTree } from '../shared/components/node-tree';

interface BaseParameters {
    limit?: number;
    offset?: number;
}

interface TeamReportsParameters extends BaseParameters {
    marker_id?: string;
    folder_id?: string;
    module_group_id?: string[];
    assigned_to?: string;
    reported_by?: string;
    search?: string;
    status?: string[];
    date1?: string;
    date2?: string;
    date1_operator?: string;
    date2_operator?: string;
}

interface ReportsByUserParameters extends BaseParameters {
    user_id: string
    skip: number,
    filter: 'myReports' | 'following' | 'nearBy' | 'other'
}

interface ReportModelReportByUser extends ReportModel {
    report?: {
        issue: string;
        photo: string;
        toggle: string;
    };
    subscribed?: {[index: string]: string;};
}

interface FolderByAccountModel {
    data: {
        doc_id: string
        name: string
        type: string
        children: EntityFolder[]
    },
    status: boolean
}

interface EntitiesNames {
    [id: string]: {
        name: string;
    };
}

interface TreeDefinition {
    [id: string]: {
        [childId: string]: TreeDefinition | object; // The value doesn't matter, as it's just used to represent the presence of children
    };
}

//#region UserTeamsResponse
// {
//     "status":true,
//     "status_code":200,
//     "message":"teams returned for user",
//     "data":[
//         {
//             "id":"team_5ad0e513413c3f3d246c6548",
//             "name":"Markers Nearby Test Site Management"
//         },
//         {
//             "id":"team_5ab01bd2413c3f62ed400f7b",
//             "name":"VOC Test Site Management"
//         }
//     ]
// }
//#endregion

let singletonInstance: ReportAdapter;
export class ReportAdapter implements ReportBaseAdapter {
    static getAdapter(): ReportBaseAdapter {
        if (!singletonInstance) {
            singletonInstance = new ReportAdapter();
        }

        return singletonInstance;
    }

    // TO-DO: To remove once Image API v2 is ready
    private reportDetailsImages: ReportDetailsImages | undefined;

    //#region getUserTeams
    async getUserTeams(registeredUser: RegisteredUser): Promise<UserTeam[]> {
        if (!registeredUser || !registeredUser.cookie) {
            return Promise.reject();
        }

        const response = await HttpConnectorAdapter
            .get(
                API.URL.REPORTS.TEAMS_FOR_USER
                    .replace(API.URL.Tokens.REPORTS.USER_ID, registeredUser.userId)
            );
        
        interface UserTeamsModel
        {
            id: string,
            name:string,
            portal: boolean
        }
        const {
            results: model
        } : { results: UserTeamsModel[] } = response.data;
        const today = new Date(Date.now());
        const userTeams: UserTeam[] = model.map((item: UserTeamsModel) => {
            const {
                id,
                name,
                portal
            } = item;
            return new UserTeam(id, today, portal, name);
        });

        return userTeams;
    }
    //#endregion

    //#region getTeamModules

    //#region TEAM_MODULES Response
    // {  
    //     "status":true,
    //     "status_code":200,
    //     "message":"module groups for team returned",
    //     "data":[  
    //         {  
    //             "module_icon":"https://s3.amazonaws.com/crowd-comfort-module-icons/just_right.png",
    //             "id":"thermal",
    //             "name":"Thermal Comfort"
    //         },
    //         {  
    //             "id":"maintenance",
    //             "name":"Maintenance"
    //         }
    //     ]
    // }
    //#endregion
    
    //#region getTeamModuleGroups
    async getTeamModuleGroups(
        registeredUser: RegisteredUser,
        teamId: string,
        groupType?: 'atomic' | 'inspection'
    ): Promise<ReportModuleGroup[]> {
        // groupType
        // “atomic” will only return non-inspection module groups.
        // “inspection” will only return inspection module groups.
        // not specified, it will return all module groups for that team.
        if (!registeredUser || !registeredUser.cookie) {
            return Promise.reject();
        }

        let params: {team_id: string, group_type?: string} = {
            team_id: teamId,
        };
        if (groupType) {
            params = {
                ...params,
                group_type: groupType
            };
        }

        const response = await HttpConnectorAdapter.get(API.URL.REPORTS.TEAM_MODULE_GROUPS, params);
        const {
            data: model
        } : { data: ReportModuleGroupModel[], status: string } = response.data;
        const modules = model.map((moduleModel) => {
            return ReportModuleGroup.create(moduleModel);
        });

        return modules;
    }
    //#endregion

    //#region getTeamReports
    async getTeamReports(
        registeredUser: RegisteredUser,
        teamId: string,
        limit: number,
        statuses: string[],
        types: string[] = [],
        assignedTo = '',
        search = '',
        offset = 0,
        fromDate?: Date,
        toDate?: Date,
        markerId?: string,
        folderId?: string,
        url = API.URL.REPORTS.TEAM_REPORTS
    ): Promise<SearchResults<Report>> {

        if (!registeredUser || !registeredUser.cookie || !teamId) {
            return Promise.reject();
        }

        const params:TeamReportsParameters = {
            limit
        };

        if (offset > 0) {
            params.offset = offset;
        }
        if(search) {
            params.search = search;
        }
        if (statuses.length > 0) {
            params.status = statuses;
        }
        if (types.length > 0) {
            params.module_group_id = types;
        }
        if(assignedTo) {
            params.assigned_to = assignedTo;
        }
        if(markerId) {
            params.marker_id = markerId;
        }
        if(folderId) {
            params.folder_id = folderId;
        }
        
        if (!!fromDate && !!toDate){
            params.date1 = fromDate.toISOString();
            params.date2 = toDate.toISOString();
            params.date1_operator = 'gte';
            params.date2_operator = 'lte';
        }

        const stringifiedParams = HttpConnectorAdapter.Utilities.stringifyGetParams(params);
        const {
            REPORTS: {
                TEAM_REPORTS,
                TEAM_REPORTS_SEARCH
            },
            Tokens: { REPORTS: { 
                TEAM_ID,
                SEARCH
            }}
        } = API.URL;

        const isSearch = (!!search);
        const finalUrl = isSearch && (url === TEAM_REPORTS) ? TEAM_REPORTS_SEARCH.replace(SEARCH, search) : url;
        const response = await HttpConnectorAdapter.get(
            finalUrl.replace(TEAM_ID, teamId)+`?${stringifiedParams}`);

        const {
            data: model,
            status
        } : {
            data: { 
                rows: ReportModel[],
                meta:{totalCount: number, resultCount: number} 
            } | undefined,
            status: boolean
        } = response.data;
        if (!model || (!status)) {
            return Promise.reject();
        }
        const {
            rows: rowsModel,
            meta
        } = model;
        
        const rows = rowsModel.map((reportModel: ReportModel) => {
            // TO-DO: In API V2 unify the data format amongst the two API calls 'team report' and 'search'
            if (!(reportModel.module_type)) {
                const { module_group_id: moduleGroupId } = reportModel;
                reportModel.module_type = moduleGroupId ? moduleGroupId : '';
            }
            return Report.create(reportModel);
        });
        return Promise.resolve({rows, meta});
    }
    //#endregion

    //#region getTeamReportsForCsvExport
    async getTeamReportsForCsvExport(
        registeredUser: RegisteredUser,
        teamId: string,
        limit: number,
        statuses: string[],
        types: string[] = [],
        assignedTo = '',
        search = '',
        offset = 0,
        fromDate?: Date,
        toDate?: Date,
        markerId?: string,
        folderId?: string
    ): Promise<SearchResults<Report>> {
        const response = await this.getTeamReports(
            registeredUser,
            teamId,
            limit,
            statuses,
            types,
            assignedTo,
            search,
            offset,
            fromDate,
            toDate,
            markerId,
            folderId,
            API.URL.REPORTS.TEAM_REPORTS_EXPORT
        );

        return response;
    }
    //#endregion

    //#region getReportsByUser
    async getReportsByUser(
        registeredUser: RegisteredUser,
        limit: number,
        offset = 0
    ): Promise<SearchResults<Report>> {

        if (!registeredUser || !registeredUser.cookie) {
            return Promise.reject();
        }
        const { userId } = registeredUser;
        const params: ReportsByUserParameters = {
            filter: 'myReports',
            limit,
            skip: offset,
            user_id: userId
        };

        // if (offset > 0) {
        //     params.offset = offset;
        // }

        const stringifiedParams = HttpConnectorAdapter.Utilities.stringifyGetParams(params);
        const response = await HttpConnectorAdapter.get(
            `${API.URL.REPORTS.REPORT_BY_USER}?${stringifiedParams}`
        );

        const { data: responseData } = response;
        if (!responseData) {
            return Promise.reject();
        }

        const {
            data,
            total_count: totalCount
        } : {
            data: {id: string, value: ReportModelReportByUser}[] | undefined,
            total_count: number
        } = responseData;
        if (!data) {
            return Promise.reject();
        }

        const rows = data.map((dataItem) => {
            const {
                id,
                value: reportModel 
            } = dataItem;
            
            // Convert ReportModelReportByUser into ReportModel
            const {
                subscribed,
                report
            } = reportModel;
            reportModel.id = id;
            reportModel.agrees = subscribed ? Object.keys(subscribed) : [];
            reportModel.photo = report?.photo;
            reportModel.module_type = '';

            return Report.create(reportModel);
        });
        return Promise.resolve({rows, meta:{totalCount, resultCount: totalCount}});
    }
    //#endregion

    //#region getTeamMarkersSearch
    async getTeamMarkersSearch(
        registeredUser: RegisteredUser,
        teamId: string,
        termsString: string,
        limit = 10, // Server default when limit isn't specified.
        offset = 0
    ): Promise<SearchResults<Marker>> {
        if (!registeredUser || !registeredUser.cookie ||
            !teamId || !termsString) {
            return Promise.reject();
        }

        const params:BaseParameters = {
            limit
        };

        if (offset > 0) {
            params.offset = offset;
        }

        const stringifiedParams =
            Object.keys(params).length > 0 ? 
                HttpConnectorAdapter.Utilities.stringifyGetParams(params)
                : '';
        const termsStringWithStar =
            termsString.lastIndexOf('*') === (termsString.length - 1) ?
                termsString :
                `${termsString}*`;
        const {
            REPORTS :{ TEAMS_MARKER_SEARCH },
            Tokens: { REPORTS: { TEAM_ID } }
        } = API.URL;
        const response = await HttpConnectorAdapter.get(
            TEAMS_MARKER_SEARCH.replace(TEAM_ID, teamId)+
            `/${termsStringWithStar}${stringifiedParams ? `?${stringifiedParams}` : ''}`);

        const {
            data: model,
            status
        } : { 
            data: { 
                rows: MarkerModel[],
                meta:{totalCount: number, resultCount: number} 
            } | undefined,
            status: boolean
        } = response.data;
        if (!model || (!status)) {
            return Promise.reject();
        }

        const {
            rows: rowsModel,
            meta
        } = model;
        const rows = rowsModel.map((markerModel) => {
            return Marker.create(markerModel);
        });
        return Promise.resolve({rows, meta});
    }
    //#endregion

    //#region getFolderMarkers
    async getFolderMarkers(
        registeredUser: RegisteredUser,
        folderId: string
    ): Promise<SearchResults<Marker>> {
        if (!registeredUser || !registeredUser.cookie || !folderId) {
            return Promise.reject();
        }

        const {
            REPORTS :{ FOLDER_MARKERS }
        } = API.URL;
        const responseMarkers = await HttpConnectorAdapter.get(`${FOLDER_MARKERS}${folderId}`);

        const {
            data: rowsModel,
            status
        } : { 
            data: MarkerModel[],
            status: boolean
        } = responseMarkers.data;
    
        if (!rowsModel || (!status)) {
            return Promise.reject();
        }

        const rows = rowsModel.map((markerModel: MarkerModel) => {
            return Marker.create(markerModel);
        });

        return Promise.resolve({rows, meta: {totalCount: rows.length, resultCount: rows.length}});
    }
    //#endregion

    //#region getTeamUsers
    async getTeamUsers(
        registeredUser: RegisteredUser,
        teamId: string,
        email?: string
    ): Promise<SearchResultsUser[]> {
        if (!registeredUser || !registeredUser.cookie || !teamId) {
            return Promise.reject();
        }

        const {
            REPORTS :{ TEAM_USERS }
        } = API.URL;


        const param = email ? `&email=${email}` : '';
        const response = await HttpConnectorAdapter.get(`${TEAM_USERS}${teamId}${param}`);
        const {
            data: userList,
            status
        } = response.data;

        if (!userList || (!status)) {
            return Promise.reject();
        }

        const users: SearchResultsUser[] = userList.map((user: SearchResultsUser) => {
            const { user_id, username, email } = user;
            return {user_id, username, email};
        });

        return Promise.resolve(users);
    }
    //#endregion

    //#region getTeamMarkersInFolder
    async getTeamMarkersInFolder(
        registeredUser: RegisteredUser,
        teamId: string,
        folderId: string,
        limit = 10, // Server default when limit isn't specified.
        offset = 0
    ): Promise<SearchResults<Marker>> {
        if (!registeredUser || !registeredUser.cookie ||
            !teamId || !folderId) {
            return Promise.reject();
        }

        const params = {
            team_id: teamId,
            folder_id: folderId,
            limit,
            offset
        };
        const url = `${API.URL.REPORTS.MARKERS}`;
        const response = await HttpConnectorAdapter.get(url, params);

        const {
            data: { rows: markerModels, meta },
            status
        } : { 
            data: {
                rows: MarkerModel[],
                meta: {totalCount: number, resultCount: number}
            },
            status: boolean
        } = response.data;

        if (!markerModels || (!status)) {
            return Promise.reject();
        }
        const rows = markerModels.length < 1 ? 
            [] as Marker[]
            : markerModels.map((markerModel) => {
                return Marker.create(markerModel);
            });

        return Promise.resolve({rows, meta});
    }
    //#endregion

    //#region getModulesByMarker
    async getModulesByMarker(
        registeredUser: RegisteredUser,
        markerId: string
    ): Promise<ReportModuleGroup[]> {
        if (!registeredUser || !registeredUser.cookie || !markerId) {
            return Promise.reject();
        }

        const params = {
            marker_id: markerId
        };
        const response = await HttpConnectorAdapter.get(
            API.URL.REPORTS.MODULE_GROUPS_BY_USER_AND_MARKER,
            params);
        const {
            data: models,
            status: responseStatus
        } : { 
            data: ReportModuleGroupModel[],
            status: boolean
        } = response.data;
    
        if (!models || (!responseStatus)) {
            return Promise.reject();
        }

        const modules: ReportModuleGroup[] = models.map((model: ReportModuleGroupModel) => {
            return ReportModuleGroup.create(model);
        });

        return Promise.resolve(modules);
    }
    //#endregion
    
    //#region getModuleInfo
    async getModuleInfo(
        registeredUser: RegisteredUser,
        markerId: string,
        moduleGroupId: string
    ): Promise<ReportModule[]> {
        if (!registeredUser || !registeredUser.cookie ||
            !markerId || !moduleGroupId) {
            return Promise.reject();
        }

        const params = {
            marker_id: markerId,
            module_group_id: moduleGroupId
        };
        const response = await HttpConnectorAdapter.get(
            API.URL.REPORTS.MODULES_BY_MODULE_GROUP_AND_MARKER,
            params);
        const {
            data: models,
            status: responseStatus
        } : { 
            data: ReportModuleModel[],
            status: boolean
        } = response.data;
    
        if (!models || (!responseStatus)) {
            return Promise.reject();
        }

        const modules: ReportModule[] = models.map((model: ReportModuleModel) => {
            return ReportModule.create(model);
        });

        return Promise.resolve(modules);
    }
    //#endregion

    //#region submitReport
    async submitReport(
        registeredUser: RegisteredUser,
        markerId: string,
        moduleId: string,
        moduleGroup: string,
        widgetValues: WidgetDataValues,
        status: string,
        summary: string
    ): Promise<void> {
        if (!registeredUser || !registeredUser.cookie) {
            return Promise.reject();
        }
        const params = {
            report: {
                marker_id: markerId,
                location: { coordinates: [0, 0]},
                module_group_id: moduleGroup,
                gps_enabled: false,
                off_site: false,
                module_id: moduleId,
                report: widgetValues,
                status,
                summary,
                device_id: 'new_web_portal'
            }
        };

        const response = await HttpConnectorAdapter.post(API.URL.REPORTS.CREATE_REPORT, params);

        const {
            data: reportId,
            status: responseStatus
        } : { 
            data: string,
            status: boolean
        } = response.data;
        if (!reportId || (!responseStatus)) {
            return Promise.reject();
        }

        return Promise.resolve();
    }
    //#endregion

    //#region Create Report Payload/Response
    //
    // Request payload
    // {
    //  "report": {
    //     "marker_id": "folder_5ad0e512413c3f3d246c64e0_marker_5ad0e513413c3f3d246c6545",
    //     "location": {
    //     "coordinates": [
    //         0,
    //         0
    //     ]
    //     },
    //     "module_group_id": "thermal",
    //     "gps_enabled": false,
    //     "off_site": false,
    //     "module_id": "thermal",
    //     "report": {
    //     "feeling": "Just right",
    //     "issue": "Test"
    //     },
    //     "status": "Unresolved",
    //     "summary": "Just right \n Notes: Test",
    //     "device_id": "new_web_portal"
    //   }
    // }
    // Response
    // {"status": true, "status_code": 201, "message": "report inserted", "data": "report::5c7dd862413c3f1ac645a780"}
    //
    //#endregion

    //#region getReportDetail
    async getReportDetail(
        registeredUser: RegisteredUser,
        reportId: string
    ): Promise<ReportResults<ReportDetail>> {
        if (!registeredUser || !registeredUser.cookie || !reportId) {
            return Promise.reject();
        }

        const params = {
            id: reportId
        };
        const errors: string[] = [];
        let reportDetail;

        try {
            const response = await HttpConnectorAdapter.get(API.URL.REPORTS.REPORT_DETAIL, params);
            const { 
                status: responseStatus,
                message: errorMessage,
                data
            } : {
                status: boolean,
                message: string | undefined,
                data: {
                    id: string,
                    value: ReportDetailModel
                } | undefined
            } = response.data;

            if (!responseStatus || !data) {
                return Promise.reject(!errorMessage ? 'Unable to retrieve report detail data.' : errorMessage);
            }

            const { value: reportDetailModel } = data;
            reportDetail = ReportDetail.create(reportDetailModel);
        } catch (e) {
            return Promise.reject('Unable to retrieve report detail.');
        }

        if (!reportDetail) {
            return Promise.reject('Error in the report detail data.');
        }

        const {
            markerId,
            miniMapS3key,
            report
        } = reportDetail;

        if (markerId && miniMapS3key && miniMapS3key !== '') {
            try {
                const floorPlanEncoded: string =
                    await this.getReportFloorPlanPicture(registeredUser, markerId);
                reportDetail.miniMapImageEncoded = floorPlanEncoded;
            } catch(e) {
                errors.push('Unable to retrieve the floor plan image.');
                reportDetail.miniMapImageEncoded = testFloorPlanImage;
            }
        }
        try {
            const reportDetailsImages: ReportDetailsImages =
                await this.getReportDetailsImages(registeredUser, reportId);
            // Filters out the widgets that aren't photo widgets
            // or that don't have an image url
            const widgets: WidgetData[] = reportDetail.widgets
                .filter((widget: WidgetData) => {
                    // Some optimization:
                    // while we are going through each widget
                    // we resolve the widget references.
                    WidgetData.resolveWidgetReferences(widget, report);
                    return (widget.type === WidgetType.Photo) && ((widget.properties as WidgetDataPhotoProperties).imageUrl);
                });
            const { reportImages } = reportDetailsImages;
            if (widgets.length === reportImages.length) {
                reportImages.forEach((encodedImage: string, index: number) => {
                    const widgetProperties = (widgets[index].properties as WidgetDataPhotoProperties);
                    widgetProperties.imageEncoded = encodedImage;
                });
            }
        } catch (e) {
            errors.push('Unable to retrieve report detail images.');
            // Resolve the widget references
            reportDetail.widgets
                .forEach((widget: WidgetData) => {
                    WidgetData.resolveWidgetReferences(widget, report);
                });
        }

        return {
            results: reportDetail,
            errors
        };
        
    }
    //#endregion

    //#region getReportFloorPlanPicture
    getReportFloorPlanPicture(
        registeredUser: RegisteredUser,
        pictureId: string
    ): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (!registeredUser || !registeredUser.cookie || !pictureId) {
                return reject();
            }

            const {REPORTS, Tokens} = API.URL;
            HttpConnectorAdapter.getBase64Image(
                REPORTS.REPORT_DETAIL_PICTURE.replace(Tokens.MARKERS.MARKER_ID, pictureId),
            ).then((response) => {
                return resolve(response?.data || testFloorPlanImage);
            }).catch((error) => {
                reject(error);
            });
        });
    }
    //#endregion

    //#region getReportDetailsImages
    async getReportDetailsImages(
        registeredUser: RegisteredUser,
        reportId: string
    ): Promise<ReportDetailsImages> {
        if (!registeredUser || !registeredUser.cookie || !reportId) {
            return Promise.reject();
        }

        if ((this.reportDetailsImages) && (this.reportDetailsImages.reportId === reportId)){
            return Promise.resolve(this.reportDetailsImages);
        }

        const {
            REPORTS :{ REPORT_DETAIL_IMAGES },
            Tokens: { REPORTS: { REPORT_ID } }
        } = API.URL;

        
        const response = await HttpConnectorAdapter.get(
            REPORT_DETAIL_IMAGES.replace(REPORT_ID, reportId));

        const {
            data: model
        } : { 
            data: ReportDetailsImagesModel
        } = response;

        this.reportDetailsImages = ReportDetailsImages.create(model);

        return Promise.resolve(this.reportDetailsImages);
    }
    //#endregion

    //#region getReportComments
    getReportComments(
        registeredUser: RegisteredUser,
        reportDetail: ReportDetail,
        limit: number,
        offset = 0
    ): Promise<ReportResults<ReportComment[]>> {
        return new Promise<ReportResults<ReportComment[]>>((resolve, reject) => {
            const { fullId: reportId } = reportDetail;
            if (!registeredUser || !registeredUser.cookie || !reportId) {
                return reject();
            }

            const params = {
                report_id: reportId,
                limit,
                skip: offset
            };

            HttpConnectorAdapter.get(
                API.URL.REPORTS.REPORT_GET_COMMENTS,
                params
            ).then((response) => {
                const {
                    data: reportCommentModels,
                    status: responseStatus
                } : { 
                    data: ReportCommentModel[],
                    status: boolean
                } = response.data;

                if (!responseStatus || (!reportCommentModels)) {
                    return reject();
                }

                if (reportCommentModels.length < 1) {
                    return resolve({
                        results: [] as ReportComment[],
                        errors:[]
                    } as ReportResults<ReportComment[]>);
                }
                
                const errors = [] as string[];
                const {
                    report
                } = reportDetail;

                this.getReportDetailsImages(registeredUser, reportId)
                    .then((reportDetailsImages: ReportDetailsImages) => {
                        const { commentImages } = reportDetailsImages;
                        const reportComments = reportCommentModels.map((model: ReportCommentModel) => {
                            // If we have images for the comments we try to find one
                            // for the current comment.
                            if (commentImages.length > 0) {
                                const modelId = model.id;
                                const foundCommentImage: CommentImage | undefined = commentImages.find(((commentImage: CommentImage) => {
                                    return commentImage.commentId === modelId;
                                }));

                                if (foundCommentImage) {
                                    model.value.photo = foundCommentImage.image;
                                }
                            }
                            return ReportComment.create(model);
                        });

                        resolve({
                            results: reportComments,
                            errors
                        });
                    })
                    .catch(() => {
                        errors.push('Unable to retrieve report detail comment images.');

                        // Resolve the widget references
                        reportDetail.widgets
                            .forEach((widget: WidgetData) => {
                                WidgetData.resolveWidgetReferences(widget, report);
                            });
                        const reportComments = reportCommentModels.map((model: ReportCommentModel) => {
                            return ReportComment.create(model);
                        });
                        resolve({
                            results: reportComments,
                            errors
                        });
                    });
            }).catch((error => {
                reject(error);
            }));
        });
    }
    //#endregion

    //#region postReportComment
    async postReportComment(
        registeredUser: RegisteredUser,
        reportId: string,
        comment: string
    ): Promise<ReportComment> {
        if (!registeredUser || !registeredUser.cookie || !reportId) {
            return Promise.reject();
        }

        const params = {
            report_id: reportId,
            comment
        };

        const response = await HttpConnectorAdapter.post(API.URL.REPORTS.REPORT_POST_COMMENT, params);
        const {
            data: reportCommentModel,
            status: responseStatus
        } : { 
            data: ReportCommentModel,
            status: boolean
        } = response.data;
        if (!responseStatus || (!reportCommentModel)) {
            return Promise.reject();
        }

        return Promise.resolve(ReportComment.create(reportCommentModel));
    }
    //#endregion

    //#region changeReportStatus
    async changeReportStatus(
        registeredUser: RegisteredUser,
        reportId: string,
        status: string
    ): Promise<void> {
        if (!registeredUser || !registeredUser.cookie ||
            !reportId || !status) {
            return Promise.reject();
        }

        const params = {
            report_id: reportId,
            new_status: status
        };

        const response = await HttpConnectorAdapter.post(API.URL.REPORTS.REPORT_CHANGE_STATUS, params);
        const {
            data: { id },
            status: responseStatus
        } : { 
            data: {id: string},
            status: boolean
        } = response.data;
        if (!responseStatus || (!id)) {
            return Promise.reject();
        }

        return Promise.resolve();
    }
    //#endregion

    //#region changeReportCategory
    async changeReportCategory(
        registeredUser: RegisteredUser,
        teamId: string,
        reportId: string,
        moduleGroupId: string
    ): Promise<void> {
        if (!registeredUser || !registeredUser.cookie ||
            !reportId || !teamId) {
            return Promise.reject();
        }

        const {
            REPORTS :{ REPORT_CHANGE_CATEGORY },
            Tokens: { REPORTS: { TEAM_ID, REPORT_ID } }
        } = API.URL;

        const url = REPORT_CHANGE_CATEGORY.replace(TEAM_ID, teamId).replace(REPORT_ID, reportId);
        const params = {
            'new_module_group_id': moduleGroupId
        };

        const response = await HttpConnectorAdapter.post(url, params);
        const {
            data: { new_module_group_id },
            status: responseStatus
        } : { 
            data: {new_module_group_id: string},
            status: boolean
        } = response.data;
        if (!responseStatus || (!new_module_group_id)) {
            return Promise.reject();
        }

        return Promise.resolve();
    }
    //#endregion

    //#region checkChangeCategoryByTeam
    async checkChangeCategoryByTeam(
        registeredUser: RegisteredUser,
        teamId: string
    ): Promise<void> {
        if (!registeredUser || !registeredUser.cookie ||
            !teamId) {
            return Promise.reject();
        }

        const {
            REPORTS :{ CHANGE_MODULE_CHECK_BY_TEAM },
            Tokens: { REPORTS: { TEAM_ID } }
        } = API.URL;

        const url = CHANGE_MODULE_CHECK_BY_TEAM.replace(TEAM_ID, teamId);

        const response = await HttpConnectorAdapter.get(url);
        const {
            status: responseStatus
        } : {
            status: boolean
        } = response.data;

        if (!responseStatus) {
            return Promise.reject();
        }

        return Promise.resolve(response.data);
    }
    //#endregion

    //#region assignReport
    async assignReport(
        registeredUser: RegisteredUser,
        reportId: string,
        email: string
    ): Promise<void> {
        if (!registeredUser || !registeredUser.cookie ||
            !reportId || !email) {
            return Promise.reject();
        }

        const params = {
            report_id: reportId,
            assign_to_user_email: email
        };

        const response = await HttpConnectorAdapter.post(API.URL.REPORTS.REPORT_ASSIGN, params);
        const {
            data: { id },
            status: responseStatus
        } : { 
            data: {
                id: string,
                target_userid: string,
                target_username: string
            },
            status: boolean
        } = response.data;
        if (!responseStatus || (!id)) {
            return Promise.reject();
        }

        return Promise.resolve();
    }
    //#endregion

    //#region deleteReport
    async deleteReport(
        registeredUser: RegisteredUser,
        reportId: string
    ): Promise<void> {
        if (!registeredUser || !registeredUser.cookie || !reportId) {
            return Promise.reject();
        }

        const {
            REPORTS :{ REPORT_DELETE },
            Tokens: { REPORTS: { REPORT_ID } }
        } = API.URL;
        
        const response = await HttpConnectorAdapter.delete(
            REPORT_DELETE.replace(REPORT_ID, reportId));
        const {
            data: { report_id: id },
            status: responseStatus
        } : { 
            data: {
                report_id: string
            },
            status: boolean
        } = response.data;
        if (!responseStatus || (!id)) {
            return Promise.reject();
        }

        return Promise.resolve();
    }
    //#endregion

    //#region getTeamFolders
    async getTeamFolders(
        registeredUser: RegisteredUser,
        teamId: string,
        folderId: string,
        limit = 10,
        offset = 0,
        permissions?: string
    ): Promise<SearchResults<Folder>> {
        if (!registeredUser || !registeredUser.cookie || !teamId) {
            return Promise.reject();
        }

        const params = {
            team_id: teamId,
            limit,
            offset
        };

        if (permissions) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (params as any).permission = permissions;
        }

        // Global variables for handling final response
        let entityFolders;
        let meta;
        let status;
        let results = {};

        // Since output with folderId has different data structure
        // We need to check and get the right keys to handling results
        if (folderId) {
            const url = `${API.URL.REPORTS.FOLDERS}/${folderId}`;
            const response = await HttpConnectorAdapter.get(url, params);

            const {
                data: { rows, meta: newMeta},
                status: newStatus
            } : { 
                data: {
                    rows: EntityFolder[]
                    meta: { totalCount: number, resultCount: number }
                },
                status: boolean
            } = response.data;

            entityFolders = rows;
            status = newStatus;
            meta = newMeta;
        } else {
            const url = `${API.URL.REPORTS.FOLDERS}`;
            const response = await HttpConnectorAdapter.get(url, params);

            const {
                data: {
                    doc_id: accountId,
                    name: accountName,
                    children,
                    type
                },
                status: newStatus
            }: FolderByAccountModel = response.data;

            entityFolders = children;
            status = newStatus;
            results = {accountId, accountName, type};
        }

        if (!entityFolders || (!status)) {
            return Promise.reject();
        }

        const rows = entityFolders.length < 1 ? 
            [] as Folder[]
            : entityFolders.map((entityFolder) => {
                return Folder.create(entityFolder);
            });
        
        return Promise.resolve({
            ...results,
            rows,
            meta: meta ? meta : {totalCount: rows.length, resultCount: rows.length}
        });
    }
    //#endregion

    //#region getFolderFloorplan
    async getFolderFloorplan(
        registeredUser: RegisteredUser,
        teamId: string,
        folderId: string,
        limit = 10,
        offset = 0
    ): Promise<SearchResults<Floorplan>> {
        if (!registeredUser || !registeredUser.cookie || !teamId) {
            return Promise.reject();
        }

        const params = {
            team_id: teamId,
            limit,
            offset
        };

        const {
            REPORTS :{ FLOORPLANS },
            Tokens: { REPORTS: { FOLDER_ID } }
        } = API.URL;

        const url = FLOORPLANS.replace(FOLDER_ID, folderId);
        
        const response = await HttpConnectorAdapter.get(url, params);

        const {
            data: { rows: entityFloorplans, meta},
            status
        } : { 
            data: {
                rows: EntityFloorplan[]
                meta: { totalCount: number, resultCount: number }
            },
            status: boolean
        } = response.data;
        if (!entityFloorplans || (!status)) {
            return Promise.reject();
        }

        const rows = entityFloorplans.length < 1 ? 
            [] as Floorplan[]
            : entityFloorplans.map((entityFloorplan) => {
                return Floorplan.create(entityFloorplan);
            });

        return Promise.resolve({rows, meta});
    }
    //#endregion

    //#region getAccountFolderTree
    constructRenderTree = (structures: TreeDefinition[], foldersData: EntitiesNames): RenderTree => {
        const rootStructure = structures[0];
        if (!rootStructure) return {} as RenderTree;
    
        const rootId = Object.keys(rootStructure)[0];
        return constructNode(rootId, rootStructure[rootId]);
    
        // This recursive function will run through all nodes and construct the required
        // RenderTree for the tree view component
        function constructNode(id: string, childrenIds: TreeDefinition | object): RenderTree {
            const folder = foldersData[id];
            const renderNode: RenderTree = {
                id: id,
                name: folder.name,
                children: []
            };

            for (const childId in childrenIds) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const childNode = childrenIds[childId];
                const childRenderNode = constructNode(childId, childNode);
                renderNode.children?.push(childRenderNode);
            }

            return renderNode;
        }
    };
    
    async getAccountFolderTree(
        registeredUser: RegisteredUser,
        permission: string
    ): Promise<RenderTree> {
        if (!registeredUser || !registeredUser.cookie) {
            return Promise.reject();
        }
        
        const params = {
            permission
        };

        const {
            REPORTS :{ FOLDER_TREE_STRUCTURE }
        } = API.URL;
        
        const response = await HttpConnectorAdapter.get(FOLDER_TREE_STRUCTURE, params);

        const {
            data: { folders_data, structures },
            status
        } : {
            data: {
                folders_data: EntitiesNames,
                structures: TreeDefinition[]
            },
            status: boolean
        } = response.data;
        
        if (!folders_data || !structures || (!status) || structures.length <= 0) {
            return Promise.reject();
        }

        const folderTree: RenderTree = this.constructRenderTree(structures, folders_data);

        // SORTING ASCENDING FOLDER STRUCTURE BY NAME
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function sortFolderTree(childrens: any) {
            childrens.sort((a: RenderTree, b: RenderTree) => {
                if (a.name < b.name) {
                    return -1;
                }

                if (a.name > b.name) {
                    return 1;
                }

                return 0;
            });

            return childrens.map((item: RenderTree) => {
                if (item.children?.length) {
                    return sortFolderTree(item.children);
                } else {
                    return item;
                }
            });
        }

        if (folderTree.children?.length) {
            sortFolderTree(folderTree.children);
        }

        return Promise.resolve(folderTree);
    }
    //#endregion

    //#region getFloorplanMarker
    async getFloorplanMarker(
        registeredUser: RegisteredUser,
        teamId: string,
        floorplanId: string,
        limit = 10,
        offset = 0
    ): Promise<SearchResults<Marker>> {
        if (!registeredUser || !registeredUser.cookie || !teamId) {
            return Promise.reject();
        }

        const params = {
            team_id: teamId,
            limit,
            offset
        };

        const {
            REPORTS :{ MARKERS_BY_FLOORPLAN_ID },
            Tokens: { REPORTS: { FLOORPLAN_ID } }
        } = API.URL;

        const url = MARKERS_BY_FLOORPLAN_ID.replace(FLOORPLAN_ID, floorplanId);
        
        const response = await HttpConnectorAdapter.get(url, params);

        const {
            data: { rows: entityMarkers, meta},
            status
        } : { 
            data: {
                rows: MarkerModel[]
                meta: { totalCount: number, resultCount: number }
            },
            status: boolean
        } = response.data;
        if (!entityMarkers || (!status)) {
            return Promise.reject();
        }

        const rows = entityMarkers.length < 1 ? 
            [] as Marker[]
            : entityMarkers.map((entityMarker) => {
                return Marker.create(entityMarker);
            });

        return Promise.resolve({rows, meta});
    }
    //#endregion

    //#region getFloorplanMarkersSearch
    async getFloorplanMarkersSearch(
        registeredUser: RegisteredUser,
        floorplanId: string,
        teamId: string,
        termsString: string,
        limit = 10, // Server default when limit isn't specified.
        offset = 0
    ): Promise<SearchResults<Marker>> {
        if (!registeredUser || !registeredUser.cookie ||
            !teamId || !termsString) {
            return Promise.reject();
        }

        const params:BaseParameters = {
            limit
        };

        if (offset > 0) {
            params.offset = offset;
        }

        const stringifiedParams =
            Object.keys(params).length > 0 ? 
                HttpConnectorAdapter.Utilities.stringifyGetParams(params)
                : '';
        const termsStringWithStar =
            termsString.lastIndexOf('*') === (termsString.length - 1) ?
                termsString :
                `${termsString}*`;
        const {
            REPORTS :{ FLOORPLANS_MARKER_SEARCH },
            Tokens: { REPORTS: { FLOORPLAN_ID } }
        } = API.URL;
        const response = await HttpConnectorAdapter.get(
            FLOORPLANS_MARKER_SEARCH.replace(FLOORPLAN_ID, floorplanId)+
            `/${termsStringWithStar}?team_id=${teamId}${stringifiedParams ? `&${stringifiedParams}` : ''}`);

        const {
            data: model,
            status
        } : { 
            data: { 
                rows: MarkerModel[],
                meta:{totalCount: number, resultCount: number} 
            } | undefined,
            status: boolean
        } = response.data;
        if (!model || (!status)) {
            return Promise.reject();
        }

        const {
            rows: rowsModel,
            meta
        } = model;
        const rows = rowsModel.map((markerModel) => {
            return Marker.create(markerModel);
        });
        return Promise.resolve({rows, meta});
    }
    //#endregion

    //#region changeAgreeInReport
    async changeAgreeInReport(
        registeredUser: RegisteredUser,
        reportId: string,
        agree: boolean
    ): Promise<boolean> {
        if (!registeredUser || !registeredUser.cookie ||
            !reportId) {
            return Promise.reject();
        }

        const params = {
            report_id: reportId,
            agree
        };

        const response = await HttpConnectorAdapter.post(
            API.URL.REPORTS.REPORT_AGREE
            , params
        );
        const {
            agree: agreeResponse,
            status: responseStatus
        } : { 
            agree: boolean,
            status: boolean
        } = response.data;
        if (!responseStatus) {
            return Promise.reject();
        }

        return Promise.resolve(agreeResponse);
    }
    //#endregion

    //#region getMarkerById
    async getMarkerById(
        registeredUser: RegisteredUser,
        markerId: string
    ): Promise<Marker | undefined> {

        if (!registeredUser || !registeredUser.cookie || !markerId) {
            return Promise.reject();
        }

        const params = {
            marker_id: markerId,
            cctype: CC_APP_TYPE
        };

        // Request marker
        const response = await HttpConnectorAdapter.post(API.URL.REPORTS.MARKER_BY_ID, params);

        const {
            data,
            status
        } = response;

        if (!status) {
            return Promise.reject(response);
        }

        const {
            folder_id: folderId,
            folder_name: folderName,
            marker_name: name,
            floorplan_id: floorplanId,
            floorplan_name: floorplanName,
            hasAccess
        } = data;

        if (!hasAccess) {
            return undefined;
        }
        
        return Promise.resolve({folderId, id: markerId, name, folderName, floorplanId, floorplanName});
    }
    //#endregion

    //#region getNewFeedbackForUser
    async getNewFeedbackForUser(
        registeredUser: RegisteredUser,
        userId: string,
        limit = 10,
        offset = 0
    ): Promise<VocFeedback> {
        if (!registeredUser || !registeredUser.cookie ||
            !userId) {
            return Promise.reject();
        }

        const params = {
            limit,
            offset
        };

        const {
            REPORTS :{ NEW_FEEDBACK_FOR_USER },
            Tokens: { REPORTS: { USER_ID } }
        } = API.URL;

        const response = await HttpConnectorAdapter.get(
            NEW_FEEDBACK_FOR_USER.replace(USER_ID, userId), params);
        const { results: vocFeedbackModel } : { 
            results: VocFeedbackModel
        } = response.data;

        return VocFeedback.create(vocFeedbackModel);
    }
    //#endregion

    //#region submitVocFeedback
    async submitVocFeedback(
        registeredUser: RegisteredUser,
        widgetData: submitVocWidgetData[],
        feedbackModuleId: string,
        reportId: string
    ): Promise<void> {
        if (!registeredUser || !registeredUser.cookie ||
            !widgetData || !feedbackModuleId || !reportId) {
            return Promise.reject();
        }

        const params = {
            user_feedback: {
                details: widgetData,
                user_feedback_module_id: feedbackModuleId
            },
            report_id: reportId.replace('report::', '')
        };

        const response = await HttpConnectorAdapter.post(
            API.URL.REPORTS.SUBMIT_FEEDBACK, params);
        const { status } : { 
            status: boolean
        } = response.data;

        if (!status) {
            return Promise.reject();
        }
    }
    //#endregion
}
