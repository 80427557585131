import * as React from 'react';

import {
    Checkbox,
    Chip,
    FormControl,
    Input,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Typography
} from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface TypeSelectorData {
    id: string;
    name: string;
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {},
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'stretch',
        flex: 1
    },
    form: {
        '&, & $formInput':{
            flex: 1,
            flexDirection: 'column'
        }
    },
    formInput: {},
    formPlaceHolder:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        // color: theme.palette.text.hint
    },
    formSelectRoot:{
        display: 'flex',
        flex: 1,
        '&$formSelect': {
            paddingRight: '0.3em'
        }
    },
    formSelect: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        paddingRight: '0.3em'
    },
    chipContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: '1em'
    },
    chip: {
        height: '2.5em',
        margin: theme.spacing(0.5),
        borderRadius: '0.7em'
    },
    chipText: {
        fontSize: '0.8em',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        padding: '0 1em'
    },
    chipDeleteIcon: {
        width: '0.8em'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    title?: string;
    data: TypeSelectorData[];
    placeholder?: string;
    selectedItemsIds: string[];
    onSelectedItemsChanged?: (itemsIds: string[]) => void;
    onItemDelete?: (itemId: string) => void;
    unselectAllItems?: boolean;
}

class DropdownChips extends React.Component<Props> {
    public static defaultProps = {
    };

    state = {
    };

    /* eslint-disable react/display-name */
    renderChips = (data: TypeSelectorData[]) => {
        return (itemsRaw: unknown): React.ReactNode => {
            const {
                classes,
                placeholder
            } = this.props;
            const items = itemsRaw as string[];
            if ((!items || (items.length < 1)) && placeholder) {
                return (
                    <em className={classes.formPlaceHolder}>{placeholder}</em>
                );
            }

            return (
                <div className={classes.chipContainer}>
                    { items ?
                        (items as string[]).map((itemId, index) => {
                            const item = data.find(
                                (value: TypeSelectorData) => {
                                    return value.id === itemId;
                                }) as TypeSelectorData;
                            return (
                                <Chip
                                    key={`${index}`}
                                    classes={{
                                        label: classes.chipText,
                                        deleteIcon: classes.chipDeleteIcon
                                    }}
                                    className={classes.chip}
                                    label={item.name}
                                    clickable={false}
                                    onDelete={this.onChipDeleteClicked(item.id)}
                                    onMouseDown={this.onChipMouseDown}
                                />);
                        })
                        : ''
                    }
                </div>
            );
        };
    };

    onChipMouseDown = (event: React.MouseEvent<Element>) => {
        event.stopPropagation();
    };

    onChipDeleteClicked = (itemId: string) => () => {
        const{
            onItemDelete
        } = this.props;

        if (onItemDelete) {
            onItemDelete(itemId);
        }
    };

    onSelectedItemsChanged = (event: SelectChangeEvent<string[]>) => {
        const { 
            onSelectedItemsChanged,
            unselectAllItems
        } = this.props;

        const itemsIds: string[] = event.target.value as string[];

        // It doesn't allow to unselect all items
        if (!unselectAllItems && itemsIds.length < 1) {
            return;
        }

        if (onSelectedItemsChanged) {
            onSelectedItemsChanged(itemsIds);
        }
    };

    public render() {
        const {
            className,
            classes,
            data,
            selectedItemsIds,
            title
        } = this.props;

        const selectFormClasses = {
            root: classes.formSelectRoot,
            select: classes.formSelect
        };

        return (
            <div className={`${classes.root}${className ? ` ${className}`: ''}`} >
                <Typography
                    className={classes.title}
                    variant="button"
                >
                    {title}
                </Typography>
                <Paper className={classes.paper}>
                    <FormControl className={classes.form}>
                        <Select
                            classes={selectFormClasses}
                            multiple={true}
                            displayEmpty={true}
                            value={selectedItemsIds}
                            onChange={this.onSelectedItemsChanged}
                            input={<Input className={classes.formInput} disableUnderline={true} />}
                            renderValue={this.renderChips(data)}
                            // MenuProps={{
                            //     PaperProps: {
                            //       style: {
                            //         maxHeight: 224,
                            //         width: 250,
                            //       },
                            //     },
                            //   }}
                        >
                            { data.map(item => (
                                <MenuItem 
                                    key={item.id}
                                    value={item.id}
                                >
                                    <Checkbox color="secondary" checked={selectedItemsIds.indexOf(item.id) > -1} />
                                    <ListItemText primary={item.name} />
                                </MenuItem>
                            ))
                            }
                        </Select>
                    </FormControl>
                </Paper>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(DropdownChips);
export {MUIComponent as DropdownChips};
