import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
import { FacesReview, FaceType } from '../faces-review';


const styles = (theme: Theme) => createStyles({
    root: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'inline-flex',
        width: '13em',
        backgroundColor: theme.palette.grey[100],
        border: '1px solid',
        borderColor: theme.palette.grey[600],
        padding: '0.3em',

        '&:focus': {
            outline: 0
        },

        '&:hover': {
            backgroundColor: theme.palette.grey[200],
            borderColor: theme.palette.grey[700],
        },

        '&:disabled': {
            cursor: 'default',
            borderColor: theme.palette.grey[400],
            opacity: '0.9',

            '&:hover': {
                backgroundColor: theme.palette.grey[100]
            }
        }
    },
    facesContainer: {
        position: 'relative',
        top: '0.1em',
        pointerEvents: 'none'
    },
    label: {
        marginLeft: '0.5em'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    selectedOption: FaceType;
    isDisabled: boolean;
    isReadOnly: boolean;
    buttonText: string;
    disabledText: string;
    onClick?: () => void;
}


class FacesReviewBtn extends React.Component<Props> {
    public static defaultProps = {
        selectedOption: FaceType.None,
        buttonText: '',
        disabledText: '',
        isDisabled: false,
        isReadOnly: false
    };

    onReviewClick = (event: React.MouseEvent<HTMLButtonElement> & React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        const { onClick } = this.props;

        if (onClick) {
            onClick();
        }
    };

    public render() {
        const {
            className,
            classes,
            isDisabled,
            selectedOption,
            buttonText,
            disabledText,
            isReadOnly
        } = this.props;

        const mainClasses = `${classes.root}${className ? ` ${className}` : ''}`;
        const labelText = isDisabled ? disabledText : buttonText;

        return (
            <button
                className={mainClasses}
                disabled={isDisabled}
                onClick={this.onReviewClick}>
                <FacesReview 
                    className={classes.facesContainer}
                    disabled={true}
                    isReadOnly={isReadOnly}
                    selectedOption={selectedOption} />
                { labelText !== '' ?
                    <span className={classes.label}>{labelText}</span>
                    : ''
                }
            </button>
        );
    }
}

const MUIComponent = withStyles(styles)(FacesReviewBtn);
export {MUIComponent as FacesReviewBtn};
