import { Button, Divider, TextField, Typography } from '@mui/material';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import memoize from 'memoize-one';
import * as React from 'react';
import {
    VocFeedbackModuleContent,
    VocFeedbackModuleContentImageToggle,
    VocFeedbackModuleContentImageToggleOption,
    VocFeedbackModuleContentLabel,
    VocFeedbackModuleContentTextfield,
    VocWidgetType,
} from '../../models/report/voc-feedback-module';
import { SimpleModal } from '../../shared/components/simple-modal';
import { FacesReview, FaceType } from '../faces-review';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: '0.7em',
            [theme.breakpoints.only('xs')]: {
                margin: '0.7em 0',
            },
            [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8)]:
                {
                    margin: '0',
                },
        },
        headerContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        headerText: {
            margin: '0.4em 0.2em 0.4em 0.5em',
            [theme.breakpoints.only('xs')]: {
                fontSize: '1.6em',
            },
            [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8)]:
                {
                    fontSize: '1.5em',
                },
        },
        footer: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
        showReportDetailButton: {
            fontSize: '0.8em',
            lineHeight: 2,
            margin: '0 1em 0 auto',
            padding: '0.4em 0.5em',
            minWidth: '12em',
        },
        submitButton: {
            margin: '0 1em 1em auto',
        },
        cancelButton: {
            margin: '0 0 1em 1em',
        },
        widgetLabel: {
            marginBottom: '0.6em',
        },
        widgetTextfield: {
            width: '100%',
        },
        widgetImageToggle: {
            width: '16em',
            marginBottom: '1em',
        },
    });

interface Props extends WithStyles<typeof styles> {
    className?: string;
    cancelButtonText?: string;
    cancelButtonTextReadOnly?: string;
    moduleContent: VocFeedbackModuleContent[] | undefined;
    open: boolean;
    readonly: boolean;
    title?: string;
    titleReadOnly?: string;
    onShowDetailClick?: () => void;
    onCancel?: () => void;
    onSubmit?: (widgetValues: WidgetValues) => void;
}

export interface WidgetValues {
    [key: string]: string;
}

interface WidgetValidationData {
    widgetType: VocWidgetType;
    isValid: boolean;
}

interface WidgetValidationList {
    [key: string]: WidgetValidationData;
}

interface States {
    widgetValues: WidgetValues;
    isFormValid: boolean;
}

class VocPopup extends React.Component<Props, States> {
    public static defaultProps = {
        cancelButtonText: 'No, thanks',
        cancelButtonTextReadOnly: 'Close',
        title: 'Rate Your Experience',
        titleReadOnly: 'Rated Experience',
    };

    state = {
        widgetValues: {} as WidgetValues,
        isFormValid: true,
    };

    widgetValidationList: WidgetValidationList = {} as WidgetValidationList;

    private generateWidgetValidationList = memoize(
        (
            moduleContent: VocFeedbackModuleContent[] | undefined
        ): WidgetValidationList => {
            if (!moduleContent) {
                return {} as WidgetValidationList;
            }
            return moduleContent.reduce(
                (
                    validationList: WidgetValidationList,
                    widget: VocFeedbackModuleContent
                ) => {
                    switch (widget.type) {
                        case VocWidgetType.Textfield:
                        case VocWidgetType.ImageToggle: {
                            const {
                                required,
                                widgetId,
                                type: widgetType,
                            } = widget as VocFeedbackModuleContentImageToggle;

                            if (required === true) {
                                validationList[widgetId] = {
                                    isValid: false, // The widget is not validated yet
                                    widgetType,
                                };
                            }
                            break;
                        }
                    }
                    return validationList;
                },
                {} as WidgetValidationList
            );
        }
    );

    componentDidUpdate(prevProps: Props) {
        const { moduleContent } = this.props;
        if (moduleContent && moduleContent !== prevProps.moduleContent) {
            // Generate the validation list
            this.widgetValidationList =
                this.generateWidgetValidationList(moduleContent);

            // Validate the validation list
            this.checkValidation();
        }
    }

    setFormValidation = (isValid: boolean) => {
        const { isFormValid } = this.state;
        if (isValid === isFormValid) {
            return;
        }

        this.setState({ isFormValid: isValid });
    };

    checkValidation = () => {
        const { props, widgetValidationList } = this;
        const { moduleContent: widgets } = props;
        if (!widgets) {
            return;
        }

        // Find the first widget that is not validated
        const notValidatedWidgetId = widgets.find((widget) => {
            switch (widget.type) {
                case VocWidgetType.Textfield: {
                    const { widgetId: textfieldWidgetId } = widget;
                    const widgetValidation =
                        widgetValidationList[textfieldWidgetId];
                    // If the current widget is not present
                    // in the list of the mandatory widgets
                    // It skips it
                    if (!widgetValidation) {
                        return false;
                    }
                    const { widgetValues } = this.state;
                    const isValid = widgetValues[textfieldWidgetId]
                        ? widgetValues[textfieldWidgetId].length > 0
                        : true;

                    if (widgetValidation.isValid !== isValid) {
                        widgetValidation.isValid = isValid;
                        widgetValidationList[textfieldWidgetId] =
                            widgetValidation;
                    }
                    return !isValid;
                }
                case VocWidgetType.ImageToggle: {
                    const { widgetId: imageWidgetID } = widget;
                    const widgetValidation =
                        widgetValidationList[imageWidgetID];
                    // If the current widget is not present
                    // in the list of the mandatory widgets
                    // It skips it
                    if (!widgetValidation) {
                        return false;
                    }
                    const { widgetValues } = this.state;
                    const isValid = widgetValues[imageWidgetID]
                        ? widgetValues[imageWidgetID].length > 0
                        : widgetValidation.isValid === true;

                    if (widgetValidation.isValid !== isValid) {
                        widgetValidation.isValid = isValid;
                        widgetValidationList[imageWidgetID] = widgetValidation;
                    }
                    return !isValid;
                }
                default:
                    return false;
            }
        });

        // Form is valid if not-validated widgets were found
        const isFormValid = !notValidatedWidgetId;
        this.setFormValidation(isFormValid);
    };

    // findWidgetById(widgetId: string): VocFeedbackModuleContent | undefined {
    //     const {moduleContent} = this.props;
    //     const result = moduleContent.find((item: VocFeedbackModuleContent) => {
    //         const {type} = item;
    //         if ((type !== VocWidgetType.Textfield) && (type !== VocWidgetType.ImageToggle)) {
    //             return false;
    //         }
    //         const itemId = (item as VocFeedbackModuleContentTextfield).widgetId;
    //         return itemId === widgetId;
    //     });
    //     return result;
    // }

    parseImageToggleIndex(
        index: number,
        options: VocFeedbackModuleContentImageToggleOption[]
    ): FaceType {
        const selectedOption = options.find((option) => {
            return option.value === index;
        });

        if (!selectedOption) {
            return FaceType.None;
        }

        return this.convertStringValueIntoFaceType(selectedOption.image);
    }

    convertStringValueIntoFaceType(value: string): FaceType {
        let result: FaceType;
        switch (value.toLocaleLowerCase()) {
            case 'happy':
                result = FaceType.Happy;
                break;
            case 'neutral':
                result = FaceType.Neutral;
                break;
            case 'sad':
                result = FaceType.Sad;
                break;
            default:
                result = FaceType.None;
                break;
        }
        return result;
    }

    renderWidgets(
        content: VocFeedbackModuleContent[] | undefined,
        isReadOnly: boolean
    ): React.ReactNode {
        if (!content) {
            return '';
        }

        const { classes } = this.props;
        let keyIndex = 0;
        const items = content.map((item: VocFeedbackModuleContent) => {
            let node: React.ReactNode;
            switch (item.type) {
                case VocWidgetType.Label: {
                    const { text } = item as VocFeedbackModuleContentLabel;

                    node = (
                        <Typography
                            key={`voc-label-widget-${keyIndex++}`}
                            className={classes.widgetLabel}
                            variant="subtitle1"
                        >
                            {text}
                        </Typography>
                    );
                    break;
                }
                case VocWidgetType.ImageToggle: {
                    const { selectedIndex, options, image, widgetId } =
                        item as VocFeedbackModuleContentImageToggle;
                    const { widgetValues } = this.state;

                    let selectedOption: FaceType | undefined;
                    if (widgetValues) {
                        const stringValue = widgetValues[widgetId];
                        const numberValue = +stringValue;
                        selectedOption =
                            isNaN(numberValue) || !options
                                ? undefined
                                : this.parseImageToggleIndex(
                                    numberValue,
                                    options
                                );
                    }

                    if (selectedOption === undefined) {
                        if (!isNaN(selectedIndex)) {
                            selectedOption =
                                selectedIndex >= 0
                                    ? this.parseImageToggleIndex(
                                        options[selectedIndex].value,
                                        options
                                    )
                                    : FaceType.None;
                        } else if (image) {
                            selectedOption =
                                this.convertStringValueIntoFaceType(image);
                        }
                        if (selectedOption === undefined) {
                            return '';
                        }
                    }

                    node = (
                        <FacesReview
                            key={`${widgetId}-${keyIndex++}`}
                            className={classes.widgetImageToggle}
                            isReadOnly={isReadOnly}
                            selectedOption={selectedOption}
                            onChange={this.onImageToggleChanged(item)}
                        />
                    );
                    break;
                }
                case VocWidgetType.Textfield: {
                    const { hint, text, widgetId } =
                        item as VocFeedbackModuleContentTextfield;
                    const { widgetValues } = this.state;
                    const widgetValue = widgetValues[widgetId];

                    node = (
                        <TextField
                            className={classes.widgetTextfield}
                            key={`${widgetId}-${keyIndex++}`}
                            multiline={true}
                            rows="4"
                            placeholder={
                                hint ? hint : 'Add your comments here...'
                            }
                            margin="normal"
                            variant="outlined"
                            value={widgetValue ? widgetValue : text}
                            disabled={isReadOnly}
                            onChange={this.onWidgetTextFieldChanged(item)}
                        />
                    );
                    break;
                }
                default:
                    node = '';
                    break;
            }
            return node;
        });
        return <React.Fragment>{items}</React.Fragment>;
    }

    updateWidgetResults(widget: VocFeedbackModuleContent, widgetValue: string) {
        let sourceWidgetId;
        switch (widget.type) {
            case VocWidgetType.ImageToggle: {
                const { widgetId } =
                    widget as VocFeedbackModuleContentImageToggle;

                sourceWidgetId = widgetId;
                break;
            }
            case VocWidgetType.Textfield: {
                const { widgetId } =
                    widget as VocFeedbackModuleContentTextfield;

                sourceWidgetId = widgetId;
                break;
            }
            default:
                sourceWidgetId = undefined;
                break;
        }

        if (!sourceWidgetId) {
            return;
        }

        // Create a copy of the values state to
        // properly trigger a refresh in react
        // when the state is updated.
        const newValues = { ...this.state.widgetValues };
        newValues[sourceWidgetId] = widgetValue;
        this.setState({ widgetValues: newValues }, () => {
            this.checkValidation();
        });
    }

    //#region Events

    onShowDetailClicked = () => {
        const { onShowDetailClick } = this.props;
        if (onShowDetailClick) {
            onShowDetailClick();
        }
    };

    onCancelClicked = () => {
        const { onCancel } = this.props;
        if (onCancel) {
            onCancel();
        }
    };

    onSubmitClicked = () => {
        const { onSubmit } = this.props;
        if (onSubmit) {
            onSubmit({ ...this.state.widgetValues });
        }
    };

    onImageToggleChanged =
        (widget: VocFeedbackModuleContent) => (value: FaceType) => {
            const { options } = widget as VocFeedbackModuleContentImageToggle;
            if (!(value in FaceType)) {
                return;
            }
            const imageValue = FaceType[value].toLocaleLowerCase();
            const foundOption = options.find((option) => {
                return option.image === imageValue;
            });
            if (!foundOption) {
                return;
            }
            this.updateWidgetResults(widget, foundOption.value.toString());
        };

    onWidgetTextFieldChanged =
        (widget: VocFeedbackModuleContent) => (
            event: React.ChangeEvent<
                HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
            >
        ) => {
            this.updateWidgetResults(widget, event.target.value);
        };
    //#endregion

    public render() {
        const {
            className,
            classes,
            cancelButtonText,
            cancelButtonTextReadOnly,
            moduleContent,
            open,
            readonly,
            title,
            titleReadOnly,
        } = this.props;
        const { isFormValid } = this.state;
        const rootClasses = `${classes.root}${
            className ? ` ${className}` : ''
        }`;

        return (
            <SimpleModal
                open={open}
                header={
                    <React.Fragment>
                        <div className={classes.headerContainer}>
                            <Typography
                                className={classes.headerText}
                                variant="h5"
                            >
                                {readonly ? titleReadOnly : title}
                            </Typography>
                            <Button
                                className={classes.showReportDetailButton}
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={this.onShowDetailClicked}
                            >
                                Show Report Detail
                            </Button>
                        </div>
                        <Divider />
                    </React.Fragment>
                }
                footer={
                    <div className={classes.footer}>
                        <Button
                            className={classes.cancelButton}
                            size="small"
                            variant="outlined"
                            color="info"
                            onClick={this.onCancelClicked}
                        >
                            {readonly
                                ? cancelButtonTextReadOnly
                                : cancelButtonText}
                        </Button>
                        {!readonly ? (
                            <Button
                                className={classes.submitButton}
                                variant="contained"
                                color="secondary"
                                size="small"
                                disabled={!isFormValid}
                                onClick={this.onSubmitClicked}
                            >
                                Submit
                            </Button>
                        ) : (
                            ''
                        )}
                    </div>
                }
            >
                <div className={rootClasses}>
                    {readonly ? (
                        this.renderWidgets(moduleContent, readonly)
                    ) : (
                        <form className={rootClasses}>
                            {this.renderWidgets(moduleContent, readonly)}
                        </form>
                    )}
                </div>
            </SimpleModal>
        );
    }
}

const MUIComponent = withStyles(styles)(VocPopup);
export { MUIComponent as VocPopup };
