import { Typography } from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
    inject,
    observer
} from 'mobx-react';
import * as React from 'react';
import { LoginAuthorizationAdapter } from '../../adapters/login-authorization-adapter';
import { VERSION } from '../../constants';
import {
    LoginWizard,
    LoginWizardNewAccountData,
    LoginWizardSteps
} from '../../shared/components/login-wizard';
import { CAPTCHA_SITE_KEY } from '../../shared/constants';
import { RootStore } from '../../stores/root.store';

const styles = (theme: Theme) => createStyles({
    root: {
        backgroundColor: '#f9f9f9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '2.24em',
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8)]: {
            marginTop: '1em'
        }
    },
    ccLogo: {
        width: '12.5em',
        height: '7.25em',
        marginBottom: '1.3em',
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8)]: {
            width: '11em',
            height: '6.5em',
            marginBottom: '1em'
        }
    },
    contentContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '60em'
    },
    version: {
        fontSize: '0.75em',
        fontWeight: 900,
        textAlign: 'end',
        width: '75%'
    }
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
}

interface States {
    email: string;
    password: string;
}

@inject('rootStore')
@observer
class Login extends React.Component<Props, States> {
    // DO NOT REMOVE Partial<Props>. It is needed by the rootStore
    public static defaultProps: Partial<Props> = {
    };

    loginAuthorizationAdapter: LoginAuthorizationAdapter = LoginAuthorizationAdapter.getAdapter();
 
    constructor(props: Readonly<Props>) {
        super(props);

        const {
            rootStore: {
                managerStore,
                routerStore
            }
        } = this.props;

        const {
            params : {accessCode}
        } = routerStore.routerState;

        this.state = {
            email: '',
            password: ''
        };
        if (accessCode) {
            managerStore.setCurrentLoginWizardStep(LoginWizardSteps.NewAccount);
        }
    }

    onCheckEmailClicked = (email: string) => {
        const {
            rootStore: {
                managerStore
            }
        } = this.props;

        this.setState({ email });
        managerStore.checkEmail(email);
    };

    onLoginClicked = (password: string, captcha: string) => {
        const {
            email
        } = this.state;

        const {
            rootStore: { managerStore }
        } = this.props;

        managerStore.login(email, password, captcha);
    };

    onForgotPasswordClicked = (email: string) => {
        const {
            rootStore: { managerStore }
        } = this.props;
        
        managerStore.clearLoginError();
        this.setState({ email });
        
        managerStore.setCurrentLoginWizardStep(LoginWizardSteps.PasswordReset);
    };

    onResetPasswordClicked = (email: string) => {
        const {
            rootStore: { managerStore }
        } = this.props;

        this.setState({ email });
        managerStore.clearLoginError();
        managerStore.resetPassword(email);
    };

    onGoBackToLoginClicked = () => {
        const {
            rootStore: { managerStore }
        } = this.props;
        managerStore.clearLoginError();
        managerStore.setCurrentLoginWizardStep(LoginWizardSteps.Login);
    };

    onGotoCreateNewAccountClicked = () => {
        const {
            rootStore: { managerStore }
        } = this.props;
        managerStore.clearLoginError();
        managerStore.setCurrentLoginWizardStep(LoginWizardSteps.NewAccount);
    };
    
    onCreateNewAccountClicked = (newAccountData: LoginWizardNewAccountData) => {
        const {
            accessCode,
            email,
            password,
            username
        } = newAccountData;
        const {
            rootStore: { managerStore }
        } = this.props;

        managerStore
            .createNewAccount(username, email, password, accessCode)
            .catch(() => {
                // this catch removes the console warning.
                // The error is handled directly in the store.
            });
    };

    onValidateNewAccountClicked = (validationCode: number) => {
        const {
            rootStore: { managerStore }
        } = this.props;
        
        const registeredUser = managerStore.loggedInUser;
        managerStore
            .validateNewAccount(registeredUser ? registeredUser.userId : '', validationCode)
            .then(() => {
                managerStore.setCurrentLoginWizardStep(LoginWizardSteps.Login);
            })
            .catch(() => {
                // this catch removes the console warning.
                // The error is handled directly in the store.
            });
    };

    public render() {
        const {
            classes
        } = this.props;
        const {
            email,
        } = this.state;

        const { rootStore } = this.props;
        const {
            routerStore,
            managerStore: {
                isAlternateLoginRequired,
                isLoginLoading,
                loginError,
                currentLoginWizardStep,
                qrMarkerId
            }
        } = rootStore;
        const {
            params : {accessCode}
        } = routerStore.routerState;
        const captchaSiteKey = isAlternateLoginRequired ? CAPTCHA_SITE_KEY : '';

        return (
            <div className={classes.root}>
                <div className={`${classes.ccLogo} cc-logo`} />
                <div className={classes.contentContainer}>
                    <LoginWizard
                        title="Log in to CrowdComfort"
                        email={email}
                        accessCode={accessCode}
                        hideAccessCode={!!qrMarkerId}
                        loading={isLoginLoading}
                        error={loginError}
                        step={currentLoginWizardStep}
                        captchaEnabled={isAlternateLoginRequired}
                        captchaSiteKey={captchaSiteKey}
                        onCheckEmail={this.onCheckEmailClicked}
                        onLogin={this.onLoginClicked}
                        onForgotPassword={this.onForgotPasswordClicked}
                        onResetPassword={this.onResetPasswordClicked}
                        onGoBackToLogin={this.onGoBackToLoginClicked}
                        onGotoCreateNewAccount={this.onGotoCreateNewAccountClicked}
                        onCreateNewAccount={this.onCreateNewAccountClicked}
                        onValidateNewAccount={this.onValidateNewAccountClicked}
                    />
                    <Typography
                        className={classes.version}
                        variant="subtitle2"
                        noWrap={true}
                    >
                        V{VERSION}
                    </Typography>
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(Login);
export {MUIComponent as Login};
