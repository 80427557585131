/* eslint-disable indent */
import { FaceType } from '../components/faces-review';
import {
    VocFeedbackModuleContent,
    VocWidgetType
} from '../models/report/voc-feedback-module';

export class FacesReviewHelper {
    public static getCurrentFace = (content: VocFeedbackModuleContent[]): FaceType => {
        let face;
        for (const item of content) {
            if (item.type === VocWidgetType.ImageToggle) {
                face = item.image;
                break;
            }
        }

        switch (face) {
            case 'happy': {
                return FaceType.Happy;
            }
            case 'neutral': {
                return FaceType.Neutral;
            }
            case 'sad': {
                return FaceType.Sad;
            }
            default: {
                return FaceType.None;
            }
        }
    };
}
