/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export class EnumerationHelper {
    static getEnumValueFromKey<T>(enumeration: any, key: string): T | undefined {
        const EnumKeys = Object.keys(enumeration);
        const keyLowerCase = key.toLowerCase();
        const keysCount = EnumKeys.length;
    
        // If it is an enumeration with integers
        if (enumeration[0] !== undefined) {
            let keyNumberFound: number | undefined;
            let count = 0;
            
            while ((count < keysCount) && (keyNumberFound === undefined)) {
                const currentKey = EnumKeys[count].toLocaleLowerCase();
                if (!isNaN(Number(currentKey)) && 
                    (enumeration[currentKey].toLocaleLowerCase() === keyLowerCase)) {
                    keyNumberFound = count;
                }
                count++;
            }
    
            return (keyNumberFound !== undefined) ? (keyNumberFound as any) as T : undefined;
        }
    
        // If the enumeration contains strings as keys
        // It tries to see if the key matches on enumeration value
        let keyStringFound: string | undefined = enumeration[key];
    
        // If the key is not a match, it tries to search among
        // the enumeration keys
        if (!keyStringFound){
            keyStringFound = EnumKeys.find((enumKey) => {
                return enumKey.toLowerCase() === keyLowerCase;
            });
        }
        return (keyStringFound !== undefined) ? (keyStringFound as any) as T : undefined;
    }

    static getEnumKeyFromValue(enumeration: any, value: string): string | undefined {
        const EnumKeys = Object.keys(enumeration);
        const valueLowerCase = value.toLowerCase();

        // Searches among the enumeration values
        const keyStringFound: string | undefined = EnumKeys.find((enumKey) => {
            return enumeration[enumKey].toLowerCase() === valueLowerCase;
        });

        return keyStringFound;
    }
}
