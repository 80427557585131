import * as React from 'react';

import { Theme } from '@mui/material/styles';
import {
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography
} from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import memoize from 'memoize-one';
import { CCSpinner } from '../cc-spinner';
import { CustomGridListCellDescriptor } from './custom-grid-list-cell-descriptor';
import { CustomGridListCellType } from './custom-grid-list-cell-type';
import { CustomGridListRowDescriptor } from './custom-grid-list-row-descriptor';

const LOADING_TEXT = 'Loading ...';

const styles = (theme: Theme) => createStyles({
    root: {
        position: 'relative'
    },
    table: {},
    row: {},
    customRow: {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        '&:first-child': {
            borderTop: '0',
        }
    },
    spinner: {
        position: 'absolute',
        backgroundColor: theme.ccPalette.disabled.light,
        width: '100%',
        height: '100%',
        zIndex: 1
    },
    noItemsLabel:{
        color: theme.ccPalette.disabled.main,
        textAlign: 'center',
        padding: '2em'
    },
    customCell: {
        flex: '1'
    },
    defaultCell: {
        textAlign: 'center'
    },
    numericCell: {
        textAlign: 'right'
    },
    stringCell : {
        textAlign: 'left'
    }
});

export interface CustomGridListProps {
    rowsData: CustomGridListRowDescriptor[];
    isLoading: boolean;
    noItemsLabel?: string
    onRowClick?: (rowDescriptor: CustomGridListRowDescriptor) => void;
}

interface Props extends CustomGridListProps, WithStyles<typeof styles> {
    className?: string;
}



class CustomGridList extends React.Component<Props> {
    public static defaultProps = {
        isLoading: false
    };

    generateGridRows = memoize((rowsData: CustomGridListRowDescriptor[], isClickable = false): React.ReactNode[] => {
        if (!rowsData || (rowsData.length < 1)) {
            return [];
        }

        return rowsData.map((row: CustomGridListRowDescriptor, index): React.ReactNode => {
            const { id: itemId } = row;
            const rows: React.ReactNode[] = this.generateRowCells(row.cells);
            const { classes } = this.props;

            if (isClickable) {
                return (
                    <ListItem
                        key={`${itemId}-${index}`}
                        className={`${classes.row} ${classes.customRow}`}
                        data-id={itemId}
                        onClick={this.onGridClicked}
                        button={true}
                    >
                        {rows}
                    </ListItem>
                );
            } else {
                return (
                    <ListItem
                        key={`${itemId}-${index}`}
                        className={`${classes.row} ${classes.customRow}`}
                        data-id={itemId}
                        component="div"
                    >
                        {rows}
                    </ListItem>
                );
            }
        });
    });

    generateRowCells = (data: CustomGridListCellDescriptor[]): React.ReactNode[] => {
        return data.map((item: CustomGridListCellDescriptor, index): React.ReactNode => {
            const { classes } = this.props;
            const {
                id: itemId,
                className,
                label,
                child,
                cellType
            } = item;

            let customAlignment = classes.defaultCell;

            switch(cellType) {
                case CustomGridListCellType.string: {
                    customAlignment = classes.stringCell;
                    break;
                }

                case CustomGridListCellType.numeric: {
                    customAlignment = classes.numericCell;
                    break;
                }
            }

            return (
                <ListItemText
                    key={`${index}-${itemId}`}
                    id={itemId}
                    className={`${classes.customCell} ${customAlignment} ${className}`}
                >
                    {child ? child : label}
                </ListItemText>
            );
        });
    };

    onGridClicked = (event: React.MouseEvent<HTMLTableRowElement>) => {
        const { onRowClick } = this.props;
        if (!onRowClick) {
            return;
        }

        const { id } = event.currentTarget.dataset;
        const descriptor: CustomGridListRowDescriptor | null = this.getDescriptorFromId(id);
        
        if (!descriptor) {
            return;
        }
        onRowClick(descriptor as CustomGridListRowDescriptor);
    };

    getDescriptorFromId = (id?: string): CustomGridListRowDescriptor | null => {
        const {
            rowsData
        } = this.props;
        if (!id) {
            return null;
        }

        const descriptors: (CustomGridListRowDescriptor)[] = rowsData;

        const foundItem = descriptors.find((item: CustomGridListRowDescriptor) => {
            return id === item.id;
        });

        return foundItem ? foundItem : null;
    };

    public render() {
        const {
            classes,
            className,
            rowsData,
            isLoading,
            noItemsLabel,
            onRowClick,
        } = this.props;

        const rows = this.generateGridRows(rowsData, !!onRowClick);
        const areRowsPresent = rows && (rows.length > 0);
        const isNoItemLabelPresent = noItemsLabel && (noItemsLabel.length > 0);

        const rootClassNames = classes.root + (className ? ` ${className}` : '');

        return (
            <Paper className={rootClassNames}>
                <CCSpinner
                    className={classes.spinner}
                    loading={isLoading}
                    size={70}
                /> 
                <List
                    className={classes.table}
                    component="div"
                >
                    {
                        (areRowsPresent) ?
                            rows :
                            <Typography
                                className={classes.noItemsLabel}
                                variant="h5"
                            >
                                { isNoItemLabelPresent ? noItemsLabel : isLoading ? LOADING_TEXT : ''}
                            </Typography>
                    }
                </List>
            </Paper>
        );
    }
}

const MUIComponent = withStyles(styles)(CustomGridList);
export { MUIComponent as CustomGridList };
