import {
    ListItem,
    ListItemText
} from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { KeyboardArrowRight } from '@mui/icons-material';
import * as React from 'react';
import {
    VirtualList
} from '../../shared/components/virtual-list';

export interface NavigationListItem {
    id: string;
    label: string;
    isFolder: boolean;
    folderData?: ReadonlyArray<NavigationListItem>;
    folderDataTotal: number;
}

export type NextPageCallback = () => Promise<void>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {},
    listItem: {
        padding: '1em 0.7em'
    },
    listItemText: {
        fontSize: '1em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%'
    },
    loadingText: {
        margin: '0.5em 0 0 0.7em',
        color: 'gray',
        fontStyle: 'italic'
    },
    errorText: {
        color: 'red'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    data: ReadonlyArray<NavigationListItem>;
    hasNextPage: boolean;
    selectedItem?: NavigationListItem;
    onClick?: (item: NavigationListItem, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onArrowClick?: (item: NavigationListItem, event: React.MouseEvent<Element>) => void;
    onNextPageLoad?: NextPageCallback;
}

interface States {
    isNextPageLoading: boolean;
    errorMessage: string;
}

class NavigationList extends React.Component<Props, States> {
    public static defaultProps = {
    };

    state = {
        isNextPageLoading: false,
        errorMessage: ''
    };

    onListItemClicked = (item: NavigationListItem) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { onClick } = this.props;
        if (onClick) {
            onClick(item, event);
        }
    };

    onArrowClicked = (item: NavigationListItem) => (event: React.MouseEvent<Element>) => {
        event.stopPropagation();

        const { onArrowClick } = this.props;
        if (onArrowClick) {
            onArrowClick(item, event);
        }
    };

    onNextPageLoading = (): Promise<void> => {
        const { onNextPageLoad } = this.props;
        if (!onNextPageLoad) {
            return Promise.resolve();
        }
        return new Promise((resolve, reject) => {
            this.setState({isNextPageLoading: true}, () => {
                onNextPageLoad()
                    .then(() => {
                        this.setState({isNextPageLoading: false}, () => {
                            resolve();
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            isNextPageLoading: false,
                            errorMessage: error ? error : 'Unable to load data.'});
                        reject(error);
                    });
            });
        });
    };

    onRenderItem = (
        data: unknown,
        style: React.CSSProperties,
        isItemLoading: boolean
    ): JSX.Element => {
        const {
            id,
            label,
            isFolder
        } = data ? data as NavigationListItem : { id:'', label:'', isFolder: false } as NavigationListItem;
        const {
            errorMessage
        } = this.state;
        const {
            classes,
            selectedItem
        } = this.props;
        const currentStyle = Object.assign({}, style, {display: 'flex'});

        return (
            <div key={id} style={currentStyle}>
                {isItemLoading ? 
                    <span className={`${classes.loadingText}${errorMessage ? ` ${classes.errorText}` : ''}`}>
                        {errorMessage ? `Error: ${errorMessage}` : 'Loading...'}
                    </span>
                    : <ListItem
                        key={id}
                        className={classes.listItem}
                        button={true}
                        divider={true}
                        selected={data === selectedItem}
                        onClick={this.onListItemClicked(data as NavigationListItem)}
                    >
                        <ListItemText
                            primary={label}
                            classes={{primary: classes.listItemText}}
                        />
                        { isFolder ?
                            <KeyboardArrowRight
                                onClick={this.onArrowClicked(data as NavigationListItem)}
                            />
                            : ''
                        }
                    </ListItem>
                }
            </div>
        );
    };

    public render() {
        const {
            classes,
            className,
            data,
            hasNextPage
        } = this.props;
        const {
            isNextPageLoading
        } = this.state;

        const rootClasses = `${classes.root}${className ? ` ${className}` : ''}`;
        return (
            <VirtualList
                className={rootClasses}
                hasNextPage={hasNextPage}
                isNextPageLoading={isNextPageLoading}
                data={(data as unknown) as Array<Record<string, unknown>>}
                onNextPageLoad={this.onNextPageLoading}
                onRenderItem={this.onRenderItem}
            />
        );
    }
}

const MUIComponent = withStyles(styles)(NavigationList);
export { MUIComponent as NavigationList };
