import { Typography } from '@mui/material';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Build } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import memoize from 'memoize-one';
import * as React from 'react';
import { Report } from '../../models/report';
import {
    CustomGridListCellDescriptor,
    CustomGridListCellType,
    CustomGridListRowDescriptor,
} from '../../shared/components/custom-grid-list';
import {
    CustomGridListPagination,
    CustomGridListUtilityFunctions,
    PageBoundary,
} from '../../shared/components/custom-grid-list-pagination';
import Thermometer from '../../shared/components/icons/thermometer';
import { FaceType } from '../faces-review';

import { FacesReviewHelper } from '../../utils/faces-review-helper';
import { MarkerGridCell } from './marker-grid-cell';
import { StatusCell } from './status-cell';

//#region Grid's Cell Creation methods
function createCell(
    id: string,
    child?: React.ReactElement<unknown>,
    className?: string,
    cellType?: CustomGridListCellType
) {
    return {
        id,
        label: '',
        className,
        cellType,
        child,
    } as CustomGridListCellDescriptor;
}

function createCellText(
    id: string,
    label = '',
    contentClassName: string,
    className?: string,
    cellType?: CustomGridListCellType
): CustomGridListCellDescriptor {
    const child = <div className={contentClassName}>{label}</div>;

    return createCell(id, child, className, cellType);
}

function createCellForModuleType(
    id: string,
    moduleType: string,
    wrenchIconClassName: string,
    thermometerClassName: string,
    className?: string,
    cellType?: CustomGridListCellType
): CustomGridListCellDescriptor {
    let child: JSX.Element;
    switch (moduleType.toLocaleLowerCase()) {
        case 'thermal':
            child = <Thermometer className={thermometerClassName} />;
            break;
        default:
            // case 'maintenance':
            child = <Build className={wrenchIconClassName} />;
            break;
    }

    return createCell(id, child, className, cellType);
}

function createCellForMarker(
    id: string,
    report: Report,
    isPortal: boolean,
    faceType: FaceType,
    contentClassName: string,
    className?: string,
    onOpenVocClick?: (report: Report) => void,
    cellType?: CustomGridListCellType
): CustomGridListCellDescriptor {
    const child = (
        <MarkerGridCell
            classes={{ root: contentClassName }}
            report={report}
            isPortal={isPortal}
            faceType={faceType}
            onOpenVocClick={onOpenVocClick}
        />
    );

    return createCell(id, child, className, cellType);
}

function createCellForStatus(
    id: string,
    report: Report,
    isPortal: boolean,
    contentClassName: string,
    className?: string,
    agreesCallback?: (report: Report) => void,
    cellType?: CustomGridListCellType
): CustomGridListCellDescriptor {
    const child = (
        <StatusCell
            classes={{ root: contentClassName }}
            report={report}
            isPortal={isPortal}
            onAgreesClick={agreesCallback}
        />
    );

    return createCell(id, child, className, cellType);
}

//#endregion

const styles = (theme: Theme) =>
    createStyles({
        wrenchIcon: {
            width: '1em',
            color: theme.ccPalette.cc_colors.solid.ccRightsilhouette.main,
        },
        thermometerIcon: {
            width: '1em',
            fill: theme.ccPalette.cc_colors.solid.ccRightsilhouette.main,
            margin: '0 0.3em',
        },
        summaryGridWrapper: {
            height: '100%',
        },
        gridWrapper: {
            flex: 1,
        },
        grid: {
            '& $gridRow:hover $cellContentTextWrapper:after': {
                background: '#F6F6F6', // Background color that blends with the translucent state color
            },
        },
        gridRow: {
            cursor: 'pointer',
            [theme.breakpoints.only('xs')]: {
                flexWrap: 'wrap',
            },
        },
        gridEmptyWrapper: {
            height: '100%',
            [theme.breakpoints.only('xs')]: {
                height: 'auto',
            },
        },
        gridEmpty: {
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            height: '100%',
            justifyContent: 'center',
            [theme.breakpoints.only('xs')]: {
                fontSize: '0.8em',
            },
        },
        gridEmptyText: {
            display: 'inline-block',
        },
        searchIcon: {
            color: '#999',
            fontSize: '10em',
            lineHeight: '10em',
        },
        cellSummaryGrid: {
            flex: '1 1 auto',
            textAlign: 'left',
            padding: '0 0.5em',
        },
        cellContentTextWrapper: {
            position: 'relative',
            maxWidth: '41em',
            overflow: 'hidden',
            lineHeight: '1.2em',
            maxHeight: '4.7em',
            textAlign: 'justify',
            marginRight: '-1em',
            paddingRight: '1em',
            // Create the points it the end
            '&:before': {
                position: 'absolute',
                content: '"..."',
                /* set position to right bottom corner of block */
                right: '0.2em',
                bottom: 0,
            },
            '&:after': {
                position: 'absolute',
                content: '""',
                /* set position to right bottom corner of text */
                right: '0.1em',
                /* set width and height */
                width: '1em',
                height: '1em',
                marginTop: '0.2em',
                /* bg color = bg color under block */
                background: 'white',
            },
        },
        cellContentText: {
            wordBreak: 'break-word',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '30em',
            [theme.breakpoints.only('xs')]: {
                fontSize: '0.9em',
            },
        },
        cellModuleType: {
            width: '3em',
            paddingRight: 0,
            [theme.breakpoints.only('xs')]: {
                position: 'absolute',
                top: '0.65em',
            },
        },
        cellMarker: {
            width: '24%',
            [theme.breakpoints.only('xs')]: {
                width: 'calc(100% - 3em)',
                paddingRight: '11em',
            },
        },
        cellMarkerContent: {
            maxWidth: '20em',
        },
        cellContentFolderName: {
            maxWidth: '11em',
        },
        cellStatusContainer: {
            width: '21%',
            textAlign: 'right',
            [theme.breakpoints.only('xs')]: {
                width: 'auto',
                position: 'absolute',
                right: '0',
                top: '1em',
            },
        },
        cellStatus: {},
        cellDescription: {
            width: '25%',
            [theme.breakpoints.only('xs')]: {
                marginTop: '0.5em',
                width: '100%',
            },
        },
        cellGroup: {
            width: '25%',
            [theme.breakpoints.only('xs')]: {
                marginTop: '0',
                width: '100%',
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    className?: string;
    reports: Report[] | undefined;
    isLoading: boolean;
    rowsPerPage: number;
    isPortal: boolean;
    emptyGridTitle: string;
    emptyGridSubTitle: string;
    totalRows?: number;
    offset?: number;
    onComponentMount?: (utilities: CustomGridListUtilityFunctions) => void;
    onPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => void;
    onClick?: (report: Report) => void;
    onAgreesClick?: (report: Report) => void;
    onOpenVocClick?: (report: Report) => void;
}

class SummaryGrid extends React.Component<Props> {
    public static defaultProps = {
        emptyGridTitle: '',
        emptyGridSubTitle: '',
        isLoading: false,
    };

    createGridRows = memoize(
        (rows: Report[] | undefined): CustomGridListRowDescriptor[] => {
            return rows
                ? rows.map((row: Report) => {
                    return this.createRow(row);
                })
                : ([] as CustomGridListRowDescriptor[]);
        }
    );

    createRow = (report: Report): CustomGridListRowDescriptor => {
        const { classes, isPortal, onAgreesClick, onOpenVocClick } = this.props;
        const {
            id,
            idNumber: cellId,
            folderName,
            moduleType,
            summary,
            userFeedback,
            userCanLeaveFeedback,
        } = report;

        let faceType = FaceType.None;
        if (userCanLeaveFeedback === false && userFeedback) {
            faceType = FacesReviewHelper.getCurrentFace(
                userFeedback[userFeedback.length - 1].content
            );
        }

        const cells: CustomGridListCellDescriptor[] = [
            createCellForModuleType(
                `${cellId}-01`,
                moduleType,
                classes.wrenchIcon,
                classes.thermometerIcon,
                `${classes.cellSummaryGrid} ${classes.cellModuleType}`
            ),
            createCellForMarker(
                `${cellId}-02`,
                report,
                isPortal,
                faceType,
                classes.cellMarkerContent,
                `${classes.cellSummaryGrid} ${classes.cellMarker}`,
                onOpenVocClick
            ),
            createCellText(
                `${cellId}-03`,
                folderName,
                `${classes.cellContentText} ${classes.cellContentFolderName}`,
                `${classes.cellSummaryGrid} ${classes.cellGroup}`
            ),
            createCellText(
                `${cellId}-04`,
                summary,
                `${classes.cellContentTextWrapper}`,
                `${classes.cellSummaryGrid} ${classes.cellDescription}`
            ),
            createCellForStatus(
                `${cellId}-05`,
                report,
                isPortal,
                `${classes.cellStatus}`,
                `${classes.cellSummaryGrid} ${classes.cellStatusContainer}`,
                onAgreesClick
            ),
        ];

        return {
            id,
            cells,
        } as CustomGridListRowDescriptor;
    };

    onRowClicked = (rowDescriptor: CustomGridListRowDescriptor) => {
        const { onClick, reports } = this.props;

        if (!onClick || !reports) {
            return;
        }

        const reportFound = reports.find((report: Report) => {
            return rowDescriptor.id === report.id;
        });
        if (reportFound) {
            onClick(reportFound);
        }
    };

    public render() {
        const {
            classes,
            className,
            reports,
            isLoading,
            rowsPerPage,
            totalRows,
            offset,
            emptyGridTitle,
            emptyGridSubTitle,
            onComponentMount,
            onPageOverBoundary,
        } = this.props;
        const rowsData = this.createGridRows(reports);

        return (
            <div className={classes.summaryGridWrapper}>
                {rowsData.length > 0 ? (
                    <CustomGridListPagination
                        classes={{
                            table: classes.grid,
                            row: classes.gridRow,
                        }}
                        className={className}
                        gridClassName={classes.gridWrapper}
                        isLoading={isLoading}
                        rowsData={rowsData}
                        rowsPerPage={rowsPerPage}
                        totalItems={totalRows}
                        offset={offset}
                        onComponentMount={onComponentMount}
                        onPageOverBoundary={onPageOverBoundary}
                        onRowClick={this.onRowClicked}
                    />
                ) : (
                    <div className={classes.gridEmptyWrapper}>
                        <div className={classes.gridEmpty}>
                            <SearchIcon className={classes.searchIcon} />
                            {emptyGridTitle || emptyGridSubTitle ? (
                                <div className={classes.gridEmptyText}>
                                    {emptyGridTitle ? (
                                        <Typography variant="h6">
                                            {emptyGridTitle}
                                        </Typography>
                                    ) : (
                                        ''
                                    )}
                                    {emptyGridSubTitle ? (
                                        <Typography variant="subtitle1">
                                            {emptyGridSubTitle}
                                        </Typography>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(SummaryGrid);
export { MUIComponent as SummaryGrid };
