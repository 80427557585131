import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
    inject,
    observer
} from 'mobx-react';
import * as React from 'react';
import { RootStore } from '../../stores/root.store';
import { DataGridList } from '../../shared/components/data-grid-list';
import { 
    GridColDef,
    GridPaginationModel,
    GridRowsProp,
    GridValidRowModel
} from '@mui/x-data-grid';
import memoize from 'memoize-one';
import { SideNavbar } from '../../shared/components/side-navbar';
import ArticleIcon from '@mui/icons-material/Article';
import {
    Autocomplete,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from '@mui/material';
import { SimpleModal } from '../../shared/components/simple-modal';
import { Close } from '@mui/icons-material';
import { CCSnackbar, CCSnackbarVariant } from '../../shared/components/cc-snackbar';
import { SimpleDialog } from '../../shared/components/simple-dialog';
import { CSVButton, ExportToCSVData } from '../../shared/components/csv-button';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { ExcelButton, exportXLSParams } from '../../shared/components/excel-button';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { CCDateTimePicker } from '../../components/cc-date-time-picker';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { FORMAT_DATETIME, TIMEZONE_MAP } from '../../constants';
import { DropdownChips, TypeSelectorData } from '../../components/dropdown-chips';
import { DefaultTaskFilters, TaskFilterParameters } from '../../shared/domain';
import { ButtonType, CCDatePickerButton, CCDatePickerDateRange } from '../../components/cc-date-picker';
import { CustomFiltersParameters, FolderFilterValues } from '../../shared/domain/completion';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import { storedCustomFilterValue } from '../../stores/manager.store';

const NO_EXPIRATION = 'No expiration';
const ANNOTATION_CREATED_MESSAGE = 'Annotation is created';
const ANNOTATION_COMPLETED_MESSAGE = 'Annotation is completed';
const ANNOTATION_STATUS_LABEL = 'Facility Vendor Review Status';
const DEFAULT_DATE_RANGE: CCDatePickerDateRange = {
    from: undefined as unknown as Date,
    to: undefined as unknown as Date,
    buttonType: ButtonType.All,
    disableFrom: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
};
dayjs.extend(timezone);
dayjs.extend(utc);

const DateButtonList = [
    ButtonType.All,
    ButtonType.Today,
    ButtonType.Yesterday,
    ButtonType.Tomorrow,
    ButtonType.Last7Days,
    ButtonType.Last30Days,
    ButtonType.LastYear,
    ButtonType.Custom
];

const styles = (theme: Theme) => createStyles({
    divider: {
        margin: '1em 0',
    },
    contentContainer: {
        '&, &$navbarContent': {
            padding: '1.45em',
        },
        [theme.breakpoints.between(
            'xs',
            theme.breakpoints.values.iPhone8_Landscape
        )]: {
            padding: '0',
        },
    },
    dataGridContainer: {
        padding: '2em',
        width: 'calc(100% - 4em)',
        height: 'calc(100vh - 6.3em)',
        '&.navbar-opened': {
            width: 'calc(100% - 20.2em)',
            transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.standard,
            })
        },
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
            '& .MuiDataGrid-cell:focus': {
                outline: '0'
            }
        },
        '& .task-first-column': {
            color: theme.palette.secondary.main,
            fontWeight: 'bold'
        }
    },
    errorContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        margin: '0 2em'
    },
    errorMessage: {
        width: '100%',
        color: 'red',
        fontWeight: 400,
        textAlign: 'center',
        margin: '1em'
    },
    navbarIconContainer: {
        padding: '0.3em 0.4em',
        backgroundColor: theme.palette.secondary.main,
    },
    navbarIconContainerSmall: {
        transform: 'scale(0.7)',
        margin: '0.7em',
    },
    navbarIcon: {
        color: 'white',
    },
    navbarContent: {
        display: 'flex',
        flex: '1 0 0',
        flexDirection: 'column',
        width: '20em',
    },
    navbarTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    navbarTitle: {
        fontWeight: 'bold',
        marginLeft: '0.4em',
    },
    navbarComponentTitle: {
        color: theme.palette.grey[500],
        fontSize: '0.9em',
        fontWeight: 600,
        marginBottom: '0.3em',
    },
    modalContainer: {
        padding: '2em',
        maxWidth: '80em',
        width: '100%',
        overflow: 'auto',
        '&.is-task-missed': {
            height: '90%'
        }
    },
    modalHeaderContainer: {
        position: 'relative',
        padding: '8px'
    },
    modalTitle: {
        fontSize: '2em',
        fontWeight: 'bold',
        marginBottom: '0.5em'
    },
    modalCloseButton: {
        position: 'absolute',
        right: '0',
        top: '0'
    },
    modalSectionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    modalBodyList: {
        padding: '0',
        margin: '0',
        listStyle: 'none',
        minWidth: '14em',
        '& li': {
            padding: '0.5em 0'
        },
        '& span': {
            fontWeight: 'bold',
            marginRight: '0.5em'
        }
    },
    bodyLeftColumn: {
        width: '32em'
    },
    headerColumn: {
        padding: '0.3em 0',
        width: '32em'
    },
    moduleGroupField: {
        width: '100%'
    },
    snackbar: {
        top: '7em',
    },
    exportButton: {
        width: '100%',
        height: '4em',
        marginBottom: '1em',
        '& .MuiSvgIcon-root': {
            marginRight: '0.2em',
            transform: 'scale(0.9)'
        }
    },
    annotationFieldsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    annotationField: {
        width: '24em',
        padding: '0 1em 1em 0',
    },
    annotationSelect: {
        width: '19em'
    },
    annotationDatetime: {
        width: '21.7em'
    },
    saveAnnotationContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        paddingTop: '1em',
        borderTop: '1px solid',
        borderColor: theme.palette.primary.main
    },
    spinner: {
        zIndex: 1400,
        position: 'fixed',
    },
    chipContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1.6em',
        minHeight: '8.5em',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1.6em',
    },
    chip: {},
    navbarInput: {
        width: '100%',
        paddingLeft: '0.4em',
    },
    navbarPlaceholder: {
        fontSize: '0.9em',
    },
    modalReadOnlyContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        '& span': {
            fontWeight: 'bold',
            marginRight: '0.5em'
        }
    },
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
}

interface TaskDetail {
    location: string;
    id: string;
    address: string;
    status: string;
    startTime: string;
    endTime: string;
    name: string;
    zipcode: string;
    city: string;
    country: string;
    state: string;
    consolidatedType: string;
    stratification: string;
    cleanable: string;
    cleaningContract: string;
    folder: string;
    moduleGroup: string;
    activeDate: string;
    expireDate: string;
    ingestionDate: string;
    rsf: string;
    isTaskMissed: boolean;
    plannedTaskId: number;
    annotationId: number;
    taskStatus: string;
    cleanings: string;
    areaManager: string;
    ifm: string;
    folderId: string;
    reportId: string | undefined;
    reportDate: string | undefined;
}

interface GridRowDic {
    [key: string]: string | number | boolean | Date | undefined;
}

interface KeyMapping {
    [key: string]: string
}

interface AnnotationField {
    [key: string]: string | number | boolean | string[];
}

interface AnnotationFields {
    [key: string]: {
        [key: string]: AnnotationField
    }
}

interface TaskingDic {
    portal3_tab: {
        summary_properties: string[];
        summary_columns_header_name: KeyMapping;
        task_status_enumeration: string[];
        start_day?: string;
        end_day?: string;
    };
    custom_filters: string[];
    custom_filters_labels: KeyMapping;
}

interface AnnotationDic {
    workflow: AnnotationFields;
    initial_review_status: string;
    review_statuses: string[];
}

interface AnnotationSelectedValues {
    [key: string]: string | number | boolean;
}

interface AnnotationSavedValues {
    [key: string]: AnnotationSelectedValues
}

interface CustomFilter {
    label: string;
    param: string;
    values: string[];
    page: number;
    search: string | undefined;
    isLoadingSearch: boolean;
    isLoadingPagination: boolean;
    isLoadingValues: boolean;
    selectedValue: string | undefined;
}

interface States {
    errorMessage: string;
    rows: GridRowsProp;
    totalRows: number;
    paginationModel: {
        page: number;
        pageSize: number
    };
    isTasksDrawerOpen: boolean;
    isTasksDetailOpen: boolean;
    taskDetail: TaskDetail;
    snackbarVariant: CCSnackbarVariant;
    snackbarMessage: string;
    snackbarOpen: boolean;
    dialogOpen: boolean;
    dialogTitle: string;
    dialogMessage: string;
    disableButton: boolean;
    annotationFields: AnnotationFields | undefined;
    summaryProperties: string[];
    summaryColumnsHeaderName: KeyMapping;
    reviewStatus: string | undefined;
    annotationSelectedValues: AnnotationSelectedValues;
    annotationSavedValues: AnnotationSavedValues;
    isAnnotationStepContinue: boolean;
    taskStatusEnumeration: string[];
    statusSelectedIds: string[];
    propertyIdToFilter: string;
    reviewStatusSelectedIds: string[];
    datePickerDateRange: CCDatePickerDateRange | undefined;
    datesToFilter: string[];
    customFilters: CustomFilter[];
    defaultFilters: DefaultTaskFilters[];
    folderFilters: FolderFilterValues[];
    folderToFilter: FolderFilterValues | undefined;
    isFolderFilterLoading: boolean;
    hasUserDocFilters: boolean;
    startDay: string;
    endDay: string;
}

const emptyTask: TaskDetail = {
    location: '',
    address: '',
    id: '',
    status: '',
    startTime: '',
    endTime: '',
    name: '',
    zipcode: '',
    city: '',
    country: '',
    state: '',
    consolidatedType: '',
    stratification: '',
    cleanable: '',
    cleaningContract: '',
    folder: '',
    moduleGroup: '',
    activeDate: '',
    expireDate: '',
    ingestionDate: '',
    rsf: '',
    isTaskMissed: false,
    plannedTaskId: 0,
    annotationId: 0,
    taskStatus: '',
    cleanings: '',
    areaManager: '',
    ifm: '',
    folderId: '',
    reportId: undefined,
    reportDate: undefined
};

@inject('rootStore')
@observer
class TasksTab extends React.Component<Props, States> {
    public static defaultProps = {
    };
    
    state = {
        errorMessage: '',
        rows: [],
        totalRows: 0,
        paginationModel: {
            page: 0,
            pageSize: 10
        },
        isTasksDrawerOpen: false,
        isTasksDetailOpen: false,
        taskDetail: emptyTask,
        snackbarVariant: 'info' as CCSnackbarVariant,
        snackbarMessage: '',
        snackbarOpen: false,
        dialogOpen: false,
        dialogTitle: '',
        dialogMessage: '',
        disableButton: false,
        annotationFields: undefined,
        summaryProperties: [],
        summaryColumnsHeaderName: {},
        reviewStatus: undefined,
        annotationSelectedValues: {} as AnnotationSelectedValues,
        annotationSavedValues: {} as AnnotationSavedValues,
        isAnnotationStepContinue: false,
        taskStatusEnumeration: [],
        statusSelectedIds: [],
        reviewStatusSelectedIds: [],
        propertyIdToFilter: '',
        datePickerDateRange: DEFAULT_DATE_RANGE,
        datesToFilter: [],
        customFilters: [] as CustomFilter[],
        defaultFilters: [],
        folderFilters: [] as FolderFilterValues[],
        folderToFilter: undefined,
        isFolderFilterLoading: true,
        hasUserDocFilters: false,
        startDay: '00:00',
        endDay: '23:59'
    };

    initialReviewStatus?: string;
    reviewStatuses?: string[];
    currentTimezone = dayjs.tz.guess();
    folderTimezone = '';
    filterStatuses = [] as TypeSelectorData[];
    filterReviewStatuses = [] as TypeSelectorData[];
    filterDates = [] as TypeSelectorData[];

    componentDidMount(): void {
        const {
            paginationModel: {
                page,
                pageSize
            },
            startDay: defaultStartDay,
            endDay: defaultEndDay
        } = this.state;
    
        const {
            rootStore: {
                managerStore: {
                    currentAccount,
                    user,
                    plannedTaskFilters,
                    setPlannedTaskFilters
                }
            }
        } = this.props;
    
        const tasking = currentAccount?.tasking as TaskingDic | undefined;
        const portal3Tab = tasking?.portal3_tab;
        if (portal3Tab) {
            const taskStatusEnumeration = portal3Tab.task_status_enumeration;
            this.filterStatuses = taskStatusEnumeration.map((value: string, key: number) => {
                return {
                    id: `${key}`,
                    name: value
                } as TypeSelectorData;
            });

            const startDay = portal3Tab?.start_day;
            const endDay = portal3Tab?.end_day;

            this.setState({
                summaryProperties: portal3Tab.summary_properties,
                summaryColumnsHeaderName: portal3Tab.summary_columns_header_name,
                taskStatusEnumeration: taskStatusEnumeration,
                startDay: startDay ? startDay : defaultStartDay,
                endDay: endDay ? endDay : defaultEndDay
            });
        }
    
        // Add annotation values
        const annotation = currentAccount?.annotation as AnnotationDic | undefined;
        const annotationFields = annotation?.workflow;
        this.initialReviewStatus = annotation?.initial_review_status;
        this.reviewStatuses = annotation?.review_statuses;
        
        // Add filters for review status
        this.filterReviewStatuses = this.reviewStatuses ? this.reviewStatuses.map((item, index) => {
            return {
                id: `${index}`,
                name: item
            } as TypeSelectorData;
        }): [];
    
        // Add custom filters
        const customFiltersLabels = tasking?.custom_filters_labels;
        const customFiltersMeta = tasking?.custom_filters;
        
        const customFilters = customFiltersLabels && customFiltersMeta ? customFiltersMeta.map((value: string) => {
            return {
                label: customFiltersLabels[value],
                param: value,
                selectedValue: undefined,
                page: 0,
                search: undefined,
                isLoadingSearch: true,
                isLoadingPagination: false,
                values: [],
                isLoadingValues: true
            } as CustomFilter;
        }) : [] as CustomFilter[];
        
        const { customSelectedFilters, ...stateStoredFilters} = plannedTaskFilters;

        this.setState({
            customFilters
        }, () => {
            customFilters.forEach((filter, index) => {
                this.fetchCustomFilterValues(10, index, customSelectedFilters);
            });
        });
    
        if (annotationFields && this.initialReviewStatus) {
            this.setState({
                annotationFields,
                reviewStatus: this.initialReviewStatus
            });
        }
    
        this.fetchFolderFilterValues();
        if (user && user.filters && !plannedTaskFilters.hasUserDocFilters) {
            const filters = user.filters as DefaultTaskFilters[];
            const stateFilters: {
                statusSelectedIds: string[],
                propertyIdToFilter: string,
                reviewStatusSelectedIds: string[],
                customFilters: CustomFilter[],
                folderToFilter: FolderFilterValues | undefined,
                hasUserDocFilters: boolean
            } = {
                statusSelectedIds: [],
                propertyIdToFilter: '',
                reviewStatusSelectedIds: [],
                customFilters,
                folderToFilter: undefined,
                hasUserDocFilters: true
            };
            
            plannedTaskFilters.customSelectedFilters = [];
            plannedTaskFilters.hasUserDocFilters = true;
            filters.forEach((filter) => {
                switch (filter.filter_type) {
                    case 'task_status':
                        stateFilters.statusSelectedIds = filter.filter_value.split(',');
                        plannedTaskFilters.statusSelectedIds = stateFilters.statusSelectedIds;
                        break;
                    case 'task_property_id':
                        stateFilters.propertyIdToFilter = filter.filter_value;
                        plannedTaskFilters.propertyIdToFilter = stateFilters.propertyIdToFilter;
                        break;
                    case 'task_annotation_status':
                        stateFilters.reviewStatusSelectedIds = filter.filter_value.split(',');
                        plannedTaskFilters.reviewStatusSelectedIds = stateFilters.reviewStatusSelectedIds;
                        break;
                    case 'task_folder_id':
                        stateFilters.folderToFilter = JSON.parse(filter.filter_value) as FolderFilterValues;
                        plannedTaskFilters.folderToFilter = stateFilters.folderToFilter;
                        break;
                    case 'task_am': {
                        const amFilter = stateFilters.customFilters.find(e => e.param === 'custom_am.user_value');
                        if (!amFilter) {
                            return;
                        }
                        amFilter.selectedValue = filter.filter_value;
                        plannedTaskFilters.customSelectedFilters.push({
                            param: 'custom_am.user_value',
                            selectedValued: amFilter.selectedValue
                        });
                        break;
                    }
                    case 'task_ifm':{
                        const ifmFilter = stateFilters.customFilters.find(e => e.param === 'custom_ifm.user_value');
                        if (!ifmFilter) {
                            return;
                        }
                        ifmFilter.selectedValue = filter.filter_value;
                        plannedTaskFilters.customSelectedFilters.push({
                            param: 'custom_ifm.user_value',
                            selectedValued: ifmFilter.selectedValue
                        });
                        break;
                    }
                    default:
                        break;
                }
            });

            setPlannedTaskFilters(plannedTaskFilters);
            this.setState(stateFilters, () => {
                this.refreshGrid(page, pageSize);
            });
            return;
        }

        // Add grid values
        this.setState(stateStoredFilters, () => {
            this.refreshGrid(page, pageSize);
        });
    }
    
    componentWillUnmount(): void {
        const {
            hasUserDocFilters,
            datePickerDateRange,
            statusSelectedIds,
            folderToFilter,
            propertyIdToFilter,
            reviewStatusSelectedIds,
            customFilters
        } = this.state;

        const {
            rootStore: {
                managerStore
            }
        } = this.props;

        managerStore.setPlannedTaskFilters({
            hasUserDocFilters,
            datePickerDateRange,
            statusSelectedIds: [...statusSelectedIds],
            folderToFilter,
            propertyIdToFilter,
            reviewStatusSelectedIds: [...reviewStatusSelectedIds],
            customSelectedFilters: customFilters.filter(e => e.selectedValue !== '' && e.selectedValue !== undefined)
                .map(j => {
                    return {
                        param: j.param,
                        selectedValued: j.selectedValue
                    };
                })
        });
    }

    refreshGrid = (page: number, pageSize: number) => {
        const {
            rootStore: {
                managerStore: {
                    getTasks
                }
            }
        } = this.props;
        const {
            statusSelectedIds,
            propertyIdToFilter,
            reviewStatusSelectedIds,
            datesToFilter,
            customFilters,
            folderToFilter
        } = this.state;
        const filters: {[key: string]: string} = {};

        if (statusSelectedIds.length) {
            filters['status'] = statusSelectedIds.toString();
        }

        if (propertyIdToFilter !== '') {
            filters['secondary_id'] = propertyIdToFilter;
        }

        if (reviewStatusSelectedIds.length) {
            filters['review_status'] = reviewStatusSelectedIds.toString();
        }

        if (datesToFilter.length) {
            filters['date'] = datesToFilter.toString();
        }

        if (folderToFilter) {
            filters['folder_id'] = folderToFilter['folder_id'];
        }

        customFilters.forEach(custom => {
            if (custom.selectedValue) {
                filters[custom.param] = custom.selectedValue;
            }
        });

        getTasks(pageSize, page * pageSize, filters as unknown as TaskFilterParameters).then((tasks) => {
            const { rows, total } = tasks;
            const tasksRows = this.createRows(rows);
            this.setState({
                rows: tasksRows,
                totalRows: total
            });
        }).catch((errorMessage) => {
            this.showError(errorMessage);
        });
    };

    createColumns = memoize((summaryProperties: string[], summaryColumnsHeaderName: KeyMapping): Array<GridColDef<GridValidRowModel>> => {
        const columnsWidth: {
            [key: string]: number;
        } = {
            'Property Zip Code': 105,
            'State': 60
        };

        const columns = summaryProperties.map((item) => {
            const column : {
                field: string;
                headerName: string;
                menu: boolean;
                flex: number;
                minWidth: number;
                cellClassName?: string; 
            } = {
                field: item,
                headerName: (item in summaryColumnsHeaderName) ? summaryColumnsHeaderName[item] : item,
                menu: false,
                flex: 1,
                minWidth: item in columnsWidth ? columnsWidth[item] : 185
            };

            return column;
        });

        if (columns.length) {
            columns[0].cellClassName = 'task-first-column';
        }

        return columns;
    });

    createRows = (schedules: GridRowDic[]): GridRowsProp => {
        const { taskStatusEnumeration } = this.state;
        const rows = schedules.map((row: GridRowDic, index) => {
            row.plannedTaskId = row.id;
            row.id = index;
            row.isTaskMissed = row.task_status === 2 ? true : false;
            row.task_status = taskStatusEnumeration[row.task_status as number];
            row.review_status = !row.isTaskMissed ? 'Not-applicable ' : !row.review_status ? this.initialReviewStatus : this.reviewStatuses?.[row.review_status as number];

            const startTime = dayjs.unix(row.task_start_time as number);
            const endTime = dayjs.unix(row.task_end_time as number);
            const timezone = `${row.timezone}`.replace(' ', '_');
            const timezoneMapped = timezone in TIMEZONE_MAP ? TIMEZONE_MAP[timezone] : timezone;
            row.task_start_time = `${dayjs(startTime).tz(timezone).format(FORMAT_DATETIME)} ${timezoneMapped}`;
            row.task_end_time = `${dayjs(endTime).tz(timezone).format(FORMAT_DATETIME)} ${timezoneMapped}`;
            row.timezone = timezone;

            return row;
        });

        return rows as GridRowsProp;
    };

    fetchCustomFilterValues = (pageSize: number, filterIndex: number, preExistingFilters?: storedCustomFilterValue[]) => {
        const {
            rootStore: {
                managerStore: {
                    getCustomFilterValues
                }
            }
        } = this.props;
        const { customFilters } = this.state;
        const filterToFetch = customFilters[filterIndex];

        const[objectType, attribute] = filterToFetch.param.split('.');
        const filters: {[key: string]: string} = {};

        filters['object_type'] = objectType;
        filters['attribute'] = attribute;
        filters['module'] = 'tasking';

        if (filterToFetch.search) {
            filters['value'] = filterToFetch.search;
        }

        getCustomFilterValues(pageSize, pageSize * filterToFetch.page, filters as CustomFiltersParameters).then((values) => {
            const { customFilters } = this.state;
            const filterToUpdate = customFilters[filterIndex];
            if (filterToUpdate.isLoadingPagination) {
                filterToUpdate.values = [...filterToUpdate.values, ...values].filter(e => e !== 'Loading more results');
            } else {
                filterToUpdate.values = values;
            }
            filterToUpdate.isLoadingPagination = false;
            filterToUpdate.isLoadingSearch = false;
            filterToUpdate.isLoadingValues = false;
            const storedValue = preExistingFilters ? preExistingFilters.find(e => e.param === filterToUpdate.param) : undefined;
            if (storedValue && storedValue.selectedValued) {
                filterToUpdate.selectedValue = storedValue.selectedValued;
            }
            this.setState({ customFilters });
        }).catch((errorMessage: string | undefined) => {
            this.showError(errorMessage);
        });
    };

    fetchFolderFilterValues = () => {
        const {
            rootStore: {
                managerStore: {
                    getFolderFiltersValues
                }
            }
        } = this.props;

        getFolderFiltersValues('tasking').then((values) => {
            this.setState({ folderFilters: values, isFolderFilterLoading: false });
        }).catch((errorMessage: string | undefined) => {
            this.showError(errorMessage);
        });
    };

    showSnackbar = (
        snackbarMessage: string,
        snackbarVariant: CCSnackbarVariant
    ) => {
        this.setState({
            snackbarVariant,
            snackbarMessage,
            snackbarOpen: true,
        });
    };

    showError = (errorMessage: string | undefined) => {
        let message;
        if (errorMessage) {
            message = errorMessage;
        } else {
            message = 'Unknown Error';
        }

        this.showSnackbar(message, 'error' as CCSnackbarVariant);
    };

    showWarning = (warningMessage: string | undefined) => {
        if (!warningMessage) {
            return;
        }

        this.showSnackbar(warningMessage, 'warning' as CCSnackbarVariant);
    };

    showDialog = (dialogTitle: string, dialogMessage: string) => {
        this.setState({
            dialogTitle,
            dialogMessage,
            dialogOpen: true
        });
    };

    onSnackbarClosed = () => {
        this.setState({ snackbarOpen: false });
    };

    onDialogClosed = () => {
        this.setState({ dialogOpen: false });
    };

    onPageChange = (model: GridPaginationModel) => {
        const { page, pageSize } = model;
        this.refreshGrid(page, pageSize);
        this.setState({ paginationModel: model });
    };

    onToggleSideNavBar = () => {
        const { isTasksDrawerOpen } = this.state;
        this.setState({ isTasksDrawerOpen: !isTasksDrawerOpen});
    };

    onRowSelected = (rowsSelected: GridRowsProp) => {
        const {
            rootStore: {
                managerStore: {
                    getAnnotation
                }
            }
        } = this.props;

        const {
            annotationFields
        } = this.state;

        if (rowsSelected.length) {
            const [ rowSelected ] = rowsSelected;
            this.folderTimezone = rowSelected['timezone'];
            const activeDateTimestampFormated = dayjs.unix(rowSelected['active_datetime_timestamp']);
            const expireDateTimestampFormated = dayjs.unix(rowSelected['expire_datetime_timestamp']);
            const activeDate = `${dayjs(activeDateTimestampFormated).tz(this.folderTimezone).format(FORMAT_DATETIME)}`;
            const expireDate = rowSelected['expire_datetime_timestamp'] !== 99999999999 ? `${dayjs(expireDateTimestampFormated).tz(this.folderTimezone).format(FORMAT_DATETIME)}` : NO_EXPIRATION;
            const ingestionDateTimestampFormated = dayjs.unix(rowSelected['ingestion_date']);
            const ingestionDate = `${dayjs(ingestionDateTimestampFormated).format(FORMAT_DATETIME)}`;
            const annotationId = rowSelected['annotation_id'] ? rowSelected['annotation_id'] : 0;
            const timezoneMapped = this.folderTimezone in TIMEZONE_MAP ? TIMEZONE_MAP[this.folderTimezone] : this.folderTimezone;
            const folderId = rowSelected['folder_doc_id'] ? rowSelected['folder_doc_id'].replace('folder::', '') : undefined;
            const propertyId = rowSelected['Property ID'];
            const ingestionTimezoneMapped = this.currentTimezone in TIMEZONE_MAP ? TIMEZONE_MAP[this.currentTimezone] : this.currentTimezone;
            const reportId = rowSelected['report_seq_number'] ? rowSelected['report_seq_number'] : undefined;
            const reportDate = rowSelected['closed_timestamp'] ? `${dayjs(dayjs.unix(rowSelected['closed_timestamp'])).tz(this.folderTimezone).format(FORMAT_DATETIME)} ${timezoneMapped}` : undefined;


            const taskDetail = {
                location: rowSelected['marker_name'],
                id: propertyId,
                folder: rowSelected['folder_name'],
                moduleGroup: rowSelected['module_group_name'],
                address: rowSelected['Property Address 1'],
                status: rowSelected['Property Status'],
                name: rowSelected['Property Name'],
                zipcode: rowSelected['Property Zip Code'],
                city: rowSelected['City'],
                country: rowSelected['Country'],
                state: rowSelected['State'],
                consolidatedType: rowSelected['Consolidated Type'],
                stratification: rowSelected['Stratification'],
                cleanable: rowSelected['Cleanable SF'],
                cleaningContract: rowSelected['Does site have a cleaning contract?'],
                startTime: rowSelected['task_start_time'],
                endTime: rowSelected['task_end_time'],
                rsf: rowSelected['Property RSF'],
                activeDate: `${activeDate} ${timezoneMapped}`,
                expireDate: expireDate === NO_EXPIRATION ? expireDate : `${expireDate} ${timezoneMapped}`,
                ingestionDate: `${ingestionDate} ${ingestionTimezoneMapped}`,
                plannedTaskId: rowSelected['plannedTaskId'],
                annotationId,
                taskStatus: rowSelected['task_status'],
                isTaskMissed: rowSelected['isTaskMissed'],
                cleanings: rowSelected['number_of_occurrences'],
                areaManager: rowSelected['custom_am.user_value'],
                ifm: rowSelected['custom_ifm.user_value'],
                folderId,
                reportId,
                reportDate
            };

            if (annotationId !== 0 && annotationFields && this.initialReviewStatus) {
                if (folderId && folderId in annotationFields) {
                    getAnnotation(annotationId).then((annotations) => {
                        const { rows } = annotations;
                        const [ rowSelected ] = rows;
                        const reviewStatusUpdated = rowSelected['review_status'] as number | undefined;
                        let reviewStatusUpdatesLabel = this.initialReviewStatus;
                        const annotationSelectedValues = {} as AnnotationSelectedValues;
                        const annotationSavedValues = {} as AnnotationSavedValues;
                        const annotationSection: AnnotationSelectedValues = annotationFields[folderId][`${reviewStatusUpdated}`];
                        for (const key in annotationSection) {
                            annotationSelectedValues[key] = rowSelected[key];
                        }
    
                        if (reviewStatusUpdated && this.reviewStatuses && this.reviewStatuses.length) {
                            reviewStatusUpdatesLabel = this.reviewStatuses[reviewStatusUpdated];
                            if (reviewStatusUpdated > 0) {
                                for (let i = 0; i < reviewStatusUpdated; i++) {
                                    const status = i;
                                    const annotationSection: AnnotationSelectedValues = annotationFields[folderId][status];
                                    const annotationSavedValuesItem = {} as AnnotationSelectedValues;
                                    annotationSavedValuesItem['review_user'] = rowSelected[`review_user_step_${status}`];
                                    for (const key in annotationSection) {
                                        annotationSavedValuesItem[key] = rowSelected[key];
                                    }
                                    annotationSavedValues[status] = annotationSavedValuesItem;
                                }
                            }
                        }
    
                        this.setState({
                            isTasksDetailOpen: true,
                            taskDetail,
                            reviewStatus: reviewStatusUpdatesLabel,
                            annotationSelectedValues,
                            annotationSavedValues
                        });
                    }).catch((errorMessage) => {
                        this.showError(errorMessage);
                    });
                } else {
                    this.showWarning(`Folder Id is not defined for ${propertyId}, check folder authorization`);
                }
            } else {
                this.setState({
                    isTasksDetailOpen: true,
                    taskDetail,
                    reviewStatus: this.initialReviewStatus,
                    annotationSelectedValues: {},
                    annotationSavedValues: {} as AnnotationSavedValues
                });
            }
        }
    };

    onCloseTaskDetail = () => {
        this.setState({ isTasksDetailOpen: false });
    };

    onExportToCSV = (): Promise<ExportToCSVData> => {
        const {
            rootStore: {
                managerStore: {
                    getTasks
                }
            }
        } = this.props;

        const { summaryProperties } = this.state;

        this.setState({ disableButton: true });

        return getTasks(0).then((tasks) => {
            const { rows } = tasks;
            const formattedRows = Array.from(this.createRows(rows));
            const rowsData = formattedRows.map((row) => {
                const rowData = summaryProperties.map((key) => {
                    return `${row[key]}`;
                });

                return rowData;
            });
            const data = [summaryProperties, ...rowsData];
            this.setState({ disableButton: false });

            return Promise.resolve({
                data,
                fileName: 'planned-tasks.csv'
            });
        }).catch((errorMessage) => {
            this.showError(errorMessage);
            return Promise.reject();
        });
    };

    onExportToExcel = (): Promise<exportXLSParams> => {
        const {
            rootStore: {
                managerStore: {
                    getTasks
                }
            }
        } = this.props;

        const { summaryProperties } = this.state;

        this.setState({ disableButton: true });

        return getTasks(0).then((tasks) => {
            const { rows } = tasks;

            const headerFields = summaryProperties.map((field) => {
                return { label: field, field };
            });

            const formattedRows = Array.from(this.createRows(rows));
            this.setState({ disableButton: false });
        
            return Promise.resolve({
                headers: headerFields,
                data: formattedRows,
                filename: 'planned-tasks'
            });
        }).catch((errorMessage) => {
            this.showError(errorMessage);
            return Promise.reject();
        });
    };

    renderAnnotationSavedSections = (annotationSavedValues: AnnotationSavedValues): React.JSX.Element[] => {
        const { classes } = this.props;
        const sections = [];
        const names: string[] = [];
        for (const sectionName in annotationSavedValues) {
            sections.push(annotationSavedValues[sectionName]);
            names.push(sectionName);
        }

        return sections.map((section, key) => {
            return (
                <React.Fragment key={`section-annotation-${key}`}>
                    <Divider className={classes.divider} />
                    <div className={classes.modalSectionContainer}>
                        { this.renderAnnotationSavedValues(section, names[key]) }
                    </div>
                </React.Fragment>
            );
        });
    };

    renderAnnotationSavedValues = (annotationSavedValuesSection: AnnotationSelectedValues, sectionName: string): React.JSX.Element | React.JSX.Element[] => {
        const { classes } = this.props;
        const {
            annotationFields,
            taskDetail: { folderId }
        } = this.state;
        const fields = [];

        if (!annotationFields) {
            return <></>;
        }

        
        for (const key in annotationSavedValuesSection) {
            if (key === 'review_user') {
                const item = {label: 'Reviewed by user', value: annotationSavedValuesSection[key], type: 'string', order: 1};
                fields.push(item as AnnotationField);
            } else {
                const annotation = annotationFields[folderId][sectionName][key] as AnnotationField;
                const item = {label: key, value: annotationSavedValuesSection[key], type: annotation.type, order: annotation.order};
                fields.push(item as AnnotationField);
            }
        }
        // Organize fields by columns
        const conditional = fields.length >= 9 ? 3 : 2;
        let list: AnnotationField[] = [];
        const columns: AnnotationField[][] = [];

        // Sort fields
        const sortedFields = fields.sort((a, b) => (a.order as number) - (b.order as number));

        sortedFields.map((field: AnnotationField, index) => {
            list.push(field);

            if ((index + 1) % conditional === 0) {
                columns.push(list);
                list = [];
            }
        });

        // Add back orphaned elements that don't cross the conditional threshold
        if (list.length && columns.length) {
            list.map((field: AnnotationField, index) => {
                columns[index].push(field);
            });
        }

        return columns.map((column, columnKey) => {
            return (
                <ul className={classes.modalBodyList} key={`column-annotation-${columnKey}`}>
                    {
                        column.map((itemList, key) => {
                            const { label, value, type } = itemList;
                            let formattedValue;
                            switch(type) {
                                case 'checkbox':
                                    formattedValue = `${value}` === '0' ? 'No' : 'Yes';
                                    break;
                                case 'datetime':
                                    // eslint-disable-next-line no-case-declarations
                                    const timestampFormated = dayjs.unix(value as number);
                                    // eslint-disable-next-line no-case-declarations
                                    const formattedDate = `${dayjs(timestampFormated).tz(this.folderTimezone).format(FORMAT_DATETIME)}`;
                                    formattedValue = `${formattedDate} ${TIMEZONE_MAP[this.folderTimezone]}`;
                                    break;
                                default:
                                    formattedValue = value;
                            }
                            
                            return (
                                <li key={`saved-annotation-${key}`}><span>{label}:</span>{formattedValue}</li>
                            );
                        })
                    }
                </ul>
            );
        });
    };

    renderAnnotationFields = (sectionFields: AnnotationFields): React.JSX.Element => {
        const { classes } = this.props;
        const { reviewStatus } = this.state;
        const fields = [];

        for (const index in sectionFields) {
            const item = {...sectionFields[index], label: index};
            fields.push(item as AnnotationField);
        }

        // Sort fields
        const sortedFields = fields.sort((a, b) => (a.order as number) - (b.order as number));
        const isStatusComplete = this.reviewStatuses && this.reviewStatuses.length && (this.reviewStatuses[this.reviewStatuses.length - 1]) === reviewStatus;
        return (
            <>
                {
                    isStatusComplete ? '' :
                        <>
                            <Divider className={classes.divider} />
                            <div className={classes.annotationFieldsContainer}>
                                {
                                    sortedFields.map((field, key) => {
                                        return (
                                            <div className={`${classes.annotationField} annotation${key}`} key={`annotation-${key}`}>
                                                {
                                                    this.setAnnotationField(field)
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            {
                                // Check if there are fields to render and all these fields are not readOnly
                                sortedFields.length && sortedFields.length !== sortedFields.filter(field => field.readOnly).length ?
                                    <div className={classes.saveAnnotationContainer}>
                                        <Button
                                            variant="contained"
                                            disabled={!this.validateAnnotationFields()}
                                            onClick={this.createAnnotation}>
                                            Save Changes
                                        </Button>
                                    </div>
                                    : ''
                            }
                        </>
                }
            </>
        );
    };

    onAnnotationSelectChange = (event: SelectChangeEvent<string | number | boolean>, key: string) => {
        const { annotationSelectedValues } = this.state;

        this.setState({
            annotationSelectedValues: {
                ...annotationSelectedValues,
                [key]: event.target.value
            }
        });
    };

    onAnnotationCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const { annotationSelectedValues } = this.state;

        this.setState({
            annotationSelectedValues: {
                ...annotationSelectedValues,
                [key]: event.target.checked
            }
        });
    };

    onAnnotationDatetimeChange = (date: Date, key: string, validationError: string | null) => {
        const { annotationSelectedValues } = this.state;

        this.setState({
            annotationSelectedValues: {
                ...annotationSelectedValues,
                [key]: dayjs.utc(date).unix()
            },
            isAnnotationStepContinue: !validationError ? false : true
        });
    };

    validateAnnotationFields = (): boolean => {
        const { annotationSelectedValues, isAnnotationStepContinue } = this.state;
        if (isAnnotationStepContinue) {
            return false;
        }

        let isValid = true;

        for (const key in annotationSelectedValues) {
            if (annotationSelectedValues[key] === '' || annotationSelectedValues[key] === null) {
                isValid = false;
                break;
            }
        }

        return isValid;
    };

    setAnnotationField = (field: AnnotationField): React.JSX.Element => {
        const { classes } = this.props;
        const { annotationSelectedValues } = this.state;
        const { type, label, values, readOnly } = field;
        const initialValue = annotationSelectedValues[label as string];
        const selectOptions: string[] = values ? values as string[] : [];

        // Check if this field is readOnly
        if (readOnly) {
            return (
                <div className={classes.modalReadOnlyContainer}>
                    <span>{label}:</span>{initialValue && initialValue !== '' ? initialValue : 'Pending'}
                </div>
            );
        }

        switch(type) {
            case 'dropdown':
                return (
                    <FormControl>
                        <InputLabel>{label}</InputLabel>
                        <Select
                            className={classes.annotationSelect}
                            onChange={(event) => this.onAnnotationSelectChange(event, label as string)}
                            value={initialValue}
                            label={label}
                        >
                            {
                                selectOptions.map((value: string, index: number) => {
                                    return (
                                        <MenuItem
                                            key={`${type}-${index + 1}`}
                                            value={value}
                                        >
                                            {value}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                );
            case 'datetime':
                return (
                    <CCDateTimePicker
                        className={classes.annotationDatetime}
                        maxDateTime={dayjs()} // Get current date
                        label={label as string}
                        onDateTimeChange={(date, validationError) => this.onAnnotationDatetimeChange(date, label as string, validationError)}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock
                        }}
                        defaultDate={initialValue ? dayjs.unix(initialValue as number).tz(this.folderTimezone) : undefined}
                    />
                );
            case 'checkbox':
                // eslint-disable-next-line no-case-declarations
                let value = initialValue;

                if (typeof initialValue === 'number') {
                    value = initialValue === 1 ? true : false;
                }
                return (
                    <FormControlLabel
                        label={label}
                        control={
                            <Checkbox
                                checked={value as boolean}
                                onChange={(event) => this.onAnnotationCheckboxChange(event, label as string)}
                            />
                        }
                    />
                );
            default:
                return <></>;
        }
    };

    createAnnotation = () => {
        const {
            annotationSelectedValues,
            taskDetail: { 
                plannedTaskId,
                annotationId,
                id
            },
            paginationModel: {
                page,
                pageSize
            }
        } = this.state;
        const {
            rootStore: {
                managerStore: {
                    submitAnnotation,
                    completeAnnotation
                }
            }
        } = this.props;

        if (annotationId === 0) {
            submitAnnotation(plannedTaskId, annotationSelectedValues).then(() => {
                this.refreshGrid(page, pageSize);
                this.setState({ isTasksDetailOpen: false });
                this.showSnackbar(
                    `${ANNOTATION_CREATED_MESSAGE} in ${id}`,
                    'success' as CCSnackbarVariant
                );
            });
        } else {
            completeAnnotation(annotationId, annotationSelectedValues).then(() => {
                this.refreshGrid(page, pageSize);
                this.setState({ isTasksDetailOpen: false });
                this.showSnackbar(
                    `${ANNOTATION_COMPLETED_MESSAGE} in ${id}`,
                    'success' as CCSnackbarVariant
                );
            });
        }
    };

    onStatusSelectedIdsChanged = (itemsId: string[]) => {
        const {
            paginationModel: {
                pageSize
            }
        } = this.state;
        this.setState({
            statusSelectedIds: itemsId
        }, () => {
            this.refreshGrid(0, pageSize);
        });
    };

    onStatusDeleted = (itemId: string) => {
        const {
            statusSelectedIds,
            paginationModel: {
                pageSize
            }
        } = this.state;
        const newStatusSelectedIds = statusSelectedIds.filter(id => id !== itemId);
        this.setState({
            statusSelectedIds: newStatusSelectedIds
        }, () => {
            this.refreshGrid(0, pageSize);
        });
    };

    onFilterByPropertyIdChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        const newValueLength = newValue.length;
        const {
            paginationModel: {
                pageSize
            }
        } = this.state;

        if (newValueLength > 0) {
            const lastCharacter = newValue[newValueLength - 1];

            // If the user press enter we refresh the tasks
            // and deselect the component.
            if (lastCharacter === '\r' || lastCharacter === '\n') {
                event.target.blur();
                this.refreshGrid(0, pageSize);
                return;
            }
        }

        this.setState({
            propertyIdToFilter: newValue.replaceAll('\r', '').replaceAll('\n', ''),
        });
    };

    onReviewStatusSelectedIdsChanged = (itemsId: string[]) => {
        const {
            paginationModel: {
                pageSize
            }
        } = this.state;
        this.setState({
            reviewStatusSelectedIds: itemsId
        }, () => {
            this.refreshGrid(0, pageSize);
        });
    };

    onReviewStatusDeleted = (itemId: string) => {
        const {
            reviewStatusSelectedIds,
            paginationModel: {
                pageSize
            }
        } = this.state;
        const newreviewStatusSelectedIds = reviewStatusSelectedIds.filter(id => id !== itemId);
        this.setState({
            reviewStatusSelectedIds: newreviewStatusSelectedIds
        }, () => {
            this.refreshGrid(0, pageSize);
        });
    };

    onDatePickerDateRangeChanged = (
        datePickerDateRange: CCDatePickerDateRange
    ) => {
        const { from, to, buttonType } = datePickerDateRange;
        const {
            paginationModel: {
                pageSize
            },
            startDay,
            endDay
        } = this.state;
        let datesToFilter: string[];
        const startDayValues: string[]= startDay.split(':');
        const endDayValues: string[] = endDay.split(':');

        if (buttonType === ButtonType.All || buttonType === ButtonType.None) {
            datesToFilter = [];
        } else if (buttonType === ButtonType.Today || buttonType === ButtonType.Yesterday) {
            // Convert epoch to seconds with business time format
            from.setHours(parseInt(startDayValues[0]), parseInt(startDayValues[1]));
            datesToFilter = [`${from.getTime() / 1000}`];
        } else if (buttonType !== ButtonType.Custom ){
            // Convert epoch to seconds with business time format
            from.setHours(parseInt(startDayValues[0]), parseInt(startDayValues[1]));
            to.setHours(parseInt(endDayValues[0]), parseInt(endDayValues[1]));
            datesToFilter = [`${from.getTime() / 1000}`, `${to.getTime() / 1000}`];
        } else {
            // Convert epoch to seconds
            datesToFilter = [`${from.getTime() / 1000}`, `${to.getTime() / 1000}`];
        }

        this.setState({
            datePickerDateRange, datesToFilter
        }, () => {
            this.refreshGrid(0, pageSize);
        });
    };

    onCustomFilterChanged = (event: React.ChangeEvent<object>, value: string | null, filter: number) => {
        const { 
            customFilters,
            paginationModel: {
                pageSize
            }
        } = this.state;
        if (value && value === 'Loading more results') {
            return;
        }
        customFilters[filter].selectedValue = value ? value : undefined;
        this.setState({
            customFilters
        }, () => {
            this.refreshGrid(0, pageSize);
        });
    };

    paginateCustomFilterResult = (filter: number) => {
        const { customFilters } = this.state;
        const selectedFilter = customFilters[filter];

        if (selectedFilter.isLoadingPagination || selectedFilter.isLoadingSearch) {
            return;
        }

        if (selectedFilter.page !== 0 && selectedFilter.page * 10 !== selectedFilter.values.length) {
            return;
        }
        customFilters[filter].isLoadingPagination = true;
        customFilters[filter].page += 1;
        customFilters[filter].values.push('Loading more results');

        this.setState({
            customFilters
        }, () => {
            this.fetchCustomFilterValues(10, filter);
        });
    };

    onCustomFilterInputChange = (event: React.SyntheticEvent, value: string, filter: number) => {
        const { customFilters } = this.state;
        customFilters[filter].search = value && value !== '' ? value : undefined;

        this.setState({customFilters});
    };

    onKeyDownCustomFilter = (event: React.KeyboardEvent<HTMLDivElement>, filter: number) => {
        if (event.key === 'Enter') {
            const { customFilters } = this.state;
            const selectedFilter = customFilters[filter];
            selectedFilter.isLoadingSearch = true;
            selectedFilter.page = 0;
            selectedFilter.values = ['Searching...'];
            this.setState({
                customFilters
            }, () => {
                this.fetchCustomFilterValues(10, filter);
            });
        }
        if (event.key === 'Delete' || event.key === 'Backspace') {
            const { customFilters } = this.state;
            const selectedFilter = customFilters[filter];

            // When the user deletes everything trigger refetch of everything
            if (!selectedFilter.search) {
                selectedFilter.isLoadingSearch = true;
                selectedFilter.page = 0;
                selectedFilter.values = ['Loading values'];
                this.setState({
                    customFilters
                }, () => {
                    this.fetchCustomFilterValues(10, filter);
                }); 
            }
        }
    };

    onCustomFilterBlur = (filter: number) => {
        const { customFilters } = this.state;
        const selectedFilter = customFilters[filter];
        selectedFilter.page = 0;
        selectedFilter.search = undefined;
        this.fetchCustomFilterValues(10, filter);
    };

    getCustomFilterDisableOptions = (option: string) =>
        option === 'Loading more results' ||
        option === 'Searching...' ||
        option === 'Loading values';

    converReviewStatusToIndex = (reviewStatus: string) => {
        if (!this.reviewStatuses) {
            return 0;
        }
        return this.reviewStatuses.indexOf(reviewStatus);
    };

    onFolderFilterInputChange = (event: React.ChangeEvent<object>, value: FolderFilterValues | null) => {
        const {
            paginationModel: {
                pageSize
            }
        } = this.state;
        
        this.setState({
            folderToFilter: value ? value : undefined
        }, () => {
            this.refreshGrid(0, pageSize);
        });
    };

    public render() {
        const {
            rows,
            totalRows,
            paginationModel,
            isTasksDrawerOpen,
            isTasksDetailOpen,
            snackbarVariant,
            snackbarMessage,
            snackbarOpen,
            dialogOpen,
            dialogTitle,
            dialogMessage,
            disableButton,
            taskDetail: {
                id,
                name: taskName,
                address,
                folder,
                activeDate,
                expireDate,
                ingestionDate,
                moduleGroup,
                status,
                zipcode,
                city,
                country,
                state,
                consolidatedType,
                stratification,
                cleanable,
                cleaningContract,
                rsf: taskRSF,
                location,
                isTaskMissed,
                taskStatus,
                cleanings,
                startTime,
                endTime,
                areaManager,
                ifm,
                folderId,
                reportId,
                reportDate
            },
            summaryProperties,
            summaryColumnsHeaderName,
            annotationFields,
            annotationSavedValues,
            reviewStatus,
            statusSelectedIds,
            propertyIdToFilter,
            reviewStatusSelectedIds,
            datePickerDateRange,
            customFilters,
            folderFilters,
            folderToFilter,
            isFolderFilterLoading,
            startDay,
            endDay
        } = this.state;

        const {
            rootStore: {
                managerStore: {
                    isTasksTabLoading,
                    isAnnotationLoading
                }
            },
            classes
        } = this.props;

        const columns = this.createColumns(summaryProperties, summaryColumnsHeaderName);
        const toolbar = {
            toolbar: {
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
            }
        };

        return (
            <>
                <SideNavbar
                    variant="persistent"
                    isDrawerOpen={isTasksDrawerOpen}
                    collapsedArea={
                        <div
                            className={`${classes.navbarIconContainer} ${classes.navbarIconContainerSmall}`}
                        >
                            <ArticleIcon className={classes.navbarIcon} />
                        </div>
                    }
                    onExpanderClick={this.onToggleSideNavBar}
                >
                    <div className={`${classes.contentContainer} ${classes.navbarContent}`}>
                        <div className={classes.navbarTitleContainer}>
                            <div className={classes.navbarIconContainer}>
                                <ArticleIcon className={classes.navbarIcon} />
                            </div>
                            <Typography
                                className={classes.navbarTitle}
                                variant="subtitle1"
                            >
                                Task Filters
                            </Typography>
                        </div>
                        <Divider className={classes.divider} />
                        <CCDatePickerButton
                            dateRange={datePickerDateRange}
                            onDateRangeChange={
                                this.onDatePickerDateRangeChanged
                            }
                            buttonList={DateButtonList}
                            startDay={startDay}
                            endDay={endDay}
                        />
                        <DropdownChips
                            className={classes.chipContainer}
                            classes={{
                                chip: classes.chip,
                                title: classes.navbarComponentTitle,
                            }}
                            title="STATUS"
                            placeholder="No selected statuses"
                            unselectAllItems={true}
                            selectedItemsIds={statusSelectedIds}
                            data={this.filterStatuses}
                            onSelectedItemsChanged={
                                this.onStatusSelectedIdsChanged
                            }
                            onItemDelete={this.onStatusDeleted}
                        />
                        <div className={classes.inputContainer}>
                            <Typography
                                className={classes.navbarComponentTitle}
                                variant="button"
                            >
                                FOLDER
                            </Typography>
                            <Paper>
                                <CCSpinner
                                    loading={isFolderFilterLoading}
                                    size={40}
                                >
                                    <Autocomplete
                                        className={classes.navbarInput}
                                        options={folderFilters}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => 
                                            <TextField
                                                {...params}
                                                placeholder="Search for folder"
                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.navbarPlaceholder,
                                                    },
                                                }}
                                            />
                                        }
                                        defaultValue={folderToFilter}
                                        onChange={(event, pickedValue) => this.onFolderFilterInputChange(event, pickedValue)}
                                    />
                                </CCSpinner>
                            </Paper>
                        </div>
                        <div className={classes.inputContainer}>
                            <Typography
                                className={classes.navbarComponentTitle}
                                variant="button"
                            >
                                PROPERTY ID
                            </Typography>
                            <Paper>
                                <TextField
                                    className={classes.navbarInput}
                                    type="text"
                                    placeholder="Type Id and press enter"
                                    variant="standard"
                                    multiline={true}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            input: classes.navbarPlaceholder,
                                        },
                                    }}
                                    maxRows="2"
                                    value={propertyIdToFilter}
                                    onChange={this.onFilterByPropertyIdChanged}
                                />
                            </Paper>
                        </div>
                        <DropdownChips
                            className={classes.chipContainer}
                            classes={{
                                chip: classes.chip,
                                title: classes.navbarComponentTitle,
                            }}
                            title="FACILITY VENDOR REVIEW STATUS"
                            placeholder="No selected statuses"
                            unselectAllItems={true}
                            selectedItemsIds={reviewStatusSelectedIds}
                            data={this.filterReviewStatuses}
                            onSelectedItemsChanged={
                                this.onReviewStatusSelectedIdsChanged
                            }
                            onItemDelete={this.onReviewStatusDeleted}
                        />
                        {
                            customFilters.map((filter, index) => {
                                return <div key={`custom_filter_${filter.param}`} className={classes.inputContainer}>
                                    <Typography
                                        className={classes.navbarComponentTitle}
                                        variant="button"
                                    >
                                        {filter.label}
                                    </Typography>
                                    <Paper>
                                        <CCSpinner
                                            loading={filter.isLoadingValues}
                                            size={40}
                                        >
                                            <Autocomplete
                                                className={classes.navbarInput}
                                                options={filter.values}
                                                getOptionLabel={(option) => option}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        placeholder="Type search and press enter"
                                                        variant="standard"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            disableUnderline: true,
                                                            classes: {
                                                                input: classes.navbarPlaceholder,
                                                            },
                                                        }}
                                                    />
                                                }
                                                defaultValue={filter.selectedValue}
                                                onChange={(event, pickedValue) => this.onCustomFilterChanged(event, pickedValue, index)}
                                                filterOptions={(x) => x}
                                                onInputChange={(EventTarget, value) => this.onCustomFilterInputChange(EventTarget, value, index)}
                                                onKeyDown={(event) => this.onKeyDownCustomFilter(event, index)}
                                                getOptionDisabled={this.getCustomFilterDisableOptions}
                                                onBlur={() => this.onCustomFilterBlur(index)}
                                                // Pagination controller
                                                ListboxProps={{
                                                    onScroll: (event: React.SyntheticEvent) => {
                                                        const listboxNode = event.currentTarget;
                                                        if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                                            this.paginateCustomFilterResult(index);
                                                        }
                                                    }
                                                }}
                                            />
                                        </CCSpinner>
                                    </Paper>
                                </div>;
                            })
                        }
                        <Divider className={classes.divider} />
                        <div>
                            <CSVButton
                                classes={{
                                    button: classes.exportButton
                                }}
                                variant="contained"
                                size="large"
                                color="secondary"
                                disabled={disableButton}
                                onClick={this.onExportToCSV}
                            >
                                <SimCardDownloadIcon /> Export to CSV
                            </CSVButton>
                            <ExcelButton
                                classes={{
                                    button: classes.exportButton
                                }}
                                variant="contained"
                                size="large"
                                disabled={disableButton}
                                onClick={this.onExportToExcel}
                            >
                                <SimCardDownloadIcon /> Export to Excel
                            </ExcelButton>
                        </div>
                    </div>
                </SideNavbar>
                <DataGridList
                    className={`${classes.dataGridContainer} ${isTasksDrawerOpen ? 'navbar-opened' : ''}`}
                    isLoading={isTasksTabLoading}
                    columns={columns}
                    rows={rows}
                    showToolbar={true}
                    disableColumnMenu={true}
                    disableColumnFilter={true}
                    rowCount={totalRows}
                    density="comfortable"
                    paginationMode="server"
                    onPaginationModelChange={this.onPageChange}
                    pageSizeOptions={[10, 30, 50]}
                    paginationModel={paginationModel}
                    componentsProps={toolbar}
                    onRowSelection={this.onRowSelected}
                />
                <SimpleModal
                    open={isTasksDetailOpen}
                    buttonOkLabel=""
                    buttonCancelLabel=""
                    className={`${classes.modalContainer} ${isTaskMissed ? 'is-task-missed': ''}`}
                    header={
                        <div className={classes.modalHeaderContainer}>
                            <Typography
                                className={classes.modalTitle}
                                variant="h2"
                            >
                                {`Task: ${location}`}
                            </Typography>
                            <IconButton
                                className={classes.modalCloseButton}
                                onClick={this.onCloseTaskDetail}
                            >
                                <Close />
                            </IconButton>
                            <Typography
                                variant="subtitle2"
                            >
                                <strong>{`ID: ${id}`}</strong>
                            </Typography>
                            <div className={classes.modalSectionContainer}>
                                <div className={classes.headerColumn}>
                                    <strong>Area Manager:</strong> {areaManager}
                                </div>
                                <div className={classes.headerColumn}>
                                    <strong>IFM:</strong> {ifm}
                                </div>
                            </div>
                            <div className={classes.modalSectionContainer}>
                                <div className={classes.headerColumn}>
                                    <strong>Folder:</strong> {folder}
                                </div>
                                <div className={classes.headerColumn}>
                                    <strong>Module Group:</strong> {moduleGroup}
                                </div>
                            </div>
                            <div className={classes.modalSectionContainer}>
                                <div className={classes.headerColumn}>
                                    <strong>Active Date:</strong> {activeDate}
                                </div>
                                <div className={classes.headerColumn}>
                                    <strong>Expire Date:</strong> {expireDate}
                                </div>
                            </div>
                            <div className={classes.modalSectionContainer}>
                                <div className={classes.headerColumn}>
                                    <strong>Task Status:</strong> {taskStatus}
                                </div>
                            </div>
                        </div>
                    }
                >
                    <Divider className={classes.divider} />
                    <div className={classes.modalSectionContainer}>
                        <ul className={classes.modalBodyList}>
                            <li><span>Name:</span>{taskName}</li>
                            <li><span>Address:</span>{address}</li>
                            <li><span>Status:</span>{status}</li>
                            <li><span>Consolidated Type:</span>{consolidatedType}</li>
                        </ul>
                        <ul className={classes.modalBodyList}>
                            <li><span>City:</span>{city}</li>
                            <li><span>State:</span>{state}</li>
                            <li><span>Country:</span>{country}</li>
                            <li><span>Zipcode:</span>{zipcode}</li>
                        </ul>
                        <ul className={classes.modalBodyList}>
                            <li><span>RSF:</span>{taskRSF}</li>
                            <li><span>Cleanable SF:</span>{cleanable}</li>
                            <li><span>Stratification:</span>{stratification}</li>
                            <li><span>Does site have a cleaning contract?</span>{cleaningContract}</li>
                        </ul>
                    </div>
                    <Divider className={classes.divider} />
                    <div className={classes.modalSectionContainer}>
                        <ul className={classes.modalBodyList}>
                            <li><span>Green Cleaning - ATM Daily Frequency:</span>{cleanings}</li>
                            <li><span>Planned Task Start Date:</span>{startTime}</li>
                            {
                                isTaskMissed ?
                                    <li><span>{ANNOTATION_STATUS_LABEL}:</span>{reviewStatus}</li>
                                    : ''
                            }
                            {
                                taskStatus === 'Confirmed' &&
                                <li><span>Report ID:</span>{reportId}</li>
                            }
                        </ul>
                        <ul className={classes.modalBodyList}>
                            <li><span>Import:</span>{ingestionDate}</li>
                            <li><span>Planned Task End Date:</span>{endTime}</li>
                            {
                                taskStatus === 'Confirmed' &&
                                <li><span>Report Date:</span>{reportDate}</li>
                            }
                        </ul>
                    </div>
                    {
                        isTasksDetailOpen && isTaskMissed && annotationFields ?
                            <>
                                {
                                    this.renderAnnotationSavedSections(annotationSavedValues)
                                }
                            </>
                            : ''
                    }
                    {
                        isTasksDetailOpen && isTaskMissed && annotationFields && reviewStatus && folderId && folderId in annotationFields ?
                            this.renderAnnotationFields(annotationFields[folderId][this.converReviewStatusToIndex(reviewStatus)])
                            : ''
                    }
                </SimpleModal>
                <CCSnackbar
                    className={classes.snackbar}
                    variant={snackbarVariant}
                    message={snackbarMessage}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transition="right"
                    open={snackbarOpen}
                    onClose={this.onSnackbarClosed}
                />
                <SimpleDialog
                    open={dialogOpen}
                    titleText={dialogTitle}
                    buttonCancelLabel=""
                    contentText={dialogMessage}
                    onDialogResult={this.onDialogClosed}
                />
                <CCSpinner
                    className={classes.spinner}
                    loading={isAnnotationLoading}
                    overlayVisible={true}
                    size={100}
                />
            </>
        );
    }

}

const MUIComponent = withStyles(styles)(TasksTab);
export { MUIComponent as TasksTab };
