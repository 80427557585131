import * as React from 'react';

import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
// import { Theme } from '@material-ui/core/styles/createTheme';
import { SVGSprite, imagesTypes } from '../svg-sprite';
import { ReactComponent as BaseTile } from '../../assets/svg/base-tile.svg';
import { ReactComponent as Check } from '../../assets/svg/check.svg';

const styles = (/*theme: Theme*/) => createStyles({
    root: {
        position: 'relative',
        height: '7em',
        width: '6em',
        // margin: '0 0.6em 1em 0',
        '& input[type="radio"]': {
            opacity: 0,
            width: 0,
            height: 0,
        }
    },
    baseTile: {
        position: 'absolute',
        zIndex: -1
    },
    check: {
        position: 'absolute',
        right: '8px',
        top: '7px',
        width: '13px'
    },
    inputLabel : {
        fontSize: '0.8em',
        fontWeight: 500,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80px',
        height: '100%',
        position: 'relative',
        bottom: '1.2em',
        '& > img': {
            marginTop: '1.2em'
        }
    },
    inputText: {
        textAlign: 'center',
        margin: '0 0.3em 1em 0.6em'
    },
});

interface Props extends WithStyles<typeof styles> {
    id: string;
    iconId: string;
    isSelected: boolean;
    label: string;
    onClick: (id:string) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface States {
}

class AddReportTile extends React.Component<Props, States> {
    public static defaultProps = {
        label: ''
    };
    
    state = {
    };
    
    onInputClicked = (id: string) => () => {
        const { onClick } = this.props;
        if (!onClick) {
            return;
        }
        onClick(id);
    };

    public render() {
        const {
            classes,
            id,
            iconId,
            isSelected,
            label
        } = this.props;
        const radioID = `module-${id}`;
        const finalIconId = imagesTypes.indexOf(iconId) >= 0 ? iconId : 'default';

        return (
            <div className={classes.root}>
                <BaseTile className={classes.baseTile}/>
                { isSelected ?
                    <Check className={classes.check}/>
                    : ''
                }
                <input
                    id={radioID}
                    key={`input-${id}`}
                    type="radio"
                    name="modules"
                    value={id}
                    checked={isSelected}
                    onClick={this.onInputClicked(id)}
                    onChange={() => {/**/}}
                /> 
                <label
                    className={classes.inputLabel}
                    htmlFor={radioID}
                >
                    <SVGSprite name={finalIconId} />
                    <span className={classes.inputText}>
                        {label ? label : 'N/A'}
                    </span>
                </label>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(AddReportTile);
export { MUIComponent as AddReportTile };
