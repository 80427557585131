/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { CellValueChangedEvent, ColDef, FillOperationParams, ProcessDataFromClipboardParams } from 'ag-grid-community';
import { Button } from '@mui/material';
import { CCSpinner } from '../cc-spinner';
import { SimpleModal } from '../simple-modal';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

export interface ValidAgGridRowModel {
    [key: string | symbol]: string | number | boolean;
}

export interface ValidGridRefData {
    [key: string | symbol]: string;
}
  
const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: 'relative'
        },
        gridContainer: {
            width: '100%',
            height: '100%'
        },
        spinner: {
            position: 'absolute',
            backgroundColor: theme.ccPalette.disabled.light,
            width: '100%',
            height: '100%',
            zIndex: 1,
            left: 0,
            top: 0
        },
        modal: {
            position: 'absolute',
            padding: '3em'
        }
    });

interface Props extends WithStyles<typeof styles> {
    className?: string;
    isLoading?: boolean;
    enableEditMode?: boolean;
    startAsEditableMode?: boolean;
    excludeColumnFillIds?: string[];
    rows: ValidAgGridRowModel[];
    columns: ColDef[];
    onGridSaved?: (rows: ValidAgGridRowModel[]) => void;
    onGridChanged?: (changeddRow: ValidAgGridRowModel[]) => void;
    validateClipboardData?: (params: ProcessDataFromClipboardParams) => string[][] | null;
}

const AgGridList: React.FC<Props & AgGridReactProps> = (props) => {
    const {
        classes,
        className,
        rows: initialRows,
        columns: initialColumns,
        isLoading,
        excludeColumnFillIds,
        enableEditMode,
        startAsEditableMode,
        onGridSaved,
        onGridChanged,
        validateClipboardData,
        ...AgGridReactProps
    } = props;

    const gridRef = React.useRef<AgGridReact<ValidAgGridRowModel>>(null);
    const rootClasses = `${classes.root}${className ? ` ${className}`: ''}`;
    const gridClasses = `${classes.gridContainer} ag-theme-quartz`;

    const [rowData, setRowData] = React.useState<ValidAgGridRowModel[]>([]);
    const [isEditMode, setEditMode] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [colDefs, setColDefs] = React.useState<ColDef[]>(initialColumns);
    const [isRowChanged, setRowChanged] = React.useState(false);

    const defaultColDef = React.useMemo<ColDef>(() => {
        return {
            flex: 1
        };
    }, []);

    const fillOperation = React.useCallback((params: FillOperationParams) => {
        if (!excludeColumnFillIds) {
            return params.values[params.values.length - 1];
        }
        if (excludeColumnFillIds.includes(params.column.getColId())) {
            return params.currentCellValue;
        }
        return params.values[params.values.length - 1];
    }, []);

    const editGridMode = () => {
        const columns = [...colDefs];
        columns.forEach(e => {
            if (e.cellEditor) {
                e.editable = true;
            }
        });
        gridRef.current!.api.setGridOption('columnDefs', columns);
        setEditMode(true);
    };

    const saveGridMode = () => {
        const columns = [...colDefs];
        columns.forEach(e => {
            if (e.cellEditor) {
                e.editable = false;
            }
        });
        gridRef.current!.api.setGridOption('columnDefs', columns);
        setEditMode(false);
    };

    const onSaveGridChanges = () => {
        setOpenModal(true);
    };

    const onModalResult = (type: number) => {
        if (onGridSaved && type === 1) {
            saveGridMode();
            onGridSaved(rowData);
        } else {
            editGridMode();
        }

        setOpenModal(false);
    };

    const onCellValueChanged = (params: CellValueChangedEvent) => {
        const changedRow = [params.data];
        setRowChanged(true);

        if (onGridChanged) {
            onGridChanged(changedRow);
        }
    };

    React.useEffect(() => {
        setRowData(initialRows);
    }, [initialRows]);

    React.useEffect(() => {
        setColDefs(initialColumns);
        setEditMode(false);
        setRowChanged(false);

        if (startAsEditableMode) {
            setTimeout(editGridMode, 500);
        }
    }, [initialColumns]);

    return (
        <div className={rootClasses}>
            <CCSpinner
                className={classes.spinner}
                loading={isLoading as boolean}
                size={70}
            />
            { enableEditMode && onGridSaved ?
                <div>
                    <Button
                        color="primary"
                        startIcon={isEditMode ? <CancelIcon/> : <EditIcon />}
                        onClick={isEditMode ? saveGridMode : editGridMode}
                    >
                        { isEditMode ? 'Close Edit' : 'Edit Grid' }
                    </Button>
                    <Button
                        color="primary"
                        startIcon={<SaveIcon />}
                        disabled={!isRowChanged}
                        onClick={onSaveGridChanges}
                    >
                        Save grid changes
                    </Button>
                </div>
                : ''
            }
            <div
                className={gridClasses}
            >
                <AgGridReact
                    {...AgGridReactProps}
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={colDefs}
                    defaultColDef={defaultColDef}
                    fillOperation={fillOperation}
                    processDataFromClipboard={validateClipboardData}
                    onCellValueChanged={onCellValueChanged}
                />
            </div>
            <SimpleModal
                className={classes.modal}
                open={openModal}
                onModalResult={onModalResult}
                buttonOkLabel="Save"
            >
                Are you sure, to save changes?
            </SimpleModal>
        </div>
    );
};

AgGridList.defaultProps = {
    isLoading: false,
    startAsEditableMode: false
};

const MUIComponent = withStyles(styles)(AgGridList);
export { MUIComponent as AgGridList };
