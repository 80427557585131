import * as React from 'react';

import { TablePagination } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { 
    Props as SimplePaginationActionsProps,
    SimplePaginationActions
} from './simple-pagination-actions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        overflow: 'hidden',
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8_Landscape)]: {
            width: '100%',
            minHeight: '4em'
        }
    },
    toolbar: {
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8_Landscape)]: {
            position: 'fixed',
            background: 'rgba(255,255,255,0.8)',
            width: '100%',
            bottom: 0
        }
    },
    caption: {
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8_Landscape)]: {
            fontSize: '1.5em',
            fontWeight: '500'
        }
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    count: number;
    isLoading: boolean;
    page: number;
    rowsPerPage: number;
    onPageChange?: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
}

const SimplePagination: React.FC<Props> = (props: Props) => {
    const {
        className,
        classes,
        count, 
        isLoading,
        page,
        rowsPerPage,
        onPageChange
    } = props;
    
    const onPageChanged = (event: React.MouseEvent<HTMLButtonElement> | null, pageNumber: number) => {
        if (isLoading) {
            return;
        }

        if (onPageChange) {
            onPageChange(event, pageNumber);
        }
    };

    // Cast component and update default prop according to "isLoading" value
    const ActionsComponent = SimplePaginationActions as React.FC<TablePaginationActionsProps & SimplePaginationActionsProps>;
    ActionsComponent.defaultProps = {
        disabled: isLoading
    };

    const rootClasses = `${classes.root}${className ? ` ${className}`: ''}`;
    const tablePaginationClasses = {
        caption: classes.caption,
        toolbar: classes.toolbar
    };

    return (
        <TablePagination
            className={rootClasses}
            classes={tablePaginationClasses}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[rowsPerPage]}
            page={page}
            backIconButtonProps={{
                'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
                'aria-label': 'Next Page',
            }}
            onPageChange={onPageChanged}
            ActionsComponent={ActionsComponent as React.ElementType<TablePaginationActionsProps>}
        />
    );

};

SimplePagination.defaultProps = {
    isLoading: false
};

const MUIComponent = withStyles(styles)(SimplePagination);
export {MUIComponent as SimplePagination};
