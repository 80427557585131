export interface ScheduleRoute {
    id: number;
    name: string;
    account_doc_id: string;
    assigned_user_id: string;
    folder_doc_id: string;
    created: string;
    last_modified: string;
    user_created: string;
    user_modified: string;
}

export interface SchedulesRoutes {
    rows: ScheduleRoute[];
    total: number;
}

export interface EntitySchedulesRoutesTab {
    status: boolean,
    data: SchedulesRoutes
}

export interface PCNList {
    id: string;
    value: string;
    account_doc_id: string;
    folder_doc_id: string;
    folder_name: string;
    shift_label: string;
    marker_count: number;
    email?: string;
    start_time?: string;
    end_time?: string | null;
}

export interface PCNRoutes {
    rows: PCNList[];
    total: number;
}

export interface PCNFiltersParameters {
    value?: string;
    folder_id?: string;
    folder_name?: string;
    filter_assign?: string;
    start_date?: string;
    end_date?: string;
}

export interface PCNAssignmentUser {
    secondary_id: string;
    user_id: string;
    start_time: string;
    end_time: string;
    email?: string;
    timestamp?: string;
}

export interface PCNMarkerCoords {
    id: string;
    name: string;
    coordinates: string;
}

export class SchedulesRoutesTab {
    static create (routesData: EntitySchedulesRoutesTab): SchedulesRoutesTab {
        const {
            status,
            data: routes
        } = routesData;
        
        return new SchedulesRoutesTab(
            status,
            routes
        );
    }
    constructor(
        public readonly status: boolean,
        public readonly routes: SchedulesRoutes
    ) {}
}
