export interface EntityFloorplan {
    floorplan_id: string;
    name: string;
    type: string;
}

export class Floorplan {
    static create (userData: EntityFloorplan): Floorplan {
        const {
            floorplan_id: id,
            name: name,
            type: type
        } = userData;

        return new Floorplan(
            id,
            name,
            type
        );
    }

    constructor (
        public readonly id: string,
        public readonly name: string,
        public readonly type: string
    ) {}
}
