/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
import { Theme } from '@mui/material/styles';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import {
    DataGrid,
    DataGridProps,
    GridActionsCellItem,
    GridColDef,
    GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowSelectionModel,
    GridRowsProp,
    GridToolbar,
    GridValidRowModel,
    ValueOptions
} from '@mui/x-data-grid';
import * as React from 'react';
import { CCSpinner } from '../cc-spinner';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';
import { SimpleModal } from '../simple-modal';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: 'relative'
        },
        gridRoot: {},
        gridActions: {},
        spinner: {
            position: 'absolute',
            backgroundColor: theme.ccPalette.disabled.light,
            width: '100%',
            height: '100%',
            zIndex: 1,
            left: 0,
            top: 0
        },
        modal: {
            position: 'absolute',
            padding: '3em'
        }
    });

interface Props extends WithStyles<typeof styles> {
    showToolbar?: boolean;
    disableRowSelection?: boolean;
    isLoading?: boolean;
    enableEditMode?: boolean;
    hideAddRowsBtn?: boolean;
    enableAllGridEdition?: boolean;
    enableEditBtn?: boolean;
    className?: string;
    rows: GridRowsProp;
    columns: Array<GridColDef<GridValidRowModel>>
    onRowSelection?: (rowsSelected: GridRowsProp) => void;
    onGridSaved?: (rows: GridRowsProp) => void;
}

const DataGridList: React.FC<Props & DataGridProps> = (props) => {
    const {
        className,
        classes,
        rows: initialRows,
        columns: initialColumns,
        showToolbar,
        isLoading,
        enableEditMode,
        disableRowSelection,
        hideAddRowsBtn,
        enableAllGridEdition,
        enableEditBtn,
        onRowSelection,
        onGridSaved,
        // tslint:disable-next-line:no-shadowed-variable
        ...DataGridProps
    } = props;
    const rootClasses = `${classes.root}${className ? ` ${className}`: ''}`;
    const toolbar = showToolbar ? { Toolbar: GridToolbar } : undefined;

    const [openModal, setOpenModal] = React.useState(false);
    const [rows, setRows] = React.useState(initialRows);
    const [columns, setColumns] = React.useState(initialColumns);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [isEditMode, setEditMode] = React.useState(false);

    const getEditRows = () => {
        const initialEditModesModel: React.SetStateAction<GridRowModesModel> = {};
        for (let i = 0; i < initialRows.length; i++) {
            initialEditModesModel[i] = { mode: GridRowModes.Edit };
        }

        return initialEditModesModel;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        setRows(rows.filter((row) => row.id !== id));
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    
        const editedRow = rows.find((row) => row.id === id);
        if (editedRow?.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const handleAddClick = () => {
        const id = randomId();
        let fieldToFocus: string | undefined;
        setRows((oldRows) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const columnFields: {[key: string]: any} = {};
            initialColumns.map((column) => {
                const { field } = column;
                if (!fieldToFocus) {
                    fieldToFocus = field;
                }

                if ('valueOptions' in column) {
                    const { valueOptions } = column;
                    const values = valueOptions?.length ? [...valueOptions as ValueOptions[]] : [''];
                    columnFields[field] = values[0];
                } else {
                    columnFields[field] = '';
                }
            });

            return [...oldRows, {...columnFields, id, isNew: true }];
        });

        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: fieldToFocus },
        }));
    };

    const editGridMode = () => {
        setRowModesModel(() => {
            const editModesModel: React.SetStateAction<GridRowModesModel> = {};
            for (let i = 0; i < rows.length; i++) {
                editModesModel[i] = { mode: GridRowModes.Edit };
            }
            return (editModesModel);
        });
        setEditMode(true);
    };

    const saveGridMode = () => {
        setRowModesModel(() => {
            const viewModesModel: React.SetStateAction<GridRowModesModel> = {};
            for (let i = 0; i < rows.length; i++) {
                viewModesModel[i] = { mode: GridRowModes.View };
            }
            return (viewModesModel);
        });
        setEditMode(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getActions = (action: any) => {
        const { id } = action;
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
            return [
                <GridActionsCellItem
                    icon={<SaveIcon />}
                    label="Save"
                    sx={{
                        color: 'primary.main',
                    }}
                    onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                    icon={<CancelIcon />}
                    label="Cancel"
                    className="textPrimary"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                />,
            ];
        }

        return [
            <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
            />,
            <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
            />,
        ];
    };

    const createColumns = () => {
        let gridColumns = [];
        
        gridColumns = initialColumns.map((column) => {
            const { editable } = column;

            if (editable === false) {
                return column;
            }

            return {
                ...column,
                editable: true
            };
        });

        if (!enableAllGridEdition) {
            gridColumns = [...gridColumns, {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                cellClassName: 'actions',
                getActions
            }] as Array<GridColDef<GridValidRowModel>>;
        }

        return gridColumns as Array<GridColDef<GridValidRowModel>>;
    };


    const onRowSelect = (rowSelectionModel: GridRowSelectionModel) => {
        const rowsSelected = rowSelectionModel.map((rowId) => {
            return rows.filter((row) => row.id === rowId)[0];
        });

        if (onRowSelection) {
            onRowSelection(rowsSelected);
        }
    };

    let updatedRows: GridValidRowModel[] = [...rows];

    const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
        let isUpdated = false; 
        for (const [key, value] of Object.entries(newRow)) {
            if (value !== oldRow[key]) {
                isUpdated = true;
                break;
            }
        }

        if (isUpdated) {
            const isNew = 'isNew' in oldRow ? { 'isNew': false } : {};
            const updatedRow = { ...newRow, ...isNew, isUpdated: true };

            if (enableAllGridEdition) {
                updatedRows = updatedRows.map((row) => (row.id === newRow.id ? updatedRow : row));
            } else {
                const newRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
                setRows(newRows);
            }

            if (newRow.id === rows.length - 1) {
                setRows(updatedRows);
            }

            return updatedRow;
        }

        if (enableAllGridEdition) {
            if (newRow.id === rows.length - 1) {
                setRows(updatedRows);
            }
        }

        return oldRow;
    };

    const onSaveGridChanges = () => {
        if (enableAllGridEdition) {
            saveGridMode();
        }

        setOpenModal(true);
    };

    const onModalResult = (type: number) => {
        if (onGridSaved && type === 1) {
            const gridRows = rows.map((rows) => {
                return enableEditMode && !('isUpdated' in rows) ? {...rows, isUpdated: false} : rows;
            });
            onGridSaved(gridRows);
        } else {
            editGridMode();
        }

        setOpenModal(false);
    };

    React.useEffect(() => {
        setRows(initialRows);
    }, [initialRows]);

    React.useEffect(() => {
        setColumns(enableEditMode ? createColumns() : initialColumns);
        setRowModesModel(enableAllGridEdition && !enableEditBtn ? getEditRows() : {});
    }, [initialColumns]);

    return (
        <div className={rootClasses}>
            <CCSpinner
                className={classes.spinner}
                loading={isLoading as boolean}
                size={70}
            />
            { enableEditMode && onGridSaved ?
                <div className={classes.gridActions}>
                    { hideAddRowsBtn ? ''
                        :   <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
                        Add row
                        </Button>
                    }
                    { enableEditBtn ?
                        <Button color="primary" startIcon={isEditMode ? <CancelIcon/> : <EditIcon />} onClick={isEditMode ? saveGridMode : editGridMode}>
                            { isEditMode ? 'Close Edit' : 'Edit Grid' }
                        </Button>
                        : ''
                    }
                    <Button color="primary" startIcon={<SaveIcon />} onClick={onSaveGridChanges}>
                        Save grid changes
                    </Button>
                </div>
                : ''
            }
            <DataGrid
                // To check all data grid props visit 
                // https://mui.com/x/api/data-grid/data-grid/
                {...DataGridProps}
                rows={rows}
                editMode="row"
                rowModesModel={rowModesModel}
                columns={columns}
                classes={{root: classes.gridRoot}}
                components={toolbar}
                disableRowSelectionOnClick={disableRowSelection}
                processRowUpdate={processRowUpdate}
                onRowSelectionModelChange={onRowSelect}
            />
            <SimpleModal
                className={`${classes.modal}`}
                open={openModal}
                onModalResult={onModalResult}
            >
                Are you sure, to save changes?
            </SimpleModal>
        </div>
    );
};

DataGridList.defaultProps = {
    isLoading: false,
    showToolbar: false,
    disableColumnMenu: false,
    checkboxSelection: false,
    disableRowSelection: false,
    enableEditBtn: false
};

const MUIComponent = withStyles(styles)(DataGridList);
export { MUIComponent as DataGridList };
