import * as React from 'react';

import {
    Button,
    Divider,
    Typography
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import {
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {},
    divider: {},
    title: {},
    error: {},
    footerTitle: {},
    footerButton: {},
    input: {},
    inputLabel: {},
    wizardContainer: {},
    subtitle: {},
    textField: {}
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    error?: string;
    email?: string;
    title: string;
    subtitle?: string;
    buttonText: string;
    footerTitleText: string;
    footerButtonText: string;
    onMainButtonClick?: (email: string) => void;
    onFooterButtonClick?: () => void;
}

interface States {
    isFormValid: boolean;
    login: string;
}

class LoginWizardStepLogin extends React.Component<Props, States> {
    public static defaultProps = {
    };

    state = {
        isFormValid: false,
        login: this.props.email ? this.props.email : ''
    };
    emailRef: React.RefObject<TextValidator> = React.createRef();

    componentDidMount() {
        const {
            state: {
                login
            },
            emailRef
        } = this;

        if (login && emailRef && emailRef.current) {
            emailRef.current.validate(login);
        }
    }
  
    onTextFieldChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target as HTMLInputElement;
        this.setState({
            login: value
        });
    };

    onValidate = (isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };
    
    onSubmitted = () => {
        const { onMainButtonClick } = this.props;
        const { login } = this.state;
        if (onMainButtonClick) {
            onMainButtonClick(login);
        }
    };

    public render() {
        const {
            classes,
            className,
            error,
            buttonText,
            title,
            subtitle,
            footerTitleText,
            footerButtonText,
            onFooterButtonClick
        } = this.props;
        const {
            isFormValid,
            login
        } = this.state;
        const rootClassNames = classes.root + (className ? ` ${className}` : '');

        return (
            <div className={rootClassNames}>
                <Typography
                    variant="h6"
                    className={classes.title}
                >
                    {title}
                </Typography>
                <Typography
                    variant="h6"
                    className={classes.error}
                >
                    {((error!== undefined) && (error.length > 0)) ? error : '\u00a0'} {/* unicode non breaking space */}
                </Typography>
                <ValidatorForm
                    className={classes.wizardContainer}
                    instantValidate={true}
                    onSubmit={this.onSubmitted}
                >    
                    <Typography
                        className={classes.subtitle}
                        variant="h6"
                    >
                        {subtitle ? subtitle : '\u00a0'} {/* unicode non breaking space */}
                    </Typography>

                    <TextValidator
                        id="email"
                        className={classes.textField}
                        ref={this.emailRef}
                        label="Email Address"
                        type="email"
                        name="email"
                        autoComplete="email"
                        placeholder="email"
                        margin="normal"
                        variant="outlined"
                        value={login}
                        onChange={this.onTextFieldChanged}
                        validatorListener={this.onValidate}
                        InputProps={{ 
                            classes: { 
                                input: classes.input
                            }
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel
                            }
                        }}
                        validators={['required', 'isEmail']}
                        errorMessages={['Email is required', 'Please enter a valid email address']}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={(login.length < 1) || !isFormValid}
                    >
                        {buttonText}
                    </Button>
                    { onFooterButtonClick ?
                        <Divider className={classes.divider} />
                        : ''
                    }
                    { onFooterButtonClick ?
                        <div className={classes.wizardContainer}>
                            <Typography
                                className={classes.footerTitle}
                                variant="h6"
                            >
                                {footerTitleText}
                            </Typography>
                            <Button
                                className={classes.footerButton}
                                variant="outlined"
                                onClick={onFooterButtonClick}
                            >
                                {footerButtonText}
                            </Button>
                        </div>
                        : ''
                    }
                </ValidatorForm>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(LoginWizardStepLogin);
export {MUIComponent as LoginWizardStepLogin};
