import {
    CookieData,
    CookieDataModel
} from './cookie-data';

// access: {}
// agree_enabled: true
// agrees: "0"
// auth: {}
// cc_channels: ["user_5c4f7aec413c3f1147e95a68", "public"]
// channels: ["user_5c4f7aec413c3f1147e95a68"]
// comments_enabled: true
// cookie: {SyncGatewaySession: "8a47a47372fb5f1e710653a73827774d56801a23"}
// digest_config: {interval: "Never"}
// email: "frank+test02@crowdcomfort.com"
// meta_actions: {}
// num_reports: "0"
// teams: {}
// timestamp: "2019-01-28T21:58:04.320Z"
// type: "user"
// unique_codes: []
// unread_notifications: 0
// user_id: "user_5c4f7aec413c3f1147e95a68"
// username: "franktest02"
// _id: "user_5c4f7aec413c3f1147e95a68"

export interface RegisteredUserModel {
    access: unknown,
    agree_enabled: boolean,
    agrees: string,
    auth: unknown,
    cc_channels: string[],
    channels: string,
    comments_enabled: boolean,
    cookie: CookieDataModel,
    digest_config: unknown,
    email: string,
    isVerificationNeeded: boolean,
    meta_actions: unknown,
    num_reports: string,
    teams: Record<string, unknown>,
    timestamp: Date,
    unique_codes: unknown[],
    unread_notifications: number,
    user_id: string,
    username: string,
    valid?: boolean,
    _id: string
}

export interface RegisteredUserModelSSO {
    access: unknown,
    access_token: string,
    id_token?: string,
    email: string,
    expiration: string,
    teams: Record<string, unknown>,
    user_id: string,
    username: string,
    validation_id: string,
    account_id: string,
    valid?: boolean,
}

export class RegisteredUser {

    public get teamId() : string {
        const teams = this.teams;
        return (teams && (teams.length > 0)) ? Object.keys(teams)[0] : '';
    }

    public get cookie(): CookieData | undefined {
        return this.pCookie;
    }

    static create (model: RegisteredUserModel): RegisteredUser {
        const {
            cookie,
            email,
            timestamp: timeStampString,
            teams: teamsRaw,
            user_id: userId,
            username,
            isVerificationNeeded: isVerificationNeededRaw,
            valid: validValue
        } = model;
        const timeStamp: Date = new Date(timeStampString);
        // If validValue is undefined it will be set to true anyway.
        const valid = (validValue === false) ? false : true;
        const teams = teamsRaw ? Object.keys(teamsRaw) : undefined;
        const isVerificationNeeded = isVerificationNeededRaw ? true : false;

        return new RegisteredUser(email, userId, username, valid, isVerificationNeeded, timeStamp, CookieData.create(cookie), teams);
    }

    static createSSOUser(model: RegisteredUserModelSSO): RegisteredUser {
        const {
            access_token: accessToken,
            id_token: idToken,
            email,
            expiration: expirationString,
            teams: teamsRaw,
            user_id: userId,
            username,
            valid: validValue,
            validation_id: validationId,
            account_id: accountId,
        } = model;

        const cookie = !idToken ? this.createCookieFromAccessToken(accessToken) : this.createCookieFromAccessToken(accessToken, idToken);
        // If validValue is undefined it will be set to true anyway.
        const valid = (validValue === false) ? false : true;
        const teams = teamsRaw ? Object.keys(teamsRaw) : undefined;
        const expiration: Date | undefined = expirationString ? new Date(expirationString) : undefined;
        return new RegisteredUser(
            email,
            userId,
            username,
            valid,
            false,
            undefined,
            cookie,
            teams,
            validationId,
            accountId,
            expiration,
            true
        );
    }

    static createFromSerializedData(serializedData: string): RegisteredUser | undefined {
        const data = JSON.parse(serializedData);
        const {
            email,
            expiration:serializedExpiration,
            isSSO,
            userId,
            username,
            valid,
            isVerificationNeeded,
            validationId,
            accountId,
            teams,
            cookie: serializedCookie,
            timeStamp: serializedTimeStamp
        } = data;
        const cookie = CookieData.createFromSerializedData(serializedCookie);

        // Deserialize optional parameters
        let expiration;
        if (serializedExpiration) {
            expiration = new Date(serializedExpiration);
        }

        let timeStamp;
        if (serializedTimeStamp) {
            timeStamp = new Date(serializedTimeStamp);
        }

        return new RegisteredUser (
            email,
            userId,
            username,
            valid,
            isVerificationNeeded,
            timeStamp,
            cookie,
            teams,
            validationId,
            accountId,
            expiration,
            isSSO
        );
    }

    private static createCookieFromAccessToken(accessToken: string, idToken: string | undefined = undefined): CookieData {
        const cookie = !idToken ? { Authorization: `Bearer ${accessToken}`} : { Authorization: `Bearer ${accessToken}`, IdToken: idToken};
        return CookieData.create(cookie);
    }

    constructor (
        public readonly email: string,
        public readonly userId: string,
        public readonly username: string,
        public readonly valid: boolean,
        public readonly isVerificationNeeded: boolean,
        public readonly timeStamp?: Date,
        private pCookie?: CookieData,
        public readonly teams?: string[],
        public readonly validationId?: string,
        public readonly accountId?: string,
        private expirationUTC?: Date,
        public readonly isSSO = false
    ){}

    public get expiration(): Date | undefined {
        const expiration = this.expirationUTC;
        if (!expiration) {
            return undefined;
        }

        const expirationToLocal = new Date(expiration.getTime()-expiration.getTimezoneOffset()*60*1000);
        return expirationToLocal;
    }

    public set expiration (expiration: Date | undefined) {
        this.expirationUTC = expiration;
    }

    public updateAccessToken (accessToken: string): void {
        if ((!accessToken) || (!this.isSSO)){
            return;
        }

        this.pCookie = RegisteredUser.createCookieFromAccessToken(accessToken);
    }

    public updateAccessAndIdToken (accessToken: string, idToken: string): void {
        if ((!accessToken) || (!idToken) || (!this.isSSO)){
            return;
        }

        this.pCookie = RegisteredUser.createCookieFromAccessToken(accessToken, idToken);
    }

    public serialize(): string {
        const {
            email,
            isSSO,
            userId,
            username,
            valid,
            isVerificationNeeded,
            validationId,
            accountId,
            teams,
            cookie: cookieData,
            expirationUTC: expiration,
            timeStamp
        } = this;
        const objectToSerialize = {
            email,
            isSSO,
            userId,
            username,
            valid,
            isVerificationNeeded
        };

        // Serialize optional parameters
        if (teams && (teams.length > 0)) {
            Object.assign(objectToSerialize, { teams });
        }
        if (validationId) {
            Object.assign(objectToSerialize, {validationId});
        }
        if (accountId) {
            Object.assign(objectToSerialize, {accountId});
        }
        if (expiration) {
            Object.assign(objectToSerialize, {expiration: expiration.getTime()});
        }
        if (timeStamp) {
            Object.assign(objectToSerialize, {timeStamp: timeStamp.getTime()});
        }
        if (cookieData) {
            Object.assign(objectToSerialize, {cookie: cookieData.serialize()});
        }
        return JSON.stringify(objectToSerialize);
    }

}