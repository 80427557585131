export interface EntityAnalyticsTab {
    id: string,
    account_id: string,
    name: string,
    refresh_rate: number,
    type: string,
    url: string,
    user_id?: string,
    user_name?: string
}

export class AnalyticsTab {
    static create (userData: EntityAnalyticsTab): AnalyticsTab | null {
        if (!userData || (userData.type !== 'tableau_auth')) {
            return null;
        }

        const {
            account_id: accountId,
            id,
            name,
            refresh_rate: refreshRate,
            url,
            user_id: userId,
            user_name: userName
        } = userData;
        
        return new AnalyticsTab(
            accountId,
            id,
            name,
            refreshRate,
            url,
            userId,
            userName
        );
    }
    constructor(
        public readonly accountId: string,
        public readonly id: string,
        public readonly name: string,
        public readonly refreshRate: number,
        public readonly url: string,
        public readonly userId?: string,
        public readonly userName?: string
    ) {}
}
