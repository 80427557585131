export interface RunningJobs {
    task_name: string,
    task_id: string,
    status: number,
    type: number,
    startedAt: number,
    elapsedTime: number,
    progress: number,
    result_data?: unknown,
    group_id?: string,
    set_to_stop: boolean,
    timer: number,
    static_progress_count: number,
    internalCallback?: (taskId: string) => void,
}

export interface JobStatusResult {
    status: string,
    completion: number,
    result: object
}

export interface JobCreationResult {
    status: boolean;
    message: string;
    jobId: string;
}

export interface JobErrorResult {
    error: string,
    stack_trace: string
}

export const STATIC_PROGRESS_LIMIT = 5;

export const JobTypes = {
    Test: 0,
    RouteCreation: 10006
};

export enum StatusTypes {
    Enqueued = 'ENQUEUED',
    Running = 'RUNNING',
    Error = 'ERROR',
    Complete = 'COMPLETE'
}
