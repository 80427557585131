/* eslint-disable no-useless-escape */
export function getUrlsFromString(text: string): string[] {
    // eslint-disable-next-line no-control-regex
    const getUrls = new RegExp('(^|[ \t\r\n])((http|https):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))','g');
    const urls = text.match(getUrls);

    if (urls === null) {
        return [] as string[];
    }

    return urls;
}

export function getTagsFromString(text: string): string[] {
    // eslint-disable-next-line no-control-regex
    const getTags = new RegExp('<[^>]*>');
    const tags = text.match(getTags);

    if (tags === null) {
        return [] as string[];
    }

    return tags;
}

export function formatTagsAndUrlsIntoString(text: string, linkClassName: string): string {
    const tags = getTagsFromString(text);
    tags.map((tag: string) => {
        const formatedTag = tag.trim();
        const result = text.replace(formatedTag, `-${formatedTag.slice(1, formatedTag.length - 1)}-`);
        text = result;
    });

    const urls = getUrlsFromString(text);
    urls.map((url: string) => {
        const formatedUrl = url.trim();
        const result = text.replace(formatedUrl, `<a class="${linkClassName}" href="${formatedUrl}" target="_blank">${formatedUrl}</a>`);
        text = result;
    });

    return text;
}
