export enum PagingBufferPageBoundary {
    Upper = 0,
    Lower
}

export class PagingBuffer<T> {
    protected bufferedListItems: T[] = [] as T[];
    protected startingElement: number;
    protected isUpperPageBoundaryPassed: boolean;
    protected isLowerPageBoundaryPassed: boolean;
    constructor (
        public readonly items: T[],
        public readonly rowsPerPage: number,
        public readonly offset: number,
        protected pageBuffer: number
    ) {
        this.startingElement = -1;
        this.isUpperPageBoundaryPassed = false;
        this.isLowerPageBoundaryPassed = false;
        this.calculateInternalVariables();
    }

    protected calculateBufferLimits(): void {
        const {
            items,
            offset,
            rowsPerPage,
            pageBuffer
        } = this;
        const startingElement = (pageBuffer * rowsPerPage)-offset;
        this.startingElement = startingElement;
        this.bufferedListItems = items.slice(startingElement, startingElement+rowsPerPage);
    }

    public get page(): number {
        return this.pageBuffer;
    }

    public set page(nextPage: number) {
        if (this.pageBuffer === nextPage) {
            return;
        }

        this.pageBuffer = nextPage;
        this.calculateInternalVariables();
    }

    public get buffer(): T[] {
        return this.bufferedListItems;
    }

    public get isUpperBoundaryPassed(): boolean {
        return this.isUpperPageBoundaryPassed;
    }

    public get isLowerBoundaryPassed(): boolean {
        return this.isLowerPageBoundaryPassed;
    }

    private calculateInternalVariables() {
        const {
            items,
            offset,
            pageBuffer,
            rowsPerPage
        } = this;
        
        const lowerBoundary = Math.floor((offset) / rowsPerPage);
        const upperBoundary = Math.ceil((items.length+offset) / rowsPerPage);
        this.isUpperPageBoundaryPassed = (pageBuffer+1) >  upperBoundary;
        this.isLowerPageBoundaryPassed = pageBuffer < lowerBoundary;

        this.calculateBufferLimits();
    }
}
