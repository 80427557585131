import { Typography } from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import memoize from 'memoize-one';
import * as React from 'react';
import { Marker } from '../../../models/marker';
import { SearchInput } from '../../../shared/components/search-input';
import { SimpleListItem } from '../../../shared/components/simple-list';
import {
    PageBoundary,
    SimpleListPagination
} from '../../../shared/components/simple-list-pagination';
import { EntityFloorplan } from '../../../shared/domain';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '95%',
        margin: 'auto 0'
    },
    divider: {
        margin: '1em 0'
    },
    listItemsContainer: {
        flex: 1
    },
    listItems: {},
    listItemsPagination: {
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8_Landscape)]: {
            minHeight: 0
        }
    },
    listItemsPaginationToolbar: {
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8_Landscape)]: {
            bottom: '7em',
            right: 0
        }
    },
    title: {
        marginBottom: '1em',
    },
    subtitle: {
        position: 'relative',
        bottom: '1.5em',
        color: '#666'
    },
    floorplanIcon: {
        position: 'relative',
        width: '1.2em',
        margin: '0 0.2em',
        top: '0.2em'
    }
});

interface Props {
    placeholder?: string;
    isLoading: boolean;
    initialSearchTerms?: string;
    searchRowsPerPage: number;
    searchResults?: Marker[];
    searchResultsTotal?: number;
    searchOffset?: number;
    selectedSearchItemId?: string;
    selectedFloorplan?: EntityFloorplan | undefined;
    onSearchBufferOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    onSearchClick?: (searchValue: string) => void;
    onSelectedMarker?: (selectedMarker : Marker) => void;
}

interface States {
    searchTerms: string;
}

class AddReportWizardStepLocation extends React.Component<Props & WithStyles<typeof styles>, States> {
    public static defaultProps = {
        isLoading: false,
        searchRowsPerPage: 0
    };

    state = {
        searchTerms: this.props.initialSearchTerms ? this.props.initialSearchTerms : ''
    };

    convertMarkersIntoListItems = memoize(
        (markers: Marker[]): SimpleListItem[] => {
            return markers.map((marker: Marker) => {
                const {
                    id,
                    name,
                    folderName
                } = marker;
                return new SimpleListItem(id, name, folderName);
            });
        }
    );

    findMarkerById = (markerId: string) : Marker | undefined => {
        const { searchResults } = this.props;
        if (!searchResults) {
            return undefined;
        }

        return searchResults.find((marker: Marker) => {
            return marker.id === markerId;
        });
    };

    onListItemCLicked = (listItem: SimpleListItem) => {
        const { onSelectedMarker } = this.props;
        if (!onSelectedMarker) {
            return;
        }

        const foundItem = this.findMarkerById(listItem.id);

        if (!foundItem) {
            return;
        }

        onSelectedMarker(foundItem);
    };

    onSearchClicked = (searchTerms: string) => {
        this.setState({
            searchTerms
        });
        const { onSearchClick } = this.props;
        if (onSearchClick) {
            onSearchClick(searchTerms);
        }
    };

    public render() {
        const {
            classes,
            isLoading,
            placeholder,
            searchRowsPerPage,
            searchResults,
            searchResultsTotal,
            selectedSearchItemId,
            searchOffset,
            selectedFloorplan,
            onSearchBufferOverBoundary
        } = this.props;

        const {
            searchTerms
        } = this.state;

        const items =
            searchResults ?
                this.convertMarkersIntoListItems(searchResults)
                : [];

        return (
            <div
                className={classes.root}
            >
                <Typography
                    className={classes.title}
                    variant="h5"
                >
                    Select Location
                </Typography>
                { searchResults && searchResults.length ?
                    <Typography
                        className={classes.subtitle}
                    >
                        {searchResults[0].folderName}: {!selectedFloorplan ? '' : selectedFloorplan.name}
                    </Typography>
                    
                    :
                    <Typography
                        className={classes.subtitle}
                    >
                        No markers found
                    </Typography>
                }
                <SearchInput
                    placeholder={placeholder}
                    buttonText="Search"
                    buttonColor="info"
                    initialSearchValue={searchTerms}
                    searchIconButtonDisabled={true}
                    onSearchClick={this.onSearchClicked}
                />
                <SimpleListPagination
                    className={classes.listItems}
                    classes={{
                        root: classes.listItemsContainer,
                        pagination: classes.listItemsPagination,
                        paginationToolbar: classes.listItemsPaginationToolbar
                    }}
                    items={items}
                    isLoading={isLoading}
                    noItemsLabel={isLoading ?
                        'Loading...' :
                        searchTerms ?
                            'No marker found' :
                            'Start a search'
                    }
                    keepItemSelected={true}
                    totalItems={searchResultsTotal}
                    selectedItemId={selectedSearchItemId}
                    offset={searchOffset}
                    rowsPerPage={searchRowsPerPage}
                    onPageOverBoundary={onSearchBufferOverBoundary}
                    onListItemClick={this.onListItemCLicked}
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(AddReportWizardStepLocation);
export {MUIComponent as AddReportWizardStepLocation};
// export {Props as AddReportWizardStepLocationProps};
export type AddReportWizardStepLocationProps = Props;
