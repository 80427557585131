import * as React from 'react';
import IdleTimer from 'react-idle-timer';

interface Props {
    enabled: boolean;
    timeout: number;
    onTimeout: () => void;
}

interface States {
    showModal: boolean;
}
type IdleTimerReference = React.RefObject<IdleTimer> & ((ref: IdleTimer) => unknown);

export class CCIdleTimer extends React.Component<Props, States> {
    public static defaultProps = {
        enabled: true
    };

    state = {
        showModal: false
    };

    idleTimerRef: IdleTimerReference = React.createRef() as IdleTimerReference;

    //#region Events
    /**
     * Function called when user is idle
     */
    onIdle = (/*e: Event*/): void => {
        this.setState({showModal: true});
        const idleTimer = this.idleTimerRef.current;
        if (idleTimer) {
            idleTimer.pause();
        }
        this.props.onTimeout();
    };

    //#endregion
    
    public render(): React.ReactNode {
        const {
            enabled,
            timeout
        } = this.props;

        return (
            enabled ?
                <IdleTimer
                    ref={this.idleTimerRef}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={timeout*1000}
                />
                : ''
        );
    }
}
