import { Button, Typography } from '@mui/material';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { PhotoOutlined } from '@mui/icons-material';
import * as React from 'react';
import { Report, ReportStatus } from '../../models/report';
import { CCAgrees } from '../cc-agrees/cc-agrees';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        statusContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginLeft: 'auto',
        },
        status: {
            textTransform: 'uppercase',
            textAlign: 'right',
            fontWeight: 'bold',
            '&.in-progress': {
                color: theme.ccPalette.cc_colors.solid.ccRightsilhouette.dark,
            },
            '&.closed': {
                color: theme.ccPalette.cc_colors.solid.ccCentersilhouette.main,
            },
            '&.urgent': {
                color: theme.ccPalette.cc_colors.solid.ccLeftsilhouette.main,
            },
            '&.unresolved': {
                color: theme.ccPalette.warning.main,
            },
        },
        assignedTo: {
            textAlign: 'right',
            maxWidth: '13em',
            wordBreak: 'break-all',
        },
        photo: {
            position: 'relative',
            top: '0.34em',
        },
        agreesButton: {
            padding: '0.2em',
        },
        agreesText: {
            margin: '0.55em 0 0 0',
        },
        thumbUp: {
            '&.agree': {
                color: theme.ccPalette.cc_colors.solid.ccRightsilhouette.main,
            },
            '&.disagree': {
                color: theme.ccPalette.disabled.main,
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    report: Report;
    isPortal: boolean;
    onAgreesClick?: (report: Report) => void;
}

class StatusCell extends React.Component<Props> {
    public static defaultProps = {};

    getStatusColorClass(status: ReportStatus | undefined): string {
        switch (status) {
            case ReportStatus.In_Progress:
                return 'in-progress';
            case ReportStatus.Closed:
                return 'closed';
            case ReportStatus.Urgent:
                return 'urgent';
            default:
                return 'unresolved';
        }
    }

    getThumbsColorClass(agrees: boolean): string {
        if (agrees) {
            return 'agree';
        }

        return 'disagree';
    }

    onAgreesButtonClicked = (
        event: React.MouseEvent<HTMLButtonElement> &
            React.MouseEvent<HTMLElement>
    ) => {
        event.stopPropagation();

        const { onAgreesClick, report } = this.props;

        if (onAgreesClick) {
            onAgreesClick(report);
        }
    };

    public render() {
        const {
            classes,
            report: { status, assignedTo, photoS3Key, agrees },
            isPortal,
        } = this.props;
        const statusColorClass = this.getStatusColorClass(status);
        // TODO: Add the logic to check if the current user agrees or disagrees to the current report
        const thumbsColorClass = this.getThumbsColorClass(true);
        const agreesCount = agrees.length;

        return (
            <div className={classes.root}>
                <div className={classes.statusContainer}>
                    <Typography
                        className={`${classes.status} ${statusColorClass}`}
                        variant="caption"
                    >
                        {status}
                    </Typography>
                    <Typography
                        className={classes.assignedTo}
                        variant="caption"
                    >
                        {assignedTo ? assignedTo : 'Unassigned'}
                    </Typography>
                    {photoS3Key || !isPortal ? (
                        <div>
                            {photoS3Key ? (
                                <PhotoOutlined className={classes.photo} />
                            ) : (
                                ''
                            )}
                            {!isPortal ? (
                                <Button
                                    className={classes.agreesButton}
                                    onClick={this.onAgreesButtonClicked}
                                >
                                    <CCAgrees
                                        classes={{
                                            thumbUp: `${classes.thumbUp} ${thumbsColorClass}`,
                                        }}
                                        count={agreesCount}
                                    />
                                </Button>
                            ) : (
                                ''
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(StatusCell);
export { MUIComponent as StatusCell };
