export interface LoginAuthorizationModel {
    authentication_type: string;
    error_code?: string;
    redirect_url?: string;
    valid: boolean;
    validation_id?: string;
}

export enum AuthenticationType {
    Unknown = -1,
    Standard = 0,
    SSO,
}

export interface RefreshSSOTokenResponseModel {
    access_token: string;
    expiration: string;
    id_token?: string;
}

export class LoginAuthorization {
    static create(model: LoginAuthorizationModel): LoginAuthorization {
        const {
            authentication_type: authenticationTypeRaw,
            error_code: errorCode,
            redirect_url: redirectUrl,
            valid,
            validation_id: validationId,
        } = model;

        let authenticationType;
        switch (authenticationTypeRaw.toLocaleLowerCase()) {
            case 'standard':
                authenticationType = AuthenticationType.Standard;
                break;
            case 'sso':
                authenticationType = AuthenticationType.SSO;
                break;
            default:
                authenticationType = AuthenticationType.Unknown;
        }

        return new LoginAuthorization(
            authenticationType,
            valid,
            errorCode,
            redirectUrl,
            validationId
        );
    }

    static createFromSerializedData(
        serializedData: string
    ): LoginAuthorization | undefined {
        try {
            const data = JSON.parse(serializedData);
            const {
                authenticationType,
                valid,
                errorCode,
                redirectUrl,
                validationId,
            } = data;

            return new LoginAuthorization(
                authenticationType,
                valid,
                errorCode,
                redirectUrl,
                validationId
            );
        } catch (e) {
            return;
        }
    }

    constructor(
        public readonly authenticationType: AuthenticationType,
        public readonly valid: boolean,
        public readonly errorCode?: string,
        public readonly redirectUrl?: string,
        public readonly validationId?: string
    ) {}

    public serialize(): string {
        const {
            authenticationType,
            valid,
            errorCode,
            redirectUrl,
            validationId,
        } = this;

        return JSON.stringify({
            authenticationType,
            valid,
            errorCode,
            redirectUrl,
            validationId,
        });
    }
}
