import { Typography } from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
import { Report } from '../../models/report';
import { DateFormatting } from '../../utils/date-formatting';
import { FaceType } from '../faces-review';
import { FacesReviewBtn } from '../faces-review-button';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    markerName: {
        fontSize: '1.2em',
        overflow: 'hidden',
        wordBreak: 'break-word',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.only('xs')]: {
            paddingLeft: '1.7em'
        }
    },
    time: {},
    id: {
        fontWeight: 'bold'
    },
    reviewBtn: {
        width: '8.2em',
        marginLeft: '-0.8em',
        transform: 'scale(0.8)'
    }
});

interface Props extends WithStyles<typeof styles> {
    report: Report;
    isPortal: boolean;
    faceType: FaceType;
    onOpenVocClick?: (report: Report) => void;
}

class MarkerGridCell extends React.Component<Props> {
    public static defaultProps = {
    };

    onClicked = () => {
        const {
            onOpenVocClick,
            report
        } = this.props;
        if (!onOpenVocClick) {
            return;
        }

        onOpenVocClick(report);
    };

    public render() {
        const {
            classes,
            report: {
                idNumber,
                markerName,
                timeStamp,
                userCanLeaveFeedback,
                userFeedback,
                status
            },
            isPortal,
            faceType
        } = this.props;
        const timeFormatted = DateFormatting.formatMarkerTimestamp(timeStamp);
        const isFacesReview = !isPortal && (status === 'Closed') && !(!userFeedback && !userCanLeaveFeedback);

        return (
            <div className={classes.root}>
                <Typography
                    className={classes.markerName}
                    variant="h6"
                >
                    {markerName}
                </Typography>
                <Typography
                    className={classes.time}
                    variant="caption"
                >
                    {timeFormatted}
                </Typography>
                <Typography
                    className={classes.id}
                    variant="caption"
                >
                    ID: {idNumber ? idNumber : 'N/A'}
                </Typography>
                { isFacesReview ?
                    <FacesReviewBtn
                        className={classes.reviewBtn}
                        selectedOption={faceType}
                        onClick={this.onClicked}
                        isReadOnly={userCanLeaveFeedback}
                    />
                    : ''
                }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(MarkerGridCell);
export { MUIComponent as MarkerGridCell };
