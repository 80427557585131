import { API } from '../shared/constants';
import { CustomFilterValues, CustomFiltersParameters } from '../shared/domain/completion';
import { CompletionBaseAdapter } from './completion-base-adapter';
import { HttpConnectorAdapter } from './http-connector-adapter';

interface BaseParameters extends CustomFiltersParameters{
    limit?: number;
    offset?: number;
}

let singletonInstance: CompletionAdapter;
export class CompletionAdapter implements CompletionBaseAdapter {
    static getAdapter(): CompletionBaseAdapter {
        if (!singletonInstance) {
            singletonInstance = new CompletionAdapter();
        }

        return singletonInstance;
    }

    async getCustomFilterValues(limit: number, offset: number, filters = {} as CustomFiltersParameters): Promise<CustomFilterValues> {
        const params: BaseParameters = {};

        if (limit > 0) {
            params.limit = limit;
        }

        if (offset > 0) {
            params.offset = offset;
        }

        for (const filter in filters) {
            params[filter as keyof CustomFiltersParameters] = filters[filter as keyof CustomFiltersParameters];
        }

        const stringifiedParams = HttpConnectorAdapter.Utilities.stringifyGetParams(params);

        const response = await HttpConnectorAdapter
            .get(
                `${API.URL.COMPLETION.CUSTOM_FILTER_VALUES}?${stringifiedParams}`
            );
        const result = response.data as CustomFilterValues;
        if (!result) {
            return Promise.reject();
        }

        return result;
    }

    async getFolderFiltersValues(tabModule: string): Promise<CustomFilterValues> {
        const params = {
            attribute: 'folder_doc_id,folder_name',
            module: tabModule
        };
        const stringifiedParams = HttpConnectorAdapter.Utilities.stringifyGetParams(params);
        const response = await HttpConnectorAdapter
            .get(
                `${API.URL.COMPLETION.CUSTOM_FILTER_VALUES}?${stringifiedParams}`
            );
        
        const result = response.data as CustomFilterValues;
        if (!result) {
            return Promise.reject();
        }

        return result;
    }
}