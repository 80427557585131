// tslint:disable: ordered-imports
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
    CancelOutlined,
    CheckCircle,
    HourglassEmpty
} from '@mui/icons-material';
import * as React from 'react';
import { SimpleListItem } from '../../shared/components/simple-list';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import {
    Button
} from '@mui/material';
import { RunningJobs } from '../../models/jobs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    simpleList: {
        overflow: 'auto',
        width: '100%'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    waitingItem: {
        color: '#ffc409',
        marginRight: '0.5em',
        fontSize: '2em'
    },
    errorItem: {
        color: '#eb445a',
        marginRight: '0.5em',
        fontSize: '2em'
    },
    readyItem: {
        color: '#2dd36f',
        marginRight: '0.5em',
        fontSize: '2em'
    },
    buttonClear: {
        float: 'right'
    },
});

interface Props extends WithStyles<typeof styles> {
    className: string;
    tasks: RunningJobs[];
    rowsPerPage: number;
    onJobClick?: (task: RunningJobs) => void;
    taskItemsOffset: number;
    onJobsDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    onJobClear?: () => void;
}

class RunningJobsList extends React.Component<Props> {
    public static defaultProps = {
    };

    onJobClick = (listItem: SimpleListItem) => {
        const {tasks, onJobClick} = this.props;
        if(!tasks) {
            return;
        }

        const currentTask = tasks.find((task:RunningJobs) => {
            return task.task_id === listItem.id;
        });
        if (!currentTask) {
            return;
        }

        if (onJobClick) {
            onJobClick(currentTask);
        }
    };

    public render() {
        const {
            className,
            classes,
            tasks,
            rowsPerPage,
            taskItemsOffset,
            onJobsDataPageOverBoundary,
            onJobClear
        } = this.props;

        const taskListItems = tasks ? tasks.map((task: RunningJobs) => {
            const newItem: SimpleListItem = {
                id: task.task_id,
                label: task.task_name,
                secondaryLabel: (task.status === 102) ? `Processing . . .` : (task.status === 200) ? `Completed in ${task.elapsedTime} Seconds` : `Error ${task.elapsedTime} Seconds`,
                icon: (task.status === 102) ? 
                    <HourglassEmpty className={ classes.waitingItem } /> :
                    (task.status === 200) ?
                        <CheckCircle className={ classes.readyItem }/> :
                        <CancelOutlined className={ classes.errorItem }/>,
                hasProgress: true,
                progress: task.progress
            };
            return newItem;
        }): [];

        return (
            <div className={`${classes.root} ${className}`} data-testid="runningTasks">
                <div className={classes.container}>
                    <div className={`${classes.container} ${classes.simpleList}`}>
                        <SimpleListPagination
                            className={classes.simpleList}
                            keepItemSelected={false}
                            items={taskListItems}
                            isLoading={false}
                            rowsPerPage={rowsPerPage}
                            totalItems={tasks.length}
                            offset={taskItemsOffset}
                            onPageOverBoundary={onJobsDataPageOverBoundary}
                            onListItemClick={this.onJobClick}
                        />
                    </div>
                    { onJobClear && 
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.buttonClear}
                            onClick={onJobClear}
                        >
                            Clear Completed
                        </Button>
                    }
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(RunningJobsList);
export {MUIComponent as RunningJobsList};