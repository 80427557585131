import { EnumerationHelper } from '../../shared/utils/enumeration-helper';
import { ReportStatus } from './report-status';
import {
    VocFeedbackModule,
    VocFeedbackModuleModel
} from './voc-feedback-module';

//#region ReportModel Data Structure
// assigned_to: ""
// email: "jim+seq2@crowdcomfort.com"
// folder_id: "folder_5ad0e512413c3f3d246c64e0"
// folder_name: "Markers Nearby Test Site"
// id: "report::5c5096cb413c3f5dee7fb30f"
// marker_id: "folder_5ad0e512413c3f3d246c64e0_marker_5ad0e512413c3f3d246c64e2"
// marker_name: "Marker 1"
// module_id: "maintenance"
// module_name: "Maintenance"
// module_type: "maintenance"
// report_seq_number: 1002
// status: "Unresolved"
// summary: "test3 seq"
// timestamp: "2019-01-29T18:09:15.918Z"
// type: "report"
//#endregion

export interface ReportModel {
    agrees: string[],
    assigned_to: string;
    email: string;
    folder_id: string;
    folder_name: string;
    id: string;
    marker_id: string;
    marker_name: string;
    module_id: string;
    module_name: string;
    module_type: string;
    module_group_id?: string;
    photo?: string;
    report_seq_number: number;
    status: string;
    summary: string;
    timestamp: string;
    user_can_leave_feedback?: boolean;
    user_feedback?: VocFeedbackModuleModel[];
    user_feedback_module_id?: string;
    username?: string;
}

export class Report {
    static create (model: ReportModel): Report {
        const {
            agrees: agreesRaw,
            assigned_to: assignedTo,
            email,
            folder_id: folderId,
            folder_name: folderName,
            id,
            marker_id: markerId,
            marker_name: markerName,
            module_id: moduleId,
            module_name: moduleName,
            module_type: moduleType,
            module_group_id: moduleGroupId,
            photo: photoS3Key,
            report_seq_number: idNumber,
            status: statusRaw,
            summary,
            timestamp: serializedTimeStamp,
            user_can_leave_feedback: userCanLeaveFeedback,
            user_feedback: userFeedbackModel,
            user_feedback_module_id: userFeedbackModuleId,
            username
        } = model;
        const statusKey: string | undefined =
            EnumerationHelper.getEnumKeyFromValue(ReportStatus, statusRaw);
        const status: ReportStatus =
            statusKey ? ReportStatus[statusKey as keyof typeof ReportStatus] : ReportStatus.Unresolved;
        const timeStamp = new Date(serializedTimeStamp);
        const agrees = agreesRaw && Array.isArray(agreesRaw) ? agreesRaw : [];
        let userFeedback: VocFeedbackModule[] | undefined = userFeedbackModel ?
            userFeedbackModel.reduce((returnedArray: VocFeedbackModule[], moduleModel: VocFeedbackModuleModel) => {
                const createdModule = VocFeedbackModule.create(moduleModel);
                if (createdModule) {
                    returnedArray.push(createdModule);
                }
                return returnedArray;
            }, [] as VocFeedbackModule[])
            : undefined;
        if (userFeedback && (userFeedback.length < 1)) {
            userFeedback = undefined;
        }

        return new Report(
            agrees,
            assignedTo,
            email,
            folderId,
            folderName,
            id,
            markerId,
            markerName,
            moduleId,
            moduleName,
            moduleType,
            moduleGroupId ? moduleGroupId : '',
            photoS3Key,
            idNumber,
            status,
            summary,
            timeStamp,
            userCanLeaveFeedback,
            userFeedback,
            userFeedbackModuleId,
            username
        );
    }

    constructor (
        public readonly agrees: string[],
        public readonly assignedTo: string,
        public readonly email: string,
        public readonly folderId: string,
        public readonly folderName: string,
        public readonly id: string,
        public readonly markerId: string,
        public readonly markerName: string,
        public readonly moduleId: string,
        public readonly moduleName: string,
        public readonly moduleType: string,
        public readonly moduleGroupId: string,
        public readonly photoS3Key: string | undefined,
        public readonly idNumber: number,
        public readonly status: ReportStatus | undefined,
        public readonly summary: string,
        public readonly timeStamp: Date,
        public readonly userCanLeaveFeedback?: boolean,
        public readonly userFeedback?: VocFeedbackModule[],
        public readonly userFeedbackModuleId?: string,
        public readonly username?: string
    ) {}
}
