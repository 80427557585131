import * as React from 'react';

import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Navigation,
    Pagination,
    Mousewheel,
    Keyboard
} from 'swiper';
import 'swiper/swiper-bundle.css';
import {
    ReportModuleGroup
} from '../../models/report';
import memoize from 'memoize-one';
import { AddReportTile } from '../add-report-tile/add-report-tile';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { SimpleModal } from '../../shared/components/simple-modal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        minHeight: '31em',
        height: '40%',
        width: '50%',
        maxWidth: '80em',
        minWidth: '37em',
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8_Landscape)]: {
            minWidth: 'auto',
            overflow: 'auto'
        }
    },
    bodyContent: {
        flex: '1',
        padding: '0.6em',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'auto'
    },
    spinner:{
        zIndex: 1
    },
    title: {
        fontSize: '1.4em',
        fontWeight: 500,
        color: '#FC502E'
    },
    header: {
        textAlign: 'center',
        padding: '2em'
    },
    footer: {
        padding: '2em'
    },
    footerCancelButton: {
        padding: '0.45em 1.7em',
        marginRight: '1em'
    },
    footerSubmitButton: {
        padding: '0.45em 1.7em'
    },
    swiperWrapper: {
        flex: 1,
        position: 'relative',
        padding: '0 0.5em',
        width: '100%',
        overflow: 'hidden',
        marginRight: '1em'
    },
    swiperContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        // marginRight: '1em',
        '& .swiper-container, & .swiper-wrapper': {
            height: '100%',
        },
        '& .swiper-container .swiper-button-prev': {
            left: 0
        },
        '& .swiper-container .swiper-button-next': {
            right: 0
        },
        '& .swiper-container .swiper-button-prev, & .swiper-container .swiper-button-next':{
            width: '2em'
        },
        '& .swiper-container .swiper-button-prev:after, & .swiper-container .swiper-button-next:after':{
            fontSize: '2em'
        },
        '& .swiper-pagination-bullets': {
            bottom: 0
        }
    },
    swiper: {
        width: '100%',
        height: '30%'
    },
    swiperSlide: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        height: '16em',
        gap: '2em 1em'
    },
    widgetLabel: {
        alignSelf: 'center',
        marginTop: '1.5em'
    },
    reportTileContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: '0 auto',
        maxWidth: '30em',
        '&.bp-2': {
            maxWidth: '24em'
        },
        '&.bp-3': {
            maxWidth: '18em'
        },
        '&.bp-4': {
            maxWidth: '12em'
        }
    }
});

// install Swiper modules
SwiperCore.use([Navigation,Pagination,Mousewheel,Keyboard]);

// Association of media queries and number of tiles in swiper
const mediaQueryAssociation: {[Key: string]: number} = {
    '(min-width: 668px)': 10,
    // '(min-width: 607px) and (max-width: 668px)': 12,
    '(min-width: 509px) and (max-width: 668px)': 10,
    '(min-width: 370px) and (max-width: 509px)': 8,
    '(min-width: 285px) and (max-width: 370px)': 6,
    '(min-width: 218px) and (max-width: 285px)': 4,
    '(max-width: 218px)': 1
} as {[Key: string]: number};

interface Props extends WithStyles<typeof styles> {
    isLoading: boolean;
    reportModuleGroups: ReportModuleGroup[];
    selectedReportModuleGroupId?: string;
    onReportModuleGroupSelect?: (reportModule: ReportModuleGroup) => void;
    className?: string;
    open: boolean;
    submitDisabled: boolean;
    cancelCategoryUpdate?: () => void;
    submitCategoryUpdate?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface States {
    tilesPerCard: number;
    mediaClassName: string;
    selectedReportModuleGroup?: ReportModuleGroup;
}

class UpdateCategoryWizard extends React.Component<Props & WithStyles<typeof styles>, States> {
    public static defaultProps = {
        isLoading: false,
        open: true,
        submitDisabled: false
    };
    
    state = {
        tilesPerCard: 10,
        mediaClassName: ''
    };

    mediaQueries = [] as MediaQueryList[];
    checkMediaQueryMatch = () => {
        if (this.mediaQueries.length < 1) {
            return;
        }

        const mediaQueriesCount = this.mediaQueries.length;
        for (let cnt = 0; cnt < mediaQueriesCount; cnt++) {
            const mediaQuery = this.mediaQueries[cnt];
            if (mediaQuery.matches) {
                const key: string = mediaQuery.media;
                const tilesPerCard = mediaQueryAssociation[key];
                this.setState({
                    tilesPerCard,
                    mediaClassName: `bp-${cnt}`
                });
                break;
            }
        }
    };

    componentDidMount() {
        for (const mediaQueryValue in mediaQueryAssociation) {
            const mediaQuery = window.matchMedia(mediaQueryValue);
            const tilesPerCard = mediaQueryAssociation[mediaQueryValue];
            mediaQuery.onchange = (e) => {
                if (!e.matches) {
                    return;
                }
                const index = this.mediaQueries.findIndex(
                    (currentMediaQuery) => currentMediaQuery.media === mediaQuery.media
                );
                this.setState({
                    tilesPerCard,
                    mediaClassName: `bp-${index}`
                });
            };
            this.mediaQueries.push(mediaQuery);
        }

        this.checkMediaQueryMatch();
    }

    componentWillUnmount () {
        this.mediaQueries.forEach(mediaQuery => {
            mediaQuery.onchange = null;
        });
    }
    
    createModulesList = memoize((
        reportModules: ReportModuleGroup[],
        selectedId: string | undefined,
        tilesPerCard: number
    ) : JSX.Element[] => {
        const { classes } = this.props;
        const { mediaClassName } = this.state;
        // Filters the element that aren't atomic (e.g. inspections)
        const filteredReports = reportModules
            .filter((moduleGroup: ReportModuleGroup) => {
                return moduleGroup.groupType === 'atomic';
            });
        const items = [] as JSX.Element[];
        const reportsCount = filteredReports.length;
        let cardTiles = [] as JSX.Element[];
        let swiperID = 1;
        // Put Inside SwiperSlide a group of 8 tiles
        for (let index = 0; index < reportsCount; index++) {
            const moduleGroup = filteredReports[index];
            cardTiles.push(this.createTile(moduleGroup, selectedId));
            if (cardTiles.length === tilesPerCard) {
                items.push(
                    <SwiperSlide
                        key={`swiper-${swiperID++}`}
                        className={classes.swiperSlide}
                    >
                        <div className={`${classes.reportTileContainer} ${mediaClassName}`}>
                            {cardTiles}
                        </div>
                    </SwiperSlide>
                );
                cardTiles = [];
            }
        }
        if (cardTiles.length > 0) {
            items.push(
                <SwiperSlide
                    key={`swiper-${swiperID++}`}
                    className={classes.swiperSlide}
                >
                    <div className={classes.reportTileContainer}>
                        {cardTiles}
                    </div>
                </SwiperSlide>
            );
            cardTiles = [];
        }
        return items;
    });

    createTile = (moduleGroup: ReportModuleGroup, selectedId: string | undefined): JSX.Element => {
        const {
            id,
            name,
            moduleTag
        } = moduleGroup;
        return (
            <AddReportTile
                key={id}
                id={id}
                iconId={moduleTag ? moduleTag : ''}
                isSelected={id === selectedId}
                label={name}
                onClick={this.onItemClicked}
            />
        );
    };

    onItemClicked = (moduleId: string) => {
        const { onReportModuleGroupSelect } = this.props;
        if (!onReportModuleGroupSelect) {
            return;
        }
        const { reportModuleGroups } = this.props;
        const reportModule: ReportModuleGroup | undefined = reportModuleGroups.find((module: ReportModuleGroup) => {
            return module.id === moduleId;
        });

        if (reportModule) {
            onReportModuleGroupSelect(reportModule);
        }
    };

    onCancelClicked = () => {
        const { cancelCategoryUpdate } = this.props;

        if (cancelCategoryUpdate) {
            cancelCategoryUpdate();
        }
    };

    onSubmitClicked = () => {
        const { submitCategoryUpdate } = this.props;

        if (submitCategoryUpdate) {
            submitCategoryUpdate();
        }
    };

    public render() {
        const {
            classes,
            reportModuleGroups,
            selectedReportModuleGroupId,
            isLoading,
            className,
            open,
            submitDisabled
        } = this.props;

        const { tilesPerCard } = this.state;
        const items = this.createModulesList(reportModuleGroups, selectedReportModuleGroupId, tilesPerCard);
        const rootClasses = `${classes.root}${className ? ` ${className}` : ''}`;

        return (
            <SimpleModal
                classes={{
                    modalContainer: rootClasses
                }}
                open={open}
                contentClasses={classes.bodyContent}
                header={
                    <div className={classes.header}>
                        <Typography
                            className={classes.title}
                            variant="caption"
                        >
                            Edit Category Type
                        </Typography>
                    </div>
                }
                footer={
                    <div className={classes.footer}>
                        <Button
                            className={classes.footerCancelButton}
                            onClick={this.onCancelClicked}
                            variant="contained"
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes.footerSubmitButton}
                            onClick={this.onSubmitClicked}
                            color="secondary"
                            variant="contained"
                            disabled={submitDisabled}
                        >
                            Update
                        </Button>
                    </div>
                }
            >
                <>
                    <div className={classes.swiperWrapper}>
                        <div className={classes.swiperContainer}>
                            <Swiper
                                id="main"
                                pagination={true}
                                navigation={true}
                                mousewheel={true}
                                keyboard={true}
                                slidesPerView={1}
                                spaceBetween={1}
                            >
                                {items}
                            </Swiper>
                        </div>
                    </div>
                    <CCSpinner
                        className={classes.spinner}
                        loading={isLoading}
                        size={70}
                        overlayVisible={true}
                    />
                </>
            </SimpleModal>
            
        );
    }
}

const MUIComponent = withStyles(styles)(UpdateCategoryWizard);
export { MUIComponent as UpdateCategoryWizard };
