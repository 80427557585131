export interface TaskRow {
    [key: string]: string | number;
}

export interface Tasks {
    rows: TaskRow[];
    total: number;
}

export interface EntityTasksTab {
    status: boolean,
    message: string,
    data: Tasks
}

export interface TaskFilterParameters {
    status?: string;
    secondary_id?: string;
    review_status?: string;
    date?: string;
}

export class TasksTab {
    static create (tasksData: EntityTasksTab): TasksTab {
        const {
            status,
            message,
            data: tasks
        } = tasksData;
        
        return new TasksTab(
            status,
            message,
            tasks
        );
    }
    constructor(
        public readonly status: boolean,
        public readonly message: string,
        public readonly tasks: Tasks
    ) {}
}

export interface AnnotationData {
    [key: string]: string | number | boolean
}

export interface Annotations {
    rows: AnnotationData[]
    total: number
}
export interface EntityAnnotation {
    status: boolean,
    message: string,
    data: Annotations
}

export class Annotation {
    static create (annotationData: EntityAnnotation): Annotation {
        const {
            status,
            message,
            data: annotations
        } = annotationData;
        
        return new Annotation(
            status,
            message,
            annotations
        );
    }
    constructor(
        public readonly status: boolean,
        public readonly message: string,
        public readonly annotations: Annotations
    ) {}
}

export type TaskFilters = 'task_status' | 'task_property_id' | 'task_annotation_status' | 'task_folder_id' | 'task_am' | 'task_ifm';

export interface DefaultTaskFilters {
    filter_type: TaskFilters;
    filter_value: string;
}
