import * as React from 'react';
import { createRoot } from 'react-dom/client';

import 'react-day-picker/lib/style.css';
import './shared/components/index.css';

// tslint:disable-next-line:ordered-imports
import 'react-app-polyfill/ie11'; // The polyfills should always come after importing react
import 'string-polyfills';
import App from './App';
import './index.css';
import BugsnagProvider from './providers/bugsnag.provider';

const bugsnagProvider = new BugsnagProvider();

bugsnagProvider.startBugsnag();
const ErrorWrapper = bugsnagProvider.createErrorWrapper();

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);
root.render(
    <ErrorWrapper>
        <App />
    </ErrorWrapper>
);
