import * as React from 'react';

import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
    DatePicker,
    DatePickerProps,
    DateValidationError,
    LocalizationProvider
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {}
});

interface Props extends WithStyles<typeof styles> {
    label?: string;
    className?: string;
    onDateChange?: (date: Date, validationError: string | null) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class CCSingleDatePicker extends React.Component<Props & DatePickerProps<any>> {
    public static defaultProps = {
    };

    state = {
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onDateChanged = (dayjsValue: any, context: FieldChangeHandlerContext<DateValidationError>) => {
        const {
            onDateChange
        } = this.props;

        if (!onDateChange) {
            return;
        }

        const { validationError } = context;

        onDateChange(dayjsValue, validationError);
    };

    public render() {
        const {
            classes,
            className,
            ...DatePickerProps
        } = this.props;

        const rootClasses = `${classes.root}${className ? ` ${className}` : ''}`;
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    // To check all datetime picker props visit 
                    // https://mui.com/x/api/date-pickers/date-picker/
                    {...DatePickerProps}
                    className={rootClasses}
                    onChange={this.onDateChanged}
                />
            </LocalizationProvider>
        );
    }
}

const MUIComponent = withStyles(styles)(CCSingleDatePicker);
export { MUIComponent as CCSingleDatePicker };
