import { EnumerationHelper } from '../../shared/utils';

export interface WidgetDataModelTextProperties {
    align: 'left' | 'center' | 'right';
    editable?: string;
    placeholder?: string;
    text?: string;
    widget_id?: string;
}

export interface WidgetDataModelPhotoProperties {
    height: number;
    image: string;
    width: number;
    widget_id?: string;
}

export interface WidgetDataModelToggleProperties {
    segment_titles: string[];
    segment_values: string[];
    selected_index: string;
    widget_id: string;
}

export interface WidgetDataModelPickerProperties {
    components: number;
    picker_values: string[];
    widget_id: string;
}

export interface WidgetDataModel {
    properties:
        | WidgetDataModelTextProperties
        | WidgetDataModelPhotoProperties
        | WidgetDataModelToggleProperties
        | WidgetDataModelPickerProperties;
    type: string;
}

export enum WidgetAlignment {
    left = 0,
    center,
    right,
}

export enum WidgetType {
    Unknown = -1,
    Label = 0,
    TextField,
    Toggle,
    Photo,
    PhotoTaker,
    Picker,
}

export interface WidgetDataTextProperties {
    align: WidgetAlignment;
    placeholder?: string;
    text?: string;
    widgetId?: string;
}

export interface WidgetDataPhotoProperties {
    height: number;
    imageUrl: string;
    imageEncoded?: string;
    width: number;
    widgetId?: string;
}

export interface WidgetDataToggleProperties {
    segmentTitles: string[];
    segmentValues: string[];
    selectedIndex?: number;
    widgetId: string;
}

export interface WidgetDataPickerProperties {
    pickerValues: string[];
    widgetId: string;
}

export interface WidgetDataValues {
    [key: string]: string;
}

type WidgetDataProperties =
    | WidgetDataTextProperties
    | WidgetDataPhotoProperties
    | WidgetDataToggleProperties
    | WidgetDataPickerProperties
    | undefined;

const TOKEN_REGEX = /[$][a-zA-Z0-9_]+/gi;

export class WidgetData {
    static create(model: WidgetDataModel): WidgetData {
        const { properties: modelProperties, type: typeModel } = model;
        const typeModelCleaned = typeModel.replace('_', '');
        const typeParsed: WidgetType | undefined =
            EnumerationHelper.getEnumValueFromKey<WidgetType>(
                WidgetType,
                typeModelCleaned
            );
        const type = typeParsed !== undefined ? typeParsed : WidgetType.Unknown;
        let properties: WidgetDataProperties;

        switch (type) {
            case WidgetType.Label:
            case WidgetType.TextField: {
                const {
                    align: alignModel,
                    placeholder,
                    text,
                    widget_id: widgetId,
                } = modelProperties as WidgetDataModelTextProperties;
                const align = alignModel
                    ? WidgetAlignment[
                        alignModel.toLocaleLowerCase() as keyof typeof WidgetAlignment
                    ]
                    : undefined;

                properties = {
                    align,
                    placeholder,
                    text,
                    widgetId,
                } as WidgetDataTextProperties;
                break;
            }
            case WidgetType.Photo:
            case WidgetType.PhotoTaker: {
                const {
                    height,
                    image: imageUrl,
                    width,
                    widget_id: widgetId,
                } = modelProperties as WidgetDataModelPhotoProperties;

                properties = {
                    height,
                    imageUrl,
                    width,
                    widgetId,
                } as WidgetDataPhotoProperties;
                break;
            }
            case WidgetType.Toggle: {
                const {
                    segment_titles: segmentTitles,
                    segment_values: segmentValues,
                    selected_index: selectedIndexRaw,
                    widget_id: widgetId,
                } = modelProperties as WidgetDataModelToggleProperties;

                const selectedIndexParsed = Number(selectedIndexRaw);
                const selectedIndex = isNaN(selectedIndexParsed)
                    ? -1
                    : selectedIndexParsed;

                properties = {
                    segmentTitles,
                    segmentValues,
                    selectedIndex,
                    widgetId,
                } as WidgetDataToggleProperties;
                break;
            }
            case WidgetType.Picker: {
                const { picker_values: pickerValues, widget_id: widgetId } =
                    modelProperties as WidgetDataModelPickerProperties;

                properties = {
                    pickerValues,
                    widgetId,
                } as WidgetDataPickerProperties;
            }
        }

        return new WidgetData(properties, type);
    }

    public static parseTagsFromString(
        text: string,
        report: WidgetDataValues
    ): string {
        const tokens = text.match(TOKEN_REGEX);
        const elementFoundCount = tokens ? tokens.length : -1;
        if (elementFoundCount < 1 || !tokens) {
            return text;
        }

        // Replace multiple token instances
        let replacedText = text;
        for (let index = 0; index < elementFoundCount; index++) {
            const token = tokens[index] as string;
            let parsedValue = report[token.replace('$', '')];
            if (!parsedValue) {
                parsedValue = '';
            }
            replacedText = replacedText.replace(token, parsedValue).trim();
        }

        return replacedText;
    }

    public static resolveWidgetReferences(
        widget: WidgetData,
        report: WidgetDataValues
    ): void {
        const { type, properties } = widget;

        if (!properties) {
            return;
        }

        switch (type) {
            case WidgetType.Label:
            case WidgetType.TextField: {
                const castedProperties = properties as WidgetDataTextProperties;
                const text = castedProperties.text
                    ? this.parseTagsFromString(castedProperties.text, report)
                    : '';
                castedProperties.text = text;
                break;
            }

            case WidgetType.Photo: {
                const castedProperties =
                    properties as WidgetDataPhotoProperties;
                const url = this.parseTagsFromString(
                    castedProperties.imageUrl,
                    report
                );
                castedProperties.imageUrl = url;
                break;
            }
        }
    }

    constructor(
        public readonly properties: WidgetDataProperties,
        public readonly type: WidgetType
    ) {}
}
