export interface ReportCommentModelValue {
    channels: string[];
    comment: string;
    info: {
        comment_type: string;
        email: string;
        user_id: string;
        username: string;
    }
    photo: string,
    report_id: string;
    timestamp: string;
}

export interface ReportCommentModel {
    id: string;
    value: ReportCommentModelValue;
}

export interface ReportCommentInfo {
    commentType: string;
    email: string;
    userId: string;
    username: string;
}
export class ReportComment {
    static create (model: ReportCommentModel): ReportComment {
        const {
            id,
            value: {
                channels,
                comment,
                info:{
                    comment_type: commentType,
                    email,
                    user_id: userId,
                    username
                },
                photo,
                report_id: reportId,
                timestamp: serializedTimeStamp
            }
        } = model;
        const timeStamp = new Date(serializedTimeStamp);
        
        return new ReportComment(
            id,
            channels,
            comment,
            {
                commentType,
                email,
                userId,
                username
            },
            photo,
            reportId,
            timeStamp
        );
    }
    constructor(
        public readonly id: string,
        public readonly channels: ReadonlyArray<string>,
        public readonly comment: string,
        public readonly info: ReportCommentInfo,
        public readonly photo: string,
        public readonly reportId: string,
        public readonly timeStamp: Date
    ){}
}
