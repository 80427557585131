import { UserTeam } from './user-team';
import{ EntityUser, ObjectAnalytics, UserDefaultFilters } from './user.entity';

export class User {
    static create (userData: EntityUser): User {
        const { 
            agrees,
            agree_enabled: agreeEnabled,
            cc_channels: ccChannels,
            comments_enabled: commentsEnabled,
            num_reports: numberOfReports,
            email, 
            is_verification_needed: isVerificationNeeded,
            teams: userTeams,
            timestamp: timestampString,
            user_id,
            username,
            firstname: firstName,
            lastname: lastName,
            analytics,
            filters
        } = userData;
        const timestamp = new Date(timestampString);
        const teams = UserTeam.createArray(userTeams);
        return new User (
            agrees,
            agreeEnabled,
            ccChannels,
            commentsEnabled,
            numberOfReports,
            email,
            isVerificationNeeded,
            teams,
            user_id,
            username,
            firstName,
            lastName,
            timestamp,
            analytics,
            filters
        );
    }
    constructor (
        public readonly agrees: number,
        public readonly agreeEnabled: boolean,
        public readonly ccChannels: string[],
        public readonly commentsEnabled: boolean,
        public readonly numberOfReports: number,
        public readonly email?: string,
        public readonly isVerificationNeeded?: boolean,
        public readonly teams?: UserTeam[],
        public readonly userId?: string,
        public readonly userName?: string,
        public readonly firstName?: string,
        public readonly lastName?: string,
        public readonly timestamp?: Date,
        public readonly analytics?: ObjectAnalytics,
        public readonly filters?: UserDefaultFilters[]
    ) {}
}
