export interface ScheduleRow {
    [key: string]: string | number;
}

export interface Schedules {
    rows: ScheduleRow[];
    total: number;
}
export interface UploadScheduleDetail {
    moduleGroupId?: string;
    activeDate?: number;
    expireDate?: number;
}

export interface EntitySchedulesTab {
    status: boolean,
    message: string,
    data: Schedules
}

export interface SchedulesJobStatus {
    status: string,
    completion: number,
    result: object
}

export interface ScheduleFilterParameters {
    status?: string;
    secondary_id?: string;
}

export interface ScheduleRoutesFiltersParameters {
    pcn_id?: string;
    folder_id?: string;
}

export class SchedulesTab {
    static create (schedulesData: EntitySchedulesTab): SchedulesTab {
        const {
            status,
            message,
            data: schedules
        } = schedulesData;
        
        return new SchedulesTab(
            status,
            message,
            schedules
        );
    }
    constructor(
        public readonly status: boolean,
        public readonly message: string,
        public readonly schedules: Schedules
    ) {}
}
