import { ThemeProvider } from '@emotion/react';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { Provider } from 'mobx-react';
import { HistoryAdapter } from 'mobx-state-router';
import * as React from 'react';
import { getMUITheme } from './shared/utils';
import { Shell } from './shell';
import { RootStore } from './stores/root.store';
import { history } from './utils/history';
import Maintenance from './Maintenance';
import { SITE_MODE } from './constants';

// Create the rootStore
const rootStore = new RootStore();
rootStore.init();

// Observe history changes
const historyAdapter = new HistoryAdapter(rootStore.routerStore, history);
historyAdapter.observeRouterStateChanges();

// Get MuiTheme
const theme = getMUITheme();

class App extends React.Component {
    public render(): React.ReactNode {
        // Fixes a black band on the bottom page on safari for iOS 15 
        document.body.style.height = '100vh';

        return (
            <>
                {   // CHECK IF SITE MODE IS MAINTENANCE OR LIVE
                    SITE_MODE === 'MAINTENANCE' ? 
                        <Maintenance />
                        :
                        <StyledEngineProvider injectFirst={true}>
                            <MuiThemeProvider theme={theme}>
                                <ThemeProvider theme={theme}>
                                    <Provider rootStore={rootStore}>
                                        <Shell />
                                    </Provider>
                                </ThemeProvider>
                            </MuiThemeProvider>
                        </StyledEngineProvider>
                }
            </>
        );
    }
}

export default App;
