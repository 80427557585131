import * as React from 'react';

export default function Maintenance() {
    return (
        <div className="maintenance-page">
            <img width="300" src="https://cdn.spark.app/media/chrisbrablc/image/crowdcomfort_1260x800.png" alt="CrowdComfort" />
            <h1>We&rsquo;ll be back soon!</h1>
            <div>
                <p>CrowdComfort is currently unavailable between 12 pm and 4 pm EDT on Saturday, July 27, 2024 due to a scheduled maintenance window. </p>
                <p>&mdash; The Team</p>
            </div>
        </div>
    );
}