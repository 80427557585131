import * as React from 'react';

import { Button, Divider, IconButton, Popover, Typography } from '@mui/material';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Calendar from '../../shared/components/icons/calendar';
import {
    ButtonType,
    CCDatePicker,
    CCDatePickerButtonType,
    CCDatePickerDateRange,
} from './cc-date-picker';
import {
    withMediaQuery,
    WithMediaQuery,
} from '../../shared/components/with-media-query';
import { RESPONSIVE_BREAKPOINTS } from '../../shared/utils';
import { isMobile } from '../../shared/utils/browser-check';
import { Close } from '@mui/icons-material';

const styles = (theme: Theme) =>
    createStyles({
        root: {},
        isMobile: {},
        calendarButton: {
            width: '12em',
            height: '3.5em',
            padding: '0.5em',
        },
        calendarButtonLabel: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        popoverRootContainer: {
            '$isMobile &': {
                top: '0!important', // Forced to use 'important' because these two properties
                left: '0!important', // are injected in the style property of the DOM element
                minWidth: '100%',
                minHeight: '100%',
                borderRadius: 0,
            },
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                top: '0!important', // Forced to use 'important' because these two properties
                left: '0!important', // are injected in the style property of the DOM element
                minWidth: '100%',
                minHeight: '100%',
                borderRadius: 0,
            },
        },
        popover: {
            fontSize: '0.8em',
        },
        popoverContainer: {
            position: 'relative',
            paddingTop: '2.5em'
        },
        datePicker: {
            marginRight: '1.8em',
        },
        dayPicker: {
            minHeight: '18em',
            flex: 1,
        },
        calendarIcon: {
            width: '2.24em',
            marginRight: '1em',
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            margin: '1em 0',
            '& Button': {
                fontSize: '1em',
                marginRight: '1em',
            },
        },
        dateRangeTitle: {
            flex: '1 1 0',
            fontSize: '0.9em',
            lineHeight: '1.4',
            textAlign: 'left',
            margin: '0 auto',
        },
        textNoticeContainer: {
            flex: 'auto',
            maxWidth: '32em',
            maxHeight: '2.9em',
            paddingLeft: '1em',
            marginRight: 'auto',
            overflow: 'hidden',
        },
        textNotice: {
            color: 'red',
            fontSize: '1em',
        },
        closeButton: {
            position: 'absolute',
            top: '0',
            right: '0'
        }
    });

interface Props extends WithMediaQuery<WithStyles<typeof styles>> {
    className?: string;
    dateRange?: CCDatePickerDateRange | undefined;
    buttonList?: ButtonType[];
    startDay: string;
    endDay: string;
    onDateRangeChange?: (dateRange: CCDatePickerDateRange) => void;
}

interface States {
    dateRange: CCDatePickerDateRange | undefined;
    isPopupOpen: boolean;
    isCustomOpen: boolean;
}

class CCDatePickerButton extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
        startDay: '00:00',
        endDay: '23:59'
    };

    initialState = {
        dateRange: undefined as CCDatePickerDateRange | undefined,
        isPopupOpen: false,
        isCustomOpen: false
    };

    defaultButtonList = [
        ButtonType.All,
        ButtonType.Today,
        ButtonType.Yesterday,
        ButtonType.Last7Days,
        ButtonType.Last30Days,
        ButtonType.LastYear,
        ButtonType.Custom
    ];

    state = Object.assign({}, this.initialState);

    popupAnchorElement: HTMLElement | null = null;
    lastDateRange = undefined as CCDatePickerDateRange | undefined;

    getTextFromDateRange(dateRange: CCDatePickerDateRange | undefined): string {
        if (dateRange && dateRange.buttonType !== CCDatePickerButtonType.All) {
            switch (dateRange.buttonType) {
                case CCDatePickerButtonType.Last30Days:
                    return 'Last 30 Days';
                case CCDatePickerButtonType.Last7Days:
                    return 'Last 7 Days';
                case CCDatePickerButtonType.LastYear:
                    return 'Last Year';
                case CCDatePickerButtonType.Today:
                    return 'Today';
                case CCDatePickerButtonType.Yesterday:
                    return 'Yesterday';
                case CCDatePickerButtonType.Tomorrow:
                    return 'Tomorrow';
                case CCDatePickerButtonType.None:
                default: {
                    const { from, to } = dateRange;
                    const formattedFromDate = !from
                        ? ''
                        : from.toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        });
                    const formattedToDate = !to
                        ? ''
                        : to.toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        });
                    if (!!from && !!to) {
                        return formattedFromDate === formattedToDate
                            ? formattedFromDate
                            : `${formattedFromDate} - ${formattedToDate}`;
                    }
                }
            }
        }
        
        return 'All Dates';
    }

    onDatePickerButtonClicked = (event: React.MouseEvent<HTMLElement>) => {
        if (!this.popupAnchorElement) {
            this.popupAnchorElement = event.currentTarget;
        }
        this.setState({ isPopupOpen: true });
    };

    onDateRangeChanged = (dateRange: CCDatePickerDateRange) => {
        this.lastDateRange = dateRange;
        if (dateRange.buttonType !== ButtonType.Custom) {
            this.onApplyClicked();
        }
        this.setState({ isCustomOpen: dateRange.buttonType === ButtonType.Custom });
    };

    onClose = () => {
        this.setState({ isPopupOpen: false, isCustomOpen: false });
    };

    onApplyClicked = () => {
        const { onDateRangeChange } = this.props;
        if (!onDateRangeChange) {
            return;
        }

        let dateRange = Object.assign({}, this.lastDateRange);
        if (!dateRange) {
            dateRange = {
                buttonType: CCDatePickerButtonType.All,
            } as CCDatePickerDateRange;
        }
        onDateRangeChange(dateRange);
        this.setState(Object.assign({}, this.initialState));
        this.onClose();
    };

    public render() {
        const {
            className,
            classes,
            dateRange: dateRangeProp,
            buttonList: buttonListProp,
            mediaQuery,
            startDay,
            endDay
        } = this.props;
        const { dateRange: dateRangeState, isPopupOpen, isCustomOpen } = this.state;
        const dateRange = dateRangeState ? dateRangeState : dateRangeProp;
        const buttonList = buttonListProp ? buttonListProp : this.defaultButtonList;
        const rootClasses = `${classes.root}${
            className ? ` ${className}` : ''
        }${isMobile() ? ` ${classes.isMobile}` : ''}`;
        const timezone = Intl.DateTimeFormat('en-US', {
            timeZoneName: 'short',
        }).formatToParts(new Date())[6].value;
        const calendarBtnClasses = {
            label: classes.calendarButtonLabel,
        };

        return (
            <div className={rootClasses}>
                <Button
                    aria-owns={isPopupOpen ? 'date-picker-popper' : undefined}
                    aria-haspopup="true"
                    className={classes.calendarButton}
                    classes={calendarBtnClasses}
                    variant="outlined"
                    color="info"
                    onClick={this.onDatePickerButtonClicked}
                >
                    <Calendar className={classes.calendarIcon} />
                    <span className={classes.dateRangeTitle}>
                        {this.getTextFromDateRange(dateRange)}
                    </span>
                </Button>
                <Popover
                    id="date-picker-popper"
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverRootContainer,
                    }}
                    anchorEl={this.popupAnchorElement}
                    open={isPopupOpen}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.popoverContainer}>
                        <IconButton
                            className={classes.closeButton}
                            onClick={this.onClose}
                        >
                            <Close />
                        </IconButton>
                        <CCDatePicker
                            className={classes.datePicker}
                            classes={{ dayPicker: classes.dayPicker }}
                            dateRange={dateRange}
                            numberOfMonths={mediaQuery ? 1 : 2}
                            buttonList={buttonList}
                            startDay={startDay}
                            endDay={endDay}
                            onDateRangeChange={this.onDateRangeChanged}
                        />
                        {
                            isCustomOpen && 
                                <>
                                    <Divider />
                                    <div className={classes.buttonContainer}>
                                        <div className={classes.textNoticeContainer}>
                                            <Typography
                                                className={classes.textNotice}
                                                component="h1"
                                                variant="h6"
                                            >
                                                Note: The time used in this filter will be based
                                                on your local time zone ({timezone})
                                            </Typography>
                                        </div>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={this.onApplyClicked}
                                        >
                                            Apply
                                        </Button>
                                        <Button
                                            variant="text"
                                            color="secondary"
                                            onClick={this.onClose}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                        }
                    </div>
                </Popover>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(
    withMediaQuery(`(max-width:${RESPONSIVE_BREAKPOINTS.sm}px)`)(
        CCDatePickerButton
    )
);
export { MUIComponent as CCDatePickerButton };
