import {
    WidgetData,
    WidgetDataModel,
    WidgetDataToggleProperties,
    WidgetDataValues,
    WidgetType,
} from './widget-data';

export interface ReportModuleContentModel {
    required_fields: string[];
    response_values: { [key: string]: string };
    widgets: WidgetDataModel[];
}

export class ReportModuleContent {
    static create(model: ReportModuleContentModel): ReportModuleContent {
        const {
            required_fields: requiredFields,
            response_values: responseValues,
            widgets: widgetsModel,
        } = model;
        const widgets = widgetsModel.map((widgetModel: WidgetDataModel) => {
            return WidgetData.create(widgetModel);
        });

        return new ReportModuleContent(requiredFields, responseValues, widgets);
    }
    constructor(
        public readonly requiredFields: string[],
        public readonly responseValues: WidgetDataValues,
        public readonly widgets: WidgetData[]
    ) {
        // Search and fix inconsistencies in the response data model
        widgets.forEach((widget: WidgetData) => {
            const { properties, type } = widget;

            switch (type) {
                case WidgetType.Toggle: {
                    const { selectedIndex, segmentValues, widgetId } =
                        properties as WidgetDataToggleProperties;

                    const defaultValue: string = responseValues[widgetId]
                        ? responseValues[widgetId].trim()
                        : '';
                    const defaultValueIndex: number =
                        segmentValues.indexOf(defaultValue);
                    const defaultValueFromIndex: string = selectedIndex
                        ? segmentValues[selectedIndex]
                        : '';
                    if (defaultValue !== defaultValueFromIndex) {
                        // If the default value is empty and the selected index
                        // is pointing to a default value
                        if (
                            !defaultValue &&
                            selectedIndex !== undefined &&
                            selectedIndex >= 0
                        ) {
                            responseValues[widgetId] = defaultValueFromIndex;
                        } else if (
                            defaultValue &&
                            (selectedIndex === undefined || selectedIndex < 0)
                        ) {
                            // If the default value exists but the selected index doesn't or
                            // it is invalid we regenerate the new index from the default value.

                            // If the default value is among the possible values, we regenerate the index.
                            if (defaultValueIndex >= 0) {
                                (
                                    properties as WidgetDataToggleProperties
                                ).selectedIndex = defaultValueIndex;
                            }
                        } else {
                            // If both values exists but they aren't the same
                            // We override the default value with
                            // the value pointed by the selected index.
                            if (defaultValueIndex >= 0) {
                                responseValues[widgetId] =
                                    defaultValueFromIndex;
                            }
                        }
                    }
                    break;
                }
            }
        });
    }
}
