export interface CommentImagesModel {
    comment_id: string,
    image: string
}
export interface CommentImage {
    commentId: string,
    image: string
}

export interface ReportDetailsImagesModel {
    total_num_images: number,
    report_id: string,
    report_images: string[],
    comment_images: CommentImagesModel[]
}

export class ReportDetailsImages {
    static create (model: ReportDetailsImagesModel): ReportDetailsImages {
        const {
            report_id: reportId,
            report_images: reportImages,
            comment_images
        } = model;
        const commentImages: CommentImage[] = comment_images.map(
            (imageModel: CommentImagesModel) => {
                const {
                    comment_id: commentId,
                    image
                } = imageModel;
                return {commentId, image} as CommentImage; 
            }
        );
        return new ReportDetailsImages(
            reportId,
            reportImages,
            commentImages
        );
    }

    constructor(
        public readonly reportId: string,
        public readonly reportImages: ReadonlyArray<string>,
        public readonly commentImages: ReadonlyArray<CommentImage>
    ) {}
}