export class DateFormatting {
    public static formatMarkerTimestamp (timeStamp: Date): string {
        return Intl.DateTimeFormat('en-US',{
            month:'short',
            day: 'numeric',
            year:'numeric',
            hour:'numeric',
            minute:'numeric',
            timeZoneName: 'short'
        }).format(timeStamp);
    }

    public static differenceInDays(oldDate: Date, currentDate: Date = new Date()): string {
        // To calculate the time difference of two dates 
        const differenceInTime = currentDate.getTime() - oldDate.getTime();
        // To calculate the number of days between two dates
        return (differenceInTime / (1000 * 3600 * 24)).toFixed();
    }
}
