import * as React from 'react';

import { Theme } from '@mui/material/styles';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import memoize from 'memoize-one';
import {
    PagingBuffer,
    PagingBufferPageBoundary as PageBoundary
} from '../../utils/paging-buffer';
import {
    CustomGridList,
    CustomGridListProps,
    CustomGridListRowDescriptor
} from '../custom-grid-list';
import { SimplePagination } from '../simple-pagination';

export interface CustomGridListUtilityFunctions {
    resetGrid: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        minHeight: '38.4em'
    },
    table: {},
    row: {}
});

interface Props extends CustomGridListProps, WithStyles<typeof styles> {
    className?: string;
    gridClassName?: string;
    offset: number;
    rowsPerPage: number;
    totalItems?: number;
    onComponentMount?: (utilities: CustomGridListUtilityFunctions) => void;
    onPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => void;
}

interface States {
    page: number;
}

class CustomGridListPagination extends React.Component<Props, States> {
    public static defaultProps = {
        enableSorting: false,
        isLoading: false,
        showHeaders: true,
        offset: 0,
        customTable: false
    };
    
    getPagingBufferInstance = memoize(
        ( rowsData: CustomGridListRowDescriptor[],
            rowsPerPage: number,
            offset: number
        ): PagingBuffer<CustomGridListRowDescriptor> => {
            const rowsDataBuffer = rowsData;
            return new PagingBuffer(rowsDataBuffer, rowsPerPage, offset, 0);
        }
    );

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            page: 0
        };
    }
    
    componentDidMount() {
        const { onComponentMount } = this.props;
        if (onComponentMount) {
            onComponentMount({
                resetGrid: this.resetGrid
            });
        }
    }

    resetGrid = () => {
        this.setState({
            page: 0
        });
    };

    onPageChanged = (event: React.MouseEvent<HTMLButtonElement> | null, nextPage: number) => {
        const {
            rowsData,
            rowsPerPage,
            offset,
            onPageOverBoundary
        } = this.props;

        const buffer: PagingBuffer<CustomGridListRowDescriptor> =
            this.getPagingBufferInstance(
                rowsData,
                rowsPerPage,
                offset
            );
        // Set the new buffer page
        buffer.page = nextPage;
        
        const {
            isUpperBoundaryPassed,
            isLowerBoundaryPassed
        } = buffer;

        // If we hit the buffer's upper boundary or the buffer's lower boundary
        if (isUpperBoundaryPassed || isLowerBoundaryPassed) {
            if (onPageOverBoundary) {
                onPageOverBoundary(
                    isUpperBoundaryPassed ? PageBoundary.Upper : PageBoundary.Lower,
                    nextPage
                );
            }
        }

        this.setState({page: nextPage});
    };

    public render() {
        const {
            classes,
            className,
            gridClassName,
            isLoading,
            offset,
            totalItems,
            rowsData,
            rowsPerPage,
            ...customGridListProps
        } = this.props;
        const {
            page
        } = this.state;

        const pagingBuffer =
            this.getPagingBufferInstance(
                rowsData,
                rowsPerPage,
                offset
            );
        pagingBuffer.page = page;
        const bufferedListItems: CustomGridListRowDescriptor[] = pagingBuffer.buffer;

        const rootClassNames = classes.root + (className ? ` ${className}` : '');
        
        return (
            <div className={rootClassNames} >
                <CustomGridList { ...customGridListProps }
                    classes={{
                        table: classes.table,
                        row: classes.row
                    }}
                    className={gridClassName} /* overrides the class name present in the props */
                    isLoading={isLoading}
                    rowsData={bufferedListItems}
                />
                <SimplePagination
                    count={totalItems ? totalItems : rowsData.length}
                    isLoading={isLoading}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={this.onPageChanged}
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(CustomGridListPagination);
export { MUIComponent as CustomGridListPagination };
export { PageBoundary };
