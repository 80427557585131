import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
import {
    NodesTreeView,
    RenderTree
} from '../../shared/components/node-tree';
import RemoveIcon from '@mui/icons-material/Remove';
import { EntityFolder } from '../../shared/domain';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    treeView: {
        '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
            background: 'rgba(54, 159, 198, 0.3)'
        }
    },
    folderItem: {
        transform: 'scale(0.5)'
    }
});

interface Props {
    folderTreeData?: RenderTree;
    foldersClicked?: string[];
    foldersExpanded?: string[];
    isLoading: boolean;
    onFolderTreeClick?: (folderId: string) => Promise<RenderTree[]>;
    onSelectFolder?: (selectedFolder: EntityFolder) => void;
    onFolderTreeToggle?: (folderIds: string[]) => void;
}


class FolderTree extends React.Component<Props & WithStyles<typeof styles>> {
    public static defaultProps = {};

    updateNode = (parent: RenderTree, nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void): void => {
        const {
            onFolderTreeClick,
            onFolderTreeToggle,
            onSelectFolder,
            foldersClicked,
            foldersExpanded,
            folderTreeData
        } = this.props;

        if (folderTreeData) {
            const isFirstChild = !foldersClicked?.length;
            const foldersClickedList = foldersClicked ? [...foldersClicked, folderTreeData.id] : [folderTreeData.id];
            const foldersExpandedList = foldersExpanded?.length ? foldersExpanded : foldersClickedList;
            // Check if node is floorplan by prefix
            const floorplanPrefix = 'floorplan::';
            if (onFolderTreeClick && onFolderTreeToggle && onSelectFolder) {
                // Check if is the first child to update the parent directly
                if (isFirstChild) {
                    onFolderTreeClick(nodeId).then((result) => {
                        parent.children = result;
                        // Update and expand the new folders list
                        const newExpandedFolderList = [...foldersExpandedList];
                        updateCallback(newExpandedFolderList);
                        onFolderTreeToggle(newExpandedFolderList);
                    });
                } else {
                    parent.children?.map((item: RenderTree) => {
                        // Loop into list and update the children that match
                        if (item.id === nodeId) {
                            onFolderTreeClick(nodeId).then((result) => {
                                // Get floorplans from folder
                                const floorplans = result.filter((item) => {
                                    const { id } = item;
                                    return id.includes(floorplanPrefix);
                                });
                                // Check if folder has floorplans
                                if (floorplans.length) {
                                    // Trigger and send the selected folder
                                    const selectedFolder = {
                                        folder_id: nodeId,
                                        folder_name: nodeLabel
                                    };

                                    onSelectFolder(selectedFolder);
                                } else {
                                    // Update the children and expand the new folders list
                                    item.children = result;
                                    const newExpandedFolderList = [...foldersExpandedList, nodeId];
                                    updateCallback(newExpandedFolderList);
                                    onFolderTreeToggle(newExpandedFolderList);
                                }
                            });
                        // eslint-disable-next-line no-prototype-builtins
                        } else if (item.hasOwnProperty('children')) {
                            // If no matches recursive into next children
                            this.updateNode(item, nodeId, nodeLabel, updateCallback);
                        }
                    });
                }
            }
        }
    };

    public render() {
        const {
            classes,
            isLoading,
            folderTreeData,
            foldersExpanded,
            onFolderTreeToggle
        } = this.props;


        return (
            <>
                {folderTreeData ?
                    <NodesTreeView
                        className={classes.treeView}
                        treeData={folderTreeData}
                        onNodeSelected={this.updateNode}
                        isLoading={isLoading}
                        labelIcon={<RemoveIcon className={classes.folderItem}/>}
                        nodesExpanded={foldersExpanded}
                        onNodeToggled={onFolderTreeToggle}
                    />
                    :
                    'WARNING: There is no folders associated with this account'
                }
            </>
        );
    }
}

const MUIComponent = withStyles(styles)(FolderTree);
export {MUIComponent as FolderTree};
