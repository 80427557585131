import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Build } from '@mui/icons-material';
import memoize from 'memoize-one';
import * as React from 'react';
import {
    ReportModuleGroup 
} from '../../../models/report';
import Thermometer from '../../../shared/components/icons/thermometer';
import { SimpleList, SimpleListItem } from '../../../shared/components/simple-list';

const styles = (theme: Theme) => createStyles({
    wrenchIcon: {
        width: '0.7em',
        color: theme.ccPalette.cc_colors.solid.ccRightsilhouette.main
    },
    thermometerIcon: {
        width: '0.7em',
        fill: theme.ccPalette.cc_colors.solid.ccRightsilhouette.main,
        margin: '0 0.25em'
    },
    paper: {}
});

interface Props {
    reportModuleGroups: ReportModuleGroup[];
    selectedReportModuleGroupId: string; 
    onReportModuleGroupSelect?: (reportModule: ReportModuleGroup) => void;
}

class AddReportWizardStepReportType extends React.Component<Props & WithStyles<typeof styles>> {
    public static defaultProps = {
    };

    createModulesList = memoize((reportModules: ReportModuleGroup[]) : SimpleListItem[] => {
        const { classes } = this.props;
        const items = reportModules
            .filter((moduleGroup: ReportModuleGroup) => {
                return moduleGroup.groupType === 'atomic';
            }) // Filters the element that aren't atomic (e.g. inspections)
            .map((moduleGroup: ReportModuleGroup) => {
                const {
                    id,
                    prompt
                } = moduleGroup;

                let icon: React.ReactElement<unknown>;
                switch(id.toLocaleLowerCase()) {
                    case 'thermal':
                        icon = <Thermometer className={classes.thermometerIcon}/>;
                        break;
                    default:
                        icon = <Build className={classes.wrenchIcon}/>;
                        break;
                }

                return new SimpleListItem(id, prompt ? prompt : 'N/A', '', icon);
            });

        return items;
    });

    onItemClicked = (listItem: SimpleListItem) => {
        const { onReportModuleGroupSelect } = this.props;
        if (!onReportModuleGroupSelect) {
            return;
        }
        const { reportModuleGroups } = this.props;
        const { id: moduleId } = listItem;
        const reportModule: ReportModuleGroup | undefined = reportModuleGroups.find((module: ReportModuleGroup) => {
            return module.id === moduleId;
        });

        if (reportModule) {
            onReportModuleGroupSelect(reportModule);
        }
    };
    
    public render() {
        const {
            classes,
            reportModuleGroups,
            selectedReportModuleGroupId
        } = this.props;
        const items = this.createModulesList(reportModuleGroups);

        return (
            <SimpleList
                classes={{
                    paper: classes.paper
                }}
                items={items}
                onListItemClick={this.onItemClicked}
                keepItemSelected={true}
                selectedItemId={selectedReportModuleGroupId}
            />
        );
    }
}

const MUIComponent = withStyles(styles)(AddReportWizardStepReportType);
export { MUIComponent as AddReportWizardStepReportType };
export type AddReportWizardStepReportTypeProps = Props;
