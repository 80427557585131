import { Typography } from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
import {
    WidgetContentGenerator,
    WidgetContentGeneratorType
} from '../../../components/widget-content-generator';
import {
    ReportModule,
    WidgetData,
    WidgetDataValues
} from '../../../models/report';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        flex: 1,
        minHeight: 0,
        alignContent: 'stretch'
    },
    title: {
        fontSize: '1.3em',
        alignSelf: 'center'
    },
    widgetLabel: {
        alignSelf: 'center',
        marginTop: '1.5em'
    }
});

interface Props {
    reportModule?: ReportModule;
    onValidationChange?: (isValid: boolean) => void;
    onWidgetChange?: (widgetValues: WidgetDataValues) => void;
}


interface States {
    requiredFields: string[];
    responseValues: WidgetDataValues;
    widgets: WidgetData[];
}

class AddReportWizardStepReportDetails extends React.Component<Props & WithStyles<typeof styles>, States> {
    public static defaultProps = {
    };

    state = {
        requiredFields: [] as string[],
        responseValues: {} as WidgetDataValues,
        widgets: [] as WidgetData[]
    };

    constructor(props: Readonly<Props & WithStyles<typeof styles>>) {
        super(props);

        const { reportModule } = this.props;
        if (!reportModule) {
            return;
        }

        const {
            reportContent: {
                requiredFields,
                responseValues,
                widgets
            }
        } = reportModule;

        this.state = {requiredFields, responseValues, widgets};
    }

    onWidgetChanged = (widgetId: string, value: string) => {
        const { responseValues } = this.state;
        const oldValue = responseValues[widgetId];

        if (oldValue === value) {
            return;
        }

        const newValues = Object.assign({}, responseValues);
        newValues[widgetId] = value;
        this.setState({responseValues: newValues});

        const { onWidgetChange } = this.props;
        if (onWidgetChange) {
            onWidgetChange(newValues);
        }
    };

    public render() {
        const {
            classes,
            reportModule,
            onValidationChange
        } = this.props;

        if (!reportModule) {
            return '';
        }

        const {
            title
        } = reportModule;
        const {
            requiredFields,
            responseValues,
            widgets
        } = this.state;

        return (
            <div className={classes.root}>
                <Typography
                    className={classes.title}
                    variant="body1"
                >
                    {title}
                </Typography>
                <WidgetContentGenerator
                    classes={{
                        widgetLabel: classes.widgetLabel
                    }}
                    generatorType={WidgetContentGeneratorType.Write}
                    mandatoryFields={requiredFields}
                    values={responseValues}
                    widgets={widgets}
                    onValidationChange={onValidationChange}
                    onWidgetChange={this.onWidgetChanged}
                />
            </div>            
        );
    }
}

const MUIComponent = withStyles(styles)(AddReportWizardStepReportDetails);
export { MUIComponent as AddReportWizardStepReportDetails };
export type AddReportWizardStepReportDetailsProps = Props;
