import * as React from 'react';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { IconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';

const styles = (theme: Theme) => createStyles({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5),
        [theme.breakpoints.only('xs')]: {
            padding: '0 1em 0 0',
            marginLeft: '0.2em'
        }
    },
    paginationButton: {
        marginLeft: '0.2em',
        padding: '0',
        transform: 'scale(1)',
        [theme.breakpoints.only('xs')]: {
            marginLeft: '1em',
            transform: 'scale(1.8)'
        }
    }
});

export interface Props extends Omit<TablePaginationActionsProps, 'classes'>, WithStyles<typeof styles> {
    count: number;
    disabled?: boolean;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void,
    page: number;
    rowsPerPage: number;
    theme: Theme;
}

const SimplePaginationActions: React.FC<Props & TablePaginationActionsProps>  = (props: Props & TablePaginationActionsProps) => {
    const { 
        classes,
        count,
        disabled,
        page,
        rowsPerPage,
        theme
    } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onPageChange(event, props.page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>)  => {
        props.onPageChange(event, props.page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>)  => {
        props.onPageChange(
            event,
            Math.max(0, Math.ceil(count / rowsPerPage) - 1),
        );
    };

    return (
        <div className={classes.root}>
            <IconButton
                className={classes.paginationButton}
                onClick={handleFirstPageButtonClick}
                disabled={disabled || (page === 0)}
                aria-label="First Page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                className={classes.paginationButton}
                onClick={handleBackButtonClick}
                disabled={disabled || (page === 0)}
                aria-label="Previous Page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                className={classes.paginationButton}
                onClick={handleNextButtonClick}
                disabled={disabled || (page >= Math.ceil(count / rowsPerPage) - 1)}
                aria-label="Next Page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                className={classes.paginationButton}
                onClick={handleLastPageButtonClick}
                disabled={disabled || (page >= Math.ceil(count / rowsPerPage) - 1)}
                aria-label="Last Page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
};

SimplePaginationActions.defaultProps = {
    disabled: false
};

const MUIComponent = withStyles(styles, { withTheme: true })(SimplePaginationActions);
export {MUIComponent as SimplePaginationActions};
