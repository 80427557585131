import { AxiosResponse } from 'axios';
import Bugsnag from '@bugsnag/js';
import { CC_APP_TYPE } from '../constants';
import {
    AuthenticationType, 
    LoginAuthorization
} from '../models/login-authorization';
import { RegisteredUser } from '../models/registered-user';
import { HttpClient } from '../shared/utils';
import { LoginAuthorizationAdapter } from './login-authorization-adapter';
// Latest repo npm updates broke getting buffer from webpack
// So we use the browser package
import { Buffer } from 'buffer';

interface RequestHeaders {
    userId: string,
    authToken?: string,
    Authorization?: string
}

let authorization: LoginAuthorization | undefined = undefined as LoginAuthorization | undefined;
let registeredUser: RegisteredUser | undefined = undefined as RegisteredUser | undefined;
let ssoTimeout = 0;

export class HttpConnectorAdapter {
    static Utilities = HttpClient.Utilities;
    static onSSORefreshToken?: (registeredUser: RegisteredUser) => void = undefined;

    static async get(url: string, params?: unknown, headers?: unknown, isBinary?: boolean): Promise<AxiosResponse> {
        let result;
        try {
            result = await this.CheckSSORefreshToken();
        } catch(e) {
            result = undefined;
        }

        if (!result) {
            return Promise.reject({error: -1, errorMessage: 'Refresh token expired'});
        }

        const httpHeaders = this.getRequestHeaders();
        const mergedHeaders = this.mergeHeaders(headers, httpHeaders);
        const response = await HttpClient.get(url, params, mergedHeaders, isBinary);
        return response;
    }

    static async post(url: string, params?: unknown, headers?: unknown): Promise<AxiosResponse> {
        const result = await this.CheckSSORefreshToken();

        if (!result) {
            return Promise.reject();
        }

        const httpHeaders = this.getRequestHeaders();
        const mergedHeaders = this.mergeHeaders(headers, httpHeaders);
        const response: AxiosResponse = await HttpClient.post(url, params, mergedHeaders);
        return response;
    }

    static async put(url: string, params?: unknown, headers?: unknown): Promise<AxiosResponse> {
        const result = await this.CheckSSORefreshToken();

        if (!result) {
            return Promise.reject();
        }

        const httpHeaders = this.getRequestHeaders();
        const mergedHeaders = this.mergeHeaders(headers, httpHeaders);
        const response: AxiosResponse = await HttpClient.put(url, params, mergedHeaders);
        return response;
    }

    static async patch(url: string, params?: unknown, headers?: unknown): Promise<AxiosResponse> {
        const result = await this.CheckSSORefreshToken();

        if (!result) {
            return Promise.reject();
        }

        const httpHeaders = this.getRequestHeaders();
        const mergedHeaders = this.mergeHeaders(headers, httpHeaders);
        const response: AxiosResponse = await HttpClient.patch(url, params, mergedHeaders);
        return response;
    }

    static async delete(url: string, params?: unknown, headers?: unknown): Promise<AxiosResponse> {
        const result = await this.CheckSSORefreshToken();
        if (!result) {
            return Promise.reject();
        }

        const httpHeaders = this.getRequestHeaders();
        const mergedHeaders = this.mergeHeaders(headers, httpHeaders);
        const response: AxiosResponse = await HttpClient.delete(url, params, mergedHeaders);
        return response;
    }

    static async getBase64Image(url: string, params?: unknown, headers?: unknown): Promise<AxiosResponse> {
        const response = await this.get(url, params, headers, true);
        const {
            data,
            headers: responseHeaders
        } = response;
        // Encodes the data retrieved as a binary array and
        // converts it to a base64 html image string
        const { 'content-type': contentTypeRaw }: { 'content-type': string } = responseHeaders;
        const contentType = contentTypeRaw ? contentTypeRaw.toLowerCase() : '';
        if (((typeof data) === 'object') && (contentType.indexOf('image/') >= 0)) {
            // Performs the base64 encoding
            const base64Data = Buffer.from(response.data, 'binary').toString('base64');
            // Takes the content type and adds it to the base64 encoding header
            response.data = `data:${contentType.replace('jpg','jpeg')};base64,${base64Data}`;
        }
        return response;
    }

    static setAuthorization(newAuthorization: LoginAuthorization | undefined): void {
        authorization = newAuthorization;
    }

    static setRegisteredUser(newRegisteredUser: RegisteredUser | undefined): void {
        registeredUser = newRegisteredUser;
        if (!registeredUser) {
            return;
        }

        if (registeredUser.valid && registeredUser.isSSO) {
            const expiration = registeredUser.expiration;
            if (expiration) {
                this.setSSOTimeout(expiration.getTime());
            }
        }
    }

    static setSSOTimeout(timeout: number): void {
        ssoTimeout = timeout;
    }

    static isSSOTimeoutExpired(): boolean {
        if (!registeredUser ||
            !(registeredUser.valid) ||
            !(registeredUser.isSSO)
        ) {
            return false;
        }

        if (ssoTimeout <= 0) {
            return true;
        }

        return ssoTimeout <= Date.now();
    }

    //#region Protected Methods
    
    //#region mergeHeaders
    protected static mergeHeaders(headersA?: unknown ,headersB?: unknown ): unknown {
        let mergedHeaders = headersA;
        if (headersB) {
            // If both headersA and headersB are not null
            // it performs a merge
            if (headersA) {
                mergedHeaders = Object.assign({}, headersA, headersB);
            } else {
                mergedHeaders = headersB;
            }
        }

        return mergedHeaders;
    }
    //#endregion

    //#region getRequestHeaders
    protected static getRequestHeaders() : RequestHeaders | undefined {
        if (!registeredUser || !registeredUser.cookie) {
            return undefined;
        }

        const {
            cookie,
            isSSO,
            userId,
            accountId
        } = registeredUser;

        let headers = {
            userId
        };

        if (isSSO) {
            const { Authorization } = cookie;
            headers = Object.assign({}, headers, {
                Authorization,
                cctype: CC_APP_TYPE,
                accountId
            });
        } else {
            const { SyncGatewaySession: authToken } = cookie;
            headers = Object.assign({}, headers, {
                authToken
            });
        }
        return headers;
    }
    //#endregion

    //#region CheckSSORefreshToken
    protected static async CheckSSORefreshToken(): Promise<boolean> {
        if (!authorization || !registeredUser || !this.isSSOTimeoutExpired()) {
            return true;
        }

        const {
            authenticationType,
            validationId
        } = authorization;

        const isSSO = (authenticationType === AuthenticationType.SSO);
        // If the user has SSO authentication, check the token refresh
        if (validationId && isSSO) {
            const loginAuthorizationAdapter = LoginAuthorizationAdapter.getAdapter();
            try {
                const response = await loginAuthorizationAdapter.refreshSSOToken(validationId);
                const {
                    expiration: expirationStringUTC,
                    access_token: accessToken,
                    id_token: idToken
                } = response;
                
                if (!idToken) {
                    registeredUser.updateAccessToken(accessToken);
                } else {
                    registeredUser.updateAccessAndIdToken(accessToken, idToken);
                }

                registeredUser.expiration = expirationStringUTC ? new Date(expirationStringUTC) : undefined;
                if (this.onSSORefreshToken) {
                    this.onSSORefreshToken(registeredUser);
                }

                // Get the expiration converted to local time
                const expiration = registeredUser.expiration;
                if (expiration) {
                    this.setSSOTimeout(expiration.getTime());
                }
            }
            catch (error) {
                Bugsnag.notify((new Error('CheckSSORefreshToken - Refresh token has an error')), (event) => {
                    event.severity = 'error';
                    event.context = 'SSO Refresh Token Actions';
                    event.addMetadata('sso_check_refresh_token', {
                        validation_id: validationId,
                        registered_user: registeredUser,
                        error: error
                    });
                });

                return false;
            }
        }

        return true;
    }
    //#endregion

    //#endregion
}