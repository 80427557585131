import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
    inject,
    observer
} from 'mobx-react';
import * as React from 'react';
import { RootStore } from '../../stores/root.store';
import { DataGridList } from '../../shared/components/data-grid-list';
import { 
    GridColDef,
    GridPaginationModel,
    GridRowsProp,
    GridValidRowModel
} from '@mui/x-data-grid';
import memoize from 'memoize-one';
import { SideNavbar } from '../../shared/components/side-navbar';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import ArticleIcon from '@mui/icons-material/Article';
import {
    Autocomplete,
    Button,
    Chip,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from '@mui/material';
import { CSVButton } from '../../shared/components/csv-button';
import { SimpleModal } from '../../shared/components/simple-modal';
import { Close } from '@mui/icons-material';
import { CCDateTimePicker } from '../../components/cc-date-time-picker';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { CCSnackbar, CCSnackbarVariant } from '../../shared/components/cc-snackbar';
import { SimpleDialog } from '../../shared/components/simple-dialog';
import { FORMAT_DATETIME, FORMAT_DATE, TIMEZONE_MAP } from '../../constants';
import { MouduleGroup, ScheduleFilterParameters } from '../../shared/domain';
import { DropdownChips, TypeSelectorData } from '../../components/dropdown-chips';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import { CCSingleDatePicker } from '../../components/cc-single-date-picker';
import { FolderFilterValues } from '../../shared/domain/completion';
import { CCSpinner } from '../../shared/components/cc-spinner';

const UPLOAD_SCHEDULE_COMPLETE = 'Upload Schedule is complete';
const NO_EXPIRATION = 'No expiration';
const JOB_POOLING_TIMER = 1000;
dayjs.extend(timezone);
dayjs.extend(utc);
interface DaysOfWeek {
    [key: string]: string;
  }
const daysOfWeek: DaysOfWeek = {
    mo: 'Monday',
    tu: 'Tuesday',
    we: 'Wednesday',
    th: 'Thursday',
    fr: 'Friday',
    sa: 'Saturday',
    su: 'Sunday'
};

const styles = (theme: Theme) => createStyles({
    divider: {
        margin: '1.6em 0 1.55em 0',
    },
    contentContainer: {
        '&, &$navbarContent': {
            padding: '1.45em',
        },
        [theme.breakpoints.between(
            'xs',
            theme.breakpoints.values.iPhone8_Landscape
        )]: {
            padding: '0',
        },
    },
    dataGridContainer: {
        padding: '2em',
        width: 'calc(100% - 4em)',
        height: 'calc(100vh - 6.3em)',
        '&.navbar-opened': {
            width: 'calc(100% - 20.2em)',
            transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.standard,
            })
        },
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
            '& .MuiDataGrid-cell:focus': {
                outline: '0'
            }
        },
        '& .schedule-first-column': {
            color: theme.palette.secondary.main,
            fontWeight: 'bold'
        }
    },
    errorContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        margin: '0 2em'
    },
    errorMessage: {
        width: '100%',
        color: 'red',
        fontWeight: 400,
        textAlign: 'center',
        margin: '1em'
    },
    navbarIconContainer: {
        padding: '0.3em 0.4em',
        backgroundColor: theme.palette.secondary.main,
    },
    navbarIconContainerSmall: {
        transform: 'scale(0.7)',
        margin: '0.7em',
    },
    navbarIcon: {
        color: 'white',
    },
    navbarContent: {
        display: 'flex',
        flex: '1 0 0',
        flexDirection: 'column',
        width: '20em',
    },
    navbarTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    navbarTitle: {
        fontWeight: 'bold',
        marginLeft: '0.4em',
    },
    uploadButton: {
        width: '100%',
        height: '4em',
        '& .MuiSvgIcon-root': {
            marginRight: '0.2em',
            transform: 'scale(0.9)'
        }
    },
    uploadProgressContainer: {
        padding: '1em 0',
        opacity: '0',
        '&.PENDING': {
            opacity: '1',
            '& .uploadProgressPercentage': {
                color: theme.palette.secondary.main
            }
        },
        '&.RUNNING': {
            opacity: '1',
            '& .uploadProgressPercentage': {
                color: theme.palette.secondary.main
            }
        },
        '&.ERROR': {
            opacity: '0',
            transition: theme.transitions.create(['opacity'], {
                easing: theme.transitions.easing.easeIn,
                duration: '4s',
            }),
            '& .uploadProgressPercentage': {
                color: theme.palette.error.main
            }
        },
        '&.COMPLETE': {
            opacity: '0',
            transition: theme.transitions.create(['opacity'], {
                easing: theme.transitions.easing.easeIn,
                duration: '4s',
            }),
            '& .uploadProgressPercentage': {
                color: theme.palette.primary.main
            }
        }
    },
    uploadProgressContainerTitle: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    uploadProgressTitle: {
        fontWeight: 'bold'
    },
    modalContainer: {
        padding: '2em',
        maxWidth: '55em',
        width: '100%'
    },
    modalHeaderContainer: {
        position: 'relative',
        padding: '8px'
    },
    modalTitle: {
        fontSize: '2em',
        fontWeight: 'bold',
        marginBottom: '0.5em'
    },
    navbarComponentTitle: {
        color: theme.palette.grey[500],
        fontSize: '0.9em',
        fontWeight: 600,
        marginBottom: '0.3em',
    },
    modalCloseButton: {
        position: 'absolute',
        right: '0',
        top: '0'
    },
    modalSectionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    modalBodyList: {
        padding: '0',
        listStyle: 'none',
        minWidth: '14em',
        '& li': {
            padding: '0.9em 0'
        },
        '& span': {
            fontWeight: 'bold',
            marginRight: '0.5em'
        }
    },
    bodyLeftColumn: {
        width: '32em'
    },
    headerColumn: {
        padding: '0.3em 0',
        width: '22em'
    },
    fileUploadModal: {
        padding: '2em',
        width: '39em'
    },
    fileUploadIntro: {
        '& span': {
            fontStyle: 'italic',
            color: theme.ccPalette.disabled.main
        }
    },
    moduleGroupField: {
        width: '100%',
        minWidth: '14.3em'
    },
    snackbar: {
        top: '7em',
    },
    fileUploadBtn: {
        marginTop: '1em'
    },
    chipContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1.6em',
        minHeight: '8.5em',
    },
    chip: {},
    navbarInput: {
        width: '100%',
        paddingLeft: '0.4em',
    },
    navbarPlaceholder: {
        fontSize: '0.9em',
    },
    filterByDateContainer: {
        position: 'relative',
        top: '1em'
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1.6em',
    },
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
}

interface ScheduleDetail {
    location: string;
    id: string;
    status: string;
    activeDate: string;
    expireDate: string;
    ingestionDate: string;
    name: string;
    address: string;
    zipcode: string;
    rsf: string;
    city: string;
    country: string;
    state: string;
    consolidatedType: string;
    stratification: string;
    cleanable: string;
    cleaningContract: string;
    folder: string;
    moduleGroup: string;
    cleanings: string;
    frequency: string;
}

interface GridRowDic {
    [key: string]: string | number;
}

interface KeyMapping {
    [key: string]: string
}

interface LinearColorMapping {
    RUNNING: string;
    PENDING: string;
    COMPLETE: string;
    ERROR: string;
}

interface SchedulingDic {
    portal3_tab: {
        summary_properties: string[];
        summary_columns_header_name: KeyMapping;
        schedule_status_enumeration: string[];
    };
}

interface States {
    errorMessage: string;
    rows: GridRowsProp;
    totalRows: number;
    paginationModel: {
        page: number;
        pageSize: number
    };
    isSchedulesDrawerOpen: boolean;
    uploadProgress: number;
    uploadProgressStatus: '' | keyof LinearColorMapping;
    uploadFilename: string;
    isScheduleDetailOpen: boolean;
    isUploadScheduleOpen: boolean;
    scheduleDetail: ScheduleDetail;
    disableExpireDate: boolean;
    uploadModuleGroup: string;
    uploadActiveDate: Date | undefined;
    uploadExpireDate: Date | undefined;
    snackbarVariant: CCSnackbarVariant;
    snackbarMessage: string;
    snackbarOpen: boolean;
    isUploadScheduleContinue: boolean;
    dialogOpen: boolean;
    dialogTitle: string;
    dialogMessage: string;
    summaryProperties: string[];
    summaryColumnsHeaderName: KeyMapping;
    scheduleStatusEnumeration: string[];
    statusSelectedIds: string[];
    propertyIdToFilter: string;
    moduleGroups: MouduleGroup[];
    filterByDate: Date | string | undefined;
    folderFilters: FolderFilterValues[];
    folderToFilter: FolderFilterValues | undefined;
    isFolderFilterLoading: boolean;
}

const emptySchedule: ScheduleDetail = {
    location: '',
    id: '',
    status: '',
    activeDate: '',
    expireDate: '',
    ingestionDate: '',
    name: '',
    address: '',
    zipcode: '',
    rsf: '',
    city: '',
    country: '',
    state: '',
    consolidatedType: '',
    stratification: '',
    cleanable: '',
    cleaningContract: '',
    folder: '',
    moduleGroup: '',
    cleanings: '',
    frequency: ''
};

const linearProgressColorMapping: LinearColorMapping = {
    RUNNING: 'secondary',
    PENDING: 'secondary',
    COMPLETE: 'primary',
    ERROR: 'error'
};

@inject('rootStore')
@observer
class SchedulesTab extends React.Component<Props, States> {
    public static defaultProps = {
    };
    
    state = {
        errorMessage: '',
        rows: [],
        totalRows: 0,
        paginationModel: {
            page: 0,
            pageSize: 10
        },
        isSchedulesDrawerOpen: false,
        uploadProgress: 0,
        uploadProgressStatus: '' as '' | keyof LinearColorMapping,
        uploadFilename: '',
        isScheduleDetailOpen: false,
        isUploadScheduleOpen: false,
        scheduleDetail: emptySchedule,
        disableExpireDate: true,
        uploadModuleGroup: '',
        uploadActiveDate: undefined,
        uploadExpireDate: undefined,
        snackbarVariant: 'info' as CCSnackbarVariant,
        snackbarMessage: '',
        snackbarOpen: false,
        isUploadScheduleContinue: false,
        dialogOpen: false,
        dialogTitle: '',
        dialogMessage: '',
        summaryProperties: [],
        summaryColumnsHeaderName: {},
        scheduleStatusEnumeration: [],
        statusSelectedIds: [],
        propertyIdToFilter: '',
        moduleGroups: [],
        filterByDate: undefined,
        folderFilters: [] as FolderFilterValues[],
        folderToFilter: undefined,
        isFolderFilterLoading: true
    };

    fileUploadRef?: HTMLInputElement;
    csvFile?: File;
    currentTimezone = dayjs.tz.guess();
    filterStatuses = [] as TypeSelectorData[];

    componentDidMount(): void {
        const {
            paginationModel: {
                page,
                pageSize
            }
        } = this.state;

        const {
            rootStore: {
                managerStore: {
                    currentAccount,
                    scheduleFilters
                }
            }
        } = this.props;

        this.setState({
            filterByDate: scheduleFilters.filterByDate,
            statusSelectedIds: scheduleFilters.statusSelectedIds,
            folderToFilter: scheduleFilters.folderToFilter,
            propertyIdToFilter: scheduleFilters.propertyIdToFilter
        }, () => {
            this.refreshGrid(page, pageSize);
        });
        const scheduling = currentAccount?.scheduling as SchedulingDic | undefined;
        const portal3Tab = scheduling?.portal3_tab;
        this.fetchFolderFilterValues();
        if (portal3Tab) {
            const scheduleStatusEnumeration = portal3Tab.schedule_status_enumeration;
            const moduleGroups = currentAccount?.moduleGroups;
            this.filterStatuses = scheduleStatusEnumeration.map((value: string, key: number) => {
                return {
                    id: `${key}`,
                    name: value
                } as TypeSelectorData;
            });

            this.setState({
                summaryProperties: portal3Tab.summary_properties,
                summaryColumnsHeaderName: portal3Tab.summary_columns_header_name,
                scheduleStatusEnumeration: scheduleStatusEnumeration,
                moduleGroups: moduleGroups ? moduleGroups : [],
                uploadModuleGroup: moduleGroups && moduleGroups.length ? moduleGroups[0].id : ''
            });
        }
    }

    componentWillUnmount(): void {
        const {
            filterByDate,
            statusSelectedIds,
            folderToFilter,
            propertyIdToFilter
        } = this.state;

        const {
            rootStore: {
                managerStore
            }
        } = this.props;

        managerStore.setScheduleFilters({
            filterByDate,
            statusSelectedIds: [...statusSelectedIds],
            folderToFilter,
            propertyIdToFilter
        });
    }

    refreshGrid = (page: number, pageSize: number) => {
        const {
            rootStore: {
                managerStore: {
                    getSchedules
                }
            }
        } = this.props;

        const {
            statusSelectedIds,
            propertyIdToFilter,
            filterByDate,
            folderToFilter
        } = this.state;
        const filters: {[key: string]: string} = {};

        if (statusSelectedIds.length) {
            filters['status'] = statusSelectedIds.toString();
        }

        if (propertyIdToFilter !== '') {
            filters['secondary_id'] = propertyIdToFilter;
        }

        if (filterByDate) {
            filters['date'] = `${dayjs(filterByDate).unix()}`;
        }

        if (folderToFilter) {
            filters['folder_id'] = folderToFilter['folder_id'];
        }

        getSchedules(pageSize, page * pageSize, filters as unknown as ScheduleFilterParameters).then((schedules) => {
            const { rows, total } = schedules;
            const schedulesRows = this.createRows(rows);
            this.setState({
                rows: schedulesRows,
                totalRows: total
            });
        }).catch((errorMessage) => {
            this.showError(errorMessage);
        });
    };

    getFileUploadRef = (element: HTMLInputElement) => {
        this.fileUploadRef = element;
    };

    fetchFolderFilterValues = () => {
        const {
            rootStore: {
                managerStore: {
                    getFolderFiltersValues
                }
            }
        } = this.props;

        getFolderFiltersValues('scheduling').then((values) => {
            this.setState({ folderFilters: values, isFolderFilterLoading: false });
        }).catch((errorMessage: string | undefined) => {
            this.showError(errorMessage);
        });
    };

    createColumns = memoize((summaryProperties: string[], summaryColumnsHeaderName: KeyMapping): Array<GridColDef<GridValidRowModel>> => {
        const columnsWidth: {
            [key: string]: number;
        } = {
            'Property Zip Code': 105,
            'State': 60
        };

        const columns = summaryProperties.map((item) => {
            const column : {
                field: string;
                headerName: string;
                menu: boolean;
                flex: number;
                minWidth: number;
                cellClassName?: string; 
            } = {
                field: item,
                headerName: (item in summaryColumnsHeaderName) ? summaryColumnsHeaderName[item] : item,
                menu: false,
                flex: 1,
                minWidth: item in columnsWidth ? columnsWidth[item] : 150
            };

            return column;
        });

        if (columns.length) {
            columns[0].cellClassName = 'schedule-first-column';
        }

        return columns;
    });

    createRows = (schedules: GridRowDic[]): GridRowsProp => {
        const { scheduleStatusEnumeration } = this.state;
        const rows = schedules.map((row: GridRowDic, index) => {
            row.scheduleId = row.id;
            row.id = index;
            row.status = scheduleStatusEnumeration[row.status as number];

            const timezone = `${row.timezone}`.replace(' ', '_');
            row.timezone = timezone;

            return row;
        });

        return rows as GridRowsProp;
    };

    onModuleGroupChange = (event: SelectChangeEvent<string>) => {
        this.setState({ uploadModuleGroup: event.target.value });
    };

    onActiveDateChange = (date: Date, validationError: string | null) => {
        this.setState({
            uploadActiveDate: date,
            disableExpireDate: false,
            isUploadScheduleContinue: !validationError ? false : true
        });
    };

    onExpiredDateChange = (date: Date, validationError: string | null) => {
        this.setState({
            uploadExpireDate: date,
            isUploadScheduleContinue: !validationError ? false : true
        });
    };

    showSnackbar = (
        snackbarMessage: string,
        snackbarVariant: CCSnackbarVariant
    ) => {
        this.setState({
            snackbarVariant,
            snackbarMessage,
            snackbarOpen: true,
        });
    };

    showError = (errorMessage: string | undefined) => {
        let message;
        if (errorMessage) {
            message = errorMessage;
        } else {
            message = 'Unknown Error';
        }

        this.showSnackbar(message, 'error' as CCSnackbarVariant);
    };

    showWarning = (warningMessage: string | undefined) => {
        if (!warningMessage) {
            return;
        }

        this.showSnackbar(warningMessage, 'warning' as CCSnackbarVariant);
    };

    showDialog = (dialogTitle: string, dialogMessage: string) => {
        this.setState({
            dialogTitle,
            dialogMessage,
            dialogOpen: true
        });
    };

    onSnackbarClosed = () => {
        this.setState({ snackbarOpen: false });
    };

    onDialogClosed = () => {
        this.setState({ dialogOpen: false });
    };

    onPageChange = (model: GridPaginationModel) => {
        const { page, pageSize } = model;
        this.refreshGrid(page, pageSize);
        this.setState({ paginationModel: model });
    };

    onToggleSideNavBar = () => {
        const { isSchedulesDrawerOpen } = this.state;
        this.setState({ isSchedulesDrawerOpen: !isSchedulesDrawerOpen});
    };

    onCSVImport = (parsedCSVFileData: string[][], csvFile?: unknown) => {
        this.csvFile = csvFile as File;
        const { name: filename } = this.csvFile;

        this.setState({
            uploadProgress: 0,
            uploadProgressStatus: 'PENDING',
            uploadFilename: filename,
            isUploadScheduleOpen: true
        });
    };

    formatUTCUploadDate = (date: Date | undefined) => {
        if (!date) {
            return undefined;
        }

        const sourceDateTime = dayjs.tz(date, this.currentTimezone);
        const userHours = sourceDateTime.hour();
        const userMinutes = sourceDateTime.minute();
        return sourceDateTime.utc().hour(userHours).minute(userMinutes);
    };

    onUploadFile = () => {
        const {
            rootStore: {
                managerStore: {
                    currentAccount,
                    submitSchedules,
                    getSubmitSchedulesStatus
                }
            }
        } = this.props;

        const {
            uploadModuleGroup,
            uploadActiveDate,
            uploadExpireDate,
            paginationModel: {
                page,
                pageSize
            }
        } = this.state;

        this.setState({ isUploadScheduleOpen: false });

        if (currentAccount && this.csvFile) {
            const { accountId } = currentAccount;
            // Convert local dates to UTC
            const utcActiveDate = this.formatUTCUploadDate(uploadActiveDate);
            const utcExpireDate = this.formatUTCUploadDate(uploadExpireDate);

            // Convert UTC dates to timestamp
            const fileUploadDetails = {
                moduleGroupId: uploadModuleGroup === '' ? undefined : uploadModuleGroup,
                activeDate: utcActiveDate ? utcActiveDate.unix() : undefined,
                expireDate: utcExpireDate ? utcExpireDate.unix() : undefined
            };

            submitSchedules(accountId, this.csvFile, fileUploadDetails).then((jobId: string) => {
                const statusCallback = (taskId: string) => {
                    getSubmitSchedulesStatus(taskId).then((jobStatus) => {
                        const {completion, status, result } = jobStatus;

                        const uploadProgressStatus = status as '' | keyof LinearColorMapping;
                        if (uploadProgressStatus === 'ERROR' && this.fileUploadRef) {
                            this.fileUploadRef.value = '';
                            this.showDialog('ERROR', `${result}`);
                            return;
                        }

                        // Completion hits here and stops here no need to return before
                        if (uploadProgressStatus === 'COMPLETE' && 'success' in result && this.fileUploadRef) {
                            const { success } = result;
                            const rowsUpdated: string[] = [];
                            this.fileUploadRef.value = '';

                            for (const key in success as KeyMapping) {
                                rowsUpdated.push(key);
                            }

                            const successMessage = `Rows updated:
                                ${rowsUpdated.length ? rowsUpdated.join(', ') : 'There is no rows updated'}

                                Total: ${rowsUpdated.length}`;

                            this.setState({
                                uploadProgress: completion,
                                uploadProgressStatus: uploadProgressStatus
                            });
                            this.showDialog(UPLOAD_SCHEDULE_COMPLETE, successMessage);
                            this.refreshGrid(page, pageSize);
                            return;
                        }

                        this.setState({
                            uploadProgress: completion,
                            uploadProgressStatus: uploadProgressStatus
                        }, () => {
                            setTimeout(statusCallback, JOB_POOLING_TIMER, taskId);
                        });
                    }).catch((errorMessage) => {
                        if (this.fileUploadRef) {
                            this.fileUploadRef.value = '';
                        }

                        this.setState({ uploadProgressStatus: 'ERROR' });
                        this.showDialog('ERROR', errorMessage);
                        return;
                    });
                };
                setTimeout(statusCallback, JOB_POOLING_TIMER, jobId);
            }).catch((errorMessage) => {
                if (this.fileUploadRef) {
                    this.fileUploadRef.value = '';
                }

                this.setState({ uploadProgressStatus: 'ERROR' });
                this.showDialog('ERROR', errorMessage);
            });
        }
    };

    onRowSelected = (rowsSelected: GridRowsProp) => {
        this.setState({ isScheduleDetailOpen: true });
        if (rowsSelected.length) {
            const [ rowSelected ] = rowsSelected;
            const activeDateTimestampFormated = dayjs.unix(rowSelected['active_datetime_timestamp']);
            const expireDateTimestampFormated = dayjs.unix(rowSelected['expire_datetime_timestamp']);
            const timezone = rowSelected['timezone'];
            const activeDate = `${dayjs(activeDateTimestampFormated).tz(timezone).format(FORMAT_DATETIME)}`;
            const expireDate = rowSelected['expire_datetime_timestamp'] !== 99999999999 ? `${dayjs(expireDateTimestampFormated).tz(timezone).format(FORMAT_DATETIME)}` : NO_EXPIRATION;
            const ingestionDateTimestampFormated = dayjs.unix(rowSelected['ingestion_date']);
            const ingestionDate = `${dayjs(ingestionDateTimestampFormated).format(FORMAT_DATETIME)}`;
            const timezoneMapped = timezone in TIMEZONE_MAP ? TIMEZONE_MAP[timezone] : timezone;
            const ingestionTimezoneMapped = this.currentTimezone in TIMEZONE_MAP ? TIMEZONE_MAP[this.currentTimezone] : this.currentTimezone;
            const frequency = rowSelected['rrule.name'].split('_').map((d: string) => daysOfWeek[d]).join(', ');
            const scheduleDetail = {
                location: rowSelected['marker_name'],
                id: rowSelected['Property ID'],
                folder: rowSelected['folder_name'],
                moduleGroup: rowSelected['module_group_name'],
                status: rowSelected['Property Status'],
                activeDate: `${activeDate} ${timezoneMapped}`,
                expireDate: expireDate === NO_EXPIRATION ? expireDate : `${expireDate} ${timezoneMapped}`,
                ingestionDate: `${ingestionDate} ${ingestionTimezoneMapped}`,
                name: rowSelected['Property Name'],
                address: rowSelected['Property Address 1'],
                zipcode: rowSelected['Property Zip Code'],
                rsf: rowSelected['Property RSF'],
                city: rowSelected['City'],
                country: rowSelected['Country'],
                state: rowSelected['State'],
                consolidatedType: rowSelected['Consolidated Type'],
                stratification: rowSelected['Stratification'],
                cleanable: rowSelected['Cleanable SF'],
                cleaningContract: rowSelected['Does site have a cleaning contract?'],
                cleanings: rowSelected['number_of_occurrences'],
                frequency
            };

            this.setState({ scheduleDetail });
        }
    };

    onCloseScheduleDetail = () => {
        this.setState({ isScheduleDetailOpen: false });
    };

    onCloseUploadSchedule = () => {
        if (this.fileUploadRef) {
            this.fileUploadRef.value = '';
        }

        this.setState({
            uploadProgress: 0,
            uploadProgressStatus: '',
            uploadFilename: '',
            isUploadScheduleOpen: false
        });
    };

    onStatusSelectedIdsChanged = (itemsId: string[]) => {
        const {
            paginationModel: {
                pageSize
            }
        } = this.state;
        this.setState({
            statusSelectedIds: itemsId
        }, () => {
            this.refreshGrid(0, pageSize);
        });
    };

    onStatusDeleted = (itemId: string) => {
        const {
            statusSelectedIds,
            paginationModel: {
                pageSize
            }
        } = this.state;
        const newStatusSelectedIds = statusSelectedIds.filter(id => id !== itemId);
        this.setState({
            statusSelectedIds: newStatusSelectedIds
        }, () => {
            this.refreshGrid(0, pageSize);
        });
    };

    onFilterByPropertyIdChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        const newValueLength = newValue.length;
        const {
            paginationModel: {
                pageSize
            }
        } = this.state;

        if (newValueLength > 0) {
            const lastCharacter = newValue[newValueLength - 1];

            // If the user press enter we refresh the tasks
            // and deselect the component.
            if (lastCharacter === '\r' || lastCharacter === '\n') {
                event.target.blur();
                this.refreshGrid(0, pageSize);
                return;
            }
        }

        this.setState({
            propertyIdToFilter: newValue.replaceAll('\r', '').replaceAll('\n', ''),
        });
    };

    onFilterByDateChange = (date: Date, validationError: string | null) => {
        const {
            paginationModel: {
                pageSize
            }
        } = this.state;
        this.setState({
            filterByDate: date
        }, () => {
            if (!validationError) {
                this.refreshGrid(0, pageSize);
            }
        });
    };

    removeFilterByDate = () => {
        const {
            paginationModel: {
                pageSize
            }
        } = this.state;

        this.setState({
            filterByDate: undefined
        }, () => {
            this.refreshGrid(0, pageSize);
        });
    };

    onFolderFilterInputChange = (event: React.ChangeEvent<object>, value: FolderFilterValues | null) => {
        const {
            paginationModel: {
                pageSize
            }
        } = this.state;
        
        this.setState({
            folderToFilter: value ? value : undefined
        }, () => {
            this.refreshGrid(0, pageSize);
        });
    };

    public render() {
        const {
            rows,
            totalRows,
            paginationModel,
            isSchedulesDrawerOpen,
            uploadProgress,
            uploadProgressStatus,
            uploadFilename,
            isUploadScheduleOpen,
            isScheduleDetailOpen,
            scheduleDetail: {
                location: scheduleLocation,
                id: scheduleId,
                status: status,
                activeDate,
                expireDate,
                ingestionDate,
                name: scheduleName,
                address: address,
                zipcode: zipcode,
                rsf: scheduleRSF,
                city,
                country,
                state,
                consolidatedType,
                stratification,
                cleanable,
                cleaningContract,
                folder,
                moduleGroup,
                cleanings,
                frequency
            },
            disableExpireDate,
            uploadActiveDate,
            uploadExpireDate,
            snackbarVariant,
            snackbarMessage,
            snackbarOpen,
            isUploadScheduleContinue,
            dialogOpen,
            dialogTitle,
            dialogMessage,
            summaryProperties,
            summaryColumnsHeaderName,
            statusSelectedIds,
            propertyIdToFilter,
            moduleGroups,
            uploadModuleGroup,
            filterByDate,
            folderFilters,
            folderToFilter,
            isFolderFilterLoading
        } = this.state;

        const {
            rootStore: {
                managerStore: {
                    isSchedulesTabLoading,
                    isSchedulesUploading
                }
            },
            classes
        } = this.props;

        // Grid config
        const columns = this.createColumns(summaryProperties, summaryColumnsHeaderName);
        const toolbar = {
            toolbar: {
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
            }
        };

        // File upload config
        const linearProgressColor = uploadProgressStatus !== '' ?
            linearProgressColorMapping[uploadProgressStatus] as 'inherit' | 'secondary' | 'primary' | 'error' | 'info' | 'success' | 'warning'
            : 'secondary';
        const uploadProgressPercentage = uploadProgressStatus === 'ERROR' ? 'Unsuccessful' : uploadProgress === 100 ?
            'Complete' : `${Math.round(uploadProgress)}%`;

        return (
            <>
                <SideNavbar
                    variant="persistent"
                    isDrawerOpen={isSchedulesDrawerOpen}
                    collapsedArea={
                        <div
                            className={`${classes.navbarIconContainer} ${classes.navbarIconContainerSmall}`}
                        >
                            <ArticleIcon className={classes.navbarIcon} />
                        </div>
                    }
                    onExpanderClick={this.onToggleSideNavBar}
                >
                    <div className={`${classes.contentContainer} ${classes.navbarContent}`}>
                        <div className={classes.navbarTitleContainer}>
                            <div className={classes.navbarIconContainer}>
                                <ArticleIcon className={classes.navbarIcon} />
                            </div>
                            <Typography
                                className={classes.navbarTitle}
                                variant="subtitle1"
                            >
                                Schedule Filters
                            </Typography>
                        </div>
                        <Divider className={classes.divider} />
                        <div className={classes.chipContainer}>
                            <Typography
                                className={classes.navbarComponentTitle}
                                variant="button"
                            >
                                DATE
                            </Typography>
                            <div className={classes.filterByDateContainer}>
                                {
                                    filterByDate ?
                                        <Chip
                                            label={dayjs(filterByDate).format(FORMAT_DATE)}
                                            variant="outlined"
                                            onDelete={this.removeFilterByDate}
                                        />
                                        : <CCSingleDatePicker
                                            label="Add date to filter"
                                            maxDate={dayjs().add(1, 'year')} // Get current date
                                            onDateChange={this.onFilterByDateChange}
                                        />

                                }
                            </div>
                        </div>
                        <DropdownChips
                            className={classes.chipContainer}
                            classes={{
                                chip: classes.chip,
                                title: classes.navbarComponentTitle,
                            }}
                            title="STATUS"
                            placeholder="No selected statuses"
                            unselectAllItems={true}
                            selectedItemsIds={statusSelectedIds}
                            data={this.filterStatuses}
                            onSelectedItemsChanged={
                                this.onStatusSelectedIdsChanged
                            }
                            onItemDelete={this.onStatusDeleted}
                        />
                        <div className={classes.inputContainer}>
                            <Typography
                                className={classes.navbarComponentTitle}
                                variant="button"
                            >
                                FOLDER
                            </Typography>
                            <Paper>
                                <CCSpinner
                                    loading={isFolderFilterLoading}
                                    size={40}
                                >
                                    <Autocomplete
                                        className={classes.navbarInput}
                                        options={folderFilters}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => 
                                            <TextField
                                                {...params}
                                                placeholder="Search for folder"
                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.navbarPlaceholder,
                                                    },
                                                }}
                                            />
                                        }
                                        defaultValue={folderToFilter}
                                        onChange={(event, pickedValue) => this.onFolderFilterInputChange(event, pickedValue)}
                                    />
                                </CCSpinner>
                            </Paper>
                        </div>
                        <div className={classes.chipContainer}>
                            <Typography
                                className={classes.navbarComponentTitle}
                                variant="button"
                            >
                                PROPERTY ID
                            </Typography>
                            <Paper>

                                <TextField
                                    className={classes.navbarInput}
                                    type="text"
                                    placeholder="Type Id and press enter"
                                    variant="standard"
                                    multiline={true}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            input: classes.navbarPlaceholder,
                                        },
                                    }}
                                    maxRows="2"
                                    value={propertyIdToFilter}
                                    onChange={this.onFilterByPropertyIdChanged}
                                />
                            </Paper>
                        </div>
                        <Divider className={classes.divider} />
                        <div>
                            <CSVButton
                                classes={{
                                    button: classes.uploadButton
                                }}
                                variant="contained"
                                size="large"
                                color="secondary"
                                importData={true}
                                disabled={isSchedulesUploading}
                                onCSVImport={this.onCSVImport}
                                inputRef={this.getFileUploadRef}
                            >
                                <UploadFileRoundedIcon /> Upload Schedule
                            </CSVButton>
                            <div className={`${classes.uploadProgressContainer} ${uploadProgressStatus}`}>
                                <div className={classes.uploadProgressContainerTitle}>
                                    <Typography
                                        variant="body2"
                                        className={classes.uploadProgressTitle}
                                    >
                                        Upload Progress
                                    </Typography>
                                    <Typography className="uploadProgressPercentage" variant="body2">
                                        {uploadProgressPercentage}
                                    </Typography>
                                </div>
                                <LinearProgress
                                    variant={uploadProgressStatus === 'PENDING' ? 'indeterminate' : 'determinate'}
                                    value={uploadProgress}
                                    color={linearProgressColor}
                                />
                                <Typography variant="body2">
                                    {uploadFilename}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </SideNavbar>
                <DataGridList
                    className={`${classes.dataGridContainer} ${isSchedulesDrawerOpen ? 'navbar-opened' : ''}`}
                    isLoading={isSchedulesTabLoading}
                    columns={columns}
                    rows={rows}
                    showToolbar={true}
                    disableColumnMenu={true}
                    disableColumnFilter={true}
                    rowCount={totalRows}
                    density="comfortable"
                    paginationMode="server"
                    onPaginationModelChange={this.onPageChange}
                    pageSizeOptions={[10, 30, 50]}
                    paginationModel={paginationModel}
                    componentsProps={toolbar}
                    onRowSelection={this.onRowSelected}
                />
                <SimpleModal
                    open={isScheduleDetailOpen}
                    buttonOkLabel=""
                    buttonCancelLabel=""
                    className={classes.modalContainer}
                    header={
                        <div className={classes.modalHeaderContainer}>
                            <Typography
                                className={classes.modalTitle}
                                variant="h2"
                            >
                                {`Schedule: ${scheduleLocation}`}
                            </Typography>
                            <IconButton
                                className={classes.modalCloseButton}
                                onClick={this.onCloseScheduleDetail}
                            >
                                <Close />
                            </IconButton>
                            <Typography
                                variant="subtitle2"
                            >
                                <strong>{`ID: ${scheduleId}`}</strong>
                            </Typography>
                            <div className={classes.modalSectionContainer}>
                                <div className={classes.headerColumn}>
                                    <strong>Folder:</strong> {folder}
                                </div>
                                <div className={classes.headerColumn}>
                                    <strong>Module Group:</strong> {moduleGroup}
                                </div>
                            </div>
                            <div className={classes.modalSectionContainer}>
                                <div className={classes.headerColumn}>
                                    <strong>Active Date:</strong> {activeDate}
                                </div>
                                <div className={classes.headerColumn}>
                                    <strong>Expire Date:</strong> {expireDate}
                                </div>
                            </div>
                        </div>
                    }
                >
                    <Divider className={classes.divider} />
                    <div className={classes.modalSectionContainer}>
                        <ul className={`${classes.modalBodyList} ${classes.bodyLeftColumn}`}>
                            <li><span>Name:</span>{scheduleName}</li>
                            <li><span>Address:</span>{address}</li>
                            <li><span>Status:</span>{status}</li>
                            <li><span>Consolidated Type:</span>{consolidatedType}</li>
                            <li><span>Stratification:</span>{stratification}</li>
                            <li><span>Does site have a cleaning contract?</span>{cleaningContract}</li>
                        </ul>
                        <ul className={classes.modalBodyList}>
                            <li><span>City:</span>{city}</li>
                            <li><span>State:</span>{state}</li>
                            <li><span>Country:</span>{country}</li>
                            <li><span>Zipcode:</span>{zipcode}</li>
                            <li><span>RSF:</span>{scheduleRSF}</li>
                            <li><span>Cleanable SF:</span>{cleanable}</li>
                        </ul>
                    </div>
                    <Divider className={classes.divider} />
                    <div className={classes.modalSectionContainer}>
                        <ul className={classes.modalBodyList}>
                            <li><span>Green Cleaning - ATM Daily Frequency:</span>{cleanings}</li>
                        </ul>
                        <ul className={classes.modalBodyList}>
                            <li><span>Import:</span>{ingestionDate}</li>
                        </ul>                    
                    </div>
                    <div className={classes.modalSectionContainer}>
                        <ul className={classes.modalBodyList} style={{marginTop: 0}}>
                            <li><span>Schedule Frequency:</span>{frequency}</li>
                        </ul>
                    </div>
                </SimpleModal>
                <SimpleModal
                    className={classes.fileUploadModal}
                    open={isUploadScheduleOpen}
                    header={
                        <div className={classes.modalHeaderContainer}>
                            <Typography
                                className={classes.modalTitle}
                                variant="h2"
                            >
                                Upload Schedule Details
                            </Typography>
                            <IconButton
                                className={classes.modalCloseButton}
                                onClick={this.onCloseUploadSchedule}
                            >
                                <Close />
                            </IconButton>
                        </div>
                    }
                    buttonOkLabel=""
                    buttonCancelLabel=""
                    footer={
                        <Button
                            className={classes.fileUploadBtn}
                            variant="contained"
                            onClick={this.onUploadFile}
                            disabled={isUploadScheduleContinue}
                        >
                            Continue
                        </Button>
                    }
                >
                    <Typography className={classes.fileUploadIntro} variant="body2">
                        Add module group name and dates and then click on continue to upload schedule<br/>
                        <span>(These fields are optional)</span>
                    </Typography>
                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <strong>Filename:</strong> {uploadFilename}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <InputLabel>Module Group</InputLabel>
                                <Select
                                    className={classes.moduleGroupField}
                                    onChange={this.onModuleGroupChange}
                                    value={uploadModuleGroup}
                                    label="Module Group"
                                >
                                    {
                                        moduleGroups.map((value: MouduleGroup) => {
                                            return (
                                                <MenuItem
                                                    key={`modulegroups-${value.id}`}
                                                    value={value.id}
                                                >
                                                    {value.name}
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <CCDateTimePicker
                                minDateTime={dayjs()} // Get current date
                                maxDateTime={uploadExpireDate}
                                label="Active Date"
                                onDateTimeChange={this.onActiveDateChange}
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CCDateTimePicker
                                label="Expire Date"
                                disabled={disableExpireDate}
                                minDateTime={uploadActiveDate}
                                onDateTimeChange={this.onExpiredDateChange}
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock
                                }}
                            />
                        </Grid>
                    </Grid>
                </SimpleModal>
                <CCSnackbar
                    className={classes.snackbar}
                    variant={snackbarVariant}
                    message={snackbarMessage}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transition="right"
                    open={snackbarOpen}
                    onClose={this.onSnackbarClosed}
                />
                <SimpleDialog
                    open={dialogOpen}
                    titleText={dialogTitle}
                    buttonCancelLabel=""
                    contentText={dialogMessage}
                    onDialogResult={this.onDialogClosed}
                    disableTransition={true}
                />
            </>
        );
    }

}

const MUIComponent = withStyles(styles)(SchedulesTab);
export { MUIComponent as SchedulesTab };
