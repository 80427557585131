import * as React from 'react';

import { Theme } from '@mui/material/styles';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        padding: 0
    }
});

export type NextPageCallback = () => Promise<void>;

interface Props extends WithStyles<typeof styles> {
    className?: string;
    containerHeight: number | string;
    containerWidth: number | string;
    data: ReadonlyArray<unknown>;
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    itemSize: number;
    onRenderItem: (data: unknown, style: React.CSSProperties, isItemLoading: boolean) => JSX.Element;
    onNextPageLoad?: NextPageCallback;
}

type PromiseResolve = (value?: void | PromiseLike<void>) => void;

class VirtualList extends React.Component<Props> {
    public static defaultProps = {
        containerHeight: 150,
        containerWidth: 'auto',
        itemSize: 30,
        isNextPageLoading: false
    };

    delayedPromisesQueue = [] as PromiseResolve[];

    componentDidUpdate(prevProps: Props) {
        const { isNextPageLoading } = this.props;
        // If it has finished loading data from Next Page call
        // and it has queued other data requests,
        // it take the next queued request and execute it
        if ((prevProps.isNextPageLoading === true) &&
            (isNextPageLoading === false) &&
            (this.delayedPromisesQueue.length > 0)) {
            const queuedPromiseResolve = this.delayedPromisesQueue.pop() as PromiseResolve;
            this.onLoadMoreItems().then(()=> {
                queuedPromiseResolve();
            });
        }
    }

    childrenCount = (): number => {
        const { data } = this.props;
        return data.length;
    };

    isItemLoaded = (index: number) => {
        const { hasNextPage } = this.props;
        // Every row is loaded except for the loading indicator row.
        return !hasNextPage || index < this.childrenCount();
    };

    // Render an item or a loading indicator.
    renderItem = (data: Readonly<unknown[]>) => (params : {
        index: number,
        style: React.CSSProperties}
    ) => {
        const {
            onRenderItem
        } = this.props;
        const {index, style} = params;
        const isItemLoading = !this.isItemLoaded(index);

        return onRenderItem(data[index], style, isItemLoading);
    };

    onLoadMoreItems =  (): Promise<void> => {
        const {
            isNextPageLoading,
            onNextPageLoad
        } = this.props;
        if (!onNextPageLoad) {
            return Promise.resolve();
        }

        // Only load 1 page of items at a time.
        // If the infinite loader requests data
        // while the previous data loading is not completed
        // It saves in a queue the promise for the next dataset.
        if (isNextPageLoading) {
            return new Promise((resolve)=> {
                this.delayedPromisesQueue.push(resolve);
            });
        }

        return new Promise((resolve)=> {
            onNextPageLoad()
                .catch(() => { return; })
                .finally(() => resolve() );
        });
    };

    public render() {
        const {
            classes,
            className,
            containerHeight,
            containerWidth,
            data,
            hasNextPage,
            itemSize,
        } = this.props;

        const childrenCount = this.childrenCount();
        const itemCount =  data ? 
            hasNextPage ?  childrenCount+ 1 : childrenCount
            : 0;

        const rootClasses = `${classes.root}${className ? ` ${className}` : ''}`;
        return (
            <InfiniteLoader
                isItemLoaded={this.isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={this.onLoadMoreItems}
            >
                {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                        className={rootClasses}
                        height={containerHeight}
                        itemCount={itemCount}
                        itemSize={itemSize}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        width={containerWidth}
                    >
                        {this.renderItem(data)}
                    </FixedSizeList>
                )}
            </InfiniteLoader>
        );
    }
}

const MUIComponent = withStyles(styles)(VirtualList);
export { MUIComponent as VirtualList };
