import * as React from 'react';

import {
    Button,
    Typography
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
// This local library is imported from modern-react-qr-reader
// It was modified to fix some issues according to flow 
// https://www.npmjs.com/package/modern-react-qr-reader
import QrReader from '../qr-reader-local';

export enum CameraType {
    Rear = 0,
    Front
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    submitQrBtn: {},
    resultLabel: {},
    noResultLabel: {
        marginBottom: '1em'
    },
    video: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '320px',
        width: '80%',

        '& video': {
            width: '100%',
            height: '100%'
        }
    }
});

interface Props extends WithStyles<typeof styles> {
    delay: number;
    width?: number;
    className?: string;
    noResultsText: string;
    resultsText: string;
    facingMode: CameraType;
    legacyMode: boolean;
    submitQRButtonText: string;
    onQRScan?: (results: string) => void;
    onError?: () => void;
    onLoad?: () => void;
}

interface States {
    isQRCodeScanned: boolean;
    legacyMode: boolean;
}

class QRScanner extends React.Component<Props, States> {
    public static defaultProps = {
        delay: 500,
        noResultsText: 'No QR scanned',
        resultsText: 'QR code scanned',
        facingMode: CameraType.Rear,
        legacyMode: false,
        submitQRButtonText: 'Submit QR Code Image'
    };

    state = {
        isQRCodeScanned: false,
        legacyMode: this.props.legacyMode
    };

    qrReaderRef: React.RefObject<typeof QrReader> = React.createRef();

    onScan = (result: string) => {
        const { onQRScan } = this.props;

        if (result) {
            this.setState({ isQRCodeScanned: true });

            if (onQRScan) {
                onQRScan(result);
            }
        }
    };

    onError = (error: {name: string}) => {
        if (error.name.includes('NotAllowedError')) {
            this.setState({ legacyMode: true });
        }

        if (this.props.onError) {
            this.props.onError();
        }
    };

    openImageDialog = () => {
        const currentQRReaderRef = ((this.qrReaderRef.current as unknown) as {openImageDialog: ()=> void});
        currentQRReaderRef.openImageDialog();
    };

    onLoad = () => {
        if (this.props.onLoad) {
            this.props.onLoad();
        }
    };

    public render() {
        const {
            delay,
            width,
            className,
            classes,
            submitQRButtonText,
            noResultsText,
            resultsText,
            facingMode
        } = this.props;

        const {
            isQRCodeScanned,
            legacyMode
        } = this.state;

        const previewStyle = width ? { width, maxWidth: 'none' } : {};
        const mainClasses = `${classes.root}${className ? ` ${className}` : ''}`;

        return (
            <div className={mainClasses}>
                { isQRCodeScanned ?
                    <Typography
                        className={classes.resultLabel}
                        variant="h5"
                    >
                        { resultsText }
                    </Typography>
                    :
                    <React.Fragment>
                        <Typography
                            className={classes.noResultLabel}
                            variant="h5"
                        >
                            { noResultsText }
                        </Typography>
                        <QrReader
                            ref={this.qrReaderRef}
                            className={classes.video}
                            delay={delay}
                            style={previewStyle}
                            onError={this.onError}
                            onScan={this.onScan}
                            facingMode={facingMode === CameraType.Rear ? 'environment' : 'user'}
                            legacyMode={legacyMode}
                            onLoad={this.onLoad}
                        />
                        { legacyMode ?
                            <Button
                                className={classes.submitQrBtn}
                                onClick={this.openImageDialog}
                            >
                                {submitQRButtonText}
                            </Button>
                            :
                            ''
                        }
                    </React.Fragment>
                }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(QRScanner);
export {MUIComponent as QRScanner};
