import {
    Button,
    Divider,
    TextField
} from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
import { ReportComment } from '../../models/report';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { CommentItem } from '../comment-item';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    textField: {
        flex: 1,
        minHeight: '6em'
    },
    textFieldBlank: {
        marginBottom: '2.7em'
    },
    buttonsContainer: {
        '& > *': {
            marginRight: '1.5em'
        }
    },
    commentItemContainer: {
        paddingBottom: '1em'
    }
});

interface Props extends WithStyles<typeof styles> {
    comments: ReportComment[] | undefined;
    isLoading?: boolean;
    onCommentPost?: (commentText: string) => Promise<void>;
}

interface States {
    commentValue: string;
}

class CommentEditor extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
    };

    state = {
        commentValue: ''
    };
    
    isComponentMounted = true;

    componentWillUnmount() {
        this.isComponentMounted = false;
    }

    onCommentChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const commentValue = event.target.value;
        this.setState({commentValue});
    };

    onPostClicked = () => {
        const { onCommentPost } = this.props;
        if (onCommentPost) {
            const { commentValue } = this.state;
            onCommentPost(commentValue)
                .then(()=>{
                    if (this.isComponentMounted) {
                        this.setState({commentValue: ''});
                    }
                });
        }
    };

    onCancelClicked = () => {
        this.setState({commentValue: ''});
    };

    public render() {
        const {
            classes,
            comments,
            isLoading
        } = this.props;    
        const{ 
            commentValue
        } = this.state;
        const commentsExist = !!comments && comments.length > 0;
        const commentsNumber = comments ? comments.length -1 : 0;
        const isTextFieldBlank = commentValue.length < 1;
        const textFieldClasses = `${classes.textField}${ isTextFieldBlank ? ` ${classes.textFieldBlank}` : ''}`;
        
        return (
            <React.Fragment>
                <form className={classes.root}>
                    <TextField
                        className={textFieldClasses}
                        multiline={true}
                        rows="3"
                        placeholder="Add a comment..."
                        margin="normal"
                        variant="outlined"
                        value={commentValue}
                        onChange={this.onCommentChanged}
                    />
                    { !isTextFieldBlank ?
                        <div className={classes.buttonsContainer}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                disabled={!!isLoading}
                                onClick={this.onPostClicked}
                            >
                                POST
                            </Button>
                            <Button
                                size="small"
                                onClick={this.onCancelClicked}
                            >
                                CANCEL
                            </Button>
                        </div>
                        : ''
                    }
                    { commentsExist ? <Divider /> : '' }
                    { comments ? 
                        <div className={classes.commentItemContainer}>
                            {
                                comments.map((comment: ReportComment, index: number) => {
                                    return (
                                        <CommentItem
                                            key={`${index}`}
                                            comment={comment}
                                            isLastOne = {commentsNumber === index}
                                        />
                                    );
                                })
                            }
                        </div>
                        : ''
                    }
                </form>
                <CCSpinner
                    loading={!!isLoading}
                    overlayVisible={true}
                />
            </React.Fragment>
        );
    }
}

const MUIComponent = withStyles(styles)(CommentEditor);
export { MUIComponent as CommentEditor };
