import * as React from 'react';

import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { NOISE } from './assets/images/noise';
import { ccLogoNoText } from '../../shared/assets/images/cc-logo';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    '@global': {
        'html': {
            minHeight: '100%'
        },
        'html body': {
            backgroundColor: '#000000',
            backgroundImage: `radial-gradient(#11581E, #041607), url(${NOISE})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        '@keyframes scan': {
            '0%': {
                backgroundPosition: '0 -100vh'
            },
            '35%, 100%': {
                backgroundPosition: '0 100vh'
            }
        },
    },
    root: {
        boxSizing: 'border-box',
        height: '100%',
        fontFamily: 'Helvetica, sans-serif',
        fontSize: '1.5rem',
        color: 'rgba(128, 255, 128, 0.8)',
        textShadow: '0 0 1ex rgba(51, 255, 51, 1), 0 0 2px rgba(255, 255, 255, 0.8)',
        '& a': {
            color: '#fff',
            textDecoration: 'none',
            '&::before': {
                content: '"["'
            },
            '&::after': {
                content: '"]"'
            }
        }
    },
    noise: {
        pointerEvents: 'none',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${NOISE})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        zIndex: -1,
        opacity: .02
    },
    overlay: {
        pointerEvents: 'none',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: ' repeating-linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0) 100%)',
        backgroundSize: 'auto 4px',
        zIndex: 1,
        '&::before': {
            content: '',
            pointerEvents: 'none',
            position: 'absolute',
            display: 'block',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'linear-gradient(0deg, transparent 0%, rgba(32, 128, 32, 0.2) 2%, rgba(32, 128, 32, 0.8) 3%, rgba(32, 128, 32, 0.2) 3%, transparent 100%)',
            backgroundRepeat: 'no-repeat',
            animation: 'scan 7.5s linear 0s infinite'
        }
    },
    terminal: {
        boxSizing: 'inherit',
        position: 'absolute',
        height: '100%',
        width: '1000px',
        maxWidth: '100%',
        padding: '4rem',
        textTransform: 'uppercase',
        textAlign: 'justify',
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '1em',
            paddingTop: '1.5em',
            '& h1': {
                fontSize: '1.5em'
            }
        }
    },      
    output: {
        color: 'rgba(128, 255, 128, 0.8)',
        textShadow: ' 0 0 1px rgba(51, 255, 51, 0.4), 0 0 2px rgba(255, 255, 255, 0.8)',
        '&::before': {
            content: '> '
        }
    },
    errorCode: {
        color: 'white'
    },
    header: {
        backgroundColor: 'rgb(20 80 31)',
        height: '3em',
        width: '100%',
        position: 'fixed',
        [theme.breakpoints.between('xs', 'sm')]: {
            height: '1.5em'
        }
    },
    ccLogo: {
        height: '2em',
        margin: '0.5em 1em',
        [theme.breakpoints.between('xs', 'sm')]: {
            height: '1em',
            marginTop: '0.3em'
        }
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
}

class NotFoundPage extends React.Component<Props> {
    public static defaultProps: Partial<Props> = {
    };

    state = {
    };

    public render() {
        const {
            classes,
            className
        } = this.props;

        const rootClasses = `${classes.root}${className ? ` ${className}` : ''}`;
        return (
            <div className={rootClasses}>
                <div className={classes.noise}></div>
                <div className={classes.overlay}></div>
                <div className={classes.header}>
                    <img
                        className={classes.ccLogo}
                        alt="CrowdComfort Logo"
                        src={ccLogoNoText}
                    />
                </div>
                <div className={classes.terminal}>
                    <h1> 
                        <span className={classes.errorCode}>404 </span>
                        NOT FOUND
                    </h1>
                    <p className={classes.output}>The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
                    <p className={classes.output}>Please try to <a href="/">return to the homepage</a>.</p>
                    <p className={classes.output}>Good luck.</p>
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(NotFoundPage);
export { MUIComponent as NotFoundPage };
