/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable semi */
/* eslint-disable no-unsafe-finally */
/* eslint-disable indent */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable quotes */
"use strict";

module.exports = function havePropsChanged(prevProps, nextProps, keys) {
  var changedProps = [];
  keys.forEach(function (key) {
    if (prevProps[key] != nextProps[key]) {
      changedProps.push(key);
    }
  });
  return changedProps;
};