// {
//     SyncGatewaySession: "8a47a47372fb5f1e710653a73827774d56801a23",
//     Authorization: "Bearer 123123131"
// }

export interface CookieDataModel {
    SyncGatewaySession?: string,
    Authorization?: string,
    IdToken?: string
}

export class CookieData {
    static create (model: CookieDataModel): CookieData {
        const {
            SyncGatewaySession,
            Authorization,
            IdToken
        } = model;

        return new CookieData(SyncGatewaySession, Authorization, IdToken);
    }

    static createFromSerializedData(serializedData: string): CookieData | undefined {
        try {
            const data = JSON.parse(serializedData);
            const {
                SyncGatewaySession,
                Authorization,
                IdToken
            } = data;
            return new CookieData(SyncGatewaySession, Authorization, IdToken);
        } catch(error) {
            return;
        }
    }

    constructor (
        public readonly SyncGatewaySession?: string,
        public readonly Authorization?: string,
        public readonly IdToken?: string
    ){}

    public serialize (): string {
        return JSON.stringify(this);
    }
}