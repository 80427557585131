import { API } from '../shared/constants';
import {
    TasksTab,
    EntityTasksTab,
    AnnotationData,
    Annotation,
    EntityAnnotation,
    TaskFilterParameters
} from '../shared/domain';
import { TasksBaseAdapter } from './tasks-base-adapter';
import { HttpConnectorAdapter } from './http-connector-adapter';

interface BaseParameters extends TaskFilterParameters {
    limit?: number;
    offset?: number;
}

let singletonInstance: TasksAdapter;
export class TasksAdapter implements TasksBaseAdapter {
    static getAdapter(): TasksBaseAdapter {
        if (!singletonInstance) {
            singletonInstance = new TasksAdapter();
        }

        return singletonInstance;
    }

    async getTasks(limit: number, offset: number, filters = {} as TaskFilterParameters): Promise<TasksTab> {
        const params: BaseParameters = {};

        if (limit > 0) {
            params.limit = limit;
        }

        if (offset > 0) {
            params.offset = offset;
        }

        for (const filter in filters) {
            params[filter as keyof TaskFilterParameters] = filters[filter as keyof TaskFilterParameters];
        }

        const stringifiedParams = HttpConnectorAdapter.Utilities.stringifyGetParams(params);
        
        const response = await HttpConnectorAdapter
            .get(
                `${API.URL.TASKS.TASKS_BY_USER_ID}?${stringifiedParams}`
            );

        const result = TasksTab.create(response.data as EntityTasksTab);
        if (!result){
            return Promise.reject();
        }

        return result;
    }

    async submitAnnotation(plannedTaskId: number, annotationData: AnnotationData): Promise<Annotation> {
        const params = {...annotationData, 'planned_task_id': plannedTaskId};

        const response = await HttpConnectorAdapter.post(API.URL.TASKS.ANNOTATIONS, params);

        const result = Annotation.create(response.data as EntityAnnotation);
        if (!result){
            return Promise.reject();
        }

        return result;
    }

    async getAnnotation(annotationId: number): Promise<Annotation> {
        const response = await HttpConnectorAdapter.get(`${API.URL.TASKS.ANNOTATIONS}${annotationId}`);

        const result = Annotation.create(response.data as EntityAnnotation);
        if (!result){
            return Promise.reject();
        }

        return result;
    }

    async completeAnnotation(annotationId: number, annotationData: AnnotationData): Promise<Annotation> {
        const response = await HttpConnectorAdapter.patch(`${API.URL.TASKS.ANNOTATIONS}${annotationId}`, annotationData);

        const result = Annotation.create(response.data as EntityAnnotation);
        if (!result){
            return Promise.reject();
        }

        return result;
    }
}
