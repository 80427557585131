import * as React from 'react';

import { TextField } from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { isiPhone } from '../../shared/utils/browser-check';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    isiPhone: {},
    textFieldContainer: {},
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width:'9.5em'
    },
    textFieldLabel: {},
    input:{
        '&:after': {
            content: `'${Intl.DateTimeFormat('en-US',{
                timeZoneName: 'short'
            }).formatToParts(new Date())[6].value}'`,
            position: 'absolute',
            right: '1.5em'
        },
        '$isiPhone &:after': {
            top: '0.46em',
            right: '1.8em'
        }
    }
});

interface Props extends WithStyles<typeof styles> {
    label: string;
    time?: string;
    className?: string;
    onChange?: (time: string) => void;
}

class CCTimePicker extends React.Component<Props> {
    public static defaultProps = {
    };

    state = {
    };
    checkTimeDigit = (digit: string): string => {
        if (digit.length === 1) {
            return `0${digit}`;
        }

        return digit;
    };

    formatTimeCheck = (time: string | undefined): string | undefined => {
        if (!time) {
            return time;
        }
        const splitTime = time.split(':');
        if (splitTime.length < 2) {
            return time;
        }

        splitTime[0] = this.checkTimeDigit(splitTime[0]);
        splitTime[1] = this.checkTimeDigit(splitTime[1]);

        return splitTime.join(':');
    };

    onTextFieldChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {
            onChange
        } = this.props;

        if (!onChange) {
            return;
        }

        onChange(event.target.value);
    };

    public render() {
        const {
            classes,
            className,
            time,
            label
        } = this.props;

        const rootClasses = `${classes.root}${className ? ` ${className}` : ''}${isiPhone() ? ` ${classes.isiPhone}` : ''}`;
        return (
            <form
                className={rootClasses}
                noValidate={true}>
                <TextField
                    className={classes.textField}
                    classes={{
                        root: classes.textFieldContainer
                    }}
                    label={label}
                    type="time"
                    variant="standard"
                    value={this.formatTimeCheck(time)}
                    InputLabelProps={{
                        className: classes.textFieldLabel,
                        shrink: true,
                    }}
                    inputProps={{
                        className: classes.input,
                        step: 300, // 5 min
                    }}
                    onChange={this.onTextFieldChanged}
                />
            </form>
        );
    }
}

const MUIComponent = withStyles(styles)(CCTimePicker);
export { MUIComponent as CCTimePicker };
