// {
//     group_type: "atomic"
//     id: "maintenance"
//     name: "Maintenance"
//     prompt: "Report a Maintenance Issue"
// }

export interface ReportModuleGroupModel {
    group_type?: string;
    id: string;
    module_icon?: string;
    module_tag?: string;
    name: string;
    prompt?: string;
}

export class ReportModuleGroup {
    static create (model: ReportModuleGroupModel): ReportModuleGroup {
        const {
            group_type: groupType,
            id,
            module_icon: moduleIconUrl,
            module_tag: moduleTag,
            name,
            prompt
        } = model;

        return new ReportModuleGroup(
            id,
            name,
            groupType,
            moduleIconUrl,
            moduleTag,
            prompt
        );
    }

    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly groupType?: string,
        public readonly moduleIconUrl?: string,
        public readonly moduleTag?: string,
        public readonly prompt?: string
    ){}
}
