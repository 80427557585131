import * as React from 'react';

import { 
    CircularProgress,
    Fade,
    Typography
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { CCOverlay } from '../cc-overlay';

const styles = (theme: Theme) => createStyles({
    root: {},
    rootContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    lightOverlay : {
        backgroundColor: theme.ccPalette.disabled.light
    },
    darkOverlay : {
        backgroundColor: theme.ccPalette.disabled.dark
    },
    label : {
        '$darkOverlay &': {
            color: 'white'
        }
    },
    circularProgressContainer: {
        position: 'relative'
    },
    circularProgressColor01: {
        '& > *': {
            color: theme.ccPalette.cc_colors.solid.ccLeftsilhouette.main
        }
    },
    circularProgressColor02: {
        '& > *': {
            color: theme.ccPalette.cc_colors.solid.ccCentersilhouette.main
        }
    },
    circularProgressColor03: {
        '& > *': {
            color: theme.ccPalette.cc_colors.solid.ccRightsilhouette.main
        }
    },
    circularProgressLogo: {
        top: '-1%',
        left: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundPosition: '50%',
        backgroundSize: '60%',
        backgroundRepeat: 'no-repeat'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    blockPageScroll: boolean;
    completion?: number;
    label?: string;
    labelClassName?: string;
    loading: boolean;
    overlayVisible: boolean;
    showCompletion: boolean;
    size: number;
    children?: React.ReactNode;
}

interface States {
    colorIndex: number;
    internalCompletion: number;
}

class CCSpinner extends React.Component<Props, States> {
    public static defaultProps = {
        blockPageScroll: true,
        overlayVisible: false,
        showCompletion: false,
        size: 50
    };

    timer?: number;
    colorAnimationNames: string[];

    constructor(props: Readonly<Props>) {
        super(props);
        
        this.state = {
            internalCompletion: 0,
            colorIndex: 0,
        };

        // Initializes the color array of the progress bar circle.
        const { classes } = this.props;
        
        this.colorAnimationNames = [
            classes.circularProgressColor01,
            classes.circularProgressColor02,
            classes.circularProgressColor03
        ];
    }

    public componentDidUpdate(prevProps: Readonly<Props>) {
        // If the user has specified the completion rate
        // it means he wants to drive the percentage manually.
        if (this.props.completion !== undefined) {
            return;
        }
        const {loading} = this.props;
        const {loading:prevLoading} = prevProps;

        if (loading && !prevLoading) {
            this.startTimer();
        } else if (!loading && prevLoading) {
            this.stopTimer();
        }
    }

    public componentWillUnmount() {
        this.stopTimer(true);
    }

    public render() {
        const {
            blockPageScroll,
            children,
            classes,
            className,
            labelClassName,
            completion,
            showCompletion,
            label,
            loading,
            overlayVisible,
            size,
        } = this.props;
        
        // It shows the children if loading is completed
        if (!loading) {
            return (children ? children : '');
        } else if (completion === undefined) { // If the completion isn't specified it runs the timer
            this.startTimer();
        }

        const {
            colorIndex,
            internalCompletion
        } = this.state;

        const isLabelPresent = (label && (label.length > 0));
        const isCompletionSet = ((completion !== undefined) && (completion >= 0));
        const rootClassNames = classes.root +
                               (className ? ` ${className}` : '') +
                               (overlayVisible ?
                                   isLabelPresent ? ` ${classes.darkOverlay}`: ` ${classes.lightOverlay}`
                                   : '');
        const labelClassNames = classes.label + (labelClassName ? ` ${labelClassName}` : '');

        return (
            <CCOverlay
                className={rootClassNames}
                blockPageScroll={blockPageScroll}
                hideChildren={false}
                visible={overlayVisible}
            >
                <Fade
                    in={loading}
                    style={{
                        transitionDelay: loading ? '250ms' : '0ms',
                    }}
                    unmountOnExit={true}
                >
                    <div className={classes.rootContainer}>
                        {
                            isLabelPresent ?
                                <Typography
                                    className={labelClassNames}
                                    variant="h5"
                                >
                                    {label}
                                </Typography>
                                : ''
                        }
                        <div className={classes.circularProgressContainer}>
                            <CircularProgress
                                className={this.colorAnimationNames[colorIndex]}
                                variant="determinate"
                                size={size}
                                value={isCompletionSet ? completion : internalCompletion}
                            />
                            <div className={`${classes.circularProgressLogo} cc-logo-no-words`} />
                        </div>
                        {
                            isCompletionSet && showCompletion ?
                                <Typography
                                    className={labelClassNames}
                                    variant="h5"
                                >
                                    {`${completion ? completion : 0}%`}
                                </Typography>
                                : ''
                        }
                    </div>
                </Fade>
            </CCOverlay>
        );
    }

    private startTimer = () => {
        if (this.timer) {
            return;
        }
        this.timer = window.setInterval(this.progress, 500);
    };

    private stopTimer = (isDestroying = false) => {
        const { timer } = this;
        if (timer) {
            window.clearInterval(timer);
        }

        this.timer = undefined;
        if (!isDestroying) {
            this.setState({ 
                internalCompletion: 0
            });
        }
    };

    private progress = () => {
        const { internalCompletion } = this.state;

        if(internalCompletion >= 100) {
            let {colorIndex} = this.state;
            colorIndex = (colorIndex >= this.colorAnimationNames.length) ? 0 : colorIndex+1;
            this.setState({ 
                colorIndex,
                internalCompletion: 0
            });
        }
        else {
            this.setState({ internalCompletion: internalCompletion+5 });
        }
        
    };
}

const MUIComponent = withStyles(styles)(CCSpinner);
export {MUIComponent as CCSpinner};
