import {
    ReportModuleContent,
    ReportModuleContentModel
} from './report-module-content';
import {
    WidgetData,
    WidgetDataModel
} from './widget-data';

export interface AutoValue {
    defaults: {
        feedback_title: string;
        status: string;
        value: string;
    }
    handlers: unknown[];
}

export interface ReportModuleModel {
    autovalue: AutoValue;
    details: string;
    module_id: string;
    module_title: string;
    module_type: string;
    name: string;
    report_content: ReportModuleContentModel;
    single_view: { widgets: WidgetDataModel[] };
    summary: string;
}

export class ReportModule {
    static create (model: ReportModuleModel): ReportModule {
        const {
            autovalue,
            details,
            module_id: id,
            module_title: title,
            module_type: type,
            name,
            report_content: reportContentModel,
            single_view: {widgets: widgetDataModels},
            summary
        } = model;
        const reportContent: ReportModuleContent = ReportModuleContent.create(reportContentModel);
        const widgets = widgetDataModels.map((widgetDataModel: WidgetDataModel) => {
            return WidgetData.create(widgetDataModel);
        });

        return new ReportModule(
            autovalue,
            details,
            id,
            title,
            (type !== undefined) ? type : '',
            name,
            reportContent,
            {widgets},
            summary
        );
    }
    constructor(
        public readonly autovalue: AutoValue,
        public readonly details: string,
        public readonly id: string,
        public readonly title: string,
        public readonly type: string,
        public readonly name: string,
        public readonly reportContent: ReportModuleContent,
        public readonly singleView: { widgets: WidgetData[] },
        public readonly summary: string
    ){}
}
