import { Tooltip, Typography } from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { ThumbUp } from '@mui/icons-material';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row'
    },
    agreesText: {
        cursor: 'default',
        margin: '0.55em 0 0 0'
    },
    thumbUp: {
        marginRight: '0.5em'
    },
    tooltip: {
        fontSize:'1em'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    count: number;
    tooltip?: string;
}

class CCAgrees extends React.Component<Props> {
    public static defaultProps = {
    };

    state = {
    };

    public render() {
        const {
            classes,
            className,
            count,
            tooltip
        } = this.props;

        return (
            <div className={`${classes.root} ${className}`}>
                { tooltip ? 
                    <Tooltip
                        title={tooltip}
                        classes={{tooltip: classes.tooltip}}
                    >
                        <ThumbUp className={classes.thumbUp}/>
                    </Tooltip>
                    : <ThumbUp className={classes.thumbUp}/>
                }
                <Typography
                    variant="caption"
                    className={classes.agreesText}
                >
                    {count > 0 ? count : 0}
                </Typography>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(CCAgrees);
export { MUIComponent as CCAgrees };
