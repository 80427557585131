import {
    Button,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
    Close,
    Comment,
    Delete,
    PictureAsPdf,
    Schedule,
    Edit,
} from '@mui/icons-material';
import jsPDF from 'jspdf';
import * as React from 'react';
import {
    Report,
    ReportComment,
    ReportDetail,
    ReportStatus,
    WidgetType,
} from '../../models/report';
import { ccLogoBottom } from '../../shared/assets/images/cc-logo';
import { CCSpinner } from '../../shared/components/cc-spinner';
import {
    DialogResult,
    SimpleDialog,
} from '../../shared/components/simple-dialog';
import { SimpleModal } from '../../shared/components/simple-modal';
import { EnumerationHelper } from '../../shared/utils';
import { DateFormatting } from '../../utils/date-formatting';
import { FacesReviewHelper } from '../../utils/faces-review-helper';
import { CCAgrees } from '../cc-agrees/cc-agrees';
import { CommentEditor } from '../comment-editor';
import { FaceType } from '../faces-review';
import { FacesReviewBtn } from '../faces-review-button';
import {
    WidgetContentGenerator,
    WidgetContentGeneratorType,
    WidgetDataForPrinting,
    WidgetPhotoData,
} from '../widget-content-generator';

const PDF_IMAGE_MAX_WIDTH = 60;

const STATUSES_MENU_ITEMS = Object.keys(ReportStatus).map((key, index) => {
    return (
        <MenuItem key={`k-${index}`} value={key}>
            {ReportStatus[key as keyof typeof ReportStatus]}
        </MenuItem>
    );
});
const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const isValidEmail = (value: string): boolean => {
    return regexEmail.test(value);
};

const styles = (theme: Theme) =>
    createStyles({
        root: {
            minWidth: '97%',
            minHeight: '97%',
            height: '97%',
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                minWidth: 'auto',
                overflow: 'auto',
            },
        },
        headerContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        headerContentWrapper: {
            display: 'flex',
            flex: 1,
        },
        headerContent: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: '1em',
        },
        closeButton: {
            alignSelf: 'flex-start',
            padding: '0.2em',
            margin: '0.5em',
        },
        timeStampContainer: {
            display: 'flex',
            '& $timeStamp, & $folderName': {
                fontSize: '1em',
            },
        },
        timeStamp: {
            marginRight: '2em',
        },
        folderName: {},
        selectsContainer: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
            margin: '0 0.4em 0 1em',
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                justifyContent: 'space-between',
                flexWrap: 'wrap',
            },
        },
        contentContainer: {
            display: 'flex',
            padding: '0 0.4em',
        },
        status: {
            marginRight: '5em',
            marginBottom: '1.5em',
            minWidth: '12em',
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                marginRight: '1em',
                minWidth: 'auto',
            },
        },
        assignedTo: {
            margin: '1em 0',
            minWidth: '19em',
            '&.cc-is-valid': {
                marginRight: '1em',
                marginBottom: '2.43em',
            },
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                minWidth: 'auto',
            },
        },
        verticalTextContainer: {
            display: 'flex',
            flexDirection: 'column',
            margin: '0 7em 1.4em 0',
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                marginRight: '0',
            },
        },
        applyButton: {
            margin: '0 1em 0 2em',
        },
        buttonText: {
            marginLeft: '1em',
        },
        agrees: {},
        assignedToIcon: {
            marginRight: '0.3em',
        },
        tooltip: {
            fontSize: '1em',
        },
        bottomContentContainer: {
            flexDirection: 'row-reverse',
            flex: 1,
            paddingTop: '2em',
            [theme.breakpoints.only('xs')]: {
                flexDirection: 'column',
                overflow: 'auto',
                paddingTop: 0,
            },
            '&$isEndUser': {
                height: '73%',
                [theme.breakpoints.between(
                    'xs',
                    theme.breakpoints.values.iPhone8_Landscape
                )]: {
                    height: 'auto',
                    paddingTop: 0,
                },
            },
        },
        isEndUser: {},
        bottomContent: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        bottomContentTitle: {
            display: 'flex',
            minHeight: '2em',
        },
        bottomContentTitleIcon: {
            marginRight: '0.3em',
        },
        bottomContentSubtitle: {
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: theme.palette.grey[500],
            marginTop: '1em',
        },
        bottomContentTextField: {
            marginTop: '0.5em',
        },
        bottomContentGroup: {
            position: 'relative',
            padding: '0 3em 0 2.3em',
            '& hr': {
                margin: '2em 0',
            },
        },
        bottomContentRight: {
            display: 'flex',
            marginLeft: 'auto',
            flexDirection: 'column',
            maxWidth: '16em',
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                marginLeft: '0',
                maxWidth: 'none',
                paddingBottom: '2.5em',
            },
        },
        exportToPdfButton: {},
        exportToPdfButtonTop: {
            alignSelf: 'flex-start',
            [theme.breakpoints.between('xs', theme.breakpoints.values.sm)]: {
                display: 'none',
            },
        },
        exportToPdfButtonBottom: {
            alignSelf: 'flex-start',
            marginTop: '2em',
            padding: '0.35em 1.2em',
            [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
                display: 'none',
            },
        },
        editCategoryButton: {
            display: 'flex',
            transform: 'scale(0.8)',
            height: '3em',
            alignItems: 'center',
            '& .MuiSvgIcon-root': {
                marginRight: '0.5em',
            },
        },
        reportedByEmail: {
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
        },
        widgetPhoto: {
            width: 185,
            marginTop: '1em',
        },
        floorPlanImage: {
            maxWidth: 210,
        },
        bodyContent: {
            flex: '1',
            padding: '0.6em',
            display: 'flex',
            flexDirection: 'row',
            overflow: 'auto',
        },
        commentEditor: {
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                marginBottom: '5em',
            },
        },
        reviewBtn: {
            width: '11em',
            margin: '1em',
            height: '3em',
        },
        editReportBtnSection: {
            margin: '0 1em 0 auto',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                margin: '1em 1em 0 0',
            },
        },
        assignedBtnSection: {
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                marginBottom: '1em',
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    className?: string;
    open: boolean;
    isPortal: boolean;
    vocFeedbackReports: Report[];
    isStatusRefreshing?: boolean;
    areCommentsLoading?: boolean;
    showUpdateCategory?: boolean;
    reportDetail?: ReportDetail;
    comments?: ReportComment[];
    onClose?: () => void;
    onStatusChange?: (reportId: string, status: ReportStatus) => void;
    onAssigneeChange?: (reportId: string, email: string) => void;
    onCommentPost?: (reportId: string, commentText: string) => Promise<void>;
    onDeleteReport?: (reportId: string) => void;
    onReturnToVoc?: () => void;
    getCategoryList?: (markerId: string) => void;
}

interface States {
    assignedTo: string;
    assignedToChanged: boolean;
    isEmailValid: boolean;
    isDeleteActionModalOpen: boolean;
}

const DEFAULT_STATE = {
    assignedTo: '',
    assignedToChanged: false,
    isEmailValid: true,
    isDeleteActionModalOpen: false,
};

export type WidgetContentGeneratorInstanceType =
    typeof WidgetContentGenerator & {
        generateOutputForPrint: () => WidgetDataForPrinting[];
    };

class WorkOrderDetails extends React.Component<Props, States> {
    public static defaultProps = {
        isPortal: true,
        showUpdateCategory: false,
    };

    state = DEFAULT_STATE;

    widgetContentGeneratorInstance: WidgetContentGeneratorInstanceType | null = null;
    setWidgetContentGeneratorInstance = (element: WidgetContentGeneratorInstanceType) => {
        this.widgetContentGeneratorInstance = element;
    };

    onCloseClicked = () => {
        const { onClose } = this.props;

        this.setState(DEFAULT_STATE);

        if (onClose) {
            onClose();
        }
    };

    onStatusChanged = (event: SelectChangeEvent<string>) => {
        const { reportDetail, onStatusChange } = this.props;

        if (!onStatusChange || !reportDetail) {
            return;
        }
        const statusKey = event.target.value;
        onStatusChange(
            reportDetail.fullId,
            ReportStatus[statusKey as keyof typeof ReportStatus]
        );
    };

    onAssignedToChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { reportDetail } = this.props;
        const assignedToEmail = event.target.value;
        const assignedToProperty = reportDetail
            ? reportDetail.assignedTo
            : undefined;
        const isEmailValid = isValidEmail(assignedToEmail);

        this.setState({
            assignedTo: assignedToEmail,
            assignedToChanged:
                !!assignedToEmail && assignedToEmail !== assignedToProperty,
            isEmailValid,
        });
    };

    onAssignedToOkClicked = () => {
        const { onAssigneeChange, reportDetail } = this.props;
        const { assignedTo } = this.state;

        if (!onAssigneeChange || !reportDetail || !assignedTo) {
            return;
        }

        onAssigneeChange(reportDetail.fullId, assignedTo);
        this.setState(DEFAULT_STATE);
    };

    onAssignedToCancelClicked = () => {
        this.setState(DEFAULT_STATE);
    };

    onFormSubmitted = (
        event: React.FormEvent<HTMLFormElement> & React.SyntheticEvent<unknown>
    ) => {
        event.preventDefault();
        this.onAssignedToOkClicked();
    };

    onDeleteClicked = () => {
        this.setState({ isDeleteActionModalOpen: true });
    };

    onCommentPosted = (commentText: string): Promise<void> => {
        const { reportDetail, onCommentPost } = this.props;

        if (!onCommentPost || !reportDetail) {
            return Promise.resolve();
        }

        return onCommentPost(reportDetail.fullId, commentText);
    };

    onDeleteDialogAction = (result: DialogResult) => {
        if (result === DialogResult.Ok) {
            const { reportDetail, onDeleteReport } = this.props;

            if (onDeleteReport) {
                onDeleteReport(reportDetail ? reportDetail.id : '');
            }
        }

        this.setState(DEFAULT_STATE);
    };

    onReturnToVocClicked = () => {
        const { onReturnToVoc } = this.props;
        if (onReturnToVoc) {
            onReturnToVoc();
        }
    };

    generatePDFButton = (extraClasses: string) => {
        const { classes, isPortal } = this.props;

        if (!isPortal) {
            return '';
        }
        const buttonClasses = `${classes.exportToPdfButton}${
            extraClasses ? ` ${extraClasses}` : ''
        }`;
        return (
            <Button
                className={buttonClasses}
                variant="outlined"
                onClick={this.onDownloadPDFClicked}
                color="info"
            >
                <PictureAsPdf />
                <Typography className={classes.buttonText} variant="overline">
                    DOWNLOAD PDF
                </Typography>
            </Button>
        );
    };

    onDownloadPDFClicked = () => {
        this.generatePDFPage();
    };

    onUpdateCategoryClicked = () => {
        const { reportDetail, getCategoryList } = this.props;

        if (reportDetail && getCategoryList) {
            getCategoryList(reportDetail.markerId);
        }
    };

    generatePDFPage = () => {
        const { reportDetail, comments } = this.props;
        if (!reportDetail) {
            return;
        }

        // The margin top/bottom for the PDF page
        const verticalMargin = 20;

        // Coordinates values are the absolute measures that jsPDF uses for PDF file creation
        // this values starting as X and Y axis positions (X=0 and Y=0 means the top left corner of page)
        // Check https://github.com/MrRio/jsPDF for more info
        let verticalCoordinate = verticalMargin;

        // The margin right for the PDF file
        const marginHorizontal = 20;
        const tabSpace = 10;

        // This the maximum value for Y coordinate that a page has
        // This number is dependent of the layout height for the PDF file
        const maxVerticalCoordinate = 288 - verticalMargin;

        function getNextVerticalCoordinate(
            rowsAdded = 1,
            nextLineIncrement = 6,
            additionalLineIncrement = 0
        ): number {
            const currentValue = verticalCoordinate;
            verticalCoordinate +=
                rowsAdded * nextLineIncrement + additionalLineIncrement;
            return currentValue;
        }

        const {
            assignedTo,
            email,
            reportSequenceNumber,
            folderName,
            markerName,
            moduleName,
            id: reportId,
            status,
            timeStamp,
            username,
        } = reportDetail;

        const timeStampAndFolder = `${DateFormatting.formatMarkerTimestamp(
            timeStamp
        )}     ${folderName}`;
        const pdfDocument = new jsPDF({ format: 'letter' });
        pdfDocument.addImage(ccLogoBottom, 'PNG', 170, 5, 30, 18);
        pdfDocument
            .setFont('Helvetica', 'bold')
            .setFontSize(18)
            .text(markerName, marginHorizontal, getNextVerticalCoordinate(2))
            .setFont('Helvetica', 'normal')
            .setFontSize(12)
            .text(
                timeStampAndFolder,
                marginHorizontal,
                getNextVerticalCoordinate()
            )
            .setFont('Helvetica', 'bold')
            .setFontSize(12)
            .text(
                `ID : ${reportSequenceNumber ? reportSequenceNumber : 'N/A'}`,
                marginHorizontal,
                getNextVerticalCoordinate()
            )
            .setFont('Helvetica', 'normal')
            .setFontSize(14)
            .text(
                [
                    `Reported by : ${username}`,
                    email,
                    `Type : ${moduleName}`,
                    `Status : ${status}`,
                    `Assigned to : ${assignedTo ? assignedTo : '[Unassigned]'}`,
                ],
                marginHorizontal,
                getNextVerticalCoordinate(7),
                { lineHeightFactor: 1.3 }
            )
            .setFontSize(18)
            .text('Summary', marginHorizontal, getNextVerticalCoordinate(2));

        //#region Summary
        const { widgetContentGeneratorInstance } = this;
        const lineIncrement = 6;
        if (widgetContentGeneratorInstance !== null) {
            const widgetDataElements =
                widgetContentGeneratorInstance.generateOutputForPrint();
            widgetDataElements.forEach((widgetData: WidgetDataForPrinting) => {
                const { widgetType, data } = widgetData;
                switch (widgetType) {
                    case WidgetType.Label:
                    case WidgetType.TextField:
                        pdfDocument
                            .setFontSize(12)
                            .text(
                                data as string,
                                marginHorizontal + tabSpace,
                                getNextVerticalCoordinate()
                            );
                        break;
                    case WidgetType.Photo: {
                        const {
                            format,
                            height,
                            imageEncoded,
                            timeStamp: imageTimeStamp,
                            width,
                        } = data as WidgetPhotoData;
                        if (imageEncoded && format) {
                            const shrunkWidth = Math.min(
                                width,
                                PDF_IMAGE_MAX_WIDTH
                            );
                            const shrunkHeight = Math.ceil(
                                (shrunkWidth * height) / width
                            );
                            const multiplier = Math.floor(
                                shrunkHeight / lineIncrement
                            );
                            pdfDocument.addImage(
                                imageEncoded,
                                format,
                                marginHorizontal + tabSpace,
                                getNextVerticalCoordinate(multiplier),
                                shrunkWidth,
                                shrunkHeight
                            );
                        }
                        if (imageTimeStamp) {
                            pdfDocument
                                .setFontSize(10)
                                .text(
                                    imageTimeStamp,
                                    marginHorizontal + tabSpace,
                                    getNextVerticalCoordinate()
                                );
                        }
                        break;
                    }
                }
            });
            getNextVerticalCoordinate(1);
        }
        //#endregion

        if (comments && comments.length > 0) {
            pdfDocument
                .setFontSize(18)
                .text(
                    'Activity',
                    marginHorizontal,
                    getNextVerticalCoordinate(2)
                );
            comments.forEach((commentItem: ReportComment) => {
                const {
                    comment,
                    info: { username: commentUsername },
                    timeStamp: commentTimeStamp,
                } = commentItem;

                // Split a string at every n characters (110 in this case) or to nearest previous space
                // This number is dependent of the layout width for the PDF file
                const splitComment = comment
                    .replace(/[\s\S]{1,110}(?!\S)/g, '$&\n')
                    .split('\n');
                const updatedComment = splitComment.map((line) => line.trim());

                // Check if coordinate exceed the maximum for create another page
                if (verticalCoordinate > maxVerticalCoordinate) {
                    verticalCoordinate = verticalMargin;
                    pdfDocument.addPage();
                }

                pdfDocument
                    .setFontSize(10)
                    .text(
                        `${commentUsername}    ${DateFormatting.formatMarkerTimestamp(
                            commentTimeStamp
                        )}`,
                        marginHorizontal + tabSpace,
                        getNextVerticalCoordinate()
                    )
                    .text(
                        updatedComment,
                        marginHorizontal + tabSpace,
                        getNextVerticalCoordinate(updatedComment.length, 4, 2)
                    );
            });
        }

        pdfDocument.save(
            `cc-${reportSequenceNumber ? reportSequenceNumber : reportId}.pdf`
        );
    };

    public render() {
        const {
            classes,
            className,
            comments,
            open,
            isPortal,
            isStatusRefreshing,
            areCommentsLoading,
            reportDetail,
            vocFeedbackReports,
            showUpdateCategory,
        } = this.props;

        if (!reportDetail) {
            return '';
        }

        const {
            assignedTo: assignedToState,
            assignedToChanged,
            isEmailValid,
            isDeleteActionModalOpen,
        } = this.state;

        const {
            agrees,
            assignedTo,
            email,
            folderName,
            markerName,
            miniMapImageEncoded,
            moduleName,
            timeStamp,
            reportSequenceNumber: id,
            status,
            username,
            userCanLeaveFeedback,
            userFeedback,
            widgets,
        } = reportDetail;
        const rootClasses = `${classes.root}${
            className ? ` ${className}` : ''
        }`;
        const currentStatus: string | undefined =
            EnumerationHelper.getEnumKeyFromValue(
                ReportStatus,
                status ? status : ''
            );
        const currentAssignedTo = assignedToState
            ? assignedToState
            : assignedTo;
        const isEmailInvalid = !!currentAssignedTo && !isEmailValid;
        const assignedToClasses = `${classes.assignedTo}${
            isEmailInvalid ? '' : ' cc-is-valid'
        }`;
        const timeStampFormatted =
            DateFormatting.formatMarkerTimestamp(timeStamp);
        const isVocView =
            status === 'Closed' && !!userFeedback && userFeedback.length > 0;
        const isVocReadonly = userCanLeaveFeedback === false && isVocView;

        let faceType = FaceType.None;
        if (userFeedback && userCanLeaveFeedback === false) {
            faceType = FacesReviewHelper.getCurrentFace(
                userFeedback[userFeedback.length - 1].content
            );
        }

        return (
            <SimpleModal
                classes={{
                    modalContainer: rootClasses,
                }}
                open={open}
                contentClasses={classes.bodyContent}
                header={
                    <div className={classes.headerContainer}>
                        <div className={classes.headerContentWrapper}>
                            <div className={classes.headerContent}>
                                <Typography variant="h5">
                                    {markerName}
                                </Typography>
                                <div className={classes.timeStampContainer}>
                                    <Typography
                                        className={classes.timeStamp}
                                        variant="caption"
                                    >
                                        {timeStampFormatted}
                                    </Typography>
                                    <Typography
                                        className={classes.folderName}
                                        variant="caption"
                                    >
                                        {folderName}
                                    </Typography>
                                </div>
                                <Typography variant="subtitle2">
                                    ID: {id ? id : 'N/A'}
                                </Typography>
                            </div>
                            {!isPortal && isVocView ? (
                                <FacesReviewBtn
                                    className={classes.reviewBtn}
                                    onClick={this.onReturnToVocClicked}
                                    buttonText="Rate"
                                    selectedOption={faceType}
                                    isReadOnly={userCanLeaveFeedback}
                                />
                            ) : (
                                ''
                            )}
                            {isPortal ||
                            !isVocView ||
                            isVocReadonly ||
                            vocFeedbackReports.length > 1 ?
                                (
                                    <IconButton
                                        className={classes.closeButton}
                                        onClick={this.onCloseClicked}
                                    >
                                        <Close />
                                    </IconButton>
                                ) : (
                                    ''
                                )
                            }
                        </div>
                        {!isStatusRefreshing ? <Divider /> : ''}
                        {!isStatusRefreshing ? (
                            <form
                                className={classes.selectsContainer}
                                autoComplete="off"
                                onSubmit={this.onFormSubmitted}
                            >
                                {isPortal ? (
                                    <FormControl
                                        variant="standard"
                                        className={classes.status}
                                    >
                                        <InputLabel htmlFor="cc-status">
                                            STATUS
                                        </InputLabel>
                                        <Select
                                            value={currentStatus}
                                            onChange={this.onStatusChanged}
                                            inputProps={{
                                                name: 'status',
                                                id: 'cc-status',
                                            }}
                                        >
                                            {STATUSES_MENU_ITEMS}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <div
                                        className={
                                            classes.verticalTextContainer
                                        }
                                    >
                                        <Typography
                                            className={
                                                classes.bottomContentSubtitle
                                            }
                                            variant="overline"
                                        >
                                            Status
                                        </Typography>
                                        <Typography variant="body2">
                                            {currentStatus}
                                        </Typography>
                                    </div>
                                )}
                                {isPortal ? (
                                    <TextField
                                        className={assignedToClasses}
                                        id="assigned_to"
                                        name="assigned_to"
                                        margin="normal"
                                        label="ASSIGNED TO"
                                        variant="standard"
                                        placeholder={
                                            !currentAssignedTo
                                                ? 'Unassigned'
                                                : ''
                                        }
                                        value={currentAssignedTo}
                                        error={isEmailInvalid}
                                        helperText={
                                            isEmailInvalid
                                                ? 'Please enter a valid email'
                                                : ''
                                        }
                                        onChange={this.onAssignedToChanged}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                ) : (
                                    <div
                                        className={
                                            classes.verticalTextContainer
                                        }
                                    >
                                        <Typography
                                            className={
                                                classes.bottomContentSubtitle
                                            }
                                            variant="overline"
                                        >
                                            Assigned To
                                        </Typography>
                                        <Typography variant="body2">
                                            {currentAssignedTo &&
                                            currentAssignedTo !== ''
                                                ? currentAssignedTo
                                                : 'Unassigned'}
                                        </Typography>
                                    </div>
                                )}
                                {assignedToChanged ? (
                                    <div className={classes.assignedBtnSection}>
                                        <Button
                                            className={classes.applyButton}
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={this.onAssignedToOkClicked}
                                        >
                                            APPLY
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={
                                                this.onAssignedToCancelClicked
                                            }
                                        >
                                            CANCEL
                                        </Button>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div className={classes.editReportBtnSection}>
                                    <CCAgrees
                                        className={`${classes.agrees} ${
                                            !isPortal ? classes.isEndUser : ''
                                        }`}
                                        count={
                                            agrees && agrees.length > 0
                                                ? agrees.length
                                                : 0
                                        }
                                        tooltip="Agrees"
                                    />
                                    {isPortal && showUpdateCategory ? (
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="info"
                                            onClick={
                                                this.onUpdateCategoryClicked
                                            }
                                            className={
                                                classes.editCategoryButton
                                            }
                                        >
                                            <Edit /> Edit Category
                                        </Button>
                                    ) : (
                                        ''
                                    )}
                                    {isPortal ? (
                                        <Tooltip
                                            title="Delete Report"
                                            classes={{
                                                tooltip: classes.tooltip,
                                            }}
                                        >
                                            <IconButton
                                                onClick={this.onDeleteClicked}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </form>
                        ) : (
                            ''
                        )}
                        <Divider />
                    </div>
                }
                footer={' '}
            >
                <CCSpinner
                    loading={!!isStatusRefreshing}
                    overlayVisible={true}
                    size={100}
                >
                    <div
                        className={`${classes.bottomContentContainer} ${
                            classes.contentContainer
                        } ${!isPortal ? classes.isEndUser : ''}`}
                    >
                        <div className={classes.bottomContentRight}>
                            {this.generatePDFButton(
                                classes.exportToPdfButtonTop
                            )}
                            <Typography
                                className={classes.bottomContentSubtitle}
                                variant="overline"
                            >
                                Category
                            </Typography>
                            <Typography variant="body2">
                                {moduleName}
                            </Typography>
                            <Typography
                                className={classes.bottomContentSubtitle}
                                variant="overline"
                            >
                                REPORTED BY
                            </Typography>
                            <Typography variant="body2">{username}</Typography>
                            <Typography
                                className={classes.reportedByEmail}
                                variant="body2"
                            >
                                {email}
                            </Typography>
                            <Typography variant="caption">
                                {timeStampFormatted}
                            </Typography>
                            {miniMapImageEncoded ? (
                                <React.Fragment>
                                    <Typography
                                        className={
                                            classes.bottomContentSubtitle
                                        }
                                        variant="overline"
                                    >
                                        FLOOR PLAN
                                    </Typography>
                                    <img
                                        alt="map"
                                        className={classes.floorPlanImage}
                                        src={miniMapImageEncoded}
                                    />
                                </React.Fragment>
                            ) : (
                                ''
                            )}
                            {this.generatePDFButton(
                                classes.exportToPdfButtonBottom
                            )}
                        </div>
                        <div className={classes.bottomContent}>
                            <div className={classes.bottomContentTitle}>
                                <Comment
                                    className={classes.bottomContentTitleIcon}
                                />
                                <Typography variant="h5">Summary</Typography>
                            </div>
                            <div className={classes.bottomContentGroup}>
                                <WidgetContentGenerator
                                    innerRef={this.setWidgetContentGeneratorInstance}
                                    classes={{
                                        widgetLabel:
                                            classes.bottomContentSubtitle,
                                        widgetTextFieldRead:
                                            classes.bottomContentTextField,
                                        widgetPhotoRead: classes.widgetPhoto,
                                    }}
                                    widgets={widgets}
                                    generatorType={
                                        WidgetContentGeneratorType.Read
                                    }
                                    timeStamp={timeStampFormatted}
                                />
                                <Divider />
                            </div>
                            <div className={classes.bottomContentTitle}>
                                <Schedule
                                    className={classes.bottomContentTitleIcon}
                                />
                                <Typography variant="h5">Activity</Typography>
                            </div>
                            <div
                                className={`${classes.bottomContentGroup} ${classes.commentEditor}`}
                            >
                                <CommentEditor
                                    comments={comments}
                                    isLoading={areCommentsLoading}
                                    onCommentPost={this.onCommentPosted}
                                />
                            </div>
                        </div>
                    </div>
                </CCSpinner>
                <SimpleDialog
                    open={isDeleteActionModalOpen}
                    titleText=""
                    contentText="Are you sure you want to delete the selected report?"
                    buttonOkLabel="YES"
                    buttonCancelLabel="NO"
                    onDialogResult={this.onDeleteDialogAction}
                />
            </SimpleModal>
        );
    }
}

const MUIComponent = withStyles(styles)(WorkOrderDetails);
export { MUIComponent as WorkOrderDetails };
