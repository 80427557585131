// tslint: no-empty
import { createRouterState, RouterStore } from 'mobx-state-router';
import { AnalyticsAdapter } from '../adapters/analytics-adapter';
import { LoginAuthorizationAdapter } from '../adapters/login-authorization-adapter';
import { ReportAdapter } from '../adapters/report-adapter';
import { ManagerStore } from './manager.store';
import { RootStoreBase } from './root.store.base';
import { ROUTE_NAMES, routes } from './routes';
import { StoreAdapters } from './store-adapters';
import { SchedulesAdapter } from '../adapters/schedules-adapter';
import { TasksAdapter } from '../adapters/tasks-adapter';
import { CompletionAdapter } from '../adapters/completion-adapter';

const notFound = createRouterState(ROUTE_NAMES.NOT_FOUND);

export class RootStore implements RootStoreBase {
    routerStore: RouterStore = new RouterStore(routes, notFound, { rootStore: this });
    managerStore: ManagerStore = new ManagerStore(this);

    // Adapters for use by all stores
    adapters: StoreAdapters  = {
        analyticsAdapter: AnalyticsAdapter.getAdapter(),
        loginAuthorizationAdapter: LoginAuthorizationAdapter.getAdapter(),
        reportAdapter: ReportAdapter.getAdapter() as ReportAdapter,
        schedulesAdapter: SchedulesAdapter.getAdapter(),
        tasksAdapter: TasksAdapter.getAdapter(),
        completionAdapter: CompletionAdapter.getAdapter()
    };

    // ----- Life cycle hooks -----
    // Useful for starting and stopping observers, autoruns and reactions

    init(): void { return; }

    destroy(): void { return; }
}
