import {
    Button,
    Step,
    StepLabel,
    Stepper,
    Typography
} from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
import { Marker } from '../../models/marker';
import {
    ReportModule,
    WidgetDataValues
} from '../../models/report';
import { CameraType } from '../../shared/components/qr-scanner-v3';
import { SimpleModal } from '../../shared/components/simple-modal';
import {
    AddReportWizardStepLocation,
    AddReportWizardStepLocationProps,
    AddReportWizardStepQrLocation,
    AddReportWizardStepQrLocationProps,
    AddReportWizardStepReportDetails,
    AddReportWizardStepFolderTree,
    AddReportWizardStepFolderTreeProps
} from './steps';
import {
    AddReportWizardStepReportType,
    AddReportWizardStepReportTypeProps
} from './steps/add-report-wizard-step-report-type';
import { AddReportWizardStepSubmitReport } from './steps/add-report-wizard-step-submit-report';
import { EntityFloorplan } from '../../shared/domain';

enum AddReportWizardSteps {
    FolderTree = 0,
    Location,
    QRLocation,
    ReportType,
    IssueDetails,
    SubmitReport
}

enum AddReportWizardResult {
    Unknown = -1,
    Continue = 0,
    Submit,
    Back,
    Cancel
}

const styles = (theme: Theme) => createStyles({
    root: {
        borderRadius: '0.4em',
        height: '90%',
        minWidth: '36em',
        width: '70%',
        maxWidth: '80em',
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone12ProMax)]: {
            minWidth: 'auto',
            minHeight: 'auto',
            borderRadius: 0
        }
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: '1em',
    },
    footer:{
        alignItems: 'center',
        flex: 1,
        padding: '1em',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8)]: {
            padding: '1em 0.5em'
        }
    },
    footerButtonContainedSecondary: {},
    footerButtonDisabled: {},
    footerButtonLabel: {},
    footerButton: {
        marginRight: '1em',
        '&.hidden': {
            display: 'none'
        },
        [theme.breakpoints.only('xs')]: {
            marginRight: '0.5em'
        },
        // The code below fixes an iPhone issue that displays
        // the font color gray when the button is not disabled
        '&$footerButtonContainedSecondary > $footerButtonLabel': {
            color: '#ffff'
        },
        '&$footerButtonContainedSecondary$footerButtonDisabled > $footerButtonLabel': {
            color: 'rgba(0, 0, 0, 0.26)'
        }
    },
    footerCancelButton: {
        padding: '0.45em 1.7em'
    },
    listItems: {},
    stepper: {
        padding: 0,
        paddingBottom: '2em',
        minHeight: '7.1em',
        '& $stepperLabel': {
            marginTop: '0.5em',
            '&.placeholder': {
                fontStyle: 'italic'
            }
        },
        '& $stepperLabel.firstStepper': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '10em',
        }
    },
    stepperLabel: {},
    stepperLabelSubTitle: {
        textAlign: 'center'
    },
    stepReportContainer: {
        flex: '1 1',
        overflow: 'auto'
    },
    switchCameraBtn: {
        flex: 1
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    open: boolean;
    currentStep: AddReportWizardSteps;
    steps: AddReportWizardSteps[];
    reportModules: ReportModule[];
    isBackButtonVisible: boolean;
    showStepper: boolean;
    selectedSearchItem?: Marker;
    selectedFloorplan?: EntityFloorplan | undefined;
    onWizardResult?: (result: AddReportWizardResult, data?: WidgetDataValues) => void;
    onPhotoChanged?: (photo: string) => void;
    onSelectFloorplan?: (selectedFloorplan: EntityFloorplan | undefined) => void;
}

interface States {
    isReportDetailsValid: boolean;
    searchTerms: string;
    cameraType: CameraType;
}

type AddReportWizardProps =
    Props &
    AddReportWizardStepLocationProps &
    AddReportWizardStepQrLocationProps &
    AddReportWizardStepReportTypeProps &
    AddReportWizardStepFolderTreeProps;

class AddReportWizard extends React.Component<
    AddReportWizardProps,
    States
> {
    public static defaultProps = {
        isBackButtonVisible: true,
        isLoading: false,
        showStepper: true
    };

    initialState =  {
        isReportDetailsValid: false,
        searchTerms: '',
        cameraType: CameraType.Rear
    };

    state = Object.assign({}, this.initialState);
    
    widgetValues = {} as WidgetDataValues;

    getCurrentModule = () => {
        const { reportModules } = this.props as Props;
        // It goes with the assumption that we have only one module
        // in the array (according a discussion with Scott L.)
        return (!!reportModules && reportModules.length > 0) ? reportModules[0] : undefined;
    };

    onSearchClicked = (searchTerms: string) => {
        this.setState({searchTerms});

        const { onSearchClick } = this.props as AddReportWizardProps;
        if (onSearchClick) {
            onSearchClick(searchTerms);
        }
    };

    onModalResult = (result: AddReportWizardResult) => () => {
        const {
            onWizardResult,
            currentStep,
            onSelectFloorplan
        } = this.props as AddReportWizardProps;

        if (onWizardResult) {
            // If submit button was hit we create the last page data
            let data: WidgetDataValues | undefined; 
            
            if ((currentStep === AddReportWizardSteps.IssueDetails) ||
                (currentStep === AddReportWizardSteps.SubmitReport)) {
                data = this.widgetValues;
            
                // If the user hasn't touched any control in the wizard
                // the onChange callback wasn't triggered so we manually get
                // the default values from the current module.
                if (data && Object.keys(data).length < 1) {
                    const reportModule = this.getCurrentModule();
                    if (reportModule) {
                        data = reportModule.reportContent.responseValues;
                    }
                }
            }

            onWizardResult(result, data);
        }

        // Some state's spring cleaning
        switch (result) {
            case AddReportWizardResult.Back:
                // Reset the report validated value when we hit the back button
                this.setState({isReportDetailsValid: false});
                this.widgetValues = {} as WidgetDataValues;

                if (currentStep === AddReportWizardSteps.Location && onSelectFloorplan) {
                    onSelectFloorplan(undefined as EntityFloorplan | undefined);
                }
                break;
            case AddReportWizardResult.Cancel:
                if (onSelectFloorplan) {
                    onSelectFloorplan(undefined as EntityFloorplan | undefined);
                }
                break;
            case AddReportWizardResult.Submit:
                // Reset the current state to the initial state
                this.setState(this.initialState);
                this.widgetValues = {} as WidgetDataValues;
                if (onSelectFloorplan) {
                    onSelectFloorplan(undefined as EntityFloorplan | undefined);
                }
                break;
        }
    };

    onReportDetailsFormValidated = (isReportDetailsValid: boolean) => {
        this.setState({isReportDetailsValid});
    };

    onWidgetChanged = (widgetValues: WidgetDataValues) => {
        this.widgetValues = widgetValues;
    };

    onSwitchCamera = () => {
        if (this.state.cameraType === CameraType.Rear) {
            this.setState({ cameraType: CameraType.Front });
        } else {
            this.setState({ cameraType: CameraType.Rear });
        }
    };

    public render() {
        const {
            classes,
            className,
            open,
            steps,
            currentStep,
            isLoading,
            isBackButtonVisible,
            reportModuleGroups,
            searchOffset,
            searchResults,
            searchResultsTotal,
            searchRowsPerPage,
            selectedFloorplan,
            selectedSearchItemId,
            selectedSearchItem,
            selectedReportModuleGroupId,
            showStepper,
            folderTreeData,
            foldersClicked,
            foldersExpanded,
            onFolderTreeClick,
            onFolderTreeToggle,
            onSelectFloorplan,
            onSearchBufferOverBoundary,
            onSelectedMarker,
            onReportModuleGroupSelect,
            onQRScan
        } = this.props as AddReportWizardProps;
        const {
            isReportDetailsValid,
            searchTerms,
            cameraType
        } = this.state;
        const currentStepPosition = steps.indexOf(currentStep);
        const isFirstStep = currentStepPosition <= 0;
        const backButtonClasses =
            `${classes.footerButton}${isFirstStep || !isBackButtonVisible ? ' hidden': ''}`;
        const selectedMarker = selectedSearchItem ? selectedSearchItem : undefined;

        let isContinueButtonDisabled;
        if (currentStep === AddReportWizardSteps.FolderTree) {
            isContinueButtonDisabled = true;
        } else if (currentStep === AddReportWizardSteps.Location) {
            isContinueButtonDisabled = !selectedSearchItemId;
        } else {
            isContinueButtonDisabled = !selectedReportModuleGroupId || !isReportDetailsValid;
        }

        const isStepReportDisabled = !selectedReportModuleGroupId;
        const selectedReportModuleGroup = selectedReportModuleGroupId ? reportModuleGroups.find((moduleGroup) => {
            return moduleGroup.id === selectedReportModuleGroupId;
        }) : undefined;
        const totalSteps = steps.length;
        const lastStep = totalSteps > 0 ? steps[totalSteps-1] : steps[0];
        const isLastStep = (currentStep === lastStep);
        const isReportDetailsDisabled = !isLastStep;
        const reportModule = this.getCurrentModule();
        const submitBtnClasses = {
            containedSecondary: classes.footerButtonContainedSecondary,
            disabled: classes.footerButtonDisabled,
            label: classes.footerButtonLabel
        };
        
        return (
            <SimpleModal
                className={`${classes.root}${className ? ` ${className}` : ''}`}
                classes={{
                    content: classes.modalContent
                }}
                open={open}
                buttonOkLabel=""
                buttonCancelLabel=""
                footer={
                    <div className={classes.footer}>
                        <div>
                            <Button
                                className={backButtonClasses}
                                variant="contained"
                                color="primary"
                                onClick={this.onModalResult(AddReportWizardResult.Back)}
                            >
                                Back
                            </Button>
                            <Button
                                key={`${isContinueButtonDisabled}`}
                                className={classes.footerButton}
                                classes={submitBtnClasses}
                                variant="contained"
                                color="secondary"
                                disabled={isContinueButtonDisabled}
                                onClick={
                                    this.onModalResult(
                                        isLastStep ?
                                            AddReportWizardResult.Submit :
                                            AddReportWizardResult.Continue
                                    )
                                }
                            >
                                { isLastStep ? 'SUBMIT' : 'CONTINUE' }
                            </Button>
                            <Button
                                className={classes.footerCancelButton}
                                onClick={this.onModalResult(AddReportWizardResult.Cancel)}
                                color="info"
                            >
                                Cancel
                            </Button>
                        </div>
                        { currentStep === AddReportWizardSteps.QRLocation ?
                            <Button
                                className={classes.switchCameraBtn}
                                onClick={this.onSwitchCamera}
                            >
                                Switch Camera
                            </Button>
                            : ''
                        }
                    </div>
                }
            >
                { showStepper ?
                    <Stepper
                        className={classes.stepper}
                        alternativeLabel={true}
                        activeStep={currentStepPosition}
                    >
                        <Step>
                            <StepLabel
                                classes={{label: `${classes.stepperLabel} firstStepper${!selectedMarker ? ' placeholder' : ''}`}}
                                optional={
                                    selectedMarker ?
                                        <Typography
                                            className={classes.stepperLabelSubTitle}
                                            variant="caption"
                                        >
                                            {selectedMarker.folderName}
                                        </Typography> :
                                        undefined
                                }
                            >
                                { selectedMarker ?
                                    selectedMarker.name:
                                    'Choose a Marker'
                                }
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel
                                classes={{label: `${classes.stepperLabel}${isStepReportDisabled ? ' placeholder' : ''}`}}
                            >
                                {
                                    selectedReportModuleGroup ?
                                        `${selectedReportModuleGroup.name} Issue Report` :
                                        'Choose Report Type'
                                }
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel
                                classes={{label: `${classes.stepperLabel}${isReportDetailsDisabled ? ' placeholder' : ''}`}}
                            >
                                Report Details
                            </StepLabel>
                        </Step>
                    </Stepper>
                    : ''
                }
                {
                    (currentStep === AddReportWizardSteps.FolderTree) ?
                        <AddReportWizardStepFolderTree
                            folderTreeData={folderTreeData}
                            onFolderTreeClick={onFolderTreeClick}
                            onFolderTreeToggle={onFolderTreeToggle}
                            onSelectFloorplan={onSelectFloorplan}
                            foldersClicked={foldersClicked}
                            foldersExpanded={foldersExpanded}
                            isLoading={isLoading}
                            selectedFloorplan={selectedFloorplan}
                        />
                        :
                        (currentStep === AddReportWizardSteps.QRLocation) ?
                            <AddReportWizardStepQrLocation
                                onQRScan={onQRScan}
                                facingMode={cameraType}
                            />
                            :
                            (currentStep === AddReportWizardSteps.Location) ?
                                <AddReportWizardStepLocation
                                    classes={{
                                        listItems: classes.listItems
                                    }}
                                    placeholder="Search for a location"
                                    isLoading={isLoading}
                                    initialSearchTerms={searchTerms}
                                    searchResults={searchResults}
                                    searchResultsTotal={searchResultsTotal}
                                    selectedSearchItemId={selectedSearchItemId}
                                    searchOffset={searchOffset}
                                    searchRowsPerPage={searchRowsPerPage}
                                    onSearchBufferOverBoundary={onSearchBufferOverBoundary}
                                    onSearchClick={this.onSearchClicked}
                                    onSelectedMarker={onSelectedMarker}
                                    selectedFloorplan={selectedFloorplan}
                                />
                                :
                                (currentStep === AddReportWizardSteps.ReportType) ?
                                    <AddReportWizardStepReportType
                                        classes={{
                                            paper: classes.stepReportContainer
                                        }}
                                        reportModuleGroups={reportModuleGroups}
                                        onReportModuleGroupSelect={onReportModuleGroupSelect}
                                        selectedReportModuleGroupId={selectedReportModuleGroupId}
                                    /> :
                                    (currentStep === AddReportWizardSteps.IssueDetails) ?
                                        <AddReportWizardStepReportDetails
                                            reportModule={reportModule}
                                            onValidationChange={this.onReportDetailsFormValidated}
                                            onWidgetChange={this.onWidgetChanged}
                                        />
                                        : (currentStep === AddReportWizardSteps.SubmitReport) ?
                                            <AddReportWizardStepSubmitReport
                                                isLoading={isLoading}
                                                reportModuleGroups={reportModuleGroups}
                                                reportModule={selectedReportModuleGroupId && !isLoading? reportModule : undefined}
                                                selectedReportModuleGroupId={selectedReportModuleGroupId}
                                                onReportModuleGroupSelect={onReportModuleGroupSelect}
                                                onValidationChange={this.onReportDetailsFormValidated}
                                                onWidgetChange={this.onWidgetChanged}
                                            />
                                            : 'Unknown Step'
                }
            </SimpleModal>
        );
    }
}

const MUIComponent = withStyles(styles)(AddReportWizard);
export { MUIComponent as AddReportWizard };
export { AddReportWizardResult, AddReportWizardSteps };
