import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
import HappyFace from '../../shared/components/icons/face-happy';
import RegularFace from '../../shared/components/icons/face-neutral';
import SadFace from '../../shared/components/icons/face-sad';

export enum FaceType {
    None = -1,
    Sad,
    Neutral,
    Happy
}

const happyFaceColor = '#28B557';
const neutralFaceColor = '#FFEC00';
const sadFaceColor = '#F2724A';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: '7em',
        width: '100%',
        maxWidth: '35em'
    },
    faceContainer: {
        borderRadius: '50%',
        overflow: 'hidden',
        flex: '1 1 0',
        margin: '0 5%',

        '&:first-child': {
            marginLeft: '0'
        },
        '&:last-child': {
            marginRight: '0'
        }
    },
    faces: {
        width: '100%'
    },
    faceHappy: {},
    faceNeutral: {},
    faceSad: {},
    facesHover: {
        cursor: 'pointer',

        '&:hover$faceHappy path:first-child': {
            fill: happyFaceColor
        },
        '&:hover$faceNeutral path:first-child': {
            fill: neutralFaceColor
        },
        '&:hover$faceSad path:first-child': {
            fill: sadFaceColor
        }
    },
    facesDisabled: {
        '&$faceHappy path:first-child': {
            fill: happyFaceColor
        },
        '&$faceNeutral path:first-child': {
            fill: neutralFaceColor
        },
        '&$faceSad path:first-child': {
            fill: sadFaceColor
        }
    },
    faceSelected: {
        '&$faceHappy path:first-child': {
            fill: happyFaceColor
        },
        '&$faceNeutral path:first-child': {
            fill: neutralFaceColor
        },
        '&$faceSad path:first-child': {
            fill: sadFaceColor
        }
    },
    radio: {
        display: 'none'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    selectedOption: FaceType;
    isReadOnly?: boolean;
    disabled: boolean
    onChange?: (selectedValue: FaceType) => void;
}

class FacesReview extends React.Component<Props> {
    public static defaultProps = {
        selectedOption: FaceType.None,
        disabled: false
    };

    state = {
    };

    onValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange } = this.props;
        if (!onChange) {
            return;
        }

        const selectedValue = parseInt(event.target.value, 10);

        if (selectedValue in FaceType) {
            onChange(selectedValue);
        }
    };

    public render() {
        const {
            className,
            classes,
            isReadOnly,
            selectedOption,
            disabled
        } = this.props;

        const mainClasses = `${classes.root}${className ? ` ${className}` : ''}`;
        const disableFaceClasses = !isReadOnly && !disabled ? ` ${classes.facesHover}` : '';
        const hoverFaceClasses = isReadOnly && selectedOption === FaceType.None ? ` ${classes.facesDisabled}` : '';
        const FaceClasses = `${classes.faces}${disableFaceClasses}${hoverFaceClasses}`;

        return (
            <div className={mainClasses}>
                <div className={classes.faceContainer}>
                    <label>
                        <input 
                            type="radio"
                            className={classes.radio}
                            value={FaceType.Happy}
                            name="review"
                            checked={selectedOption === FaceType.Happy}
                            onChange={this.onValueChanged}
                            disabled={isReadOnly || disabled} />
                        <HappyFace className={`${FaceClasses} ${classes.faceHappy}${selectedOption === FaceType.Happy ? ` ${classes.faceSelected}` : ''}`} />
                    </label>
                </div>
                <div className={classes.faceContainer}>
                    <label>
                        <input
                            type="radio"
                            className={classes.radio}
                            value={FaceType.Neutral}
                            name="review"
                            checked={selectedOption === FaceType.Neutral}
                            onChange={this.onValueChanged}
                            disabled={isReadOnly || disabled} />
                        <RegularFace className={`${FaceClasses} ${classes.faceNeutral}${selectedOption === FaceType.Neutral ? ` ${classes.faceSelected}` : ''}`} />
                    </label>
                </div>
                <div className={classes.faceContainer}>
                    <label>
                        <input
                            type="radio"
                            className={classes.radio}
                            value={FaceType.Sad}
                            name="review"
                            checked={selectedOption === FaceType.Sad}
                            onChange={this.onValueChanged}
                            disabled={isReadOnly || disabled} />
                        <SadFace className={`${FaceClasses} ${classes.faceSad}${selectedOption === FaceType.Sad ? ` ${classes.faceSelected}` : ''}`} />
                    </label>
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(FacesReview);
export {MUIComponent as FacesReview};
