import * as React from 'react';

import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
    LocalizationProvider,
    DateTimePickerProps,
    DateTimeValidationError,
    DateTimePicker
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {}
});

interface Props extends WithStyles<typeof styles> {
    label?: string;
    className?: string;
    defaultDate?: dayjs.Dayjs;
    onDateTimeChange?: (date: Date, validationError: string | null) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class CCDateTimePicker extends React.Component<Props & DateTimePickerProps<any>> {
    public static defaultProps = {
    };

    state = {
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onDateChanged = (dayjsValue: any, context: FieldChangeHandlerContext<DateTimeValidationError>) => {
        const {
            onDateTimeChange
        } = this.props;

        if (!onDateTimeChange) {
            return;
        }

        const { validationError } = context;

        onDateTimeChange(dayjsValue, validationError);
    };

    public render() {
        const {
            classes,
            className,
            defaultDate,
            ...DateTimePickerProps
        } = this.props;

        const rootClasses = `${classes.root}${className ? ` ${className}` : ''}`;
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    // To check all datetime picker props visit 
                    // https://mui.com/x/api/date-pickers/date-time-picker/
                    {...DateTimePickerProps}
                    className={rootClasses}
                    onChange={this.onDateChanged}
                    defaultValue={defaultDate}
                />
            </LocalizationProvider>
        );
    }
}

const MUIComponent = withStyles(styles)(CCDateTimePicker);
export { MUIComponent as CCDateTimePicker };
