import { API } from '../shared/constants';
import {
    AnalyticsTab,
    EntityAnalyticsTab
} from '../shared/domain';
import { AnalyticsBaseAdapter } from './analytics-base-adapter';
import { HttpConnectorAdapter } from './http-connector-adapter';

let singletonInstance: AnalyticsAdapter;
export class AnalyticsAdapter implements AnalyticsBaseAdapter {
    static getAdapter(): AnalyticsBaseAdapter {
        if (!singletonInstance) {
            singletonInstance = new AnalyticsAdapter();
        }

        return singletonInstance;
    }

    async getAnalyticsTab(tabId: string): Promise<AnalyticsTab> {
        if (!tabId) {
            return Promise.reject();
        }
        const response = await HttpConnectorAdapter
            .get(
                API.URL.ANALYTICS.GET_TAB
                    .replace(API.URL.Tokens.ANALYTICS.TAB_ID, tabId)
            );

        const result = AnalyticsTab.create(response.data as EntityAnalyticsTab);
        if (!result){
            return Promise.reject();
        }
        return result;
    }
}
