import {
    Chip,
    InputProps as MUIInputProps,
    MenuItem,
    MenuItemClassKey,
    Paper,
    StandardTextFieldProps,
    TextField,
    Typography
} from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Downshift, { GetInputPropsOptions } from 'downshift';
import memoize from 'memoize-one';
import * as React from 'react';

export enum TypeaheadType {
    DropdownChips = 0
}

export interface TypeaheadData {
    id: string;
    name: string;
}
interface LocalStyles {
    classes: {
        inputFieldWrapper: string,
        title: string,
        inputPaper: string,
        inputRoot: string,
        inputInput: string,
        inputInputDisabled: string,
        inputLabel: string
    }
}

//#region Global Render Functions
function renderInputField(
    inputProps: GetInputPropsOptions &
                Partial<MUIInputProps> &
                StandardTextFieldProps & LocalStyles
): JSX.Element {
    const { InputProps, classes, title, disabled, ref, ...other } = inputProps;

    return (
        <div className={classes.inputFieldWrapper}>
            <Typography
                className={classes.title}
                variant="button"
            >
                {title}
            </Typography>
            <Paper className={classes.inputPaper}>
                <TextField
                    variant="standard"
                    InputProps={{
                        inputRef: ref,
                        classes: {
                            root: classes.inputRoot,
                            input: classes.inputInput,
                            disabled: classes.inputInputDisabled
                        },
                        ...InputProps
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.inputLabel
                        }
                    }}
                    {...other}
                    disabled={disabled}
                />
            </Paper>
        </div>
    );
}

function getTypeAheadMatchingItems(
    typedCharacters: string,
    typeAheadData: TypeaheadData[],
    selectedItems: TypeaheadData[],
    maxVisibleEntries: number,
    strictSearch: boolean
): TypeaheadData[] {
    const trimmedTypedCharacters = typedCharacters.trim().toLowerCase();
    const inputLength = trimmedTypedCharacters.length;
    if ((maxVisibleEntries < 1) || (inputLength < 0)) {
        return [];
    }

    let count = 0;
    return (
        typeAheadData.filter(data => {
            const { name } = data;
            const isNotSelected = selectedItems.indexOf(data) === -1;
            const isDataMatching =
                (inputLength === 0) ?
                    true
                    : strictSearch ?
                        ( name.slice(0, inputLength).toLowerCase() === trimmedTypedCharacters)
                        : ( name.indexOf(trimmedTypedCharacters) >= 0);
            const keep = (count < maxVisibleEntries) && isDataMatching && isNotSelected;

            if (keep) {
                count++;
            }

            return keep;
        })
    );
}

function renderChild ( 
    typeAheadItem: TypeaheadData,
    index: number,
    itemProps: {classes: Partial<Record<MenuItemClassKey, string>>; className: string;},
    highlightedIndex: number,
    selectedItems: TypeaheadData[],
    hideSelection: boolean
): JSX.Element | '' {
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItems.indexOf(typeAheadItem) > -1;

    const {
        classes,
        className,
        ...other
    } = itemProps;

    if (isSelected && hideSelection) {
        return '';
    }
    
    return (
        <MenuItem
            disabled={isSelected}
            {...other}
            key={typeAheadItem.id}
            className={className}
            classes={classes}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: 400
            }}
        >
            {typeAheadItem.name}
        </MenuItem>
    );
}

//#endregion

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        position: 'relative',
    },
    inputFieldWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    title: {},
    paper: {
        maxHeight: '13em',
        overflowY: 'auto',
        marginTop: theme.spacing(1),
        zIndex: 1,
    },
    chip: {
        height: '2.5em',
        margin: theme.spacing(0.5),
        borderRadius: '0.7em'
    },
    chipText: {
        fontSize: '0.8em',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        padding: '0 1em'
    },
    chipDeleteIcon: {
        width: '0.8em'
    },
    inputRoot: {
        flexWrap: 'wrap',
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1,
        '&$inputInputDisabled':{
            display: 'none'
        }
    },
    inputInputDisabled: {},
    inputPaper: {
        flex: 1,
        padding: '0.4em',
    },
    inputLabel: {}
});

function getItemIndexFromTypeaheadDataArray(data: TypeaheadData[], item: TypeaheadData): number {
    return data.findIndex((selectedItem: TypeaheadData) => selectedItem.id === item.id);
}

interface Props extends WithStyles<typeof styles> {
    data: TypeaheadData[];
    multiple: boolean;
    type: TypeaheadType;
    className?: string;
    hideSelection: boolean;
    maxVisibleEntries: number;
    placeholder?: string;
    selectedItemsIds?: string[];
    strictSearch: boolean;
    title?: string;
    label?: string;
    onItemAdded?: (addedItem: TypeaheadData) => void;
    onItemDelete?: (removedItem: TypeaheadData) => void;
}

interface States {
    typedCharacters: string;
    selectedItems: TypeaheadData[];
}

class Typeahead extends React.Component<Props, States> {
    public static defaultProps = {
        hideSelection: true,
        maxVisibleEntries: 100,
        strictSearch: true
    };

    state = {
        typedCharacters: '',
        selectedItems: [] as TypeaheadData[]
    };

    renderTypeAheadChildren = memoize(
        ( typeAheadData: TypeaheadData[],
            typedCharacters: string,
            highlightedIndex: number,
            selectedItems: TypeaheadData[],
            maxVisibleEntries: number,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getItemProps: (options: any) => any,
            hideSelection: boolean,
            strictSearch: boolean,
            childProps?: Record<string, unknown>,
        ): Array<JSX.Element | ''> => {
            const typeAheadItems: TypeaheadData[] = getTypeAheadMatchingItems(typedCharacters, typeAheadData, selectedItems, maxVisibleEntries, strictSearch);
            return typeAheadItems.map((typeAheadItem, index) => {
                const currentChildProps = getItemProps({ item: typeAheadItem });
                const allChildProps = childProps ? 
                    { ...childProps, ...currentChildProps}
                    : currentChildProps;
        
                return renderChild(
                    typeAheadItem,
                    index,
                    allChildProps,
                    highlightedIndex,
                    selectedItems,
                    hideSelection
                );
            });
        });

    //#region events
    onKeyDownPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { typedCharacters, selectedItems } = this.state;
        if (selectedItems.length && !typedCharacters.length && event.key === 'Backspace') {
            this.setState({
                selectedItems: selectedItems.slice(0, selectedItems.length - 1),
            });
        }
    };
    
    onInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ typedCharacters: event.target.value });
    };
    

    onItemAdded = (item: TypeaheadData) => {
        const { selectedItems } = this.state;

        // If the item was already added we get out.
        if (selectedItems.indexOf(item) !== -1) {
            return;
        }
        const {
            data,
            selectedItemsIds
        } = this.props;
        const selectedItemsFromIds =
            selectedItemsIds && (selectedItemsIds.length > 0) ? 
                data.filter((value: TypeaheadData) => {
                    return (selectedItemsIds.indexOf(value.id) >= 0) &&
                            (getItemIndexFromTypeaheadDataArray(selectedItems, value) < 0);
                })
                : [];

        this.setState({
            typedCharacters: '',
            selectedItems : [...selectedItems, ...selectedItemsFromIds, item],
        });

        const { onItemAdded } = this.props;
        if (onItemAdded) {
            onItemAdded(item);
        }
    };
    
    onItemDeleted = (item: TypeaheadData) => () => {
        const selectedItems = [...this.state.selectedItems];
        selectedItems.splice(getItemIndexFromTypeaheadDataArray(selectedItems, item), 1);
        this.setState({
            selectedItems
        });

        const { onItemDelete } = this.props;
        if (onItemDelete) {
            onItemDelete(item);
        }
    };
    //#endregion

    itemToString = (item: TypeaheadData) => item.id;

    public render() {
        const {
            className,
            classes,
            data: typeAheadData,
            hideSelection,
            maxVisibleEntries,
            placeholder,
            title,
            label,
            strictSearch,
            selectedItemsIds
        } = this.props;
        const {
            typedCharacters,
            selectedItems: selectedItemsFromState
        } = this.state;
        
        const selectedItems =
            selectedItemsIds ?
                typeAheadData.filter((value: TypeaheadData) => {
                    return selectedItemsIds.indexOf(value.id) >= 0;
                })
                : selectedItemsFromState;

        return (
            <Downshift
                inputValue={typedCharacters}
                onChange={this.onItemAdded}
                selectedItem={selectedItems}
                itemToString={this.itemToString}
            >
                {({
                    getInputProps,
                    getItemProps,
                    isOpen,
                    inputValue,
                    selectedItem: downShiftSelectedItems,
                    highlightedIndex,
                    openMenu
                }) => (
                    <div className={`${classes.root}${className ? ` ${className}` : ''}`}>
                        {
                            renderInputField({
                                fullWidth: true,
                                classes,
                                InputProps: getInputProps({
                                    startAdornment: selectedItems.map(item => (
                                        <Chip
                                            key={item.id}
                                            className={classes.chip}
                                            classes={{
                                                label: classes.chipText,
                                                deleteIcon: classes.chipDeleteIcon
                                            }}
                                            label={item.name}
                                            tabIndex={-1}
                                            onDelete={this.onItemDeleted(item)}
                                        />
                                    )),
                                    onChange: this.onInputChanged,
                                    onKeyDown: this.onKeyDownPressed,
                                    onClick: openMenu,
                                    placeholder,
                                    disableUnderline: true
                                }) as Partial<MUIInputProps>,
                                title,
                                label,
                                disabled: (selectedItems.length === typeAheadData.length)
                            })
                        }
                        {isOpen ? 
                            <Paper
                                className={classes.paper}
                                square={true}
                            >
                                {
                                    this.renderTypeAheadChildren(
                                        typeAheadData,
                                        inputValue ? inputValue : '',
                                        (highlightedIndex !== null) ? highlightedIndex : -1,
                                        downShiftSelectedItems ? downShiftSelectedItems as TypeaheadData[] : [],
                                        maxVisibleEntries,
                                        getItemProps,
                                        hideSelection,
                                        strictSearch
                                    )
                                }
                            </Paper>
                            : null
                        }
                    </div>
                )}
            </Downshift>
        );
    }
}

const MUIComponent = withStyles(styles)(Typeahead);
export {MUIComponent as Typeahead};
