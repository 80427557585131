import * as React from 'react';

import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Theme } from '@mui/material/styles';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { RESPONSIVE_BREAKPOINTS } from '../../utils/mui-utils'; 
import {
    withMediaQuery,
    WithMediaQuery
} from '../with-media-query/with-media-query';

export enum ModalResult {
    Unknown = 0,
    Ok,
    Cancel
}

const styles = (theme: Theme) => createStyles({
    modalContainer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        '&:focus': {
            /*  Remove the focus indicator on mouse-focus for browsers
                that do support :focus-visible */
            outline: 'transparent'
        }
    },
    content: {
        padding: theme.spacing(1),
        flex: '1'
    },
    footer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    button: {
        margin: theme.spacing(1),
    },
    noScroll: {
        overflow: 'hidden'
    }
});

const TOP_DEFAULT = '50%';
const LEFT_DEFAULT = '50%';
const MODAL_CONSTANTS = {
    BUTTON_IDS: {
        OK: 'OkBtn',
        CANCEL: 'CancelBtn'
    }
};

interface Props extends WithMediaQuery<WithStyles<typeof styles>> {
    className?: string;
    open: boolean;
    top?: string;
    left?: string;
    width?: string;
    height?: string;
    header?: React.ReactChild,
    headerClassName?: string,
    footer?: React.ReactChild,
    contentClasses?: string,
    buttonOkLabel?: string;
    buttonCancelLabel?: string;
    onModalResult?: (result: ModalResult) => void;
    fullscreen?: boolean;
    children?: React.ReactNode;
}

class SimpleModal extends React.Component<Props> {
    public static defaultProps = {
        className: '',
        top: TOP_DEFAULT,
        left: LEFT_DEFAULT,
        buttonOkLabel: 'Ok',
        buttonCancelLabel: 'Cancel'
    };
    
    componentDidUpdate(prevProps: Props) {
        const {
            classes,
            open
        } = this.props;
        const { open: prevOpen } = prevProps;
        if (prevOpen !== open) {
            document.body.classList.toggle(classes.noScroll, open);
        }
    }

    componentWillUnmount() {
        const { classes } = this.props;
        document.body.classList.toggle(classes.noScroll, false);
    }

    getModalStyle(top: string, left: string, isFullscreen: boolean | undefined, width?:string, height?: string) {
        const style = {
            top: `${isFullscreen ? '0' : top}`,
            left: `${isFullscreen ? '0' : left}`,
        };

        if (isFullscreen) {
            Object.assign(style, { width: '100%', minHeight: '100%' });
        } else {
            if ((top === '50%') && (left === '50%') && !width && !height) {
                Object.assign(style, {
                    transform: `translate(-${top}, -${left})`
                });
            } else {
                if (width) {
                    Object.assign(style, { width });
                }
                if (height) {
                    Object.assign(style, { height });
                }
            }
        }

        return style;
    }

    generateFooter = () => {
        const {
            classes,
            footer,
            buttonOkLabel,
            buttonCancelLabel,
        } = this.props;

        // If the footer is defined we return it
        if (footer) {
            return (
                <div className={classes.footer}>
                    {footer}
                </div>
            );
        }

        // If no button text is specified we don't render the footer
        if ((!buttonOkLabel || buttonOkLabel.length < 1) &&
            (!buttonCancelLabel || buttonCancelLabel.length < 1)) {
            return '';
        }

        // Generates the default children
        return (
            <div className={classes.footer}>
                {
                    (buttonOkLabel && buttonOkLabel.length > 0) ?
                        <Button
                            id= {MODAL_CONSTANTS.BUTTON_IDS.OK}
                            className={classes.button}
                            size="small"
                            variant="contained"
                            onClick={this.onButtonClicked}
                        >
                            {buttonOkLabel}
                        </Button> : ''
                }
                {
                    (buttonCancelLabel && buttonCancelLabel.length > 0) ?
                        <Button
                            id= {MODAL_CONSTANTS.BUTTON_IDS.CANCEL}
                            className={classes.button}
                            size="small"
                            variant="outlined"
                            onClick={this.onButtonClicked}
                        >
                            {buttonCancelLabel}
                        </Button> : ''
                }
            </div>
        );
    };

    invokeModalResult = (modalResult: ModalResult) => {
        const { onModalResult } = this.props;
        if(onModalResult) {
            onModalResult(modalResult);
        }
    };

    onButtonClicked = (event: React.MouseEvent<HTMLElement>) => {
        const {currentTarget:button} = event;
        let result: ModalResult;

        switch (button.id) {
            case MODAL_CONSTANTS.BUTTON_IDS.OK:
                result = ModalResult.Ok;
                break;
            case MODAL_CONSTANTS.BUTTON_IDS.CANCEL:
                result = ModalResult.Cancel;
                break;
            default:
                result = ModalResult.Unknown;
                break;
        }

        this.invokeModalResult(result);
    };

    public render() {
        const { 
            children,
            className,
            classes,
            contentClasses,
            header,
            headerClassName,
            open,
            top: topProp,
            left: leftProp,
            width,
            height,
            fullscreen,
            mediaQuery
        } = this.props;

        const isFullscreen = fullscreen ? fullscreen : mediaQuery;
        const top = topProp ? topProp : TOP_DEFAULT;
        const left = leftProp ? leftProp : LEFT_DEFAULT;

        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={this.onButtonClicked}
            >
                <div 
                    style = {
                        this.getModalStyle(top, left, isFullscreen, width, height)}
                    className={`simple-modal-container ${classes.modalContainer} ${className ? className : ''}`}
                >
                    { header ?
                        <div className={headerClassName}>
                            {header}
                        </div>
                        : ''
                    }
                    <div className={contentClasses ? contentClasses : classes.content}>
                        {children}
                    </div>
                    { this.generateFooter() }
                </div>
            </Modal>
        );
    }
}

const MUIComponent = withStyles(styles)(withMediaQuery(`(max-width:${RESPONSIVE_BREAKPOINTS.iPhone8_Landscape}px)`)(SimpleModal));
export {MUIComponent as SimpleModal};
