import React, { FC, ReactElement } from 'react';

// import './css/sprite.css';
import imagesTypes from './images-types.json';

import svgSprite from './sprite.svg';
import { Theme } from '@mui/material/styles';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        width: '45px',
        height: '45px',
        marginBottom: 'auto'
    }
});

interface Props extends WithStyles<typeof styles> {
    name: string;
}

const SVGSprite: FC<Props> = (props: Props): ReactElement => {
    const {
        name,
        classes
    } = props;

    return (
        <img src={`${svgSprite}#${name}`} className={classes.root} alt={name}/>
    );
};

const MUIComponent = withStyles(styles)(SVGSprite);
export { MUIComponent as SVGSprite, imagesTypes };
