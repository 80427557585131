import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
import {
    NodesTreeView,
    RenderTree
} from '../../../shared/components/node-tree';
import RemoveIcon from '@mui/icons-material/Remove';
import { Typography } from '@mui/material';
import { EntityFloorplan } from '../../../shared/domain';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        height: '100%'
    },
    title: {
        marginTop: '1em',
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '0.2em',
        borderBottom: '1px solid'
    },
    treeView: {
        '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
            background: 'rgba(54, 159, 198, 0.3)'
        }
    },
    floorplanItem: {
        transform: 'scale(0.5)'
    }
});

interface Props {
    folderTreeData?: RenderTree;
    onFolderTreeClick?: (folderId: string) => Promise<RenderTree[]>;
    onSelectFloorplan?: (selectedFloorplan: EntityFloorplan | undefined) => void;
    onFolderTreeToggle?: (folderIds: string[]) => void;
    foldersClicked?: string[];
    foldersExpanded?: string[];
    isLoading: boolean;
    selectedFloorplan?: EntityFloorplan | undefined;
}

class AddReportWizardStepFolderTree extends React.Component<Props & WithStyles<typeof styles>> {
    public static defaultProps = {};

    updateNode = (parent: RenderTree, nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void): void => {
        const {
            onFolderTreeClick,
            onFolderTreeToggle,
            onSelectFloorplan,
            foldersClicked,
            foldersExpanded
        } = this.props;
        const { folderTreeData } = this.props;

        if (folderTreeData) {
            const isFirstChild = !foldersClicked?.length;
            const foldersClickedList = foldersClicked ? [...foldersClicked, folderTreeData.id] : [folderTreeData.id];
            const isUpdated = foldersClickedList.filter((id) => id === nodeId).length;
            const foldersExpandedList = foldersExpanded?.length ? foldersExpanded : foldersClickedList;

            if (isFirstChild || !isUpdated) {
                // Check if node is floorplan by prefix
                const floorplanPrefix = 'floorplan::';
                if (nodeId.includes(floorplanPrefix) && onSelectFloorplan) {
                    const selectedFloorplan = {
                        floorplan_id: nodeId.replace(floorplanPrefix, ''),
                        name: nodeLabel
                    } as EntityFloorplan;
                    onSelectFloorplan(selectedFloorplan);
                } else if (onFolderTreeClick && onFolderTreeToggle) {
                    // Check if is the first child to update the parent directly
                    if (isFirstChild) {
                        onFolderTreeClick(nodeId).then((result) => {
                            parent.children = result;
                            // Update and expand the new folders list
                            const newExpandedFolderList = [...foldersExpandedList];
                            updateCallback(newExpandedFolderList);
                            onFolderTreeToggle(newExpandedFolderList);
                        });
                    } else {
                        parent.children?.map((item: RenderTree) => {
                            // Loop into list and update the children that match
                            if (item.id === nodeId) {
                                onFolderTreeClick(nodeId).then((result) => {
                                    item.children = result;
                                    // Update and expand the new folders list
                                    const newExpandedFolderList = [...foldersExpandedList, nodeId];
                                    updateCallback(newExpandedFolderList);
                                    onFolderTreeToggle(newExpandedFolderList);
                                });
                            // eslint-disable-next-line no-prototype-builtins
                            } else if (item.hasOwnProperty('children')) {
                                // If no matches recursive into next children
                                this.updateNode(item, nodeId, nodeLabel, updateCallback);
                            }
                        });
                    }
                }
            }
        }
    };

    showIconCondition = (nodeId: string) => {
        return nodeId.includes('floorplan');
    };

    public render() {
        const {
            classes,
            isLoading,
            folderTreeData,
            foldersExpanded,
            onFolderTreeToggle
        } = this.props;

        return (
            <div className={classes.root}>
                <Typography
                    className={classes.title}
                    variant="h5"
                >
                    Select Floor
                </Typography>
                {folderTreeData ?
                    <NodesTreeView
                        className={classes.treeView}
                        treeData={folderTreeData}
                        onNodeSelected={this.updateNode}
                        isLoading={isLoading}
                        labelIcon={<RemoveIcon className={classes.floorplanItem}/>}
                        labelIconCondition={this.showIconCondition}
                        nodesExpanded={foldersExpanded}
                        onNodeToggled={onFolderTreeToggle}
                    />
                    :
                    'WARNING: There is no folders associated with this account'
                }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(AddReportWizardStepFolderTree);
export {MUIComponent as AddReportWizardStepFolderTree};
export type AddReportWizardStepFolderTreeProps = Props;
